import { approve3DPlanNotification } from "../components/notifications/approve_3d_plan_notification";
import { API_APPROVE_PLAN } from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from "./user_notification";

export function tryToApprovePlan(patient_id: string, case_id: string, steps: string) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.approve.ok",
    level: "success",
    position: "tc",
    autoDismiss: 10,
    children: approve3DPlanNotification(),
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.approve.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_APPROVE_PLAN(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        plan_id: parseInt(case_id),
        steps: parseInt(steps),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to approve 3D Plan!");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "POST_PLAN_APPROVED", json: json.patient });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}
