import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { requireOfferSign } from "~/require-offer-sign";

import { MeetingBookPage } from "./meeting-book-page";
import { MeetingDetailPage } from "./meeting-detail-page";
import { MeetingsEnrolledPage } from "./meetings-enrolled-page";
import { MeetingsLayout } from "./meetings-layout";
import { MeetingsPage } from "./meetings-page";

function MeetingsRoutes() {
  const isMeetingDetailRoute = Boolean(useRouteMatch("/pages/meetings/enrolled/:meeting_id"));

  return (
    <MeetingsLayout displayHeader={!isMeetingDetailRoute}>
      <Switch>
        <Route path="/pages/meetings/enrolled/:meeting_id" component={MeetingDetailPage} />
        <Route path="/pages/meetings/enrolled" component={MeetingsEnrolledPage} />
        <Route path="/pages/meetings/book/:expert_id" component={MeetingBookPage} />
        <Route path="/pages/meetings" component={MeetingsPage} />
      </Switch>
    </MeetingsLayout>
  );
}

export default requireOfferSign(MeetingsRoutes);
