import { createAction, createReducer } from "@reduxjs/toolkit";

import type { PaymentOptionId } from "~/common/constants";
import type { Case } from "~/common/courses";
import { remoteLog, shouldLogError } from "~/common/logging";
import { API_COURSE_INSTALLMENT_GET } from "~/config";
import type { AppDispatch } from "~/store";

type TCourseInstallment = Record<Case, Partial<Record<PaymentOptionId, number[]>>>;

const initialState: Partial<TCourseInstallment> = {};

const getCourseInstallmentAction = createAction<TCourseInstallment>(
  "GET_COURSE_INSTALLMENT_SUCCESS",
);

export const courseInstallment = createReducer(initialState, (builder) => {
  builder.addCase(getCourseInstallmentAction, (_, action) => action.payload);
});

export function getCourseInstallment() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_COURSE_INSTALLMENT_GET, { credentials: "include" });
      if (!response.ok) {
        throw new Error("Failed loading {GET_COURSE_INSTALLMENT_FAILURE}: " + response.statusText);
      }

      const json = await response.json();
      dispatch(getCourseInstallmentAction(json));
    } catch (err) {
      if (shouldLogError(err)) {
        remoteLog(err, "getCourseInstallment");
      }
    }
  };
}
