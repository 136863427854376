import React from "react";
import { FormattedMessage } from "react-intl";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";

import { notificationWasShownAction, sendInvoiceRequest } from "../../actions/notifications";
import exlamation from "../../assets/img/exclamation.svg";
import { useAppDispatch, useAppSelector } from "../../store";

export function paymentReminder() {
  return <PaymentReminder />;
}

function PaymentReminder() {
  const dispatch = useAppDispatch();

  const notifications = useAppSelector((state) => state.notifications);
  const shown = useAppSelector((state) => state.notificationWasShown);

  if (notifications.length == 0 || !shown) {
    return null;
  }

  function invoiceRequest() {
    dispatch(notificationWasShownAction(false));
    dispatch(sendInvoiceRequest());
  }

  function closeModal() {
    dispatch(notificationWasShownAction(false));
  }

  return (
    <div style={{ visibility: "visible", top: "50px", zIndex: "1000" }}>
      <Modal
        contentClassName="reminer-modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={shown}
        className="question-modal custom-question-modal"
        style={{
          overlay: {
            backgroundColor: "#787d7d7d",
            zIndex: "1000",
          },
          content: {
            outline: "none",
            border: "2px solid #32c5d2",
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <img src={exlamation} style={{ marginTop: "-40px", width: "80px" }} alt="" />

        <div
          className="modal-body"
          style={{
            maxWidth: "450px",
            margin: "0 auto",
            textAlign: "center",
            padding: "2px 20px 20px",
          }}
        >
          <button onClick={closeModal} className="close custom-close">
            <span aria-hidden="true">&times;</span>
          </button>

          <p>
            <FormattedMessage id="reminder.payment" />
          </p>

          <ul className="custom-list">
            <li style={{ marginBottom: "10px" }}>
              <FormattedMessage id="reminder.payment.card" />
              <NavLink
                to="/pages/payments"
                onClick={closeModal}
                style={{
                  color: "#32c5d2",
                  textDecoration: "underline",
                  marginLeft: "5px",
                  fontWeight: "900",
                }}
              >
                <FormattedMessage id="reminder.my.payments" />
              </NavLink>
              <FormattedMessage id="page" />
            </li>

            <li>
              <FormattedMessage id="reminder.payment.invoice" />
              <a
                onClick={invoiceRequest}
                style={{
                  color: "#32c5d2",
                  textDecoration: "underline",
                  marginLeft: "5px",
                  fontWeight: "900",
                }}
              >
                <FormattedMessage id="here" />
              </a>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}
