import React from "react";

import { API_MANUALS_GET_STATIC } from "../../config";
import img from "./default_thumbnail.png";
import type { TManual } from "./manuals_list";

const SOURCE_TYPE_FILE = 1;
const SOURCE_TYPE_URL = 2;

const cardBodyStyle = {
  display: "flex",
  width: "200px",
  backgroundColor: "#ffffff",
  allignItems: "center",
  textAlign: "center",
  borderStyle: "solid 0.5px",
  margin: "0px 10px 10px 10px",
  flexDirection: "column",
  marginBottom: "10px",
} as const;

const linkStyle = {
  textDecoration: "none",
};

const imgStyle = {
  width: "130px",
  height: "130px",
  objectFit: "cover",
} as const;

const textStyle = {
  marginTop: "5px",
  transition: "color 0.5s ease",
};

export default function ManualsCard({ manual }: { manual: TManual }) {
  const href =
    manual.source_type == SOURCE_TYPE_URL
      ? manual.url
      : API_MANUALS_GET_STATIC("document", manual.manual_id);

  return (
    <div className="card-body" id="manuals-card-body" style={cardBodyStyle}>
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        id="manuals-card-link"
        style={linkStyle}
      >
        <div className="pic" id="manuals-card-img">
          <img
            src={API_MANUALS_GET_STATIC("thumbnail", manual.manual_id)}
            style={imgStyle}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = img;
            }}
            alt=""
          />
        </div>
        <div className="text" id="manuals-card-text" style={textStyle}>
          {manual.title}
        </div>
      </a>
    </div>
  );
}
