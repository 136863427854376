import "react-loading-skeleton/dist/skeleton.css";

import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import { PlayIcon } from "@heroicons/react/24/solid";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { isMatching, match, P } from "ts-pattern";
import type { O } from "ts-toolbelt";

import { addUserActionNotification } from "~/actions/user_notification";
import { formatTime } from "~/common/formatters";
import { buildLocale } from "~/common/locale";
import { formatPrice } from "~/components/common/FormatNumber";
import { Button, type ButtonProps } from "~/components/ui/button";
import { ConfirmationDialog } from "~/components/ui/confirmation-dialog";
import { Img } from "~/components/ui/image";
import { LoadingButton } from "~/components/ui/loading-button";
import { DevToolsOnly } from "~/features/dev-tools/dev-tools-only";
import { useLoadingDelay } from "~/hooks/use-loading-delay";
import { useSearchParams } from "~/hooks/use-search-params";
import { EventMeetingType, PaymentStatus, type TEvent } from "~/reducers/events";
import { useAppDispatch } from "~/store";

import onlineIcon from "./online.svg";
import recordingFallbackImg from "./recording-fallback.jpg";

/**
 * The business asked to not display the date and time of this event. This event is "constant".
 */
const EXCEPTION_FOR_EVENT_ID = 41;

const API_EVENTS_IMAGE = (event_id: TEvent["id"], filename: string) =>
  `${process.env.API}/events/${event_id}/images/${filename}`;

// NOTE: px is needed for skeletons, without it there won't be a gap between them.
const buttonClassName = "tw-z-[1] tw-w-full";

export type EventCardProps = {
  meetingType?: EventMeetingType;
  displayPaymentStatus?: boolean;
  displayAvailableRecording?: boolean;
  displayAsRecording?: boolean;
  displayVideoLink?: boolean;
};

type EventCardComponentProps = Required<Pick<EventCardProps, "displayAsRecording">>;

export function EventCard({
  event,
  meetingType,
  displayPaymentStatus = true,
  displayAvailableRecording = false,
  displayAsRecording = false,
  displayVideoLink = false,
}: { event: TEvent | undefined } & EventCardProps) {
  const [searchParams] = useSearchParams();
  const modalEventId = parseInt(searchParams.get("event_id") ?? "");

  const isRecording = meetingType == EventMeetingType.RECORDING;
  // We need two different dialogs, because this way radix-ui will know which trigger to focus
  // after modal is closed. It is just a technicality. So we need two different states for them.
  // These dialogs have absolutely the same content.
  const [isFirstDialogOpen, setIsFirstDialogOpen] = useState(modalEventId == event?.id);
  const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);

  return (
    <CardRoot event={event}>
      <CardHeader meetingType={meetingType ?? event?.meeting_type}>
        <CardHeaderFirstRow
          event={event}
          isRecording={isRecording}
          displayAsRecording={displayAsRecording}
        />

        <CardHeaderSecondRow
          event={event}
          isRecording={isRecording}
          displayAsRecording={displayAsRecording}
        />

        <DevToolsOnly>
          {event ? (
            <div className="tw-absolute tw-left-1/2 -tw-translate-x-1/2">
              <span className="tw-text-2xl tw-font-semibold tw-text-white">{event.id}</span>
            </div>
          ) : null}
        </DevToolsOnly>
      </CardHeader>

      <div className="tw-flex tw-h-full tw-flex-col tw-gap-5 tw-px-5 tw-py-6">
        {displayPaymentStatus ? <PaymentStatusInfo event={event} /> : null}

        <Dialog.Root open={isFirstDialogOpen} onOpenChange={setIsFirstDialogOpen}>
          {isMatching(videoPattern, event) ? (
            <VideoRedirectDialog
              event={{ title: event.title, recording_links: event.recording_links }}
              onClose={() => setIsFirstDialogOpen(false)}
            />
          ) : null}

          {displayAvailableRecording ? <VideoAvailableInfo event={event} /> : null}

          <CardImage event={event} displayVideoLink={displayVideoLink} />
        </Dialog.Root>

        <h2
          id={event ? `event-card-${event.id}-title` : undefined}
          className="tw-m-0 tw-text-base tw-font-semibold"
        >
          {event ? event.title : <Skeleton containerClassName="tw-space-y-1" count={4} />}
        </h2>

        <div className="tw-mt-auto tw-flex tw-flex-col tw-gap-6">
          {event ? <CardInfo event={event} /> : null}

          <Dialog.Root open={isSecondDialogOpen} onOpenChange={setIsSecondDialogOpen}>
            {isMatching(videoPattern, event) ? (
              <VideoRedirectDialog
                event={{ title: event.title, recording_links: event.recording_links }}
                onClose={() => setIsSecondDialogOpen(false)}
              />
            ) : null}

            {isMatching(zoomPattern, event) ? (
              <ZoomRedirectDialog
                event={{ title: event.title, zoom_join_url: event.zoom_join_url }}
                onClose={() => setIsSecondDialogOpen(false)}
              />
            ) : null}

            <div className="tw-flex tw-gap-4">
              <MoreDetailsLink event={event} />
              <ActionLink event={event} displayAsRecording={displayAsRecording} />
            </div>
          </Dialog.Root>
        </div>
      </div>
    </CardRoot>
  );
}

const videoPattern = {
  enrolled: true,
  recording_links: P.array(),
};

const zoomPattern = {
  enrolled: true,
  payment: P.union(PaymentStatus.SUCCESS, null),
  meeting_type: EventMeetingType.ONLINE,
  zoom_join_url: P.string.minLength(1),
  end_date: P.when((t) => typeof t == "string" && new Date() < new Date(t)),
};

function CardRoot({ event, children }: { event: TEvent | undefined; children: React.ReactNode }) {
  return (
    <article
      className={clsx(
        "tw-relative tw-flex tw-flex-col tw-rounded-2xl tw-text-[#34495e]",
        "tw-no-underline tw-shadow-lg",
        { "tw-transition-transform hover:tw-scale-[1.03]": Boolean(event) },
      )}
    >
      {event ? (
        <Link
          id={`event-card-${event.id}`}
          aria-labelledby={`event-card-${event.id}-title`}
          className={clsx(
            "before:tw-absolute before:tw-inset-0 before:tw-ring-blue-600",
            "before:tw-rounded-2xl before:tw-content-['']",
            "before:focus-visible:tw-ring-2",
          )}
          to={`/pages/events/${event.id}`}
        >
          {null}
        </Link>
      ) : null}
      {children}
    </article>
  );
}

function CardHeader({
  meetingType,
  children,
}: {
  meetingType: EventMeetingType | undefined;
  children: React.ReactNode;
}) {
  const [baseColor, background] = match(meetingType)
    .with(EventMeetingType.OFFLINE, () => [
      "#14acb1",
      "linear-gradient(90deg, #008996 12.63%, #14acb1 110%)",
    ])
    .with(EventMeetingType.ONLINE, () => [
      "#3380ff",
      "linear-gradient(90deg, #61a5ff 0.39%, #3380ff 96.04%)",
    ])
    .with(EventMeetingType.RECORDING, () => [
      "#ff785b",
      "linear-gradient(90deg, #ffa38f 0.09%, #ff785b 99.95%)",
    ])
    .otherwise(() => ["#f0f0f0 ", "linear-gradient(90deg, #cccccc 10%, #f0f0f0 90%)"]);

  return (
    <div
      className={clsx(
        "tw-flex tw-rounded-t-2xl tw-p-6 tw-text-white",
        "tw-min-h-[102px] tw-flex-col tw-justify-center tw-gap-2",
      )}
      style={{ background }}
    >
      <SkeletonTheme baseColor={baseColor}>{children}</SkeletonTheme>
    </div>
  );
}

function CardHeaderFirstRow({
  event,
  isRecording,
  displayAsRecording,
}: { event: TEvent | undefined; isRecording: boolean } & EventCardComponentProps) {
  return match(event)
    .with({ id: EXCEPTION_FOR_EVENT_ID }, () => null)
    .with({ meeting_type: EventMeetingType.RECORDING, duration: P.number }, (evt) => (
      <div className="tw-flex tw-items-center tw-justify-between">
        <span className="tw-text-base">
          <FormattedMessage id="events.recording" />
        </span>

        <div className="tw-flex tw-items-center tw-gap-1.5">
          <ClockIcon />
          <span>{formatDuration(evt.duration)}</span>
        </div>
      </div>
    ))
    .with(
      {
        meeting_type: P.union(EventMeetingType.OFFLINE, EventMeetingType.ONLINE),
        duration: P.number,
        recording_links: P.array(),
      },
      (_) => displayAsRecording,
      (evt) => (
        <div className="tw-flex tw-items-center tw-justify-between">
          <span className="tw-text-base">
            <FormattedMessage id="events.recording" />
          </span>

          <div className="tw-flex tw-items-center tw-gap-1.5">
            <ClockIcon />
            <span>{formatDuration(evt.duration)}</span>
          </div>
        </div>
      ),
    )
    .with({ meeting_type: EventMeetingType.OFFLINE, city: P.string }, (evt) => (
      <span className="tw-text-base">{evt.city}</span>
    ))
    .with({ meeting_type: EventMeetingType.ONLINE }, () => (
      <div className="tw-flex tw-items-center tw-gap-1.5">
        <span className="tw-text-base">
          <FormattedMessage id="events.meeting_type.online" />
        </span>
        <img width={26} height={26} src={onlineIcon} alt="" />
      </div>
    ))
    .with(undefined, () =>
      isRecording ? (
        <>
          <Skeleton className="tw-rounded-full" width={100} height={26} />
          <Skeleton
            className="tw-rounded-full"
            containerClassName="tw-space-y-2"
            width={100}
            height={20}
          />
        </>
      ) : (
        <Skeleton className="tw-rounded-full" width={100} height={26} />
      ),
    )
    .otherwise(() => null);
}

function CardHeaderSecondRow({
  event,
  isRecording,
  displayAsRecording,
}: { event: TEvent | undefined; isRecording: boolean } & EventCardComponentProps) {
  return match(event)
    .with({ id: EXCEPTION_FOR_EVENT_ID }, () => null)
    .with(
      {
        meeting_type: P.union(EventMeetingType.OFFLINE, EventMeetingType.ONLINE),
        end_date: P.string,
      },
      (_) => !displayAsRecording,
      (evt) => (
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-1.5">
            <CalendarIcon />
            {formatStartEndDate(evt.start_date, evt.end_date)}
          </div>

          <div className="tw-flex tw-items-center tw-gap-1.5">
            <ClockIcon />
            {areDatesEqual(new Date(evt.start_date), new Date(evt.end_date))
              ? `${formatTime(evt.start_date)} - ${formatTime(evt.end_date)}`
              : formatTime(evt.start_date)}
          </div>
        </div>
      ),
    )
    .with(undefined, () =>
      isRecording ? null : (
        <div className="tw-flex tw-justify-between">
          <Skeleton className="tw-rounded-full" width={100} height={20} />
          <Skeleton
            className="tw-rounded-full"
            containerClassName="tw-space-y-2"
            width={100}
            height={20}
          />
        </div>
      ),
    )
    .otherwise(() => null);
}

function PaymentStatusInfo({ event }: { event: TEvent | undefined }) {
  return (
    <div>
      {match(event)
        .with(
          {
            payment: P.union(
              PaymentStatus.CREATED,
              PaymentStatus.PROCESSING,
              PaymentStatus.PENDING,
              PaymentStatus.DECLINED,
              PaymentStatus.CANCELED,
            ),
          },
          (evt) => <PaymentStatusBadge payment={evt.payment} />,
        )
        .with({}, () => (
          // NOTE: We render an invisible badge, so that every card has the same spacing at the top
          <span className="tw-invisible">
            <PaymentStatusBadge payment={PaymentStatus.CREATED} />
          </span>
        ))
        .with(undefined, () => <Skeleton className="tw-rounded-full" width={125} height={25} />)
        .otherwise(() => null)}
    </div>
  );
}

function PaymentStatusBadge({ payment }: { payment: Exclude<PaymentStatus, "SUCCESS"> }) {
  const backgroundColor = match(payment)
    .with(PaymentStatus.CREATED, () => "#2563eb")
    .with(PaymentStatus.PROCESSING, PaymentStatus.PENDING, () => "#e6bf40")
    .with(PaymentStatus.DECLINED, PaymentStatus.CANCELED, () => "#dc2626")
    .exhaustive();

  return (
    <CardBadge color={backgroundColor}>
      <FormattedMessage id={`event.registration.${payment}`} />
    </CardBadge>
  );
}

function VideoAvailableInfo({ event }: { event: TEvent | undefined }) {
  return (
    <div>
      {match(event)
        .with(
          { recording_links: P.array() },
          (evt) => evt.recording_links.length > 0,
          (evt) => <VideoAvailableBadge eventId={evt.id} />,
        )
        .with({}, () => (
          // NOTE: We render an invisible badge, so that every card has the same spacing at the top
          <span className="tw-invisible">
            <VideoAvailableBadge eventId={Math.random()} />
          </span>
        ))
        .with(undefined, () => <Skeleton className="tw-rounded-full" width={125} height={25} />)
        .otherwise(() => null)}
    </div>
  );
}

function VideoAvailableBadge({ eventId }: { eventId: TEvent["id"] }) {
  return (
    <Dialog.Trigger
      id={`event-card-${eventId}-video-available`}
      aria-labelledby={`event-card-${eventId}-video-available event-card-${eventId}-title`}
      className={clsx(
        "tw-rounded-full tw-border-0 tw-bg-transparent tw-p-0 tw-transition-opacity",
        "tw-relative tw-z-[1]",
        "focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600",
        "hover:tw-opacity-80 focus-visible:tw-ring-offset-1 active:tw-opacity-80",
      )}
    >
      <CardBadge color="#ff785b">
        <FormattedMessage id="events.video_available" />
        <span
          className={clsx(
            "tw-inline-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center",
            "tw-rounded-full tw-bg-white",
          )}
        >
          <ArrowUpRightIcon className="tw-h-3 tw-w-3 tw-fill-[#ff785b]" />
        </span>
      </CardBadge>
    </Dialog.Trigger>
  );
}

function CardBadge({
  children,
  color,
}: {
  children: React.ReactNode;
  color: React.CSSProperties["backgroundColor"];
}) {
  return (
    <span
      className={clsx(
        "tw-inline-flex tw-items-center tw-justify-center tw-gap-1.5 tw-rounded-full",
        "tw-px-2 tw-py-1 tw-text-center tw-text-xs tw-font-bold tw-text-white",
      )}
      style={{ backgroundColor: color }}
    >
      {children}
    </span>
  );
}

// FIXME: lazy loading of images. We should ideally only load images once they are in the viewport.
function CardImage({
  event,
  displayVideoLink,
}: { event: TEvent | undefined } & Required<Pick<EventCardProps, "displayVideoLink">>) {
  const imageClassName = clsx(
    "tw-max-h-56 tw-rounded-xl tw-border-2 tw-border-solid tw-border-gray-300",
    "tw-outline tw-outline-4 -tw-outline-offset-2 tw-outline-transparent",
    "tw-transition-all tw-duration-200",
    "group-hover:tw-outline-[#FE947E] group-active:tw-outline-[#FE947E]",
  );

  const loadingElement = <Skeleton className="tw-aspect-video tw-rounded-xl" />;

  const fallbackElement = (
    <Img
      errorFallback={null}
      loadingFallback={loadingElement}
      className={clsx(
        imageClassName,
        "tw-flex tw-aspect-video tw-w-full tw-items-center tw-justify-center",
      )}
      src={recordingFallbackImg}
      alt=""
    />
  );

  return match(event)
    .with({ image: { small: P.string.minLength(1) } }, (evt) => {
      const imageElement = (
        <Img
          errorFallback={fallbackElement}
          loadingFallback={loadingElement}
          className={clsx(imageClassName, "tw-h-full tw-w-full tw-object-cover")}
          src={API_EVENTS_IMAGE(evt.id, evt.image.small)}
          alt=""
        />
      );
      return displayVideoLink ? <VideoLink event={evt}>{imageElement}</VideoLink> : imageElement;
    })
    .with({}, (evt) => {
      return displayVideoLink ? (
        <VideoLink event={evt}>{fallbackElement}</VideoLink>
      ) : (
        fallbackElement
      );
    })
    .with(undefined, () => loadingElement)
    .otherwise(() => null);
}

function VideoLink({ event, children }: { event: TEvent; children: React.ReactNode }) {
  return Array.isArray(event.recording_links) ? (
    <Dialog.Trigger
      id={`event-card-${event.id}-video`}
      aria-labelledby={`event-card-${event.id}-video event-card-${event.id}-title`}
      className={clsx(
        "tw-group tw-relative tw-z-[1] tw-inline-block tw-rounded-xl tw-border-0 tw-p-0",
        "tw-bg-transparent focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600",
      )}
    >
      {children}

      <span className="tw-sr-only">
        <FormattedMessage id="events.action.watch" />
      </span>

      <span
        className={clsx(
          // z-index is needed, so that play icon appears above the loading skeleton
          "tw-absolute tw-left-1/2 tw-top-1/2 tw-z-[1] -tw-translate-x-1/2 -tw-translate-y-1/2",
          "tw-inline-flex tw-items-center tw-justify-center",
          "tw-h-10 tw-w-10 tw-rounded-full tw-bg-white sm:tw-h-12 sm:tw-w-12",
          "tw-transition-all hover:tw-scale-150 group-active:tw-scale-150",
        )}
      >
        <PlayIcon className="tw-relative tw-left-px tw-h-6 tw-w-6 tw-fill-[#ff785b] sm:tw-h-7 sm:tw-w-7 " />
      </span>
    </Dialog.Trigger>
  ) : (
    <>{children}</>
  );
}

export function VideoRedirectDialog({
  event,
  onClose,
}: {
  event: O.NonNullable<Pick<TEvent, "title" | "recording_links">>;
  onClose(): void;
}) {
  const firstLink = event.recording_links[0];
  if (!firstLink) {
    return null;
  }

  if (event.recording_links.length > 1) {
    return <MultipleVideoRedirectDialog event={event} onClose={onClose} />;
  }

  const service = firstLink.link.startsWith("https://vk.com") ? "VK Видео" : "YouTube";

  return (
    <RedirectDialog
      event={event}
      title={<FormattedMessage id="events.watch_modal.title" values={{ service }} />}
      description={<FormattedMessage id="events.watch_modal.description" values={{ service }} />}
      href={firstLink.link}
      onClose={onClose}
    />
  );
}

function MultipleVideoRedirectDialog({
  event,
  onClose,
}: {
  event: O.NonNullable<Pick<TEvent, "title" | "recording_links">>;
  onClose(): void;
}) {
  const firstLink = event.recording_links[0];
  if (!firstLink) {
    return null;
  }

  const service = firstLink.link.startsWith("https://vk.com") ? "VK Видео" : "YouTube";

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        className={clsx(
          // NOTE: tw-z-50 is not enough, page header has a higher z index
          "tw-fixed tw-inset-0 tw-z-[100] tw-bg-black/30 tw-backdrop-blur-sm",
          "data-[state=open]:tw-animate-in data-[state=open]:tw-fade-in-0",
          "data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0",
        )}
      />

      <Dialog.Content
        className={clsx(
          "tw-fixed tw-left-1/2 tw-top-1/2 tw-z-[100] -tw-translate-x-1/2 -tw-translate-y-1/2",
          "tw-w-full tw-border tw-bg-white tw-p-6 tw-shadow-lg tw-duration-200",
          "sm:tw-max-w-xl sm:tw-rounded-lg",
          "data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out",
          "data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0",
          // FIXME: these don't work for whatever reason
          "data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95",
          "data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=open]:tw-slide-in-from-left-1/2",
          "data-[state=closed]:tw-slide-out-to-top-1/2 data-[state=open]:tw-slide-in-from-top-1/2",
        )}
      >
        <div className="tw-space-y-6">
          <Dialog.Title className="tw-m-0 tw-text-xl tw-font-semibold">
            <FormattedMessage id="events.watch_modal.title" values={{ service }} />
          </Dialog.Title>

          <Dialog.Description className="tw-m-0 tw-space-y-2 tw-leading-relaxed">
            <span className="tw-block">
              {<FormattedMessage id="events.watch_modal.description" values={{ service }} />}
            </span>
            <span className="tw-block tw-font-semibold">{event.title}</span>
          </Dialog.Description>

          <ul
            role="list"
            className="tw-m-0 tw-list-none tw-space-y-2 tw-p-0 tw-leading-relaxed"
            style={{}}
          >
            {event.recording_links?.map((link) => (
              <li key={link.id}>
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
                  <span className="tw-block tw-font-semibold">{link.title}</span>

                  <Button
                    variant="primary"
                    rounded
                    className="tw-flex-1 tw-grow-0"
                    style={{ minWidth: "30%" }}
                    asChild
                  >
                    <a href={link.link} target="_blank" rel="noopener noreferrer" onClick={onClose}>
                      <FormattedMessage id="events.watch_modal.open" />
                    </a>
                  </Button>
                </div>
                <hr className="tw-mb-0 tw-mt-2 tw-w-full" />
              </li>
            ))}
          </ul>

          <div className="tw-flex tw-justify-center tw-gap-4">
            <Dialog.Close asChild>
              <Button variant="outline" rounded size="xl" style={{ flex: "0 1 50%" }}>
                <FormattedMessage id="events.watch_modal.cancel" />
              </Button>
            </Dialog.Close>
          </div>
        </div>

        <Dialog.Close
          className={clsx(
            "tw-absolute tw-right-4 tw-top-4 tw-rounded-sm",
            "tw-opacity-70 tw-transition-opacity hover:tw-opacity-100",
            "tw-h-5 tw-w-5 tw-border-0 tw-bg-transparent tw-p-0",
          )}
        >
          <Cross2Icon className="tw-h-5 tw-w-5" />
          <span className="tw-sr-only">
            <FormattedMessage id="alert.close" />
          </span>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  );
}

export function ZoomRedirectDialog({
  event,
  onClose,
}: {
  event: O.NonNullable<Pick<TEvent, "title" | "zoom_join_url">>;
  onClose(): void;
}) {
  return (
    <RedirectDialog
      event={event}
      title={<FormattedMessage id="events.zoom_modal.title" />}
      description={<FormattedMessage id="events.zoom_modal.description" />}
      href={event.zoom_join_url}
      onClose={onClose}
    />
  );
}

function RedirectDialog({
  event,
  title,
  description,
  href,
  onClose,
}: {
  event: Pick<TEvent, "title">;
  title: React.ReactElement;
  description: React.ReactElement;
  href: string;
  onClose(): void;
}) {
  return (
    <ConfirmationDialog.Content
      title={title}
      description={
        <>
          <span className="tw-block">{description}</span>
          <span className="tw-block tw-font-semibold">{event.title}</span>
        </>
      }
      actionProps={{
        asChild: true,
        children: (
          <a href={href} target="_blank" rel="noopener noreferrer" onClick={onClose}>
            <FormattedMessage id="events.watch_modal.open" />
          </a>
        ),
      }}
    />
  );
}

function CardInfo({ event }: { event: TEvent }) {
  return (
    <div className="tw-flex tw-justify-between tw-text-[#b0bfc7]">
      {event.price != null ? (
        <div>
          <FormattedMessage id="events.price" />: <br />
          <span className="tw-text-base">
            {formatPrice(event.price)} <FormattedMessage id="events.currency" />
          </span>
        </div>
      ) : (
        <div>
          <FormattedMessage id="events.price" />: <br />
          <span className="tw-text-base">
            <FormattedMessage id="events.free" />
          </span>
        </div>
      )}
    </div>
  );
}

function MoreDetailsLink({ event }: { event: TEvent | undefined }) {
  return event ? (
    <Button variant="outline" rounded size="xl" className={buttonClassName} asChild>
      <Link
        id={`event-card-${event.id}-more-details`}
        aria-labelledby={`event-card-${event.id}-more-details event-card-${event.id}-title`}
        to={`/pages/events/${event.id}`}
      >
        <FormattedMessage id="events.more_details" />
      </Link>
    </Button>
  ) : (
    <Skeleton containerClassName="tw-w-full" className="tw-rounded-md" height={35} />
  );
}

function ActionLink({
  event,
  displayAsRecording,
}: { event: TEvent | undefined } & EventCardComponentProps) {
  const buttonProps = (event: TEvent): ButtonProps => ({
    id: `event-card-${event.id}-action`,
    "aria-labelledby": `event-card-${event.id}-action event-card-${event.id}-title`,
    className: buttonClassName,
    variant: "primary",
    rounded: true,
    size: "xl",
  });

  return match(event)
    .with(
      {
        enrolled: true,
        end_date: P.when((t) => t != null && new Date(t) < new Date()),
        feedback: false,
      },
      (_) => !displayAsRecording,
      (evt) => (
        <Button {...buttonProps(evt)} asChild>
          <Link to={`/pages/events/${evt.id}`}>
            {/* WARN: From 640px to 710px and 990px - 1180px the text overflows the card. */}
            <FormattedMessage id="events.action.feedback" />
          </Link>
        </Button>
      ),
    )
    .with(
      videoPattern,
      (_) => displayAsRecording,
      (evt) => (
        <Dialog.Trigger asChild>
          <Button {...buttonProps(evt)} disabled={evt.recording_links[0]?.link.length == 0}>
            <FormattedMessage id="events.action.watch" />
          </Button>
        </Dialog.Trigger>
      ),
    )
    .with({ enrolled: false, available_seats: 0 }, (evt) => (
      <Button {...buttonProps(evt)} disabled>
        <FormattedMessage id="events.registration.completed" />
      </Button>
    ))
    .with({ enrolled: false, meeting_type: EventMeetingType.RECORDING }, (evt) => (
      <Button {...buttonProps(evt)} asChild>
        <Link to={`/pages/events/${evt.id}`}>
          <FormattedMessage id={evt.price ? "events.action.buy" : "events.action.get"} />
        </Link>
      </Button>
    ))
    .with({ enrolled: false }, (evt) => (
      <Button {...buttonProps(evt)} asChild>
        <Link to={`/pages/events/${evt.id}`}>
          <FormattedMessage id="events.action.signup" />
        </Link>
      </Button>
    ))
    .with(
      { enrolled: true, payment: P.union(PaymentStatus.PENDING, PaymentStatus.DECLINED) },
      (evt) => <EventPayButton {...buttonProps(evt)} eventId={evt.id} />,
    )
    .with(zoomPattern, (evt) => (
      <Dialog.Trigger asChild>
        <Button {...buttonProps(evt)}>
          <FormattedMessage id="events.action.zoom_join" />
        </Button>
      </Dialog.Trigger>
    ))
    .with({ enrolled: true, start_date: P.when((t) => new Date(t) > new Date()) }, (evt) => (
      <Button {...buttonProps(evt)} disabled>
        <FormattedMessage id="event.registration.enrolled" />
      </Button>
    ))
    .with(undefined, () => <Skeleton containerClassName="tw-w-full" className="tw-rounded-md" height={35} />)
    .otherwise(() => null);
}

export function EventPayButton({ eventId, ...props }: ButtonProps & { eventId: TEvent["id"] }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const displayLoading = useLoadingDelay(isSubmitting);
  const dispatch = useAppDispatch();

  const controllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  async function handleClick() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      controllerRef.current = new AbortController();
      const response = await fetch(`${process.env.API}/events/payments/link/${eventId}`, {
        method: "POST",
        credentials: "include",
        signal: controllerRef.current.signal,
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText} - ${JSON.stringify(data)}`);
      }

      if (!isMatching({ href: P.string }, data)) {
        throw new Error("unexpected json data received from events payments link endpoint");
      }

      // TODO: In safari if you go back in history after being redirected to the payment page,
      // the spinner will keep on spinning.
      window.location.href = data.href;
    } catch (err) {
      if (err instanceof Error && err.name == "AbortError") {
        // user aborted the request by navigating away from the page, do nothing
      } else {
        console.error(err);
        dispatch(
          addUserActionNotification({
            level: "error",
            position: "tl",
            autoDismiss: 4,
            message: "events.action.pay.error",
          }),
        );
      }
      setIsSubmitting(false);
    }
  }

  return (
    <LoadingButton
      {...props}
      // `min-width: 100px` is set because with english translations the button is too thin
      className={clsx(props.className, "tw-min-w-[100px]")}
      onClick={handleClick}
      isLoading={displayLoading}
    >
      <FormattedMessage id="events.action.pay" />
    </LoadingButton>
  );
}

function CalendarIcon() {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.35 1.2H8.8V0.6C8.8 0.24 8.58 0 8.25 0C7.92 0 7.7 0.24 7.7 0.6V1.2H3.3V0.6C3.3 0.24 3.08 0 2.75 0C2.42 0 2.2 0.24 2.2 0.6V1.2H1.65C0.715 1.2 0 1.98 0 3V3.6H11V3C11 1.98 10.285 1.2 9.35 1.2ZM0 10.2C0 11.22 0.715 12 1.65 12H9.35C10.285 12 11 11.22 11 10.2V4.8H0V10.2ZM8.25 6C8.58 6 8.8 6.24 8.8 6.6C8.8 6.96 8.58 7.2 8.25 7.2C7.92 7.2 7.7 6.96 7.7 6.6C7.7 6.24 7.92 6 8.25 6ZM8.25 8.4C8.58 8.4 8.8 8.64 8.8 9C8.8 9.36 8.58 9.6 8.25 9.6C7.92 9.6 7.7 9.36 7.7 9C7.7 8.64 7.92 8.4 8.25 8.4ZM5.5 6C5.83 6 6.05 6.24 6.05 6.6C6.05 6.96 5.83 7.2 5.5 7.2C5.17 7.2 4.95 6.96 4.95 6.6C4.95 6.24 5.17 6 5.5 6ZM5.5 8.4C5.83 8.4 6.05 8.64 6.05 9C6.05 9.36 5.83 9.6 5.5 9.6C5.17 9.6 4.95 9.36 4.95 9C4.95 8.64 5.17 8.4 5.5 8.4ZM2.75 6C3.08 6 3.3 6.24 3.3 6.6C3.3 6.96 3.08 7.2 2.75 7.2C2.42 7.2 2.2 6.96 2.2 6.6C2.2 6.24 2.42 6 2.75 6ZM2.75 8.4C3.08 8.4 3.3 8.64 3.3 9C3.3 9.36 3.08 9.6 2.75 9.6C2.42 9.6 2.2 9.36 2.2 9C2.2 8.64 2.42 8.4 2.75 8.4Z"
        fill="white"
      />
    </svg>
  );
}

function ClockIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C9.3138 12 12 9.3138 12 6C12 2.6862 9.3138 0 6 0C2.6862 0 0 2.6862 0 6C0 9.3138 2.6862 12 6 12ZM6.6 3C6.6 2.84087 6.53679 2.68826 6.42426 2.57574C6.31174 2.46321 6.15913 2.4 6 2.4C5.84087 2.4 5.68826 2.46321 5.57574 2.57574C5.46321 2.68826 5.4 2.84087 5.4 3V5.7516C5.40007 6.06983 5.52654 6.37501 5.7516 6.6L7.3758 8.2242C7.48896 8.3335 7.64052 8.39397 7.79784 8.3926C7.95516 8.39124 8.10565 8.32814 8.21689 8.21689C8.32814 8.10565 8.39124 7.95516 8.3926 7.79784C8.39397 7.64052 8.3335 7.48896 8.2242 7.3758L6.6 5.7516V3Z"
        fill="white"
      />
    </svg>
  );
}

function areDatesEqual(date1: Date, date2: Date) {
  return (
    date1.getFullYear() == date2.getFullYear() &&
    date1.getMonth() == date2.getMonth() &&
    date1.getDate() == date2.getDate()
  );
}

function formatStartEndDate(startDateStr: string, endDateStr: string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const isSameDate = startDate.getDate() == endDate.getDate();
  const isSameMonth = startDate.getMonth() == endDate.getMonth();
  const isSameYear = startDate.getFullYear() == endDate.getFullYear();

  const includeYear = startDate.getFullYear() != new Date().getFullYear();

  // day month [year]
  if (isSameDate && isSameMonth && isSameYear) {
    let startStr = startDate.toLocaleDateString(buildLocale, {
      year: includeYear ? "numeric" : undefined,
      day: "numeric",
      month: "long",
    });
    if (includeYear && buildLocale == "ru-RU") {
      startStr = startStr.slice(0, -2); // strip "г."
    }
    return startStr;
  }

  // day - day month [year]
  if (isSameMonth && isSameYear) {
    let startStr = startDate
      .toLocaleDateString(buildLocale, {
        year: includeYear ? "numeric" : undefined,
        month: "long",
        day: "2-digit", // We include the day to preserve the correct ending of the month in russian
      })
      .slice(2); // We strip off the day part afterwards
    if (includeYear && buildLocale == "ru-RU") {
      startStr = startStr.slice(0, -2); // strip "г."
    }
    return `${startDate.getDate()} - ${endDate.getDate()} ${startStr}`;
  }

  // day month - day month [year]
  if (isSameYear) {
    const startStr = startDate.toLocaleDateString(buildLocale, {
      month: "long",
      day: "numeric",
    });
    let endStr = endDate.toLocaleDateString(buildLocale, {
      year: includeYear ? "numeric" : undefined,
      month: "long",
      day: "numeric",
    });
    if (includeYear && buildLocale == "ru-RU") {
      endStr = endStr.slice(0, -2); // strip "г."
    }
    return `${startStr} - ${endStr}`;
  }

  // day month year - day month year
  let startStr = startDate.toLocaleDateString(buildLocale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let endStr = endDate.toLocaleDateString(buildLocale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  if (buildLocale == "ru-RU") {
    startStr = startStr.slice(0, -2); // strip "г."
    endStr = endStr.slice(0, -2); // strip "г."
  }
  return `${startStr} - ${endStr}`;
}

function formatDuration(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const minutesText = match(buildLocale)
    .with("en-GB", "en-US", () => getEnglishMinuteText(remainingMinutes))
    .with("ru-RU", () => getRussianMinuteText(remainingMinutes))
    .exhaustive();

  const hoursText = match(buildLocale)
    .with("en-GB", "en-US", () => getEnglishHourText(hours))
    .with("ru-RU", () => getRussianHourText(hours))
    .exhaustive();

  if (hours == 0) {
    return `${remainingMinutes} ${minutesText}`;
  }
  if (remainingMinutes == 0) {
    return `${hours} ${hoursText}`;
  }
  return `${hours} ${hoursText} ${remainingMinutes} ${minutesText}`;
}

function getRussianHourText(n: number) {
  // if number is 1 or ends in 1 but is not in the teens
  if (n == 1 || (n % 10 == 1 && n % 100 != 11)) {
    return "час";
  }
  // if number is 2-4 or ends in 2-4 but not in the teens
  if ((n >= 2 && n <= 4) || (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20))) {
    return "часа";
  }
  return "часов";
}

function getRussianMinuteText(n: number) {
  // if number is 1 or ends in 1 but is not in the teens
  if (n == 1 || (n % 10 == 1 && n % 100 != 11)) {
    return "минута";
  }
  // if number is 2-4 or ends in 2-4 but not in the teens
  if ((n >= 2 && n <= 4) || (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20))) {
    return "минуты";
  }
  return "минут";
}

function getEnglishHourText(n: number) {
  return n == 1 ? "hour" : "hours";
}

function getEnglishMinuteText(n: number) {
  return n == 1 ? "minute" : "minutes";
}
