import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React from "react";
import { FormattedMessage, type MessageDescriptor, useIntl } from "react-intl";

import { Button } from "~/components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "~/components/ui/select";
import { EventMeetingType } from "~/reducers/events";

import type { TEventCity } from "./events-api";
import { EventsZIndex, PricingType, TrainingLevel } from "./events-const";

type EventsFiltersProps = { isOpen: boolean; onReset(): void } & React.ComponentProps<
  typeof MeetingTypeSelect
> &
  React.ComponentProps<typeof TrainingLevelSelect> &
  React.ComponentProps<typeof CitySelect> &
  React.ComponentProps<typeof PricingSelect>;

export function EventsFilters({ isOpen, onReset, ...props }: EventsFiltersProps) {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0, opacity: 0 },
    animate: { height: "auto", opacity: 1 },
    exit: { height: 0, opacity: 0 },
    transition: { duration: 0.3 },
  };

  return (
    <AnimatePresence initial={false}>
      {isOpen ? (
        <motion.div {...(shouldReduceMotion == true ? {} : animationProps)}>
          <div className="tw-flex tw-flex-col tw-gap-3 tw-px-2 tw-pb-5 tw-pt-1 md:tw-flex-row">
            <MeetingTypeSelect
              meetingType={props.meetingType}
              onMeetingTypeChange={props.onMeetingTypeChange}
            />
            <TrainingLevelSelect
              trainingLevel={props.trainingLevel}
              onTrainingLevelChange={props.onTrainingLevelChange}
            />
            <CitySelect
              cities={props.cities}
              cityId={props.cityId}
              onCityIdChange={props.onCityIdChange}
            />
            <PricingSelect
              pricingType={props.pricingType}
              onPricingTypeChange={props.onPricingTypeChange}
            />
            <Button type="button" rounded variant="secondary" onClick={onReset}>
              <FormattedMessage id="events.filters.reset" />
            </Button>
          </div>
          <hr className="tw-my-0 tw-pb-4" />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

type SelectOption<T extends string> = { label: MessageDescriptor["id"]; value: T };

function MeetingTypeSelect({
  meetingType,
  onMeetingTypeChange,
}: {
  meetingType?: EventMeetingType;
  onMeetingTypeChange(newMeetingType?: EventMeetingType): void;
}) {
  const ALL = "all";
  const options: SelectOption<EventMeetingType | typeof ALL>[] = [
    { label: "events.all", value: ALL },
    { label: "events.meeting_type.offline", value: EventMeetingType.OFFLINE },
    { label: "events.meeting_type.online", value: EventMeetingType.ONLINE },
    { label: "events.meeting_type.recording", value: EventMeetingType.RECORDING },
  ];
  const selectedOption = options.find((option) => option.value == meetingType);

  return (
    <Select
      value={meetingType ?? ALL}
      onValueChange={(newMeetingType) =>
        onMeetingTypeChange(newMeetingType == ALL ? undefined : (newMeetingType as EventMeetingType))
      }
    >
      <SelectTrigger className={meetingType == undefined ? "tw-text-gray-400" : "tw-text-gray-500"}>
        <FormattedMessage
          id={meetingType == undefined ? "events.meeting_type" : selectedOption?.label}
        />
      </SelectTrigger>

      <SelectContent style={{ zIndex: EventsZIndex.PRICING_SELECT }}>
        <SelectGroup>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              <FormattedMessage id={option.label} />
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function TrainingLevelSelect({
  trainingLevel,
  onTrainingLevelChange,
}: {
  trainingLevel?: TrainingLevel;
  onTrainingLevelChange(newTrainingLevel?: TrainingLevel): void;
}) {
  const ALL = "all";
  const options: SelectOption<TrainingLevel | typeof ALL>[] = [
    { label: "events.all", value: ALL },
    { label: "events.training_level.0", value: TrainingLevel.BEGINNER },
    { label: "events.training_level.1", value: TrainingLevel.BASE },
    { label: "events.training_level.2", value: TrainingLevel.ADVANCED },
  ];
  const selectedOption = options.find((option) => option.value == trainingLevel);

  return (
    <Select
      value={trainingLevel ?? ALL}
      onValueChange={(newTrainingLevel) =>
        onTrainingLevelChange(
          newTrainingLevel == ALL ? undefined : (newTrainingLevel as TrainingLevel),
        )
      }
    >
      <SelectTrigger
        className={trainingLevel == undefined ? "tw-text-gray-400" : "tw-text-gray-500"}
      >
        <FormattedMessage
          id={trainingLevel == undefined ? "events.training_level" : selectedOption?.label}
        />
      </SelectTrigger>

      <SelectContent style={{ zIndex: EventsZIndex.PRICING_SELECT }}>
        <SelectGroup>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              <FormattedMessage id={option.label} />
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function CitySelect({
  cities,
  cityId,
  onCityIdChange,
}: {
  cities: TEventCity[];
  cityId?: TEventCity["city_id"];
  onCityIdChange(newCityId?: TEventCity["city_id"]): void;
}) {
  const intl = useIntl();

  const CITY_ID_ALL = -1;
  const selectedCity = cities.find((city) => city.city_id == cityId);
  cities = [{ city_id: CITY_ID_ALL, title: intl.formatMessage({ id: "events.all" }) }, ...cities];

  return (
    <Select
      value={`${cityId ?? CITY_ID_ALL}`}
      onValueChange={(newCityId) =>
        onCityIdChange(Number(newCityId) == CITY_ID_ALL ? undefined : Number(newCityId))
      }
    >
      <SelectTrigger className={selectedCity ? "tw-text-gray-500" : "tw-text-gray-400"}>
        {selectedCity ? selectedCity.title : <FormattedMessage id="events.city" />}
      </SelectTrigger>

      <SelectContent style={{ zIndex: EventsZIndex.PRICING_SELECT }}>
        <SelectGroup>
          {cities.map((city) => (
            <SelectItem key={city.city_id} value={`${city.city_id}`}>
              {city.title}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

// FIXME: selecting an option on mobile screen activates the tab under that option
function PricingSelect({
  pricingType,
  onPricingTypeChange,
}: {
  pricingType?: PricingType;
  onPricingTypeChange(newPricingType?: PricingType): void;
}) {
  const intl = useIntl();

  const ALL = "all";
  const options: SelectOption<PricingType | typeof ALL>[] = [
    { label: "events.all", value: ALL },
    { label: "events.price.free", value: PricingType.FREE },
    { label: "events.price.paid", value: PricingType.PAID },
  ];
  const selectedOption = options.find((option) => option.value == pricingType);

  return (
    <Select
      value={pricingType ?? ALL}
      onValueChange={(newPricingType) =>
        onPricingTypeChange(newPricingType == ALL ? undefined : (newPricingType as PricingType))
      }
    >
      <SelectTrigger
        aria-label={intl.formatMessage({ id: "events.price" })}
        className={pricingType == undefined ? "tw-text-gray-400" : "tw-text-gray-500"}
      >
        <FormattedMessage id={pricingType == undefined ? "events.price" : selectedOption?.label} />
      </SelectTrigger>

      <SelectContent style={{ zIndex: EventsZIndex.PRICING_SELECT }}>
        <SelectGroup>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              <FormattedMessage id={option.label} />
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
