import clsx from "clsx";
import * as React from "react";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  rounded?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function Input({ className, rounded, ...props }, forwardedRef) {
    return (
      <input
        className={clsx(
          "tw-block tw-w-full tw-bg-white tw-px-3 tw-py-1.5",
          // NOTE: Using font-size less than 16px, zooms the input in on ios and doesn't zoom back after blur.
          "tw-text-base tw-text-[#4d6b8a] sm:tw-text-sm",
          "tw-border tw-border-solid tw-border-[#c2cad8]",
          "tw-outline-none tw-ring-blue-600 focus-visible:tw-ring-1",
          "tw-transition-all",
          "placeholder:tw-text-gray-400",
          { "tw-rounded-md": rounded },
          className,
        )}
        ref={forwardedRef}
        {...props}
      />
    );
  },
);
