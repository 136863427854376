import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { sendComment } from "~/actions/comments";
import { getPatientId } from "~/actions/get_patient_id";
import { tryToReworkPlan } from "~/actions/rework_plan";
import { CommentType } from "~/common/constants";
import { Status } from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { Button } from "~/components/ui/button";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
  };
};

const mapDispatchToProps = {
  getPatient: getPatientId,
  planRework: tryToReworkPlan,
  sendComment,
};

type PlanReworkProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
    patient_id: string;
    case_id: string;
  }>;

type PlanReworkState = {
  case_id: string | null;
  patient_id: string | null;
  canSubmit: boolean;
  showLoader: boolean;
  loading: boolean;
  comment: string | null;
};

class PlanRework extends Component<PlanReworkProps, PlanReworkState> {
  constructor(props: PlanReworkProps) {
    super(props);
    this.state = {
      case_id: null,
      patient_id: null,
      canSubmit: false,
      showLoader: false,
      loading: true,
      comment: null,
    };
    this.planRework = this.planRework.bind(this);
    this.setComment = this.setComment.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_rework_page");
  }

  componentDidMount() {
    const { case_id, patient_id } = this.props.match.params;
    this.setState({ case_id, patient_id });
    void this.props.getPatient(patient_id);
    setDocumentTitle(this.props.intl.formatMessage({ id: "plan.rework.header" }) + " " + patient_id)
  }

  UNSAFE_componentWillReceiveProps(nextProps: PlanReworkProps) {
    const { patient_id } = this.state;
    if (
      nextProps &&
      nextProps.patient &&
      Object.keys(nextProps).length &&
      String(nextProps.patient.patient_id) === String(patient_id)
    ) {
      if (nextProps.patient.status != Status._3D_PLAN_APPROVAL) {
        this.props.history.push("/pages/patients");
      } else {
        this.setState({ loading: false });
      }
    }
  }

  setComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const comment = e.target.value.trim();
    const canSubmit = comment !== undefined && comment !== null && comment !== "";
    this.setState({ comment: e.target.value, canSubmit });
  }

  planRework() {
    const { comment } = this.state;
    this.setState({ canSubmit: false });
    this.setState({ showLoader: true });

    /**
     * If planRework method fails so at least we have commentary from Doctor.
     * sendComment method must not depend on response status of planRework method.
     */
    this.props.planRework(this.state.patient_id, this.state.case_id);
    this.props.sendComment(this.state.patient_id, comment, CommentType.REWORK, this.state.case_id);
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <Layout>
          <div className="row">
            <div className="col-md-8">
              <Loader />
            </div>
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        <div className="row">
          <div className="col-md-8">
            <Portlet as="main">
              <PortletTitle iconClassName="icon-book-open">
                <FormattedMessage id="plan.rework.header" />
              </PortletTitle>
              <div className="portlet-body">
                <div>
                  <h4>
                    <FormattedMessage id="plan.rework.body" /> {this.state.case_id}
                  </h4>
                  <br />
                  <br />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="comment">
                    <strong>
                      <FormattedMessage id="pat.comment.rework.header" />
                    </strong>
                  </label>
                  <textarea
                    style={{ minHeight: "350px", resize: "vertical" }}
                    className="form-control"
                    id="comment"
                    rows={5}
                    name="comment"
                    defaultValue=""
                    onChange={(e) => this.setComment(e)}
                  />
                </div>
                <div>
                  <Button
                    id="test-btn"
                    disabled={!this.state.canSubmit}
                    onClick={() => this.planRework()}
                  >
                    <FormattedMessage id="plan.rework.btn" />
                  </Button>
                  {this.state.showLoader ? <Loader /> : null}
                </div>
              </div>
            </Portlet>
          </div>
        </div>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanRework));
