import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { PaymentStatusMessage } from "~/components/common/payment-status-message";
import { Button } from "~/components/ui/button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { useDocumentTitle } from "~/hooks/use-document-title";

import { EventPayButton } from "./event-card";
import { useGetEventPaymentStatusQuery } from "./events-api";

type RouteParams = {
  event_id: string;
  transaction_id: string;
};

export default function EventPaymentStatusPage() {
  useDocumentTitle(useIntl().formatMessage({ id: "pat.payments.online.payment" }));
  const { event_id, transaction_id } = useParams<RouteParams>();

  const invoiceQuery = useGetEventPaymentStatusQuery({ transactionId: transaction_id });
  const invoiceStatus = invoiceQuery.data?.status;

  return (
    <Portlet as="main" className="tw-h-full">
      <PortletTitle iconClassName="icon-book-open">
        <FormattedMessage id="pat.payments.online.payment" />
      </PortletTitle>

      <div className="portlet-body tw-space-y-4 max-desktop:tw-text-center">
        <PaymentStatusMessage query={invoiceQuery} />

        {invoiceStatus == "succeeded" ? (
          <Button asChild>
            <Link to={`/pages/events/${event_id}`}>
              <FormattedMessage id="events.payment_status.goto" />
            </Link>
          </Button>
        ) : null}

        {invoiceStatus == "pending" ? <EventPayButton eventId={Number(event_id)} /> : null}
      </div>
    </Portlet>
  );
}
