import React from "react";
import { useParams } from "react-router-dom";

import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";
import { useLoadingDelay } from "~/hooks/use-loading-delay";

import { CabinetWidget, CabinetWidgetLoader } from "./cabinet-widget";
import { useGetMeetingsExpertsQuery } from "./meetings-api";
import { MeetingsEmptyFallback, MeetingsErrorFallback } from "./meetings-shared";

export function MeetingBookPage() {
  useIntlDocumentTitle("meetings.book.title");
  const { expert_id } = useParams<{ expert_id: string }>();
  const expertQuery = useGetMeetingsExpertsQuery({ expert_id });

  return (
    <RemoteLogErrorBoundary
      component="meeting_book_page"
      fallbackRender={MeetingsErrorFallback}
      onReset={expertQuery.refetch}
    >
      <MeetingBookPageContent />
    </RemoteLogErrorBoundary>
  );
}

function MeetingBookPageContent() {
  const { expert_id } = useParams<{ expert_id: string }>();
  const expertQuery = useGetMeetingsExpertsQuery({ expert_id });
  const isLoadingMinDuration = useLoadingDelay(expertQuery.isLoading, { delay: 100 });
  const className = "tw-rounded-3xl tw-bg-white tw-py-4 tw-shadow-sm";

  if (expertQuery.isUninitialized || expertQuery.isLoading || isLoadingMinDuration) {
    return (
      <div className={className}>
        <CabinetWidgetLoader />
      </div>
    );
  }

  if (expertQuery.isError) {
    return (
      <MeetingsErrorFallback title="meetings.book.error" resetErrorBoundary={expertQuery.refetch} />
    );
  }

  const expert = expertQuery.data[0];

  if (!expert) {
    return (
      <MeetingsEmptyFallback
        title="meetings.book.not_found"
        description="meetings.book.not_found.description"
        link={{ to: "/pages/meetings", children: "meetings.tabs.new" }}
      />
    );
  }

  return (
    <div className={className}>
      <CabinetWidget expert={expert} />
    </div>
  );
}
