import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

import { remoteLog } from "../../../common/logging";
import { formatDate, DATE_FORMAT } from "../../../common/datetime";
import Wrapper from "../../hoc/main_wrapper";
import { Loader } from "~/components/ui/loader";
import {
  connectPatientToMedit,
  createPatientFromMedit,
  disConnectCase,
  getMeditCase,
  getMeditCases,
  searchPatientsData,
  uploadFile,
} from "../../../actions/medit";
import { getPatientId } from "../../../actions/get_patient_id";
import { addInstructions } from "../../../slices/instructions";
import scanners from "../../../assets/img/scanner-blue.svg";
import Pagination from "react-js-pagination";

const PAGE_SIZE = 20;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    cases: state.meditCases,
    spinner: state.spinner,
    instructions: state.instructions,
    fileUploaded: state.fileUploaded,
    fileSpinner: state.fileSpinner,
    fileUploadError: state.fileUploadError,
    meditPatients: state.meditPatients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCases: (page, start, end, searchString) =>
      dispatch(getMeditCases(page, start, end, searchString, PAGE_SIZE)),
    getMeditCase: (case_id) => dispatch(getMeditCase(case_id)),
    createPatientFromMedit: (...args) => dispatch(createPatientFromMedit(...args)),
    addInstruction: (data) => dispatch(addInstructions(data)),
    searchPatients: (search) => dispatch(searchPatientsData(search)),
    connectPatientToMedit: (case_id, patient_id, files) =>
      dispatch(connectPatientToMedit(case_id, patient_id, files)),
    disConnectCase: (patient_id) => dispatch(disConnectCase(patient_id)),
    uploadFile: (case_id, patient_id, file_id) =>
      dispatch(uploadFile(case_id, patient_id, file_id)),
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
  };
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: "flex", textAlign: "center", justifyContent: "space-between" }}>
          <label style={{ marginLeft: "10px" }}>{props.label}</label>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
        </div>
      </components.Option>
    </div>
  );
};

class MeditCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalElements: null,
      startDate: null,
      endDate: null,
      start: null,
      end: null,
      searchString: null,
      meditCase: {},
      patient_id: null,
      file_name: null,
      uploading_files_names: [],
      files: [],
      showNote: false,
      patient_name: null,
      optionSelected: null,
      patientSelected: null,
      patient_has_been_uploaded: false,
      hidden_upload_btn: false,
      full_form: true,
      showReconnect: true,
      showSelectFiles: true,
      noFileSelected: true,
      showRedirect: false,
    };
    this.fm = this.fm.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.handleEnterSearch = this.handleEnterSearch.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.submit = this.submit.bind(this);
    this.clear = this.clear.bind(this);
    this.getCase = this.getCase.bind(this);
    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.addMiddleName = this.addMiddleName.bind(this);
    this.submitSaveButton = this.submitSaveButton.bind(this);
    this.searchPatient = this.searchPatient.bind(this);
    this.connect = this.connect.bind(this);
    this.disConnectCase = this.disConnectCase.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.redirectToEditPage = this.redirectToEditPage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setPatient = this.setPatient.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, "medit_cases");
  }

  fm(id) {
    return this.props.intl.formatMessage({ id: id });
  }

  redirectToEditPage(patient_id) {
    window.location = `/pages/edit_patient/${patient_id}`;
  }

  componentDidMount() {
    const { user } = this.props;

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }
    this.props.addInstruction({ doctor_id: this.props.user.account_id });
    const { start, end, searchString } = this.state;
    this.props.getCases(0, start, end, searchString).then((res) => {
      this.setState({ totalElements: res.totalElements });
    });
  }

  handlePageChange(pageNumber) {
    const { start, end, searchString } = this.state;
    this.props.getCases(pageNumber, start, end, searchString);
    this.setState({ page: pageNumber });
  }

  onChangeStartDate(e) {
    this.setState({ startDate: e && e._d ? formatDate(e._d) : null });
    this.setState({ start: e && e._d ? formatDate(e._d)._i : null });
  }

  onChangeEndDate(e) {
    this.setState({ endDate: e && e._d ? formatDate(e._d) : null });
    this.setState({ end: e && e._d ? formatDate(e._d)._i : null });
  }

  handleFilterChange(str) {
    if (str.keyCode === 32) {
      return false;
    } else {
      const string = str.target.value.trimEnd().trimStart().toString().toLowerCase();
      if (str.target.value.length === 0) {
        this.setState({ searchString: false });
      } else {
        this.setState({ searchString: string });
      }
    }
  }

  handleEnterSearch(event) {
    if (event.key === "Enter") {
      const str = event.target.value.trimEnd().trimStart();
      if (str === "") {
        this.setState({ searchString: false });
      } else {
        this.setState({ searchString: str });
      }
      this.props.getCases(
        this.state.page,
        this.state.start,
        this.state.end,
        this.state.searchString,
      );
    }
  }

  submit() {
    const { page, start, end, searchString } = this.state;
    this.setState({ page: 1 });
    this.props.getCases(page, start, end, searchString);
  }

  clear() {
    this.setState({
      startDate: null,
      endDate: null,
      start: null,
      end: null,
      searchString: null,
      page: 1,
    });
    document.getElementById("patient-cases-form-input").value = "";
    this.props.getCases(0, null, null, null);
  }

  closeModal() {
    const { page } = this.state;
    this.setState({
      files: [],
      uploading_files_names: [],
      file_name: null,
      patient_id: null,
      showNote: false,
      optionSelected: null,
      patientSelected: null,
      patient_has_been_uploaded: false,
      full_form: true,
      showReconnect: true,
      showSelectFiles: true,
      showRedirect: false,
      hidden_upload_btn: false,
    });
    this.props.getCases(page, null, null, null);
  }

  getCase(case_id, id) {
    this.setState({ patient_has_been_uploaded: false });
    this.props
      .getMeditCase(case_id)
      .then((res) => {
        this.setState({ meditCase: res });
        if (res.patient_id !== null) {
          this.setState({
            patient_name: res.patient_data.first_name + " " + res.patient_data.last_name,
            full_form: false,
            hidden_upload_btn: false,
          });
        } else {
          this.setState({ showReconnect: false });
        }
        this.addFirstName(res.name.split(" ")[0]);
        this.addLastName(res.name.split(" ").pop());
      })
      .then(() => {
        $(`${id}`).modal("show");
      })
      .catch(() => {
        window.location = "/pages/scanners";
      });
  }

  addFirstName(data) {
    this.props.addInstruction({ first_name: data });
  }

  addLastName(data) {
    this.props.addInstruction({ last_name: data });
  }

  addMiddleName(data) {
    this.props.addInstruction({ middle_name: data });
  }

  searchPatient(v) {
    const value = v.target.value;
    if (value.length >= 2) {
      this.props.searchPatients(value);
    }
  }

  setPatient(v) {
    const { patient_id, meditCase } = this.state;
    if (v.value == meditCase.patient_id) {
      this.setState({ showNote: false, patientSelected: v.label });
    } else if (patient_id !== null && v.value !== meditCase.patient_id) {
      this.setState({ showNote: true, patientSelected: v.label });
    }
    this.setState({ patient_id: v.value, patientSelected: v.label });
    this.props.getPatient(v.value);
  }

  connect(case_id, patient_id) {
    this.setState({
      hidden_upload_btn: true,
      showNote: false,
      full_form: false,
      showReconnect: false,
      showSelectFiles: false,
    });
    const { files } = this.state;
    const upload_files = [];
    const files_names = [];
    files.map((f) => files_names.push(f.label));
    this.props.connectPatientToMedit(case_id, patient_id, upload_files).then(async (response) => {
      this.setState({ meditCase: response });
      this.setState({ patient_has_been_uploaded: true, showNote: false, showRedirect: true });
      files.map((f) =>
        response.files.filter((c) => c.uuid === f.value && !c.uploaded).length > 0
          ? upload_files.push(f.value)
          : null,
      );
      const nameArr = [];
      for (const i of upload_files) {
        const file_name = files.map((f) => (f.value === i ? f.label : null));
        this.setState({ patient_id: patient_id, file_name: file_name, showNote: false });
        await this.props.uploadFile(case_id, Number(patient_id), i);
        nameArr.push(file_name);
        this.setState({ uploading_files_names: [...nameArr] });
      }
    });
  }

  async uploadFiles(case_id, patient_id) {
    this.setState({ hidden_upload_btn: true, showReconnect: false, showSelectFiles: false });
    const { files } = this.state;
    const upload_files = [];
    const files_names = [];
    files.map((f) => files_names.push(f.label));
    files.map((f) => upload_files.push(f.value));
    const nameArr = [];
    for (const i of upload_files) {
      const file_name = files.map((f) => (f.value === i ? f.label : null));
      this.setState({ file_name: file_name, showNote: false, showRedirect: true });
      await this.props.uploadFile(case_id, Number(patient_id), i);
      nameArr.push(file_name);
      this.setState({ uploading_files_names: [...nameArr] });
    }
  }

  disConnectCase(patient_id) {
    this.setState({ files: [] });
    this.props
      .disConnectCase(patient_id)
      .then((res) => res.status)
      .then((status) => {
        if (status === 200) {
          const { start, end, searchString } = this.state;
          this.props.getCases(0, start, end, searchString);
        }
      });
  }

  onSelectFile(v, i) {
    if (v.length > 0) {
      this.setState({ noFileSelected: false });
    } else {
      this.setState({ noFileSelected: true });
    }
    const meditFiles = [];
    v.map((f) => meditFiles.push({ value: f.value, label: f.label }));
    this.setState({ files: meditFiles, optionSelected: meditFiles.value });
  }

  submitSaveButton() {
    this.setState({ hidden_upload_btn: true, showSelectFiles: false, showRedirect: true });
    const case_id = this.state.meditCase.uuid;
    const { files } = this.state;
    const upload_files = [];
    const files_names = [];
    files.map((f) => upload_files.push(f.value));
    files.map((f) => files_names.push(f.label));

    if (
      !["first_name", "last_name"].filter((elm) => {
        const cond =
          this.props.instructions.hasOwnProperty(elm) && this.props.instructions[elm] !== "";
        $(`[name="${elm}"]`)
          .parent()
          .find("label")
          .css({ color: elm !== "doctor_id" && !cond ? "red" : "#34495e" });
        return !cond;
      }).length
    ) {
      this.props
        .createPatientFromMedit(this.props.instructions, case_id)
        .then((response) => {
          this.setState({ patient_id: response.patient_id });
          return response.patient_id;
        })
        .then(async (patient_id) => {
          if (patient_id) {
            this.setState({ patient_id: patient_id });
            const nameArr = [];
            for (const i of upload_files) {
              const file_name = files.map((f) => (f.value === i ? f.label : null));
              this.setState({ patient_id: patient_id, file_name: file_name });
              await this.props.uploadFile(case_id, patient_id, i);
              nameArr.push(file_name);
              this.setState({ uploading_files_names: [...nameArr] });
            }
          }
          return patient_id;
        });
    } else {
      $("#modalSubmitBtn").removeClass("-error");
      setTimeout(() => {
        $("#modalSubmitBtn").addClass("-error");
      }, 0);
    }
  }

  render() {
    const { meditPatients, spinner, fileSpinner, fileUploadError } = this.props;
    const {
      totalElements,
      meditCase,
      uploading_files_names,
      patient_id,
      patient_name,
      file_name,
      optionSelected,
      patientSelected,
      full_form,
      showNote,
      showReconnect,
      showSelectFiles,
      noFileSelected,
      showRedirect,
      patient_has_been_uploaded,
    } = this.state;
    const instructions = this.props.instructions;
    const animatedComponents = makeAnimated();
    const options = [];
    meditPatients.map((p) =>
      p.patient_id !== meditCase.patient_id
        ? options.push({
            value: `${p.patient_id}`,
            label: `${p.first_name} ${p.last_name}   (${p.patient_id})`,
          })
        : null,
    );
    const cases = this.props.cases.content ? this.props.cases.content : [];
    const filesOptions = [];
    const uploaded = [];
    const submitFileOptions = [];
    const files = Object.keys(meditCase).length !== 0 ? meditCase.files : [];
    const case_id = meditCase.uuid;
    !full_form || patient_id == meditCase.patient_id
      ? files.map((c) =>
          !c.uploaded
            ? filesOptions.push({ value: `${c.uuid}`, label: `${c.name} ${c.fileType}` })
            : null,
        )
      : files.map((c) =>
          filesOptions.push({ value: `${c.uuid}`, label: `${c.name} ${c.fileType}` }),
        );
    files.map((c) => (c.uploaded && meditCase.patient_id !== null ? uploaded.push(c.name) : null));
    files.map((c) =>
      submitFileOptions.push({ value: `${c.uuid}`, label: `${c.name} ${c.fileType}` }),
    );

    const first_name = meditCase.patient_data ? meditCase.patient_data.first_name : null;
    const last_name = meditCase.patient_data ? meditCase.patient_data.last_name : null;

    return (
      <div>
        <div>
          <div className="page-content-wrapper">
            <div className="page-content" id="patient-cases-table">
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption">
                    <img src={scanners} style={{ width: "35px", marginLeft: "-8px" }} />
                    <span
                      style={{ marginLeft: "2px" }}
                      className="caption-subject font-green bold uppercase"
                      id="patients-table-block-title"
                    >
                      <FormattedMessage id="medit.title" />
                    </span>
                  </div>
                </div>
                <div className="portlet-body">
                  <div id="patient-cases_table_wrapper" className="dataTables_wrapper no-footer">
                    <div
                      data-backdrop="static"
                      data-keyboard="false"
                      className="modal fade"
                      id="submit_patient"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              onClick={() => this.closeModal()}
                              disabled={fileSpinner || spinner}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="modal-title" id="exampleModalLabel">
                              <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
                            </h3>
                          </div>
                          <div className="modal-body">
                            <form>
                              <div className="row" style={{ marginTop: "5px" }}>
                                <div className="col-md-6 form-group">
                                  <label className="col-form-label">
                                    <FormattedMessage id="PAT_INFO_LAST_NAME" />
                                    <span className="required" aria-required="true">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    data-matomo-mask
                                    data-hj-suppress
                                    type="text"
                                    className="form-control"
                                    id="patient-lastname-value"
                                    name="last_name"
                                    value={instructions.last_name}
                                    onChange={(e) => this.addLastName(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6 form-group">
                                  <label className="col-form-label">
                                    <FormattedMessage id="PAT_INFO_FIRST_NAME" />
                                    <span className="required" aria-required="true">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    data-matomo-mask
                                    data-hj-suppress
                                    type="text"
                                    className="form-control"
                                    id="patient-firstname-value"
                                    name="first_name"
                                    onChange={(e) => this.addFirstName(e.target.value)}
                                    value={instructions.first_name}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">
                                  <FormattedMessage id="PAT_INFO_MIDDLE_NAME" />
                                </label>
                                <input
                                  data-matomo-mask
                                  data-hj-suppress
                                  type="text"
                                  className="form-control"
                                  id="patient-middlename-value"
                                  name="middle_name"
                                  onChange={(e) => this.addMiddleName(e.target.value)}
                                />
                              </div>
                              <div
                                data-matomo-mask
                                data-hj-suppress
                                style={{ display: !showSelectFiles ? "none" : "block" }}
                                className="form-group"
                              >
                                <label className="col-form-label">
                                  <FormattedMessage id="th.medit.action.сhoose.file" />
                                </label>
                                <Select
                                  components={{ Option }}
                                  isMulti
                                  onChange={(v, i) => this.onSelectFile(v, i)}
                                  options={submitFileOptions}
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={true}
                                  id="patient-choose-file"
                                  name="medit_file"
                                  value={this.state.optionSelected}
                                />
                              </div>
                              {spinner ? (
                                <div>
                                  <FormattedMessage id="th.medit.patient.create" />
                                  <Loader />
                                </div>
                              ) : null}
                              {fileSpinner ? (
                                <div data-matomo-mask data-hj-suppress>
                                  <span>
                                    <FormattedMessage id="th.medit.file.name" />
                                    <span style={{ color: "#31c5d2", fontWeight: "800" }}>
                                      {file_name}
                                    </span>
                                    <FormattedMessage id="th.medit.file.uploading" />
                                  </span>
                                  <div>
                                    <Loader />
                                  </div>
                                </div>
                              ) : null}
                              {uploading_files_names.length > 0 && !fileSpinner
                                ? uploading_files_names.map((f) => {
                                    return (
                                      <div
                                        data-matomo-mask
                                        data-hj-suppress
                                        key={f}
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <span>
                                          <FormattedMessage id="th.medit.file.name" />
                                          <span style={{ color: "#31c5d2", fontWeight: "800" }}>
                                            {f}
                                          </span>
                                          <FormattedMessage id="th.medit.file.uploaded" />
                                        </span>
                                        &nbsp;&nbsp;
                                        <i
                                          className="icon-check"
                                          style={{
                                            color: "#82D9D4",
                                            fontSize: "24px",
                                            fontWeight: 900,
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                            </form>
                          </div>
                          <div className="modal-footer">
                            {showRedirect ? (
                              <button
                                style={{ marginTop: "15px" }}
                                disabled={fileSpinner || spinner}
                                type="button"
                                className="btn green"
                                data-dismiss="modal"
                                onClick={() => this.redirectToEditPage(patient_id)}
                              >
                                <FormattedMessage id="th.medit.action.redirect" />
                              </button>
                            ) : null}
                            <button
                              style={{
                                display: this.state.hidden_upload_btn ? "none" : "",
                                marginTop: "15px",
                              }}
                              disabled={
                                instructions.last_name == "" || instructions.first_name == ""
                              }
                              type="button"
                              className="btn green"
                              onClick={(e) => this.submitSaveButton(e.target)}
                            >
                              <FormattedMessage id="BTN_SUBMIT" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-backdrop="static"
                      data-keyboard="false"
                      className="modal fade"
                      id="connect"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              onClick={() => this.closeModal()}
                              disabled={fileSpinner || spinner}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="modal-title" id="exampleModalLabel">
                              <FormattedMessage id="th.medit.connect.patient" />
                            </h3>
                          </div>
                          <div className="modal-body">
                            {meditCase.patient_id !== null && patient_id === null ? (
                              <div
                                data-matomo-mask
                                data-hj-suppress
                                className="alert alert-success"
                                id="upload-ct-alert-warning"
                              >
                                <FormattedMessage id="th.medit.case.connected.to" />
                                {first_name} {last_name} ({meditCase.patient_id})
                              </div>
                            ) : null}
                            {!full_form && patient_id == meditCase.patient_id ? <div></div> : null}
                            {showNote &&
                            (patient_id && meditCase.patient_id
                              ? patient_id !== meditCase.patient_id
                              : null) ? (
                              <div
                                className="alert"
                                key={patient_id}
                                style={{ fontSize: "12px", backgroundColor: "#00AEBE" }}
                              >
                                <FormattedMessage
                                  id="th.medit.first.note"
                                  values={{ labelTitle: WEBPACK_LABEL_TITLE }}
                                />
                                <NavLink
                                  data-matomo-mask
                                  data-hj-suppress
                                  style={{ display: "block" }}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  to={`/pages/patient/${meditCase.patient_id}`}
                                >
                                  {patient_name} ({meditCase.patient_id}).
                                </NavLink>
                                <p>
                                  <FormattedMessage id="th.medit.second.note" />
                                </p>
                              </div>
                            ) : null}
                            {patient_has_been_uploaded ? (
                              <div className="alert alert-success" id="upload-ct-alert-warning">
                                <FormattedMessage id="th.medit.patient.connect.success" />
                              </div>
                            ) : null}
                            {(uploaded.length > 0 && patient_id == null) ||
                            (patient_id == meditCase.patient_id && uploaded.length > 0) ? (
                              <div>
                                <h4
                                  style={{
                                    display: meditCase.patient_id == null ? "none" : "block",
                                  }}
                                >
                                  <FormattedMessage id="th.medit.uploaded.files" />
                                </h4>
                                {uploaded.map((f) => {
                                  return (
                                    <div
                                      data-matomo-mask
                                      data-hj-suppress
                                      key={f}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <span>
                                        File <span style={{ color: "#31c5d2" }}>{f}</span>
                                      </span>
                                      &nbsp;&nbsp;
                                      <i
                                        className="icon-check"
                                        style={{ color: "#82D9D4", fontWeight: "900" }}
                                      />
                                    </div>
                                  );
                                })}{" "}
                              </div>
                            ) : null}
                            <br />
                            {showReconnect ? (
                              <div style={{ display: "flex", textAlign: "center" }}>
                                <input
                                  type="checkbox"
                                  checked={full_form}
                                  onChange={(e) =>
                                    e.target.checked
                                      ? this.setState({
                                          full_form: true,
                                          showNote: true,
                                          optionSelected: null,
                                        })
                                      : this.setState({
                                          full_form: false,
                                          patient_id: null,
                                          showNote: false,
                                          optionSelected: null,
                                          patientSelected: null,
                                          noFileSelected: true,
                                        })
                                  }
                                />
                                <label style={{ marginLeft: "10px" }}>
                                  <FormattedMessage id="th.medit.case.reconnected.to" />
                                </label>
                              </div>
                            ) : null}
                            <br />
                            <div
                              data-matomo-mask
                              data-hj-suppress
                              style={{ display: !full_form ? "none" : "block" }}
                              className="form-group"
                            >
                              <label className="col-form-label">
                                <FormattedMessage
                                  id="th.medit.action.сhoose.patient"
                                  values={{ labelTitle: WEBPACK_LABEL_TITLE }}
                                />
                              </label>
                              <Select
                                isSearchable
                                components={animatedComponents}
                                onKeyDown={(v) => this.searchPatient(v)}
                                onChange={(v) => this.setPatient(v)}
                                options={options}
                                id="medit-patient"
                                name="medit_patient"
                                placeholder={this.fm("patient.search.placeholder")}
                                value={
                                  patient_id ? { value: patient_id, label: patientSelected } : null
                                }
                              />
                            </div>
                            {filesOptions.length > 0 ? (
                              <div
                                data-matomo-mask
                                data-hj-suppress
                                style={{ display: !showSelectFiles ? "none" : "block" }}
                                className="form-group"
                              >
                                <label className="col-form-label">
                                  <FormattedMessage id="th.medit.action.сhoose.file" />
                                </label>
                                <Select
                                  components={{ Option }}
                                  isMulti
                                  onChange={(v, i) => this.onSelectFile(v, i)}
                                  options={filesOptions}
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={true}
                                  id="patient-choose-file"
                                  name="medit_file"
                                  value={optionSelected}
                                />
                              </div>
                            ) : (
                              <div style={{ display: !showSelectFiles ? "none" : "block" }}>
                                <FormattedMessage id="th.medit.no.files" />
                              </div>
                            )}
                            {spinner ? (
                              <div>
                                <FormattedMessage id="th.medit.patient.connect" /> <Loader />
                              </div>
                            ) : null}
                            {fileSpinner ? (
                              <div data-matomo-mask data-hj-suppress>
                                <span>
                                  <FormattedMessage id="th.medit.file.name" />
                                  <span style={{ color: "#31c5d2", fontWeight: "800" }}>
                                    {file_name}
                                  </span>
                                  <FormattedMessage id="th.medit.file.uploading" />
                                </span>
                                <div>
                                  <Loader />
                                </div>
                              </div>
                            ) : null}
                            {uploading_files_names.length > 0 && !fileSpinner && !fileUploadError
                              ? uploading_files_names.map((f) => {
                                  return (
                                    <div
                                      data-matomo-mask
                                      data-hj-suppress
                                      key={f}
                                      style={{ display: "flex", alignItems: "center" }}
                                    >
                                      <span>
                                        <FormattedMessage id="th.medit.file.name" />
                                        <span style={{ color: "#31c5d2", fontWeight: "800" }}>
                                          {f}
                                        </span>
                                        <FormattedMessage id="th.medit.file.uploaded" />
                                      </span>
                                      &nbsp;&nbsp;
                                      <i
                                        className="icon-check"
                                        style={{
                                          color: "#82D9D4",
                                          fontSize: "24px",
                                          fontWeight: 900,
                                        }}
                                      />
                                    </div>
                                  );
                                })
                              : null}
                            {fileUploadError
                              ? uploading_files_names.map((f) => {
                                  return (
                                    <div key={f} style={{ display: "flex", alignItems: "center" }}>
                                      <span style={{ color: "red" }}>
                                        <FormattedMessage id="th.medit.file.name" />
                                        <span style={{ fontWeight: "800" }}>{f}</span>
                                        <FormattedMessage id="th.medit.file.upload_error" />
                                      </span>
                                      &nbsp;&nbsp;
                                      <i
                                        className="icon-dislike"
                                        style={{ color: "red", fontSize: "24px", fontWeight: 900 }}
                                      />
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                          <div className="modal-footer">
                            {showRedirect ? (
                              <button
                                style={{ marginTop: "15px" }}
                                disabled={fileSpinner || spinner}
                                type="button"
                                className="btn green"
                                data-dismiss="modal"
                                onClick={() =>
                                  this.redirectToEditPage(
                                    patient_id && patient_id !== null
                                      ? patient_id
                                      : meditCase.patient_id,
                                  )
                                }
                              >
                                <FormattedMessage id="th.medit.action.redirect" />
                              </button>
                            ) : null}
                            <button
                              style={{
                                display: this.state.hidden_upload_btn ? "none" : "",
                                marginTop: "15px",
                              }}
                              disabled={full_form ? noFileSelected || !patient_id : noFileSelected}
                              type="button"
                              className="btn green"
                              onClick={() =>
                                full_form
                                  ? this.connect(case_id, patient_id)
                                  : this.uploadFiles(case_id, meditCase.patient_id)
                              }
                            >
                              <FormattedMessage id="BTN_SUBMIT" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-xs-8">
                        <DatePicker
                          className="form-control"
                          dateFormat={DATE_FORMAT}
                          selected={this.state.startDate}
                          onChange={(e) => this.onChangeStartDate(e)}
                          startDate={this.state.start}
                          placeholderText={this.fm("table.from")}
                          minDate={new Date("1970-01-01T00:00:00")}
                        />
                      </div>
                      <div className="col-md-3 col-xs-8">
                        <DatePicker
                          className="form-control"
                          dateFormat={DATE_FORMAT}
                          selected={this.state.endDate}
                          onChange={(e) => this.onChangeEndDate(e)}
                          endDate={this.state.end}
                          minDate={new Date()}
                          placeholderText={this.fm("table.to")}
                        />
                      </div>
                      <div className="col-md-3 col-xs-8">
                        <input
                          name="search"
                          maxLength="128"
                          onKeyUp={(e) => this.handleFilterChange(e)}
                          type="search"
                          className="form-control"
                          aria-controls="patient-cases_table"
                          id="patient-cases-form-input"
                          placeholder={this.fm("table.search")}
                          style={{ height: "34px" }}
                          onKeyDown={(e) => this.handleEnterSearch(e)}
                        />
                      </div>
                      <div className="col-md-1 col-xs-2">
                        <button
                          type="submit"
                          style={{ backgroundColor: "#efefef" }}
                          onClick={this.submit}
                          className="btn btn-circle"
                          title={this.fm("table.search")}
                        >
                          <span className="glyphicon glyphicon-search"></span>
                        </button>
                      </div>
                      <div className="col-md-1 col-xs-1">
                        <button
                          type="reset"
                          style={{ backgroundColor: "#efefef" }}
                          onClick={this.clear}
                          className="btn btn-circle"
                          title={this.fm("table.clear")}
                        >
                          <span className="glyphicon glyphicon-refresh"></span>
                        </button>
                      </div>
                    </div>
                    <br />
                    {!this.props.spinner ? (
                      <div className="table-responsive">
                        <table
                          className="table table-bordered table-hover dataTable no-footer"
                          id="patient-cases_table"
                          role="grid"
                        >
                          <thead id="patient-cases-head">
                            <tr role="row">
                              <th>
                                <FormattedMessage id="th.medit.patientId" />
                              </th>
                              <th>
                                <FormattedMessage id="th.medit.caseName" />
                              </th>
                              <th>
                                <FormattedMessage id="th.medit.status" />
                              </th>
                              <th>
                                <FormattedMessage id="th.medit.patientName" />
                              </th>
                              <th>
                                <FormattedMessage id="th.medit.date.updated" />
                              </th>
                              <th>
                                <FormattedMessage id="th.medit.date.scanning" />
                              </th>
                              <th>
                                <FormattedMessage id="th.medit.action" />
                              </th>
                            </tr>
                          </thead>

                          <tbody id="patient-cases-body">
                            {cases.map((c, index) => {
                              return (
                                <tr key={`cases${index}`}>
                                  <td data-matomo-mask data-hj-suppress>
                                    {c.patient_data ? (
                                      <NavLink to={`/pages/patient/${c.patient_id}`}>
                                        <div>{c.patient_data.patient_id}</div>
                                        <div>{c.patient_data.first_name}</div>
                                        <div>{c.patient_data.last_name}</div>
                                      </NavLink>
                                    ) : null}
                                  </td>
                                  <td data-matomo-mask data-hj-suppress>{c.name}</td>
                                  <td>
                                    <FormattedMessage id={c.status} />
                                  </td>
                                  <td data-matomo-mask data-hj-suppress>{c.patient.name}</td>
                                  <td>{c.dateUpdated.split("T")[0]}</td>
                                  <td>{c.dateScanned.split("T")[0]}</td>
                                  <td>
                                    {c.patient_id === null ? (
                                      <button
                                        style={{
                                          border: "1px solid #32c5d2",
                                          margin: "5px",
                                          backgroundColor: "#efefef",
                                        }}
                                        type="button"
                                        className="btn"
                                        data-toggle="modal"
                                        data-whatever="@mdo"
                                        onClick={() => this.getCase(c.uuid, "#submit_patient")}
                                      >
                                        <FormattedMessage id="th.medit.action.submit" />
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          border: "1px solid #32c5d2",
                                          margin: "5px",
                                          backgroundColor: "#efefef",
                                        }}
                                        type="button"
                                        className="btn"
                                        onClick={() => this.disConnectCase(c.patient_id)}
                                      >
                                        <FormattedMessage id="th.medit.action.disconnect" />
                                      </button>
                                    )}
                                    <button
                                      style={{
                                        border: "1px solid #32c5d2",
                                        margin: "5px",
                                        backgroundColor: "#efefef",
                                      }}
                                      type="button"
                                      className="btn"
                                      data-toggle="modal"
                                      data-whatever="@mdo"
                                      onClick={() => this.getCase(c.uuid, "#connect")}
                                    >
                                      <FormattedMessage
                                        id={
                                          c.patient_id === null
                                            ? "th.medit.action.connect"
                                            : "th.medit.action.upload"
                                        }
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loader />
                    )}
                    {cases.length === 0 && !this.props.spinner ? (
                      <div>
                        <FormattedMessage id="3shape.search.message" />
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="dataTables_paginate paging_bootstrap_number"
                          id="patient_cases_table_paginate"
                        >
                          <Pagination
                            onChange={this.handlePageChange.bind(this)}
                            activePage={this.state.page}
                            pageRangeDisplayed={20}
                            itemsCountPerPage={PAGE_SIZE}
                            totalItemsCount={totalElements || cases.length}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(injectIntl(MeditCases)));
