import "react-loading-skeleton/dist/skeleton.css";

import { XCircleIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { api } from "~/api";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Loader } from "~/components/ui/loader";
import { useLoadingDelay } from "~/hooks/use-loading-delay";
import { useSearchParams } from "~/hooks/use-search-params";
import { useAppDispatch } from "~/store";

import { EventCard, type EventCardProps } from "./event-card";
import { type EventsQueryArgs, useGetEventsQuery } from "./events-api";
import { EventsZIndex } from "./events-const";

export function EventsHeader({
  displayLoader,
  searchProps,
  onFiltersToggle,
}: {
  displayLoader: boolean;
  searchProps: React.ComponentProps<typeof EventsSearch>;
  onFiltersToggle: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const headingElement = (
    <h1
      className={clsx(
        "tw-flex tw-items-center tw-gap-1.5 tw-text-base tw-font-bold tw-uppercase tw-text-primary",
        "tw-my-0",
      )}
    >
      <i className="icon-calendar font-green" />
      <FormattedMessage id="events" />
    </h1>
  );

  const loaderElement = displayLoader ? <Loader className="tw-p-0 max-sm:tw-hidden" /> : null;

  const filtersToggleElement = (
    <button
      type="button"
      className={clsx(
        "tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-transparent",
        "tw-h-9 tw-w-9 tw-border-none tw-transition-colors hover:tw-bg-[#f4f4f5] active:tw-bg-[#f4f4f5]",
      )}
      onClick={() => onFiltersToggle((prev) => !prev)}
    >
      {/* NOTE: min-width is needed for IOS safari, it doesn't use `width` value for whatever reason */}
      <AdjustmentsHorizontalIcon width={24} height={24} className="tw-min-w-[24px]" />
      <span className="tw-sr-only">
        <FormattedMessage id="events.filters" />
      </span>
    </button>
  );

  return (
    <>
      <div className="tw-flex tw-flex-col tw-justify-between tw-gap-5 tw-px-5 tw-pb-5 tw-pt-1 sm:tw-flex-row">
        {headingElement}

        <div className="tw-flex tw-items-center tw-gap-4">
          {loaderElement}
          <EventsSearch className="tw-w-full tw-min-w-[250px]" {...searchProps} />
          {filtersToggleElement}
        </div>
      </div>

      <hr className="tw-mx-5 tw-my-0" />
    </>
  );
}

function EventsSearch({
  disabled,
  search,
  onSearchChange,
  className,
}: {
  disabled: boolean;
  search: string;
  onSearchChange(newSearch: string): void;
  className?: string;
}) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const shouldReduceMotion = useReducedMotion();
  const animationProps: AnimationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { type: "spring" },
  };

  useEffect(() => {
    function focusSearchInput(event: KeyboardEvent) {
      if (event.key == "/") {
        event.preventDefault();
        inputRef.current?.focus();
      }
    }
    window.addEventListener("keydown", focusSearchInput);
    return () => window.removeEventListener("keydown", focusSearchInput);
  }, []);

  return (
    <div className={clsx("col-xs-12 col-sm-6 col-md-6 col-lg-4 tw-relative tw-p-0", className)}>
      <Input
        ref={inputRef}
        type="search"
        rounded
        className="tw-h-9"
        style={{ height: 38, paddingRight: 26 }}
        placeholder={intl.formatMessage({ id: "events.search.placeholder" })}
        aria-label={intl.formatMessage({ id: "search" })}
        disabled={disabled}
        maxLength={128}
        value={search}
        onChange={(event) => onSearchChange(event.target.value)}
      />
      <AnimatePresence>
        {search.length > 0 ? (
          <motion.button
            {...(shouldReduceMotion == true ? {} : animationProps)}
            type="button"
            aria-label={intl.formatMessage({ id: "search.clear" })}
            className={clsx(
              "tw-group tw-absolute tw-right-2 tw-top-2 tw-h-5 tw-w-5 tw-rounded-full",
              "tw-border-none tw-bg-transparent tw-p-0",
            )}
            onClick={() => {
              onSearchChange("");
              inputRef.current?.focus();
            }}
          >
            <XCircleIcon
              className={clsx(
                "tw-h-5 tw-w-5 tw-text-gray-400 tw-transition-colors",
                "group-hover:tw-text-gray-500 group-active:tw-text-gray-500",
              )}
            />
          </motion.button>
        ) : (
          <MagnifyingGlassIcon className="tw-absolute tw-right-2.5 tw-top-2 tw-h-5 tw-w-5 tw-text-gray-400" />
        )}
      </AnimatePresence>
    </div>
  );
}

export function EventsTabsTrigger({
  isActive,
  children,
  ...props
}: React.ComponentPropsWithoutRef<typeof Tabs.Trigger> & { isActive: boolean }) {
  const shouldReduceMotion = useReducedMotion();
  const className = "tw-rounded-[10px]";

  return (
    <Tabs.Trigger
      {...props}
      className={clsx(
        "tw-relative tw-border-none tw-bg-transparent tw-px-1 tw-py-3",
        "tw-flex-1 data-[state=active]:tw-font-semibold data-[state=active]:tw-text-[#5b9bd1]",
        "tw-outline-none tw-ring-blue-600 focus-visible:tw-ring-2",
        { "data-[state=active]:tw-bg-[#f2f6f9]": shouldReduceMotion },
        className,
        props.className,
      )}
    >
      <span className="tw-relative" style={{ zIndex: EventsZIndex.TABS_TRIGGER }}>
        {children}
      </span>

      {isActive && shouldReduceMotion == false ? (
        <motion.div
          className={clsx(className, "tw-absolute tw-inset-0 tw-bg-[#f2f6f9] tw-text-[#5b9bd1]")}
          layoutId="tabs-indicator"
        />
      ) : null}
    </Tabs.Trigger>
  );
}

export const EventsTabsList = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithRef<typeof Tabs.List> & { hrMarginX?: boolean, topPaddingY?: boolean }
>(function EventsTabsList({ children, hrMarginX = true, topPaddingY = true, ...props }, forwardedRef) {
  const [isStuck, setIsStuck] = useState(false);

  const { ref, entry } = useInView({
    threshold: 1,
    onChange() {
      if (entry) {
        setIsStuck(entry.intersectionRatio < 1);
      }
    },
  });

  return (
    <>
      <Tabs.List
        {...props}
        ref={composeRefs<HTMLDivElement>(ref, forwardedRef)}
        className={clsx(
          props.className,
          // top: -1px is needed for the intersection observer to detect the stuck state
          // https://stackoverflow.com/a/74812383
          "tw-sticky -tw-top-[1px] tw-flex tw-rounded-b-xl tw-bg-white tw-pb-3",
          "tw-border-2 tw-border-solid",
          isStuck ? "tw-border-[#f2f6f9]" : "tw-border-transparent",
        { "tw-pt-3": topPaddingY },
        )}
        style={{ ...props.style, zIndex: EventsZIndex.TABS_LIST }}
      >
        {children}
      </Tabs.List>

      <hr className={clsx("tw-my-0 tw-pb-4", { "tw-mx-5": hrMarginX })} />
    </>
  );
});

export function EventsInfiniteScroll({
  queryArgs,
  numberOfPages,
  fetchNextPage,
  eventCardProps,
  eventGridProps,
}: {
  queryArgs: EventsQueryArgs;
  numberOfPages: number;
  fetchNextPage(): void;
  eventCardProps?: EventCardProps;
  eventGridProps: Pick<React.ComponentProps<typeof EventsGrid>, "noEventsFallback">;
}) {
  const query = useGetEventsQuery(queryArgs);
  const isLoadingMinDuration = useLoadingDelay(query.isLoading);
  const hasNextPage = typeof query.data?.next == "number";

  const [intersectionRef, inView] = useInView({
    skip: isLoadingMinDuration,
    onChange() {
      const hasNextPage = typeof query.data?.next == "number";
      if (inView && hasNextPage && !query.isFetching) {
        fetchNextPage();
      }
    },
  });

  return (
    <EventsErrorBoundary>
      <div className="tw-grid tw-gap-y-4">
        {Array.from({ length: numberOfPages }, (_, index) => (
          <EventsGrid
            key={index}
            queryArgs={queryArgs}
            page={index + 1}
            eventCardProps={eventCardProps}
            {...eventGridProps}
          />
        ))}
        {hasNextPage ? <div ref={intersectionRef} /> : null}
      </div>
    </EventsErrorBoundary>
  );
}

export function EventsGrid({
  queryArgs,
  page,
  eventCardProps,
  noEventsFallback,
}: {
  queryArgs: EventsQueryArgs;
  page?: number;
  eventCardProps?: EventCardProps;
  noEventsFallback: React.ReactElement;
}) {
  const query = useGetEventsQuery({ ...queryArgs, page });
  // NOTE: This `useSpinDelay` call should be the same as in <EventsInfiniteScroll />.
  // Otherwise intersection observer ref will be assigned earlier than the <EventsGrid />
  // has been rendered and we will end up fetching mutiple pages of data on the first render.
  const isLoadingMinDuration = useLoadingDelay(query.isLoading);

  const gridClassName = "tw-grid tw-gap-x-3 tw-gap-y-4 tw-px-1 sm:tw-grid-cols-2";

  /**
   * The number of skeletons should be even, because we have a two column layout
   * on desktops. Displaying 4 skeletons shifts the layout too much, causes a
   * vertical overflow and feels worse.
   */
  const numberOfSkeletons = 2;

  if (query.isUninitialized || query.isLoading || isLoadingMinDuration) {
    return (
      <div className={clsx(gridClassName, "tw-duration-300 tw-animate-in tw-fade-in-50")}>
        {Array.from({ length: numberOfSkeletons }, (_, index) => (
          <EventCard key={index} event={undefined} {...eventCardProps} />
        ))}
      </div>
    );
  }

  if (query.isError) {
    return <EventsError onReset={query.refetch} />;
  }

  if (query.data.total == 0) {
    return noEventsFallback;
  }

  const events = query.data.results;

  if (events.length == 0) {
    return (
      <div
        className={clsx(
          "tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
          "tw-p-3.5 tw-text-center",
        )}
      >
        <strong className="tw-text-base">
          <FormattedMessage id="events.not_found.heading" />
        </strong>

        <p className="tw-my-0 tw-text-gray-400">
          <FormattedMessage id="events.not_found.description" />
        </p>
      </div>
    );
  }

  return (
    <div className={gridClassName}>
      {events.map((event) => (
        <EventCard key={event.id} event={event} {...eventCardProps} />
      ))}
    </div>
  );
}

export function EventsErrorBoundary({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();

  return (
    <RemoteLogErrorBoundary
      component="events_page"
      fallbackRender={({ resetErrorBoundary }) => (
        <EventsError
          onReset={() => {
            dispatch(api.util.invalidateTags(["Event"]));
            resetErrorBoundary();
          }}
        />
      )}
    >
      {children}
    </RemoteLogErrorBoundary>
  );
}

function EventsError({ onReset }: { onReset(): void }) {
  return (
    <div className="tw-space-y-4 tw-p-3.5 tw-text-center">
      <strong className="tw-text-base tw-text-red-500">
        <FormattedMessage id="events.error.heading" />
      </strong>

      <p className="tw-my-0 tw-text-gray-400">
        <FormattedMessage id="events.error.description" />
      </p>

      <Button rounded onClick={onReset}>
        <FormattedMessage id="events.error.reset" />
      </Button>
    </div>
  );
}

export function useEventsSearch() {
  const searchParamName = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(() => searchParams.get(searchParamName) ?? "");
  const [debouncedSearch] = useDebounce(search.trim(), 300);

  const debouncedSetSearchParam = useDebouncedCallback((newSearch: string) => {
    setSearchParams(
      (prevParams) => {
        if (!newSearch) {
          prevParams.delete(searchParamName);
        } else {
          prevParams.set(searchParamName, newSearch);
        }
        return prevParams;
      },
      { replace: true },
    );
  }, 300);

  useEffect(() => {
    debouncedSetSearchParam(search);
  }, [search, debouncedSetSearchParam]);

  return { search, debouncedSearch, setSearch };
}

function composeRefs<T>(...refs: (React.Ref<T> | undefined)[]): (element: T) => void {
  return (element: T) => refs.forEach((ref) => setRef(ref, element));
}

function setRef<T>(ref: React.Ref<T> | undefined, element: T) {
  if (typeof ref == "function") {
    ref(element);
  } else if (ref) {
    (ref as React.MutableRefObject<T>).current = element;
  }
}

export function AdjustmentsHorizontalIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      fill="none"
      strokeWidth={1.5}
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
      />
    </svg>
  );
}
