import React from "react";

import Notification from "../notification";
import { PageContent } from "./page_content";
import PageFooter from "./page_footer";
import PageHeader from "./page_header";

export default function PatientArea() {
  return (
    <>
      <PageHeader />
      <div className="container max-md:tw-px-1">
        <div className="page-container">
          <PageContent />
        </div>
        <Notification />
      </div>
      <PageFooter />
    </>
  );
}
