import { ChatBubbleOvalLeftEllipsisIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { forwardRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Drawer } from "vaul";

import { SUPPORT_PHONE_NUMBER, ZIndex } from "~/common/constants";
import { formatPhoneNumber } from "~/common/utils";

export function SupportWidget() {
  return (
    <>
      <div className="sm:tw-hidden">
        <SupportWidgetMobile />
      </div>

      <div className="max-sm:tw-hidden">
        <SupportWidgetDesktop />
      </div>
    </>
  );
}

export function SupportWidgetMobile() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Drawer.Root open={isOpen} onOpenChange={setIsOpen}>
      <Drawer.Trigger asChild>
        <SupportWidgetTrigger />
      </Drawer.Trigger>

      <Drawer.Portal>
        <Drawer.Overlay className="tw-fixed tw-inset-0 tw-z-10 tw-bg-black/40 sm:tw-hidden" />
        <Drawer.Content
          className={clsx(
            "tw-fixed tw-inset-x-0 tw-bottom-0 tw-mt-24 tw-flex tw-h-fit tw-flex-col",
            "tw-rounded-t-[10px] tw-bg-gray-100 sm:tw-hidden",
          )}
          style={{ zIndex: ZIndex.SUPPORT_WIDGET + 1 }}
        >
          <div className="tw-relative tw-flex-1 tw-rounded-t-[10px] tw-bg-white tw-p-4">
            <div
              aria-hidden
              className="tw-mx-auto tw-mb-6 tw-h-1.5 tw-w-12 tw-shrink-0 tw-rounded-full tw-bg-gray-300"
            />
            <SupportWidgetClose onClick={() => setIsOpen(false)} />
            <SupportWidgetContent />
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export function SupportWidgetDesktop() {
  const [isOpen, setIsOpen] = useState(false);

  const animationProps: AnimationProps = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
    exit: { opacity: 0, transition: { duration: 0.15 } },
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <SupportWidgetTrigger />
      </Popover.Trigger>

      <AnimatePresence>
        {isOpen ? (
          <Popover.Portal forceMount>
            <Popover.Content
              className={clsx(
                "tw-relative tw-right-4 tw-rounded-[10px] tw-border tw-border-solid tw-border-[#e4e4e7]",
                "tw-bg-white tw-p-4 tw-shadow-md max-sm:tw-hidden lg:tw-bottom-1 lg:tw-right-6",
              )}
              side="bottom"
              sideOffset={-75}
              asChild
              style={{ zIndex: ZIndex.SUPPORT_WIDGET + 1 }}
            >
              <motion.div {...animationProps}>
                <SupportWidgetClose onClick={() => setIsOpen(false)} />
                <div className="tw-h-1" />
                <SupportWidgetContent />
              </motion.div>
            </Popover.Content>
          </Popover.Portal>
        ) : null}
      </AnimatePresence>
    </Popover.Root>
  );
}

const SupportWidgetTrigger = forwardRef<HTMLButtonElement>(function Trigger(props, forwardedRef) {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
  };

  return (
    <motion.button
      ref={forwardedRef}
      {...props}
      {...(shouldReduceMotion == true ? {} : animationProps)}
      className={clsx(
        "tw-fixed tw-bottom-4 tw-right-4 tw-h-16 tw-w-16 tw-rounded-[18px] tw-p-0",
        "tw-border-2 tw-border-solid tw-border-brand-green tw-bg-brand-green",
        "lg:tw-bottom-8 lg:tw-right-8",
        "tw-group tw-shadow-md tw-transition-all",
        "hover:tw-border-brand-green-hover hover:tw-bg-brand-green-hover active:tw-scale-[0.85]",
      )}
      style={{ zIndex: ZIndex.SUPPORT_WIDGET }}
    >
      <ChatBubbleOvalLeftEllipsisIcon className="tw-h-8 tw-w-8 tw-text-white tw-transition-colors group-hover:tw-text-gray-200" />
    </motion.button>
  );
});

function SupportWidgetClose({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <button
      className={clsx(
        "tw-absolute tw-right-3 tw-top-3 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center",
        "tw-rounded-full tw-border-none tw-bg-gray-200 tw-p-0 tw-outline-none",
        "tw-group tw-transition-all hover:tw-bg-gray-300 active:tw-scale-[0.85]",
      )}
      onClick={onClick}
    >
      <Cross2Icon className="tw-h-4 tw-w-4 tw-transition-all group-active:tw-scale-[0.85]" />
      <span className="tw-sr-only">
        <FormattedMessage id="alert.close" />
      </span>
    </button>
  );
}

function SupportWidgetContent() {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <p className="tw-m-0 tw-p-0 tw-leading-9">
        <span className="tw-inline-block tw-pb-2">
          <FormattedMessage id="care_support.dear.doctor" />
        </span>
        <br />
        <FormattedMessage id="care_support.description.1" />
        <br />
        <FormattedMessage id="care_support.description.2" />
      </p>

      <ul role="list" className="tw-m-0 tw-list-none tw-p-0">
        <hr className="tw-m-0" />

        <li>
          <a
            href="https://api.whatsapp.com/send?phone=79852114454"
            target="_blank"
            rel="noopener noreferrer"
            className="tw-flex tw-h-14 tw-items-center tw-gap-4 tw-py-4 tw-font-semibold tw-no-underline"
          >
            <WhatsAppIcon className="tw-h-6 tw-w-6" />
            <FormattedMessage id="care_support.write" /> WhatsApp
          </a>
        </li>

        <hr className="tw-m-0" />

        <li>
          <a
            href={`tel:${SUPPORT_PHONE_NUMBER}`}
            className="tw-flex tw-h-14 tw-items-center tw-gap-4 tw-py-4 tw-font-semibold tw-no-underline"
          >
            <PhoneIcon className="tw-h-4 tw-w-6" />
            <span className="tw-inline-block">
              <FormattedMessage id="care_support.call" />{" "}
              {formatPhoneNumber(SUPPORT_PHONE_NUMBER).replaceAll("-", " ")}
            </span>
          </a>
        </li>

        <hr className="tw-m-0" />
      </ul>
    </div>
  );
}

function WhatsAppIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={clsx("tw-fill-[#2BB641]", className)}
    >
      {/* Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.*/}
      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
    </svg>
  );
}
