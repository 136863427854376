import React from "react";
import { FormattedMessage } from "react-intl";

import { EXTRA_SERVICE_OPTION_TRANSLATION_MAP } from "~/common/constants";
import { CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT } from "~/common/courses";
import type { TInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";

export function PatientCardAdditionalServices({
  patient,
  services,
  old_instructions,
}: {
  patient: TPatient;
  services: RootState["services"];
  old_instructions: Partial<TInstructions>;
}) {
  return (
    <div style={{ marginBottom: "20px" }}>
      <h3 className="block">
        <FormattedMessage id="ADDITIONAL_INSTRUCTIONS" />
      </h3>

      {old_instructions.anthropometric_diagnostic ? (
        <label className="control-label">
          <FormattedMessage id="C_ANTHROPOMETRIC_DIAGNOSTIC_ITEM" />
        </label>
      ) : null}

      {renderExtraServices({ patient, services })}
    </div>
  );
}

function renderExtraServices({
  patient,
  services,
}: {
  patient: TPatient;
  services: RootState["services"];
}) {
  const course = patient.course;
  const rows = [];
  let deep_cbct_added = false;

  if (Array.isArray(services)) {
    deep_cbct_added = services.some((service) => service.tag == "CBCT_ANALYSIS");

    for (const service of services) {
      rows.push(
        <div key={service["id"]}>
          <label className="control-label">
            <FormattedMessage id={service.tag} />

            {service.option !== null && service.option.material !== null ? (
              <span>
                {" "}
                (
                <FormattedMessage
                  id={EXTRA_SERVICE_OPTION_TRANSLATION_MAP[service.option.material]}
                />{" "}
                )
              </span>
            ) : null}
          </label>
        </div>,
      );
    }
  }

  if (
    (course.course_option_tag === CO_TAG_FULL_3_STEPS_CBCT ||
      course.course_option_tag === CO_TAG_TEEN_3_STEPS_CBCT) &&
    deep_cbct_added === false
  ) {
    rows.push(
      <div key="cbct_for_full">
        <label className="control-label">
          <FormattedMessage id="CBCT_ANALYSIS" />
        </label>
      </div>,
    );
  }

  return rows;
}
