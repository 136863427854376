import React from "react";

import { useDocumentTitle } from "../../../hooks/use-document-title";
import { Portlet, PortletTitle } from "../../ui/portlet";
import { PublicLayout } from "../../ui/public-layout";

export default function Contacts() {
  useDocumentTitle("Контакты");

  return (
    <PublicLayout>
      <Portlet as="main">
        <PortletTitle iconClassName="icon-user-follow">
          Контактные данные организации
        </PortletTitle>
        <div className="portlet-body form">
          <h3>Адрес и телефон:</h3>
          <p>
            121205, Москва <br />
            Территория Инновационного Центра «Сколково» <br />
            Большой бульвар, 42, корп. 1<br />
            Лаборатория &quot;3D Smile&quot; <br />
            <br />
            +7 (495) 211-44-54 <br />
            <br />
            Электронная почта: <br />
            info@3d-smile.ru <br />
          </p>
          <h3>Реквизиты:</h3>
          <p>
            Общество с ограниченной ответственностью «Доммар» <br />
            ОГРН 1147746363368 <br />
            ИНН/КПП 7709951104/773101001 <br />
            Юридический адрес: 121205, Москва, Территория Инновационного Центра «Сколково»,
            Технопарк, Большой бульвар, 42, корп. 1<br />
          </p>
        </div>
      </Portlet>
    </PublicLayout>
  );
}
