import React from "react";
import { FormattedMessage } from "react-intl";

import { PortletTerm } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";

export function PatientCardClinic({ patient }: { patient: TPatient }) {
  return (
    <div>
      <h3 className="block" id="clinic-section">
        <FormattedMessage id="HEADER_CLINIC" />
      </h3>

      <PortletTerm id="clinic-name-label">
        <FormattedMessage id="clin_view.name.placeholder" />
      </PortletTerm>

      <p id="clinic-name-value">{patient.clinic ? patient.clinic.title : ""}</p>
    </div>
  );
}
