import React from "react";
import { FormattedMessage } from "react-intl";

import { translateRxOption } from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPrescription } from "~/types/prescription";

export function PatientCardCanines({ prescription }: { prescription: TPrescription }) {
  const { canine_ratio_right, canine_ratio_left, canine_method_ai, canine_method, canine_ipr } =
    prescription;

  return (
    <div>
      <h4 style={{ fontWeight: "500" }} className="block">
        <FormattedMessage id="HEADER_CANINE" />
      </h4>

      <div className="row">
        <div className="form-group col-md-3">
          <PortletTerm id="canines-ratio-right-label">
            <FormattedMessage id="CANINE_RIGHT" />
          </PortletTerm>

          <p id="canines-ratio-right-value">
            <FormattedMessage id={translateRxOption("canine_ratio_right", canine_ratio_right)} />
          </p>
        </div>

        <div className="form-group col-md-3">
          <PortletTerm id="canines-ratio-left-label">
            <FormattedMessage id="CANINE_LEFT" />
          </PortletTerm>

          <p id="canines-ratio-left-value">
            <FormattedMessage id={translateRxOption("canine_ratio_left", canine_ratio_left)} />
          </p>
        </div>

        {canine_method_ai ? (
          <div className="form-group col-lg-4 col-sm-6">
            <PortletTerm id="canines-ratio-howto-label">
              <FormattedMessage id="CANINE_HOWTO" />
            </PortletTerm>

            <p style={{ marginTop: "10px" }}>
              <FormattedMessage id={translateRxOption("canine_method_ai", canine_method_ai)} />
            </p>
          </div>
        ) : (
          <>
            <div className="form-group col-md-3">
              <PortletTerm id="canines-ratio-howto-label">
                <FormattedMessage id="CANINE_HOWTO" />
              </PortletTerm>

              <p id="canines-ratio-howto-value">
                <FormattedMessage id={translateRxOption("canine_method", canine_method)} />
              </p>
            </div>

            {canine_ipr ? (
              <div className="form-group col-lg-3 col-sm-5">
                <p style={{ marginTop: "10px" }}>
                  <FormattedMessage id={translateRxOption("canine_ipr", canine_ipr)} />
                </p>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
