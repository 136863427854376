import * as Tabs from "@radix-ui/react-tabs";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, type LinkProps } from "react-router-dom";
import { match } from "ts-pattern";

import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { ScrollToTop } from "~/components/ui/scroll-to-top";
import { useDocumentTitle } from "~/hooks/use-document-title";
import { useEnumSearchParam } from "~/hooks/use-enum-search-param";
import { useLoadingDelay } from "~/hooks/use-loading-delay";
import { useSearchParams } from "~/hooks/use-search-params";
import { useUpdateEffect } from "~/hooks/use-update-effect";
import { EventMeetingType } from "~/reducers/events";

import {
  type EventsQueryArgs,
  useGetEventsCitiesQuery,
  useGetEventsQuery,
  useGetUnpaidEventsQuery,
} from "./events-api";
import { PricingType, TrainingLevel } from "./events-const";
import { EventsFilters } from "./events-filters";
import { useUpcomingEventsPages } from "./events-layout";
import {
  EventsHeader,
  EventsInfiniteScroll,
  EventsTabsList,
  EventsTabsTrigger,
  useEventsSearch,
} from "./events-list-shared";

export enum Tab {
  SCHEDULE = "schedule",
  ENROLLED = "enrolled",
  RECORDINGS = "recordings",
}

export default function EventsListPage() {
  const intl = useIntl();
  useDocumentTitle(intl.formatMessage({ id: "events" }));

  const { pages, setNextPage, resetPages } = useUpcomingEventsPages();
  const [activeTab = Tab.SCHEDULE, setActiveTab] = useEnumSearchParam({ name: "tab", enum: Tab });
  const [meetingType, setMeetingType] = useEnumSearchParam({ name: "type", enum: EventMeetingType });
  const [trainingLevel, setTrainingLevel] = useEnumSearchParam({
    name: "training-level",
    enum: TrainingLevel,
  });
  const [pricingType, setPricingType] = useEnumSearchParam({ name: "pricing", enum: PricingType });
  const [cityId, setCityId] = useCityId();
  const { search, setSearch, debouncedSearch } = useEventsSearch();

  const [areFiltersOpen, setAreFiltersOpen] = useState(() => {
    return (
      meetingType != undefined ||
      trainingLevel != undefined ||
      cityId != undefined ||
      pricingType != undefined
    );
  });

  const [, setSearchParams] = useSearchParams();

  function resetFilters() {
    setSearch("");
    setSearchParams((prevParams) => {
      const names = ["type", "training-level", "city", "pricing"];
      for (const name of names) {
        prevParams.delete(name);
      }
      return prevParams;
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToRef = useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    const userHasScrolled = window.scrollY > window.innerHeight / 2;

    if (userHasScrolled) {
      // If you use smooth behavior, sometimes does not scroll on ios.
      scrollToRef.current?.scrollIntoView();
    }
  }, [activeTab]);

  const commonQueryArgs = {
    type: meetingType,
    training_level: trainingLevel,
    city_id: cityId,
    free: pricingType ? pricingType == PricingType.FREE : undefined,
    search: debouncedSearch || undefined,
    // Needs to be an even number for infinite scrolling pagination display
    // to work seemlessly. Every fetched page should have enough events to fill one grid.
    per_page: 10,
  };

  const offlineQueryArgs: EventsQueryArgs = {
    ...commonQueryArgs,
    endpoint: "schedule",
    page: pages[Tab.SCHEDULE],
  };

  const onlineQueryArgs: EventsQueryArgs = {
    ...commonQueryArgs,
    endpoint: "enrolled",
    page: pages[Tab.ENROLLED],
  };

  const recordingQueryArgs: EventsQueryArgs = {
    ...commonQueryArgs,
    endpoint: "recordings",
    page: pages[Tab.RECORDINGS],
  };

  const query = useGetEventsQuery(
    match(activeTab)
      .with(Tab.SCHEDULE, () => offlineQueryArgs)
      .with(Tab.ENROLLED, () => onlineQueryArgs)
      .with(Tab.RECORDINGS, () => recordingQueryArgs)
      .exhaustive(),
  );

  const citiesQuery = useGetEventsCitiesQuery();
  const cities = citiesQuery.data ?? [];

  const unpaidQuery = useGetUnpaidEventsQuery();

  const numberOfUnpaidUpcoming = unpaidQuery.isSuccess
    ? unpaidQuery.data[EventMeetingType.OFFLINE] + unpaidQuery.data[EventMeetingType.ONLINE]
    : 0;

  const numberOfUnpaidRecordings = unpaidQuery.isSuccess
    ? unpaidQuery.data[EventMeetingType.RECORDING]
    : 0;

  const isInitialLoading = query.isLoading;
  const isFetching = !query.isLoading && query.isFetching;
  const displayLoader = useLoadingDelay(isFetching);

  return (
    <main className="portlet light bordered tw-rounded-none tw-px-0 tw-py-4">
      <EventsHeader
        displayLoader={displayLoader}
        searchProps={{
          disabled: isInitialLoading,
          search: search,
          onSearchChange(newSearch) {
            setSearch(newSearch);
            resetPages();
          },
        }}
        onFiltersToggle={setAreFiltersOpen}
      />
      <div ref={scrollToRef}></div>

      <Tabs.Root
        className="[&>*]:tw-px-5"
        value={activeTab}
        onValueChange={(newValue) => setActiveTab(newValue as Tab)}
      >
        <EventsTabsList>
          <EventsTabsTrigger isActive={activeTab == Tab.SCHEDULE} value={Tab.SCHEDULE}>
            <FormattedMessage id="events.upcoming" />
          </EventsTabsTrigger>

          <EventsTabsTrigger isActive={activeTab == Tab.ENROLLED} value={Tab.ENROLLED}>
            <FormattedMessage id="events.enrolled" />
            {numberOfUnpaidUpcoming > 0 ? (
              <Badge
                className="tw-absolute -tw-right-4 -tw-top-4 sm:-tw-right-7"
                srOnlyText={intl.formatMessage({ id: "events.badge" })}
              >
                {numberOfUnpaidUpcoming}
              </Badge>
            ) : null}
          </EventsTabsTrigger>

          <EventsTabsTrigger isActive={activeTab == Tab.RECORDINGS} value={Tab.RECORDINGS}>
            <FormattedMessage id="events.recordings" />
            {numberOfUnpaidRecordings > 0 ? (
              <Badge
                className="tw-absolute -tw-right-4 -tw-top-4 sm:-tw-right-7"
                srOnlyText={intl.formatMessage({ id: "events.badge" })}
              >
                {numberOfUnpaidRecordings}
              </Badge>
            ) : null}
          </EventsTabsTrigger>
        </EventsTabsList>

        <EventsFilters
          isOpen={areFiltersOpen}
          onReset={resetFilters}
          cities={cities}
          cityId={cityId}
          onCityIdChange={setCityId}
          meetingType={meetingType}
          onMeetingTypeChange={setMeetingType}
          trainingLevel={trainingLevel}
          onTrainingLevelChange={setTrainingLevel}
          pricingType={pricingType}
          onPricingTypeChange={setPricingType}
        />

        <Tabs.Content value={Tab.SCHEDULE}>
          <EventsInfiniteScroll
            queryArgs={offlineQueryArgs}
            numberOfPages={pages[Tab.SCHEDULE]}
            fetchNextPage={() => setNextPage(Tab.SCHEDULE)}
            eventGridProps={{
              noEventsFallback: (
                <NoEvents
                  heading={<FormattedMessage id="events.upcoming.empty.heading" />}
                  description={<FormattedMessage id="events.upcoming.empty.description" />}
                />
              ),
            }}
          />
        </Tabs.Content>

        <Tabs.Content value={Tab.ENROLLED}>
          <EventsInfiniteScroll
            queryArgs={onlineQueryArgs}
            numberOfPages={pages[Tab.ENROLLED]}
            fetchNextPage={() => setNextPage(Tab.ENROLLED)}
            eventGridProps={{
              noEventsFallback: (
                <NoEvents
                  heading={<FormattedMessage id="events.enrolled.empty.heading" />}
                  description={<FormattedMessage id="events.enrolled.empty.description" />}
                  link={{
                    to: "/pages/events",
                    children: <FormattedMessage id="events.enrolled.empty.link" />,
                  }}
                />
              ),
            }}
          />
        </Tabs.Content>

        <Tabs.Content value={Tab.RECORDINGS}>
          <EventsInfiniteScroll
            queryArgs={recordingQueryArgs}
            numberOfPages={pages[Tab.RECORDINGS]}
            fetchNextPage={() => setNextPage(Tab.RECORDINGS)}
            eventGridProps={{
              noEventsFallback: (
                <NoEvents
                  heading={<FormattedMessage id="events.recordings.empty.heading" />}
                  description={
                    <FormattedMessage id="events.recordings.empty.description" />
                  }
                  link={{
                    to: "/pages/events",
                    children: <FormattedMessage id="events.recordings.empty.link" />,
                  }}
                />
              ),
            }}
            eventCardProps={{
              meetingType: EventMeetingType.RECORDING,
              displayAsRecording: true,
              displayVideoLink: true,
            }}
          />
        </Tabs.Content>
      </Tabs.Root>

      <ScrollToTop />
    </main>
  );
}

function NoEvents({
  heading,
  description,
  link,
}: {
  heading: React.ReactNode;
  description?: React.ReactNode;
  link?: LinkProps;
}) {
  return (
    <div className="tw-space-y-4 tw-p-3.5 tw-text-center">
      <strong className="tw-text-base">{heading}</strong>
      {description != null ? <p className="tw-my-0 tw-text-gray-400">{description}</p> : null}
      {link ? (
        <Button rounded asChild>
          <Link {...link}>{link.children}</Link>
        </Button>
      ) : null}
    </div>
  );
}

function useCityId() {
  const [searchParams, setSearchParams] = useSearchParams();
  const cityId = parseInt(searchParams.get("city") ?? "", 10) || undefined;

  function setCityId(newCityId: number) {
    setSearchParams(
      (prevParams) => {
        if (newCityId == undefined) {
          prevParams.delete("city");
        } else {
          prevParams.set("city", `${newCityId}`);
        }
        return prevParams;
      },
      { replace: true },
    );
  }

  return [cityId, setCityId] as const;
}
