import type { MessageDescriptor } from "react-intl";

import type { TPatient } from "~/types/patient";

// Memory
export const KB = 1024;
export const MB = KB * KB;
export const GB = KB * KB * KB;

// LIMITs
export const CBCT_MAX_FILESIZE = 1.5 * GB;

// Time
export const SEC = 1000;
export const MIN = SEC * 60;
export const HOUR = MIN * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

// Deploy
export const DEPLOY_USA = "DEPLOY_USA";
export const DEPLOY_RUSSIA = "DEPLOY_RUSSIA";

export enum RxType {
  STANDARD = 1,
  SHORT = 2,
}

export enum DentalNotationType {
  FDI = 1,
  PALMER = 2,
  UNIVERSAL = 3,
}

export const DN_MAP_PALMER: Record<number, string> = {
  18: 'UR8', 17: 'UR7', 16: 'UR6', 15: 'UR5', 14: 'UR4', 13: 'UR3', 12: 'UR2', 11: 'UR1',

  28: 'UL8', 27: 'UL7', 26: 'UL6', 25: 'UL5', 24: 'UL4', 23: 'UL3', 22: 'UL2', 21: 'UL1',

  48: 'LR8', 47: 'LR7', 46: 'LR6', 45: 'LR5', 44: 'LR4', 43: 'LR3', 42: 'LR2', 41: 'LR1',

  38: 'LL8', 37: 'LL7', 36: 'LL6', 35: 'LL5', 34: 'LL4', 33: 'LL3', 32: 'LL2', 31: 'LL1',
}

export const DN_MAP_UNIVERSAL: Record<number, string> = {
  18: '1', 17: '2', 16: '3', 15: '4', 14: '5', 13: '6', 12: '7', 11: '8',

  28: '16', 27: '15', 26: '14', 25: '13', 24: '12', 23: '11', 22: '10', 21: '9',

  48: '32', 47: '31', 46: '30', 45: '29', 44: '28', 43: '27', 42: '26', 41: '25',

  38: '17', 37: '18', 36: '19', 35: '20', 34: '21', 33: '22', 32: '23', 31: '24',
}

export const PLAN_LANG_OPTION_MAP = {
  [DentalNotationType.FDI]: "predict_fdi",
  [DentalNotationType.PALMER]: "predict_pns",
  [DentalNotationType.UNIVERSAL]: "predict_uns",
} as const;

export enum CommentType {
  COMMON = 0,
  CORRECTION = 1,
  REWORK = 2,
  APPROVE = 3,
  CE_QUESTION = 4,
  REWORK_PRESICE = 5,
  ORDER_RETAINERS = 6,
}

export const COMMENT_TYPE_OPTIONS: Partial<
  Record<CommentType, { color: string; translation: MessageDescriptor["id"] }>
> = {
  [CommentType.REWORK]: {
    color: "orange",
    translation: "COMMENT_TYPE_REWORK",
  },
  [CommentType.CORRECTION]: {
    color: "orange",
    translation: "COMMENT_TYPE_CORRECTION",
  },
  [CommentType.CE_QUESTION]: {
    color: "green",
    translation: "COMMENT_TYPE_CE_QUESTION",
  },
  [CommentType.REWORK_PRESICE]: {
    color: "red",
    translation: "COMMENT_TYPE_REWORK_PRESICE",
  },
  [CommentType.ORDER_RETAINERS]: {
    color: "green",
    translation: "COMMENT_TYPE_ORDER_RETAINERS",
  },
};

export enum FileType {
  _3SHAPE = "3shape",
  DICOM = "dicom",
  PREXION = "prexion",
  MEDIT = "medit",
}

export enum Gender {
  FEMALE = 1,
  MALE = 2,
}

export const GENDER_MAP = {
  [Gender.FEMALE]: "SEX_F",
  [Gender.MALE]: "SEX_M",
} as const;

export enum PaymentOptionId {
  ADVANCED = 1,
  SPLIT_2 = 2,
  SPLIT_3 = 3,
  SPLIT_2_2 = 4,
  SPLIT_3_2 = 5,
}

export enum PaymentOption {
  ADVANCED = "PO_ADVANCE",
  SPLIT_2 = "PO_SPLIT_2",
  SPLIT_3 = "PO_SPLIT_3",
  SPLIT_2_2 = "PO_SPLIT_2_2",
  SPLIT_3_2 = "PO_SPLIT_3_2",
}

export enum PaymentMethodId {
  INVOICE = 1,
  /** @deprecated */
  CASH = 2,
  CARD = 3,
}

export enum PaymentMethod {
  BANK = "PM_BANK",
  /** @deprecated */
  CASH = "PM_CASH",
  CARD = "PM_CARD",
}

export const DECIMAL_PRECISION = 2;

export enum Payer {
  CLINIC = 1,
  DOCTOR = 2,
  PATIENT = 3,
}

export const PAYERS_TRANSLATION_MAP = {
  [Payer.CLINIC]: "PAYER_CLINIC",
  [Payer.PATIENT]: "PAYER_PATIENT",
  [Payer.DOCTOR]: "PAYER_DOCTOR",
} as const;

export const PAYMENT_METHODS_TRANSLATION_MAP: Record<PaymentMethodId, MessageDescriptor["id"]> = {
  [PaymentMethodId.INVOICE]: "PM_BANK",
  [PaymentMethodId.CASH]: "PM_CASH",
  [PaymentMethodId.CARD]: "PM_CARD",
};

export const LINK_TARGET_BLANK = "_blank";
export const LINK_TARGET_SELF = "_self";

export const PAYMENT_OPTIONS_TRANSLATION_MAP: Record<PaymentOptionId, MessageDescriptor["id"]> = {
  [PaymentOptionId.ADVANCED]: "PO_ADVANCE",
  [PaymentOptionId.SPLIT_2]: "PO_SPLIT_2",
  [PaymentOptionId.SPLIT_2_2]: "PO_SPLIT_2_2",
  [PaymentOptionId.SPLIT_3]: "PO_SPLIT_3",
  [PaymentOptionId.SPLIT_3_2]: "PO_SPLIT_3_2",
} as const;

export enum SOMaterial {
  LAST_STAGE = 1,
  USE_SCAN = 2,
  USE_IMPRESSIONS = 3,
}

export enum SOArch {
  BOTH = 1,
  UPPER = 2,
  LOWER = 3,
}

export const EXTRA_SERVICE_OPTION_TRANSLATION_MAP = {
  [SOMaterial.LAST_STAGE]: "EXTRA_SERVICE_OPTION_LAST_STAGE",
  [SOMaterial.USE_SCAN]: "EXTRA_SERVICE_OPTION_USE_SCAN",
  [SOMaterial.USE_IMPRESSIONS]: "EXTRA_SERVICE_OPTION_USE_IMPRESSIONS",
} as const;

export const ARCHES = {
  1: "TA_BOTH",
  2: "TA_UPPER",
  3: "TA_LOWER",
} as const;

type TStatusOption = { value: number; label: TPatient["status"] };

export const statuses: TStatusOption[] = [
  { value: 0, label: "S_UNFILLED_CASE" },
  { value: 1, label: "S_INSTRUCTIONS_PROCESSING" },
  { value: 2, label: "S_RETAKE_IMPRESSIONS" },
  { value: 3, label: "S_WAITING_FOR_A_PAYMENT" },
  { value: 4, label: "S_3D_SCANNING" },
  { value: 5, label: "S_3D_PLAN_MODELING" },
  { value: 6, label: "S_3D_PLAN_APPROVAL" },
  { value: 7, label: "S_3D_PLAN_MODELS_BUILDING" },
  // {value:8, label: 'S_PRODUCTION_PRINTING'},
  // {value:9, label: 'S_PRODUCTION_FABRICATING'},
  { value: 10, label: "S_FINAL_STAGE_READY" },
  { value: 11, label: "S_TREATMENT_FINISHED" },
  { value: 12, label: "S_CANCEL_TREATMENT" },
  { value: 13, label: "S_RETAINERS_ORDERED" },
  { value: 22, label: "S_RETAINERS_RETAKE_IMPRESSIONS" },
  { value: 23, label: "S_RETAINERS_WAITING_FOR_A_PAYMENT" },
  { value: 14, label: "S_RETAINERS_PRINTING" },
  // {value:15, label: 'S_RETAINERS_FABRICATING'},
  { value: 16, label: "S_RETAINERS_READY" },
  // {value:17, label: 'S_RETAINERS_3D_SCANNING'},
];

export const statuses_predict: TStatusOption[] = [
  { value: 0, label: "S_UNFILLED_CASE" },
  { value: 1, label: "S_INSTRUCTIONS_PROCESSING" },
  { value: 2, label: "S_RETAKE_IMPRESSIONS" },
  { value: 3, label: "S_WAITING_FOR_A_PAYMENT" },
  { value: 4, label: "S_3D_SCANNING" },
  { value: 5, label: "S_3D_PLAN_MODELING" },
  { value: 6, label: "S_3D_PLAN_APPROVAL" },
  { value: 7, label: "S_3D_PLAN_MODELS_BUILDING" },
  // {value:8, label: 'S_PRODUCTION_PRINTING'},
  // {value:9, label: 'S_PRODUCTION_FABRICATING'},
  // {value:10, label: 'S_FINAL_STAGE_READY'},
  { value: 11, label: "S_TREATMENT_FINISHED" },
  { value: 12, label: "S_CANCEL_TREATMENT" },
  { value: 13, label: "S_RETAINERS_ORDERED" },
  { value: 14, label: "S_RETAINERS_PRINTING" },
  // {value:15, label: 'S_RETAINERS_FABRICATING'},
  { value: 16, label: "S_RETAINERS_READY" },
  // {value:17, label: 'S_RETAINERS_3D_SCANNING'},
];

export enum PaymentSystem {
  SBERBANK = "sberbank",
  YOOKASSA = "yookassa",
}

export const PAYMENT_SYSTEM_TYPE: PaymentSystem = PaymentSystem.YOOKASSA;

export const SBERBANK_DISCLAIMER = `Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение
с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола
шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified
By Visa или MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля.
Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации
обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев,
предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом
соответствии с требованиями платежных систем МИР, Visa Int. и MasterCard Europe Sprl.`;

// Common
export const PREDICT_SUPPORT_PHONE = "+1 888 333 8771";
export const PREDICT_SUPPORT_EMAIL = "support@3dpredict.com";

export enum ZIndex {
  SUPPORT_WIDGET = 49,
  YANDEX_METRIKA = 50,
}

export const SUPPORT_PHONE_NUMBER = "+74952114454";
