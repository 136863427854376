import clsx from "clsx";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useRequiredUser } from "~/common/user";
import { formatPersonName } from "~/components/common/PersonName";
import { Loader } from "~/components/ui/loader";
import { useScript } from "~/hooks/use-script";
import { useUpdateEffect } from "~/hooks/use-update-effect";
import type { TUser } from "~/types/user";

import { useGetMeetingsPatientsQuery } from "./meetings-api";
import type { TExpert } from "./meetings-types";

export function CabinetWidget({ expert }: { expert: TExpert }) {
  const user = useRequiredUser();

  const history = useHistory();
  const onBackClick = history.goBack;

  const containerRef = useRef<HTMLDivElement>(null);
  const scriptStatus = useScript("https://cabinet.fm/external/widget.js");
  const patientsQuery = useGetMeetingsPatientsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const [patientNumber, setPatientNumber] = useState(1);

  const patientIdsRef = useRef<number[]>([]);

  useLayoutEffect(() => {
    patientIdsRef.current = (patientsQuery.data ?? []).map(({ id }) => id);
  }, [patientsQuery.data]);

  useUpdateEffect(() => {
    const form = containerRef.current?.querySelector("form");
    if (!form) {
      return;
    }
    if (patientNumber > 1) {
      showExtraFormFields(form, patientNumber);
    }
    if (patientNumber == 3) {
      const addPatientButton = document.getElementById("add-patient-btn");
      addPatientButton?.classList.add("tw-hidden");
    }
  }, [patientNumber]);

  useEffect(() => {
    const isCabinetWidgetScriptLoaded = typeof Cabinet != "undefined";
    if (!isCabinetWidgetScriptLoaded) {
      return;
    }

    let observer: MutationObserver | undefined;
    let backButton: HTMLDivElement | undefined;

    function handleBackButtonClick(event: MouseEvent) {
      event.stopPropagation();
      onBackClick();
    }

    function handleAddPatientClick() {
      setPatientNumber((prevNumber) => Math.min(prevNumber + 1, 3));
    }

    const SOLOP_ACCOUNT_ID = 3342;

    if (typeof MutationObserver != "undefined") {
      observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type == "childList" && mutation.addedNodes.length > 0) {
            const addedNode = mutation.addedNodes[0];

            if (addedNode instanceof HTMLElement) {
              addedNode.querySelectorAll("button").forEach((button) => {
                if (isSelectButton(button)) {
                  button.click();
                } else if (
                  expert.account_id == SOLOP_ACCOUNT_ID &&
                  (button.textContent == "09:30" || button.textContent == "09:30Забронировать")
                ) {
                  // This expert has a fixed schedule and is only available from 09:00 am to 09:30 am
                  // Cabinet.fm only allows to pick 1 hour shifts, so we hide the button to schedule
                  // a meeting from 09:30 am to 10:00 am
                  button.style.visibility = "hidden";
                } else {
                  changeContinueButton(button);
                  changeBookButton(button);
                }
              });
            }

            if (addedNode instanceof HTMLButtonElement) {
              changeBookButton(addedNode);
              if (
                expert.account_id == SOLOP_ACCOUNT_ID &&
                (addedNode.textContent == "09:30" || addedNode.textContent == "09:30Записаться")
              ) {
                // This expert has a fixed schedule and is only available from 09:00 am to 09:30 am
                // Cabinet.fm only allows to pick 1 hour shifts, so we hide the button to schedule
                // a meeting from 09:30 am to 10:00 am
                addedNode.style.visibility = "hidden";
              }
            }

            if (addedNode instanceof HTMLDivElement) {
              changeBookText(addedNode);

              if (isBackButton(addedNode)) {
                if (backButton) {
                  onBackClick();
                } else {
                  backButton = addedNode;
                  backButton.addEventListener("click", handleBackButtonClick);
                }
              }

              if (isSuccessScreen(addedNode)) {
                addedNode.appendChild(createBackBtn(onBackClick));
                window.scrollTo(0, 0);
              }
            }

            if (addedNode instanceof HTMLFormElement) {
              const form = addedNode;
              fillInForm(form, user, expert);
              hideExtraFormFields(form);
              insertAddPatientButton(form, handleAddPatientClick);

              if (backButton) {
                backButton.removeEventListener("click", handleBackButtonClick);
              }

              const bookButton = getBookButton(form);
              const patientIdInput = getPatientIdInput(form);

              if (bookButton && patientIdInput) {
                bookButton.addEventListener("click", function (event) {
                  const maybePatientId = patientIdInput.value.trim();
                  const validPatientIds = patientIdsRef.current;

                  const isPositiveInteger = /^[1-9]\d*$/.test(maybePatientId);
                  const isPatientIdValid =
                    isPositiveInteger && validPatientIds.includes(Number(maybePatientId));

                  const parent = patientIdInput.parentElement;
                  parent?.querySelector("#patient-id-error")?.remove();

                  if (maybePatientId && !isPatientIdValid) {
                    event.preventDefault();
                    parent?.appendChild(createPatientIdError());
                  }
                });

                patientIdInput.addEventListener("input", function () {
                  const maybePatientId = patientIdInput.valueAsNumber;
                  const validPatientIds = patientIdsRef.current;
                  const isPatientIdValid = validPatientIds.includes(maybePatientId);

                  if (maybePatientId && isPatientIdValid) {
                    const parent = patientIdInput.parentElement;
                    parent?.querySelector("#patient-id-error")?.remove();
                  }
                });
              }
            }
          }
        }
      });

      if (containerRef.current) {
        observer.observe(containerRef.current, { subtree: true, childList: true });
      }
    }

    Cabinet.init({
      container: "#cabinet-container",
      company: parseInt(process.env.CABINET_COMPANY, 10),
      id: parseInt(expert.link, 10),
      locale: "ru",
    });

    return () => {
      observer?.disconnect();
    };
  }, [scriptStatus, user, expert, onBackClick]);

  return (
    <>
      {scriptStatus == "loading" ? <CabinetWidgetLoader /> : null}

      <div
        id="cabinet-container"
        ref={containerRef}
        style={
          {
            "--cb-shadow": "none",
          } as React.CSSProperties
        }
      ></div>

      <datalist id="patients">
        {patientsQuery.data?.map((patient) => (
          <option key={patient.id} value={patient.id}>
            {patient.name}
          </option>
        ))}
      </datalist>
    </>
  );
}

export function CabinetWidgetLoader() {
  return (
    <div className="tw-mx-auto tw-grid tw-min-h-[570px] tw-max-w-[700px] tw-place-items-center tw-bg-white">
      <Loader />
    </div>
  );
}

export function createBackBtn(onClick: () => void): HTMLButtonElement {
  const btn = document.createElement("button");
  btn.textContent = "Записаться к другому эксперту";
  btn.className = clsx(
    "tw-border-none tw-bg-brand-green tw-px-4 tw-py-2 tw-uppercase tw-text-white",
    "tw-text-xs tw-transition-colors tw-duration-250 hover:tw-bg-brand-green-hover",
    "tw-mx-auto -tw-mt-4 tw-mb-4 tw-block",
  );
  btn.onclick = onClick;
  return btn;
}

function createPatientIdError(): HTMLDivElement {
  const error = document.createElement("div");
  error.id = "patient-id-error";
  error.textContent = "Пожалуйста введите правильный ID пациента";
  error.style.color = "#DA6F6F";
  error.style.fontSize = "0.75em";
  error.style.marginTop = "9px";
  error.style.lineHeight = "1.4";
  return error;
}

export function isSelectButton(button: HTMLButtonElement) {
  return button.textContent?.toLowerCase() == "выберите";
}

function isBookButton(button: HTMLButtonElement) {
  return button.textContent?.toLowerCase() == "записаться";
}

export function isSuccessScreen(div: HTMLDivElement): boolean {
  for (const textNode of textNodes(div)) {
    if (textNode.textContent && /поздравляем/i.test(textNode.textContent)) {
      return true;
    }
  }
  return false;
}

export function changeContinueButton(button: HTMLButtonElement) {
  if (button.textContent && /продолжить/i.test(button.textContent)) {
    // We perform a replace because continue button's textContent includes an arrow on mobile screens.
    button.textContent = button.textContent.replace(/продолжить/i, "Записаться");
  }
}

export function changeBookButton(button: HTMLButtonElement) {
  button.querySelectorAll("span").forEach((span) => {
    if (span.textContent?.toLowerCase() == "забронировать") {
      span.textContent = "Записаться";
    }
  });
}

export function changeBookText(div: HTMLDivElement) {
  for (const textNode of textNodes(div)) {
    if (!textNode.textContent) {
      continue;
    }
    if (/бронирования/i.test(textNode.textContent)) {
      textNode.textContent = textNode.textContent.replace(/бронирования/i, "записи");
    } else if (/Бронирование прошло/i.test(textNode.textContent)) {
      textNode.textContent = textNode.textContent.replace(/Бронирование прошло/i, "Запись прошла");
    } else if (/сотрудника/i.test(textNode.textContent)) {
      textNode.textContent = textNode.textContent.replace(/сотрудника/i, "эксперта");
    }
  }
}

function getPatientIdInput(form: HTMLFormElement): HTMLInputElement | undefined {
  return Array.from(form.querySelectorAll("input")).find((input) =>
    testInputLabel(input, "ID пациента"),
  );
}

function getBookButton(form: HTMLFormElement): HTMLButtonElement | undefined {
  return Array.from(form.querySelectorAll("button")).find(isBookButton);
}

export function fillInForm(form: HTMLFormElement, user: TUser, expert?: TExpert): void {
  for (const input of form.querySelectorAll("input")) {
    if (input.type == "tel") {
      changeInputValue(input, user.phone);
    } else if (input.type == "email") {
      changeInputValue(input, user.email);
    } else if (testInputLabel(input, "Имя Фамилия")) {
      changeInputValue(input, formatPersonName({ person: user, useMiddleName: true }));
    } else if (testInputLabel(input, "ID доктора")) {
      changeInputValue(input, String(user.account_id));
      hideInput(input);
    } else if (testInputLabel(input, "ID пациента")) {
      input.setAttribute("list", "patients");
    } else if (testInputLabel(input, "ID эксперта") && expert) {
      changeInputValue(input, String(expert.account_id));
      hideInput(input);
    }
  }
  for (const textarea of form.querySelectorAll("textarea")) {
    if (testInputLabel(textarea, "Комментарий")) {
      if (expert) {
        hideInput(textarea);
      } else {
        textarea.rows = 5;
      }
    } else if (testInputLabel(textarea, "Вопрос по пациенту")) {
      textarea.rows = 5;
    }
  }
}

function insertAddPatientButton(form: HTMLFormElement, onClick: () => void): void {
  for (const textarea of form.querySelectorAll("textarea")) {
    if (testInputLabel(textarea, "Вопрос по пациенту")) {
      textarea.parentElement?.parentElement?.appendChild(createAddPatientButton(onClick));
      break;
    }
  }
}

function createAddPatientButton(onClick: () => void): HTMLButtonElement {
  const btn = document.createElement("button");
  const span = document.createElement("span");
  span.textContent = "+";
  span.className = "tw-inline-block tw-text-2xl";
  btn.type = "button";
  btn.id = "add-patient-btn";
  btn.className = clsx(
    "tw-h-10 tw-w-10 tw-border-none tw-bg-brand-green tw-p-0 tw-uppercase tw-text-white",
    "tw-transition-colors tw-duration-250 hover:tw-bg-brand-green-hover",
    "tw-mx-auto tw-mb-4 tw-block tw-rounded-full ",
  );
  btn.addEventListener("click", onClick);
  btn.appendChild(span);
  return btn;
}

function hideExtraFormFields(form: HTMLFormElement): void {
  const inputsToHide = [2, 3].map((n) => `ID пациента ${n}`);
  const menusToHide = [2, 3].map((n) => `Тема ${n}`);
  const textAreasToHide = [2, 3].map((n) => `Вопрос по пациенту ${n}`);

  Array.from(form.querySelectorAll("input"))
    .filter((input) => inputsToHide.some((label) => testInputLabel(input, label)))
    .forEach(hideInput);

  Array.from(textNodes(form))
    .filter((textNode) => menusToHide.some((label) => textNode.textContent == label))
    .forEach((textNode) => {
      if (textNode.parentElement) {
        hideInput(textNode.parentElement);
      }
    });

  Array.from(form.querySelectorAll("textarea"))
    .filter((textarea) => textAreasToHide.some((label) => testInputLabel(textarea, label)))
    .forEach(hideInput);
}

function showExtraFormFields(form: HTMLFormElement, index: number): void {
  for (const input of form.querySelectorAll("input")) {
    if (testInputLabel(input, `ID пациента ${index}`)) {
      showInput(input);
      input.setAttribute("type", "number");
      input.setAttribute("list", "patients");
      if (input.previousSibling instanceof HTMLElement) {
        input.previousSibling.innerHTML = input.previousSibling.innerHTML.replace(
          `${index}`,
          `<span class="tw-invisible">${index}</span>`,
        );
      }
      break;
    }
  }

  for (const textNode of textNodes(form)) {
    if (textNode.textContent == `Тема ${index}` && textNode.parentElement) {
      showInput(textNode.parentElement);
      if (textNode.parentElement instanceof HTMLElement) {
        textNode.parentElement.innerHTML = textNode.parentElement.innerHTML.replace(
          `${index}`,
          `<span class="tw-invisible">${index}</span>`,
        );
      }
      break;
    }
  }

  for (const textarea of form.querySelectorAll("textarea")) {
    if (testInputLabel(textarea, `Вопрос по пациенту ${index}`)) {
      showInput(textarea);
      textarea.setAttribute("rows", "5");
      if (textarea.previousSibling instanceof HTMLElement) {
        textarea.previousSibling.innerHTML = textarea.previousSibling.innerHTML.replace(
          `${index}`,
          `<span class="tw-invisible">${index}</span>`,
        );
      }
      break;
    }
  }
}

/**
 * @see https://stackoverflow.com/a/46012210
 */
function changeInputValue(input: HTMLInputElement, value: string): void {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
  )?.set;
  nativeInputValueSetter?.call(input, value);

  const event = new Event("input", { bubbles: true });
  input.dispatchEvent(event);
}

function testInputLabel(input: HTMLInputElement | HTMLTextAreaElement, label: string): boolean {
  if (!input.parentElement) {
    return false;
  }

  for (const textNode of textNodes(input.parentElement)) {
    if (textNode.textContent == label) {
      return true;
    }
  }

  return false;
}

function* textNodes(element: HTMLElement): Generator<Node> {
  const nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT);

  for (let textNode; (textNode = nodeIterator.nextNode()); ) {
    yield textNode;
  }
}

function hideInput(input: HTMLInputElement | HTMLTextAreaElement | Node): void {
  if (!input.parentElement) {
    return;
  }

  input.parentElement.classList.add("tw-invisible", "tw-absolute");

  if (input instanceof HTMLInputElement) {
    input.readOnly = true;
    input.type = "hidden";
  }
}

function showInput(input: HTMLInputElement | HTMLTextAreaElement | Node): void {
  if (!input.parentElement) {
    return;
  }

  input.parentElement.classList.remove("tw-invisible", "tw-absolute");

  if (input instanceof HTMLInputElement) {
    input.readOnly = false;
  }
}

export function isBackButton(addedNode: HTMLDivElement): boolean {
  return addedNode.textContent == "← Назад";
}
