import React from "react";
import { FormattedMessage } from "react-intl";

import { PAYERS_TRANSLATION_MAP } from "~/common/constants";
import { SBCreditComponent } from "~/components/common/common";
import ProbablyFormattedMessage from "~/components/probably_formatted_message";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";

export function PatientCardPayment({ patient }: { patient: TPatient }) {
  return (
    <div>
      <h3 className="block" id="payment-section">
        <FormattedMessage id="BLOCKHEAD_PAYMENT" />
      </h3>

      <PortletTerm id="payer-label">
        <FormattedMessage id="HEADER_PAYER" />
      </PortletTerm>

      <p id="payer-value">
        {patient.payer_id ? (
          <FormattedMessage id={PAYERS_TRANSLATION_MAP[patient.payer_id]} />
        ) : (
          <FormattedMessage id="None" />
        )}
      </p>

      <PortletTerm id="payment-method-label">
        <FormattedMessage id="HEADER_PAYMENT_METHOD" />
      </PortletTerm>

      <p id="payment-method-value">
        <ProbablyFormattedMessage id={patient.course.payment_method} />
      </p>

      <PortletTerm id="payment-option-label">
        <FormattedMessage id="HEADER_PAYMENT_OPTION" />
      </PortletTerm>

      <div id="payment-option-value">
        {patient.sber_credit ? (
          <SBCreditComponent showConditions={false} />
        ) : (
          <ProbablyFormattedMessage id={patient.course.payment_option} />
        )}
      </div>
      <br />

      {patient.logistics_comment ? (
        <>
          <PortletTerm id="payment-option-label">
            <FormattedMessage id="CLINIC_SHIP_COMMENTS" />
          </PortletTerm>

          <p id="payment-option-value">{patient.logistics_comment}</p>
        </>
      ) : null}
    </div>
  );
}
