import clsx from "clsx";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";

import { api } from "../api";
import { deployedUSA } from "../common/utils";
import { API_DOCTOR_OFFERS_PRIVATE_STATIC } from "../config";
import { useAppSelector } from "../store";
import { RemoteLogErrorBoundary } from "./common/remote-log-error-boundary";
import styles from "./page_footer.module.css";

export default function PageFooter(props: { center?: boolean }) {
  return (
    <RemoteLogErrorBoundary component="page_footer" fallbackRender={null}>
      {deployedUSA() ? <PageFooterUSA {...props} /> : <PageFooterRussia {...props} />}
    </RemoteLogErrorBoundary>
  );
}

function PageFooterRussia({ center = false }: React.ComponentProps<typeof PageFooter>) {
  const intl = useIntl();
  const user = useAppSelector((state) => state.user);

  const location = useLocation();
  const isPrivatePage = location.pathname.startsWith("/pages");

  return (
    <PageFooterContainer center={center}>
      <PageFooterFirstRow>
        <span>
          <PageFooterYear /> <FormattedMessage id="page.footer.header" />
        </span>
        <a href="https://3d-smile.ru" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="page.footer.content" />
        </a>
      </PageFooterFirstRow>

      {intl.locale == "ru" ? (
        <PageFooterSecondRow>
          <a href="https://3d-smile.ru/policy" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="Privacy" />
          </a>

          <a href="/contacts">
            <FormattedMessage id="page.footer.contacts" />
          </a>

          {/* Without checking for the private page, the footer will change on the login page
              while user is being logged in. */}
          {isPrivatePage && user.has_signed_offer == true ? (
            <a href={API_DOCTOR_OFFERS_PRIVATE_STATIC}>
              <FormattedMessage id="page.footer.offer" />
            </a>
          ) : null}

          {isPrivatePage && Object.keys(user).length > 0 ? (
            <NavLink to="/payment/details">
              <FormattedMessage id="page.footer.payment" />
            </NavLink>
          ) : null}
        </PageFooterSecondRow>
      ) : null}
    </PageFooterContainer>
  );
}

function PageFooterUSA({ center = false }: React.ComponentProps<typeof PageFooter>) {
  const user = useAppSelector((state) => state.user);

  const location = useLocation();
  const isPrivatePage = location.pathname.startsWith("/pages");

  return (
    <PageFooterContainer center={center}>
      <PageFooterFirstRow>
        <span>
          <PageFooterYear /> <FormattedMessage id="page.footer.header.usa" />
        </span>
        <a href="https://3dpredict.com" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="page.footer.content.usa" />
        </a>
      </PageFooterFirstRow>

      {/* Without checking for the private page, the footer will change on the login page
          while user is being logged in. */}
      {isPrivatePage && user.has_signed_offer == true ? (
        <a href={API_DOCTOR_OFFERS_PRIVATE_STATIC} rel="noopener noreferrer" target="_blank">
          <FormattedMessage id="page.predict.footer.offer" />
        </a>
      ) : null}
    </PageFooterContainer>
  );
}

export function useServerYear(): number | undefined {
  const query = api.useGetYearQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  return query.data?.year;
}

export function PageFooterYear() {
  return <span style={{ width: "3.5ch" }}>{useServerYear()}</span>;
}

export function PageFooterContainer({
  center,
  children,
}: {
  center: boolean;
  children: React.ReactNode;
}) {
  return (
    <footer className={clsx(styles.footer, center ? styles.center : "")}>
      <div className={styles.footerInner}>{children}</div>
    </footer>
  );
}

export function PageFooterFirstRow({ children }: { children: React.ReactNode }) {
  return <div className={styles.firstRow}>{children}</div>;
}

export function PageFooterSecondRow({ children }: { children: React.ReactNode }) {
  return <nav className={styles.secondRow}>{children}</nav>;
}
