import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getBanners } from "../actions/banners";
import { useDocumentTitle } from "../hooks/use-document-title";
import { useAppDispatch, useAppSelector } from "../store";
import RenderBanners from "./promotion/banners";
import { Layout } from "./ui/layout";
import { Portlet, PortletTitle } from "./ui/portlet";

export default function HomePage() {
  useDocumentTitle(useIntl().formatMessage({ id: "pat_table.block_banners" }));
  const dispatch = useAppDispatch();
  const banners = useAppSelector((state) => state.banners);
  const bannersHome = banners.filter((banner) => banner.align == "center");

  useEffect(() => {
    void dispatch(getBanners());
  }, [dispatch]);

  return (
    <Layout>
      <Portlet as="main">
        <PortletTitle iconClassName="icon-pin">
          <FormattedMessage id="pat_table.block_banners" />
        </PortletTitle>

        <div id="carousel-home" className="carousel slide">
          <div className="carousel-inner" role="listbox">
            <RenderBanners banners={bannersHome} mobile={true} />
          </div>
        </div>
      </Portlet>
    </Layout>
  );
}
