import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { Field, FormSection, getFormValues } from "redux-form";

import { getLastCorrection, isPatient } from "~/common/patient";
import type { ElasticRecoilLeft } from "~/common/prescription";
import { convertToDentalNotation } from "~/common/utils";
import type { RootState } from "~/store";
import type { ValueOf } from "~/types/common";
import type { TPrescription } from "~/types/prescription";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    formValues: getFormValues("correction")(state) as TPrescription,
  };
};

type PlanCorrectionButtonsHooksLeftProps = PropsFromRedux & {
  defaultValue: number[];
  elastics_recoil_left: ValueOf<typeof ElasticRecoilLeft> | null | undefined;
  resetForm(...args: string[]): void;
};

type PlanCorrectionButtonHooksLeftState = {
  name: string | null;
  isChecked: boolean;
};

class PlanCorrectionButtonsHooksLeft extends Component<
  PlanCorrectionButtonsHooksLeftProps,
  PlanCorrectionButtonHooksLeftState
> {
  teeth: number[][];

  constructor(props: PlanCorrectionButtonsHooksLeftProps) {
    super(props);
    const quads = [1, 2, 4, 3];
    this.teeth = quads.map((quad) => {
      const arr = [];
      for (let i = 1; i <= 8; i++) {
        arr.push(quad * 10 + i);
      }
      if (quad === 1 || quad === 4) arr.reverse();
      return arr;
    });
    this.state = {
      name: null,
      isChecked: false,
    };
    this.setClassName = this.setClassName.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>, i: number) {
    if (e.target.checked === true) {
      this.setState({ name: e.target.name, isChecked: true });
    } else if (e.target.checked === false) {
      this.setState({ name: null, isChecked: !this.state.isChecked });
      this.props.resetForm(`elastics_options.${i}`);
    }
  }

  setClassName(i: number) {
    const elastics_options = isPatient(this.props.patient)
      ? getLastCorrection(this.props.patient).prescription?.elastics_options ?? null
      : null;

    if (
      this.props.formValues.elastics_options &&
      this.props.formValues.elastics_options[i] === "2"
    ) {
      return "button_hook_tip button";
    } else if (
      this.props.formValues.elastics_options &&
      this.props.formValues.elastics_options[i] === "1"
    ) {
      return "button_hook_tip hook";
    } else if (
      this.props.formValues.elastics_options &&
      this.props.formValues.elastics_options[i] === 2 &&
      elastics_options &&
      elastics_options[i] === 2
    ) {
      return "button_hook_tip button";
    } else if (
      this.props.formValues.elastics_options &&
      this.props.formValues.elastics_options[i] === 1 &&
      elastics_options &&
      elastics_options[i] === 1
    ) {
      return "button_hook_tip hook";
    } else if (!this.props.elastics_recoil_left) {
      return "button_hook_tip input_button elastic_disabled";
    } else {
      return "button_hook_tip input_button";
    }
  }

  render() {
    const allTeeth = this.teeth.map(this.renderQuadrant.bind(this));
    const upperTeeth = allTeeth.slice(1, 2);
    const lowerTeeth = allTeeth.slice(3, 4);

    return (
      <div>
        <div style={{ marginBottom: "0.5rem" }} className="teeth-controls-upper checkbox-list">
          {upperTeeth}
        </div>
        <div className="teeth-controls-lower checkbox-list">{lowerTeeth}</div>
      </div>
    );
  }

  renderQuadrant(quad: number[], i: number) {
    const teeth = quad.map(this.renderTooth.bind(this));

    return (
      <div key={i} style={{ borderRight: "none" }} className="checkbox-list teeth-controls">
        {teeth}
      </div>
    );
  }

  renderTooth(i: number) {
    const { name, isChecked } = this.state;
    const { user, defaultValue = [], elastics_recoil_left } = this.props;
    const dentalNotation = user.preferences?.dental_notation;
    const { elastics_options } = this.props.formValues;

    return (
      <label key={i}>
        {convertToDentalNotation(i, dentalNotation)}

        <div className={this.setClassName(i)}>
          {elastics_options[i] === 2 ||
          elastics_options[i] === 1 ||
          elastics_options[i] === "2" ||
          elastics_options[i] === "1" ? (
            <FormattedMessage id="CANCEL" tagName="span" />
          ) : null}

          <input
            disabled={elastics_recoil_left ? false : true}
            type="checkbox"
            name={`options_${i}`}
            onChange={(e) => this.handleChange(e, i)}
            defaultChecked={defaultValue.includes(i)}
          />
        </div>

        {name === `options_${i}` && isChecked && elastics_recoil_left ? (
          <FormSection name="elastics_options" className="button_hook">
            <div>
              <Field
                component="input"
                name={`${i}`}
                type="radio"
                id={`button${i}`}
                value={2}
                onChange={(e) => this.setState({ name: e.target.name })}
              />
              &nbsp;
              <label htmlFor={`button${i}`}>
                <FormattedMessage id="BUTTON" tagName="span" />
              </label>
            </div>

            <div>
              <Field
                component="input"
                name={`${i}`}
                type="radio"
                id={`hook${i}`}
                value={1}
                onChange={(e) => this.setState({ name: e.target.name })}
              />
              &nbsp;
              <label htmlFor={`hook${i}`}>
                <FormattedMessage id="HOOK" tagName="span" />
              </label>
            </div>
          </FormSection>
        ) : null}
      </label>
    );
  }
}

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PlanCorrectionButtonsHooksLeft);
