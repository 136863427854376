import { Payer } from "~/common/constants";
import { isChildrenCourse, isRetainersCourse, isTeenCourse } from "~/common/courses";
import { convertReduxFormOcclusalPlaneTeethToArray } from "~/common/instructions";
import {
  getOcclusalPlaneTeeth,
  OcclusalPlane,
  OcclusalPlaneAction,
} from "~/common/prescription";
import type { TInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

/**
 * When you update a patient, depending on the status, form values could be `undefined`.
 */
export type PatientUpdateFormValues = TPrescriptionReduxForm | undefined;

export const PATIENT_NEW_REQUIRED_FIELDS = [
  "validation-clinic_id",
  "validation-payer_id",
  "validation-payment_method_id",
  "validation-payment_option_id",
  "patient-block-body",
  "validation-email",
  "validation-course_id",
  "validation-condition",
  "validation-comment",
  "validation-material",
  "validation-test_plastic",
  "validation-arch",
  "validation-vertical_overlap_comment",
  "validation-midline",
  "validation-occlusal_plane",
  "photo-protocol-block",
] as const;

export type TPatientNewRequiredField = (typeof PATIENT_NEW_REQUIRED_FIELDS)[number];

export function validatePatientName(
  instructions: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
  { ignoreDoctorId = false }: { ignoreDoctorId?: boolean } = {},
): boolean {
  return (
    (["doctor_id", "first_name", "last_name"] as const).filter((field) => {
      if (ignoreDoctorId && field == "doctor_id") {
        return false;
      }

      let value = instructions[field];
      if (typeof value == "string") {
        value = value.trim();
      }
      const isValid = Boolean(value);

      $(`[name="${field}"]`)
        .parent()
        .find("label")
        .css({ color: field !== "doctor_id" && !isValid ? "red" : "#34495e" });

      if (!isValid) {
        emptyFields.push("patient-block-body");
      }

      return !isValid;
    }).length == 0
  );
}

export function validateMaterial(
  formValues: PatientUpdateFormValues,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  return (
    (["material"] as const).filter((elm) => {
      const isValid =
        formValues != null &&
        Object.prototype.hasOwnProperty.call(formValues, elm) &&
        formValues[elm] !== null;

      $(`#validation-${elm}`).css({ color: !isValid ? "red" : "#34495e" });

      if (!isValid) {
        emptyFields.push(`validation-${elm}`);
      }

      return !isValid;
    }).length == 0
  );
}

export function validateVerticalOverlapComment(
  formValues: PatientUpdateFormValues,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  const vertical_overlap_comment =
    formValues &&
    Object.prototype.hasOwnProperty.call(formValues, "vertical_overlap") &&
    (formValues.vertical_overlap === 1 || formValues.vertical_overlap === 2);

  return (
    (["vertical_overlap_comment"] as const).filter((elm) => {
      if (!vertical_overlap_comment) {
        return false;
      }

      const isValid =
        Object.prototype.hasOwnProperty.call(formValues, elm) && formValues[elm] !== "";

      $(`#validation-${elm}`).css({ color: !isValid ? "red" : "#34495e" });

      if (!isValid) {
        emptyFields.push(`validation-${elm}`);
      }

      return !isValid;
    }).length == 0
  );
}

export function validateTestPlastic(
  { test_plastic }: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  const isValid = typeof test_plastic == "boolean";

  $(`#validation-test_plastic`).css({ color: !isValid ? "red" : "#34495e" });

  if (!isValid) {
    emptyFields.push("validation-test_plastic");
  }

  return isValid;
}

export function validatePayer(
  instructions: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  const isValid =
    instructions.payer_id != null && [1, 2, 3, "1", "2", "3"].includes(instructions.payer_id);

  $("#validation-payer_id").css({ color: !isValid ? "red" : "#34495e" });

  if (!isValid) {
    emptyFields.push("validation-payer_id");
  }

  return isValid;
}

export function validatePaymentOption(
  instructions: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  const isValid =
    (instructions.payment_option_id != null &&
      [1, 2, 3, 4, 5, "1", "2", "3", "4", "5"].includes(instructions.payment_option_id)) ||
    instructions.sber_credit == true;

  $("#validation-payment_option_id").css({ color: !isValid ? "red" : "#34495e" });

  if (!isValid) {
    emptyFields.push("validation-payment_option_id");
  }

  return isValid;
}

export function validateEmail(
  instructions: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  if (instructions.payer_id != Payer.PATIENT) {
    return true;
  }

  const isValid = typeof instructions.email == "string" && isValidEmailAddress(instructions.email);

  $("#validation-email").css({ color: !isValid ? "red" : "#34495e" });

  if (!isValid) {
    emptyFields.push("validation-email");
  }

  return isValid;
}

export function validateClinicAndPaymentMethodAndCourse(
  instructions: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  return !(["clinic_id", "payment_method_id", "course_id"] as const).filter((field) => {
    const isValid = instructions[field] != null && !Number.isNaN(instructions[field]);

    $(`#validation-${field}`).css({ color: !isValid ? "red" : "#34495e" });

    if (!isValid) {
      emptyFields.push(`validation-${field}`);
    }

    return !isValid;
  }).length;
}

export function validateArch(
  formValues: PatientUpdateFormValues,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  const isValid =
    formValues != null &&
    Object.prototype.hasOwnProperty.call(formValues, "arch") &&
    !Number.isNaN(formValues["arch"]) &&
    formValues["arch"] !== null;

  if (!isValid) {
    emptyFields.push("validation-arch");
  }

  $("#validation-arch").css({ color: !isValid ? "red" : "#34495e" });

  return isValid;
}

export function validateLinks(links: string[], emptyFields: TPatientNewRequiredField[]): boolean {
  return (
    links.filter((link) => {
      const isValid = link != "";

      if (!isValid) {
        emptyFields.push("validation-link");
      }

      return !isValid;
    }).length == 0
  );
}

export const PATIENT_NEW_REQUIRED_PHOTOS = [
  "front_view",
  "full_face_with_smile",
  "full_face_without_smile",
  "profile",
  "lateral_view_left",
  "lateral_view_right",
  "occlusal_view_lower",
  "occlusal_view_upper",
] as const;

export function validatePhotos(
  instructions: Partial<TInstructions>,
  media: RootState["media"],
  emptyFields: TPatientNewRequiredField[],
  patient?: TPatient,
): boolean {
  return !PATIENT_NEW_REQUIRED_PHOTOS.filter((elm) => {
    if (isRetainersCourse(instructions.course_id)) {
      return false;
    }

    let isValid = media[elm] != null && media[elm]?.md5 != null;

    if (!isValid && patient) {
      isValid = patient.media.required_images[elm] != null;
    }

    if (!isValid) {
      emptyFields.push("photo-protocol-block");
    }

    $(`#${elm}`)
      .parent()
      .prev()
      .prev()
      .css({ color: !isValid ? "red" : "#34495e" });

    return !isValid;
  }).length;
}

export function validatePatientPayerNameForChildrenOrTeenCourse(
  instructions: Partial<TInstructions>,
  emptyFields: TPatientNewRequiredField[],
): boolean {
  const { payer_id, course_id } = instructions;

  const shouldValidate =
    payer_id == Payer.PATIENT && (isChildrenCourse(course_id) || isTeenCourse(course_id));

  if (!shouldValidate) {
    return true;
  }

  return (
    (["payer_first_name", "payer_last_name", "payer_patronymic"] as const).filter((field) => {
      const isValid = Boolean(instructions[field]);

      if (!isValid) {
        emptyFields.push("patient-block-body");
      }

      $(`[name="${field}"]`)
        .parent()
        .find("label")
        .css({ color: !isValid ? "red" : "#34495e" });

      return !isValid;
    }).length == 0
  );
}

export function validateOcclusalPlane(
  instructions: Partial<TInstructions>,
  formValues: PatientUpdateFormValues,
  emptyFields: TPatientNewRequiredField[],
) {
  if (isRetainersCourse(instructions.course_id)) {
    return true;
  }

  const isValid = isOcclusalPlaneValid(formValues);

  if (!isValid) {
    emptyFields.push("validation-occlusal_plane");
  }

  $("#validation-occlusal_plane").css({ color: !isValid ? "red" : "#34495e" });

  return isValid;
}

export function isOcclusalPlaneValid(formValues: PatientUpdateFormValues): boolean {
  const { occlusal_plane, occlusal_plane_action, occlusal_plane_subaction } = formValues ?? {};

  let isValid = occlusal_plane != null;

  if (isValid && (occlusal_plane == OcclusalPlane.RIGHT || occlusal_plane == OcclusalPlane.LEFT)) {
    isValid =
      occlusal_plane_action == OcclusalPlaneAction.SAVE ||
      occlusal_plane_action == OcclusalPlaneAction.MODIFY;

    if (isValid && occlusal_plane_action == OcclusalPlaneAction.MODIFY) {
      isValid =
        occlusal_plane_subaction == OcclusalPlaneAction.EXTRUDE ||
        occlusal_plane_subaction == OcclusalPlaneAction.INTRUDE;

      if (isValid) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const occlusalPlaneTeeth = convertReduxFormOcclusalPlaneTeethToArray(formValues!);

        isValid =
          occlusalPlaneTeeth.length > 0 &&
          occlusalPlaneTeeth.length <= getOcclusalPlaneTeeth(occlusal_plane, occlusal_plane_subaction).length;
      }
    }
  }

  return isValid;
}

function isValidEmailAddress(value: string): boolean {
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEX.test(value);
}
