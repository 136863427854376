export enum TrainingLevel {
  BEGINNER = "0",
  BASE = "1",
  ADVANCED = "2",
}

export enum PricingType {
  FREE = "free",
  PAID = "paid",
}

export const EventsZIndex = {
  TABS_TRIGGER: 1,
  TABS_LIST: 3,
  PRICING_SELECT: 4,
} as const;
