import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { addUserActionNotification } from "~/actions/user_notification";
import { remoteLog } from "~/common/logging";
import { Button } from "~/components/ui/button";
import { Loader } from "~/components/ui/loader";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { API_PUBLIC_LINK, PLAN_LINK_V2, PLAN_LINK_V2_RU } from "~/config";
import type { TUserActionNotification } from "~/reducers/dashboard";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    lang: state.intl,
  };
};

const mapDispatchToProps = {
  addUserActionNotification,
};

type Patient3DPlanProps = PropsFromRedux & { patient: TPatient; token: string };

type Patient3DPlanState = {
  public_link: string | null;
  showLoader: boolean;
  canSubmit: boolean;
};

class Patient3DPlan extends Component<Patient3DPlanProps, Patient3DPlanState> {
  link: HTMLTextAreaElement | null = null;

  constructor(props: Patient3DPlanProps) {
    super(props);
    this.state = {
      public_link: null,
      showLoader: false,
      canSubmit: true,
    };
    this.sharePublicLink = this.sharePublicLink.bind(this);
    this.sharePublicLinkNotification = this.sharePublicLinkNotification.bind(this);
    this.copyPublicLink = this.copyPublicLink.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_3d_plan");
  }

  sharePublicLink() {
    const { patient } = this.props;

    this.setState({ showLoader: true });
    this.setState({ canSubmit: false });

    fetch(API_PUBLIC_LINK(patient.patient_id), { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response;
      })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ public_link: response, showLoader: false });
        this.sharePublicLinkNotification();
      })
      .catch(() => {
        const NOTIFICATION_LINK: TUserActionNotification = {
          message: "public.plan.error",
          level: "error",
          position: "tl",
          autoDismiss: 4,
        };
        this.props.addUserActionNotification(NOTIFICATION_LINK);
        this.setState({ public_link: null, showLoader: false, canSubmit: true });
      });
  }

  sharePublicLinkNotification() {
    if (this.props.user.email_notify || this.props.user.telegram_notify) {
      const NOTIFICATION_LINK: TUserActionNotification = {
        message: "",
        level: "success",
        position: "tl",
        autoDismiss: 4,
      };

      if (this.props.user.email_notify && this.props.user.telegram_notify) {
        NOTIFICATION_LINK.message = "notify.link_tg_email";
      } else if (this.props.user.email_notify) {
        NOTIFICATION_LINK.message = "notify.link_email";
      } else if (this.props.user.telegram_notify) {
        NOTIFICATION_LINK.message = "notify.link_tg";
      }
      this.props.addUserActionNotification(NOTIFICATION_LINK);
    }
  }

  copyPublicLink() {
    const NOTIFICATION_LINK_COPY: TUserActionNotification = {
      message: "notify.link_copy",
      level: "success",
      position: "tl",
      autoDismiss: 4,
    };
    this.props.addUserActionNotification(NOTIFICATION_LINK_COPY);
  }

  render() {
    const { patient, token, lang } = this.props;

    const planHref = window.location.hostname.endsWith(".com")
      ? PLAN_LINK_V2(patient.patient_id, token, lang.locale)
      : PLAN_LINK_V2_RU(patient.patient_id, token, lang.locale);

    return (
      <Portlet>
        <PortletTitle as="h2" iconClassName="icon-docs">
          <FormattedMessage id="pat.plan.header" />
        </PortletTitle>

        <div className="portlet-body">
          <div className="form-group">
            <Button id="test-btn" asChild>
              <a href={planHref}>
                <FormattedMessage id="pat.plan.button" />
              </a>
            </Button>
          </div>

          {this.state.showLoader ? <Loader /> : null}

          {this.state.public_link ? (
            <>
              <div className="form-group">
                <CopyToClipboard text={this.state.public_link} onCopy={() => this.copyPublicLink()}>
                  <Button>
                    <FormattedMessage tagName="span" id="pat.plan.copy_link" />
                  </Button>
                </CopyToClipboard>
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  readOnly
                  rows={3}
                  value={this.state.public_link}
                  ref={(input) => (this.link = input)}
                  onClick={() => this.link.select()}
                />
              </div>
            </>
          ) : (
            <div className="form-group">
              <Button
                type="button"
                onClick={() => this.sharePublicLink()}
                disabled={!this.state.canSubmit}
              >
                <FormattedMessage id="pat.plan.link" />
              </Button>
            </div>
          )}
        </div>
      </Portlet>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Patient3DPlan);
