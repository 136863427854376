import { buildLocale } from "./locale";

/**
 * Formats a date string into a time string in the format "hh:mm"
 */
export function formatTime(dateStr: string): string {
  return new Date(dateStr).toLocaleTimeString([buildLocale], {
    hour: "2-digit",
    minute: "2-digit",
  });
}
