import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const shouldReduceMotion = useReducedMotion();
  const animationProps: AnimationProps = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
  };

  useEffect(() => {
    function handleScroll() {
      const rootElement = document.documentElement;
      // NOTE: 2.75 is an arbitrary value, feel free to change it if you want
      setIsVisible(rootElement.scrollTop > rootElement.clientHeight * 2.75);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Tooltip.Provider delayDuration={600}>
      <Tooltip.Root>
        <AnimatePresence>
          {isVisible ? (
            <Tooltip.Trigger asChild>
              <motion.button
                {...(shouldReduceMotion == true ? {} : animationProps)}
                className={clsx(
                  "tw-fixed tw-bottom-24 tw-right-4 tw-h-16 tw-w-16 tw-rounded-full tw-p-0",
                  "tw-flex tw-items-center tw-justify-center tw-border-none tw-bg-white tw-shadow-md",
                  "lg:tw-bottom-28 lg:tw-right-8",
                  // should have a big zIndex to be displayed over any other positioned elements
                  // e.g. loading skeletons
                  "tw-z-50",
                  "tw-transition-all hover:tw-bg-gray-200 active:tw-scale-[0.85]",
                )}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <ArrowUpIcon />
              </motion.button>
            </Tooltip.Trigger>
          ) : null}
        </AnimatePresence>

        <Tooltip.Portal>
          <Tooltip.Content
            className={clsx(
              "tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-shadow-md",
              "motion-safe:tw-duration-300 motion-safe:tw-animate-in",
              "data-[state=delayed-open]:tw-fade-in-0",
              "data-[state=delayed-open]:tw-slide-in-from-top-1",
            )}
            sideOffset={5}
          >
            <FormattedMessage id="scroll_to_top" />
            <Tooltip.Arrow className="tw-fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function ArrowUpIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM14.9998 12.9734V21.44C14.9998 22 15.4532 22.44 15.9998 22.44C16.5598 22.44 16.9998 22 16.9998 21.44V12.9734L20.2932 16.28C20.4798 16.4667 20.7465 16.5734 20.9998 16.5734C21.2518 16.5734 21.5065 16.4667 21.7065 16.28C22.0932 15.8934 22.0932 15.2534 21.7065 14.8667L16.7065 9.84002C16.3332 9.46669 15.6665 9.46669 15.2932 9.84002L10.2932 14.8667C9.9065 15.2534 9.9065 15.8934 10.2932 16.28C10.6932 16.6667 11.3198 16.6667 11.7198 16.28L14.9998 12.9734Z"
        className="tw-fill-brand-green"
      />
    </svg>
  );
}
