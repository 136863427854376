import clsx from "clsx";
import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import { Field } from "redux-form";

export function RecipeRadio({
  name,
  value,
  intlId,
  ariaLabelledBy,
}: {
  name: string;
  value: number;
  intlId: MessageDescriptor["id"];
  ariaLabelledBy: string | string[];
}) {
  const inputId = `${name}-value-${value}`;

  return (
    <label htmlFor={inputId}>
      <div className="radio">
        <Field
          id={inputId}
          aria-labelledby={clsx(ariaLabelledBy, inputId)}
          name={name}
          component="input"
          type="radio"
          value={`${value}`}
        />
      </div>

      <FormattedMessage id={intlId} />
    </label>
  );
}

export function RecipeCheckbox<T extends MessageDescriptor["id"]>({
  name,
  value,
  intlId,
  ariaLabelledBy,
  onChange,
  renderLabel,
}: {
  name: string;
  value: number | undefined;
  intlId: T;
  ariaLabelledBy: string | string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  renderLabel?: (intlId: T) => React.ReactNode;
}) {
  const inputId = `${name}-value-${value ?? ""}`;

  return (
    <label htmlFor={inputId}>
      <div className="checkbox-round checker">
        <Field
          id={inputId}
          aria-labelledby={clsx(ariaLabelledBy, inputId)}
          name={name}
          component="input"
          type="checkbox"
          // NOTE: otherwise checkbox is inaccessible to accessibility tree because of add-pacient.css styles
          className="tw-visible tw-opacity-0"
          format={typeof value == "number" ? (v?: number) => v === value : undefined}
          normalize={typeof value == "number" ? (v: boolean) => (v ? value : null) : undefined}
          onChange={onChange}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label id="checkbox_label" htmlFor={inputId}></label>
      </div>

      {renderLabel ? renderLabel(intlId) : <FormattedMessage id={intlId} />}
    </label>
  );
}
