import { StarIcon } from "@heroicons/react/24/outline";
import { Separator } from "@radix-ui/react-separator";
import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { buildLocale } from "~/common/locale";
import { remoteLog } from "~/common/logging";
import { FormatDate } from "~/components/common/FormatDate";
import { PersonName } from "~/components/common/PersonName";
import { Button } from "~/components/ui/button";
import type { Loading } from "~/types/common";

import { ExpertImage, ExpertName, MeetingsTag } from "./meetings-shared";
import type { TExpert } from "./meetings-types";

export function ExpertCard({ expert }: { expert: Loading<TExpert> }) {
  return (
    <li>
      <ExpertCardDesktop expert={expert} />
      <ExpertCardMobile expert={expert} />
    </li>
  );
}

function ExpertCardDesktop({ expert }: { expert: Loading<TExpert> }) {
  const isNotAvailable = expert ? expert.nearest_slot === null : false;

  return (
    <article
      className={clsx(
        "tw-list-none tw-rounded-3xl tw-bg-white tw-p-5 tw-shadow-sm max-sm:tw-hidden",
        {
          "tw-opacity-70 tw-transition-opacity hover:tw-opacity-100": isNotAvailable,
        },
      )}
    >
      {/* Mobile */}
      <div className="tw-flex tw-items-center tw-gap-8 sm:tw-hidden">
        <ExpertImage expert={expert} />

        <div className="tw-flex tw-flex-col tw-gap-4">
          <ExpertInfo expert={expert} />
          <ExpertLink expert={expert} />
        </div>
      </div>

      {/* Desktop */}
      <div className="tw-flex tw-items-center tw-justify-between max-sm:tw-hidden">
        <div className="tw-flex tw-items-center tw-gap-4">
          <ExpertImage expert={expert} />
          <ExpertInfo expert={expert} />
        </div>

        <ExpertLink expert={expert} />
      </div>
    </article>
  );
}

export function ExpertInfo({
  expert,
  displayNearestSlot = true,
  displayDescription = true,
  averageRatingStyle = "stars",
}: {
  expert: Loading<TExpert>;
  displayNearestSlot?: boolean;
  displayDescription?: boolean;
  averageRatingStyle?: "stars" | "number";
}) {
  const averageRatingAsInt = expert ? Math.ceil(expert.average_rating ?? 0) : 0;
  const description = expert ? getDescription(expert) : "";
  const nearestSlotDateStr = expert ? getNearestSlotDateStr(expert) : undefined;

  return expert ? (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <div className="tw-flex tw-items-center tw-gap-2">
        <PersonName
          person={expert}
          useMiddleName
          className="tw-text-base tw-font-semibold tw-text-brand-black"
        />

        {displayDescription && description ? (
          <MeetingsTag className="tw-text-xs">{description}</MeetingsTag>
        ) : null}
      </div>

      <div className="tw-flex tw-items-center tw-gap-2">
        {averageRatingStyle == "stars" ? <ExpertRating rating={averageRatingAsInt} /> : null}

        {averageRatingStyle == "number" ? (
          <div className="tw-flex tw-items-center tw-gap-1">
            <StarIcon className="tw-h-5 tw-w-5" />
            {expert.average_rating?.toFixed(1)}
          </div>
        ) : null}
      </div>

      {displayNearestSlot ? (
        <div className="tw-text-brand-gray sm:tw-text-[15px]">
          <FormattedMessage id="meetings.nearest_slot" />
          : <br className="sm:tw-hidden" />
          {nearestSlotDateStr ? (
            <FormatDate value={nearestSlotDateStr} date time />
          ) : (
            <FormattedMessage id="meetings.nearest_slot.empty" />
          )}
        </div>
      ) : null}
    </div>
  ) : (
    <Skeleton
      className="tw-rounded-md"
      containerClassName="tw-space-y-2"
      count={3}
      width={250}
      height={15}
    />
  );
}

function ExpertLink({ expert, className }: { expert: Loading<TExpert>; className?: string }) {
  className = clsx("tw-rounded-xl max-sm:tw-self-start", className);

  if (!expert) {
    return (
      <Skeleton
        containerClassName="max-sm:tw-w-full"
        className={className}
        width={150}
        height={50}
      />
    );
  }

  if (expert.nearest_slot === null) {
    // Expert has no nearest slot available and, because link can't be disabled,
    // we display a disabled button instead.

    return (
      <Button rounded="extra" size="2xl" className={className} disabled>
        <FormattedMessage id="meetings.book" />
      </Button>
    );
  }

  return (
    <Button variant="primary" rounded="extra" size="2xl" className={className} asChild>
      <Link to={`/pages/meetings/book/${expert.account_id}`}>
        <FormattedMessage id="meetings.book" />
      </Link>
    </Button>
  );
}

function ExpertRating({ rating }: { rating: number }) {
  return (
    <div className="tw-max-h-[22px]">
      {Array.from({ length: rating }).map((_, i) => (
        <CustomStarIcon key={i} />
      ))}
    </div>
  );
}

function ExpertCardMobile({ expert }: { expert: Loading<TExpert> }) {
  const averageRatingAsInt = expert ? Math.ceil(expert.average_rating ?? 0) : 0;
  const isNotAvailable = expert ? expert.nearest_slot === null : false;
  const nearestSlotDateStr = expert ? getNearestSlotDateStr(expert) : undefined;
  const description = expert ? getDescription(expert) : "";

  return (
    <article
      className={clsx(
        "tw-flex tw-max-w-[700px] tw-flex-col tw-gap-6 tw-rounded-3xl tw-bg-white tw-p-5 tw-shadow-sm sm:tw-hidden",
        { "tw-transition-opacity hover:tw-opacity-100 sm:tw-opacity-70": isNotAvailable },
      )}
    >
      <div className="tw-flex tw-flex-col tw-gap-1">
        <div className="tw-flex tw-justify-between tw-gap-3">
          <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
            {expert ? (
              <span className="tw-inline-flex tw-items-center tw-gap-2 tw-text-[#808080]">
                <FormattedMessage id="meetings.expert" />

                {description ? (
                  <MeetingsTag className="tw-text-xs">{description}</MeetingsTag>
                ) : null}
              </span>
            ) : (
              <Skeleton className="tw-rounded-md" width={150} height={20} />
            )}

            {expert ? (
              <ExpertName expert={expert} />
            ) : (
              <Skeleton className="tw-rounded-md" width={150} height={30} />
            )}
          </div>

          <ExpertImage expert={expert} />
        </div>

        {expert ? (
          <ExpertRating rating={averageRatingAsInt} />
        ) : (
          <Skeleton className="tw-rounded-md" width={110} height={20} />
        )}
      </div>

      <Separator className="tw-h-px tw-bg-[#D9DCE7]" />

      <div className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-4">
        <div className="tw-self-start tw-text-brand-gray">
          {expert ? (
            <>
              <FormattedMessage id="meetings.nearest_slot" />:
            </>
          ) : (
            <Skeleton className="tw-rounded-md" width={200} height={20} />
          )}

          {expert ? <br /> : null}

          {expert ? (
            nearestSlotDateStr ? (
              formatRelativeDateTime(nearestSlotDateStr)
            ) : (
              <FormattedMessage id="meetings.nearest_slot.empty" />
            )
          ) : (
            <Skeleton className="tw-mt-2 tw-rounded-md" width={200} height={20} />
          )}
        </div>

        <ExpertLink expert={expert} className="tw-w-full" />
      </div>
    </article>
  );
}

function formatRelativeDateTime(dateStr: string): string {
  const date = new Date(dateStr);
  const todayDate = new Date();

  const formatter = new Intl.DateTimeFormat(buildLocale, {
    day: "2-digit",
    month: "long",
    year: date.getFullYear() !== todayDate.getFullYear() ? "numeric" : undefined,
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  try {
    // NOTE: For some doctors sometimes throws `RangeError: date value is not finite in DateTimeFormat format()`.
    return formatter.format(date);
  } catch (err) {
    remoteLog({ err, dateStr }, "formatRelativeDateTime()");
    return "";
  }
}

function getDescription(expert: TExpert): string {
  const MOLOTOVA_ACCOUNT_ID = 3320;
  const SOLOP_ACCOUNT_ID = 3342;

  switch (expert.account_id) {
    case MOLOTOVA_ACCOUNT_ID:
      return "Дети";

    case SOLOP_ACCOUNT_ID:
      return "ДВНЧС";

    default:
      return "";
  }
}

function getNearestSlotDateStr(expert: TExpert): string | undefined {
  const SOLOP_ACCOUNT_ID = 3342;
  const nearestSlot = { ...expert.nearest_slot };

  if (expert.account_id == SOLOP_ACCOUNT_ID && nearestSlot.times) {
    // This expert has a fixed schedule and is only available from 09:00 am to 09:30 am
    // Cabinet.fm only allows to pick 1 hour shifts, so we remove the time for meeting
    // from 09:30 am to 10:00 am
    // Times array should have two values ~ ['09:00-09:30', '09:30-10:00'];
    // We remove '09:30-10:00' one.
    nearestSlot.times = nearestSlot.times.slice(0, -1);
  }

  return nearestSlot.times?.[0]?.time;
}

function CustomStarIcon() {
  return (
    <svg width="19" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.09814 3.27095C9.43614 2.43519 10.6194 2.43519 10.9574 3.27095L12.3983 6.83375C12.5419 7.18895 12.8754 7.43125 13.2576 7.4581L17.0913 7.72746C17.9906 7.79065 18.3562 8.91602 17.6658 9.49573L14.7226 11.967C14.4292 12.2134 14.3018 12.6054 14.3944 12.9772L15.3229 16.7065C15.5407 17.5813 14.5834 18.2768 13.8187 17.7994L10.5589 15.7639C10.2339 15.561 9.82166 15.561 9.49667 15.7639L6.23684 17.7994C5.47215 18.2768 4.51486 17.5813 4.73266 16.7065L5.66116 12.9772C5.75372 12.6054 5.62634 12.2134 5.33291 11.967L2.38974 9.49573C1.69933 8.91602 2.06498 7.79065 2.96428 7.72746L6.79795 7.4581C7.18015 7.43125 7.51365 7.18895 7.6573 6.83375L9.09814 3.27095Z"
        fill="#5BDCDC"
      />
    </svg>
  );
}
