import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, FieldArray } from "redux-form";

import { CloseAllGaps } from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { ValueOf } from "~/types/common";
import type { TPrescriptionReduxFormChangeFn } from "~/types/redux-form";

import RecipeCloseAllGapsSpaces from "./recipe-close-all-gaps-spaces";
import { RecipeCheckbox } from "./recipe-shared";

export function RecipeCloseAllGaps({
  close_all_gaps,
  change,
}: {
  close_all_gaps: ValueOf<typeof CloseAllGaps> | null | undefined;
  change: TPrescriptionReduxFormChangeFn;
}) {
  const intl = useIntl();

  return (
    <div id="close-all-gaps-section" style={{ marginTop: 15 }}>
      <div className="form-group">
        <PortletTerm id="close-all-gaps-label">
          <FormattedMessage id="HEADER_CLOSE_ALL" />
        </PortletTerm>

        <div className="checkbox-list checkbox_list">
          <RecipeCheckbox
            name="close_all_gaps"
            value={CloseAllGaps.YES}
            intlId="CAG_YES"
            onChange={() => change("close_all_gaps_value", "")}
            ariaLabelledBy="close-all-gaps-label"
          />

          {close_all_gaps == CloseAllGaps.MAINTAIN ? (
            <RecipeCheckbox
              name="close_all_gaps"
              value={CloseAllGaps.MAINTAIN}
              intlId="CAG_MAINTAIN"
              onChange={() => change("close_all_gaps_value", "")}
              ariaLabelledBy="close-all-gaps-label"
            />
          ) : null}

          <RecipeCheckbox
            name="close_all_gaps"
            value={CloseAllGaps.LEAVE_SPACE}
            intlId="CAG_LEAVE_SPACE"
            onChange={() => change("close_all_gaps_value", "")}
            ariaLabelledBy="close-all-gaps-label"
          />

          <div style={{ width: "70%" }}>
            <Field
              aria-labelledby={`close_all_gaps-value-${CloseAllGaps.LEAVE_SPACE}`}
              component="textarea"
              type="text"
              rows={5}
              disabled={close_all_gaps != CloseAllGaps.LEAVE_SPACE}
              className="form-control"
              id="close-all-gaps-input"
              name="close_all_gaps_value"
              placeholder={intl.formatMessage({ id: "CAG_LEAVE_SPACE_ADD_COMMENT" })}
              maxLength={256}
            />
          </div>
          <br />

          {close_all_gaps == CloseAllGaps.LEAVE_SPACE ? (
            <div>
              <PortletTerm id="close-all-gaps-leave-space-label">
                <FormattedMessage id="CAG_LEAVE_SPACE_FOR" />
              </PortletTerm>

              <FieldArray
                key={close_all_gaps}
                name="close_all_gaps_spaces"
                component={RecipeCloseAllGapsSpaces}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
