import clsx from "clsx";
import React, { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { change, Field } from "redux-form";

import { remoteLog } from "~/common/logging";
import { convertToDentalNotation } from "~/common/utils";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  change,
};

type PlanCorrectionTeethCheckProps = PropsFromRedux & {
  name: string;
  ariaLabelledBy: string;
};

class PlanCorrectionTeethCheck extends Component<PlanCorrectionTeethCheckProps> {
  teeth: number[][];

  constructor(props: PlanCorrectionTeethCheckProps) {
    super(props);
    const quads = [1, 2, 4, 3];
    this.teeth = quads.map((quad) => {
      const arr = [];
      for (let i = 1; i <= 8; i++) {
        arr.push(quad * 10 + i);
      }
      if (quad === 1 || quad === 4) arr.reverse();
      return arr;
    });
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_correction_teeth_check");
  }

  render() {
    const allTeeth = this.teeth.slice();
    const upperTeeth = allTeeth.slice(0, 2);
    const lowerTeeth = allTeeth.slice(2, 4);

    return (
      <div className="PatientTeethItems form-group">
        <div className="row">
          <div className="col-xs-12">
            <div className="teeth-controls-upper">
              {upperTeeth.map(this.renderQuadrant.bind(this))}
            </div>
          </div>

          <div className="col-xs-12">
            <div className="teeth-controls-lower">
              {lowerTeeth.map(this.renderQuadrant.bind(this))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderQuadrant(quadrant: number[], i: number) {
    return (
      <div key={i} className="checkbox-list teeth-controls">
        {quadrant.map(this.renderTooth.bind(this))}
      </div>
    );
  }

  renderTooth(i: number) {
    const { user, name } = this.props;
    const dentalNotation = user.preferences?.dental_notation;

    return (
      <label key={i}>
        {convertToDentalNotation(i, dentalNotation)}

        <div className="checkbox-teeth">
          <Field
            component="input"
            type="checkbox"
            name={`${name}_${i}`}
            id={`${name}teeth_check${i}`}
            aria-labelledby={clsx(this.props.ariaLabelledBy, `${name}teeth_check${i}`)}
          />
        </div>
      </label>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PlanCorrectionTeethCheck);
