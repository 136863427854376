import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";


export function Img({
  loadingFallback,
  errorFallback,
  ...imgProps
}: {
  loadingFallback: React.ReactNode;
  errorFallback: React.ReactNode;
} & React.ImgHTMLAttributes<HTMLImageElement>) {
  const [status, setStatus] = useState<"idle" | "loading" | "error" | "success">("idle");
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current && !imageRef.current.complete) {
      setStatus("loading");
    }
  }, []);

  if (status == "error") {
    return <>{errorFallback}</>;
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        {...imgProps}
        ref={imageRef}
        onLoad={() => setStatus("success")}
        onError={() => setStatus("error")}
        className={clsx(imgProps.className, { "tw-hidden": status == "loading" })}
      />
      {status == "loading" ? loadingFallback : null}
    </>
  );
}
