export function devTools(
  state = false,
  action: { type: "TOGGLE_DEVTOOLS"; payload: boolean },
): boolean {
  switch (action.type) {
    case "TOGGLE_DEVTOOLS":
      return action.payload;

    default:
      return state;
  }
}
