import { CommentType } from "../common/constants";
import {
  API_GET_PATIENT_COMMENTS,
  API_POST_PATIENT_COMMENTS,
  API_POST_PATIENT_QUESTIONS,
} from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { TPatient } from "~/types/patient";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from "./user_notification";

export function getComments(patient_id: TPatient["patient_id"]) {
  return (dispatch: AppDispatch) => {
    fetch(API_GET_PATIENT_COMMENTS(patient_id), { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw Error("Error occurred trying to fetch comments");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => dispatch({ type: "GET_PATIENT_COMMENTS", json }))
      .catch(() => {
        dispatch({ type: "GET_PATIENT_COMMENTS", json: [] });
      });
  };
}

export function sendComment(
  patient_id: TPatient["patient_id"],
  comment: string,
  type: CommentType = CommentType.COMMON,
  plan_id: string | null = null,
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.comment",
    level: "success",
    position: "tl",
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.comment.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_POST_PATIENT_COMMENTS(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ comment, type, plan_id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred while trying to save comment");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "POST_PATIENT_COMMENTS", json });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

/**
 * Creates question for clinical expert
 */
export function sendQuestion(patient_id: TPatient["patient_id"], comment: string) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.question",
    level: "success",
    position: "tl",
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.question.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_POST_PATIENT_QUESTIONS(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ comment }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to send question");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "POST_PATIENT_COMMENTS", json });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}
