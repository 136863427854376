import React from "react";
import { FormattedMessage } from "react-intl";

import { CloseAllGaps, translateRxOption } from "~/common/prescription";
import { convertToDentalNotation } from "~/common/utils";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPrescription } from "~/types/prescription";
import type { TUser } from "~/types/user";

export function PatientCardCloseAllGaps({
  user,
  prescription,
}: {
  user: TUser;
  prescription: TPrescription;
}) {
  const dentalNotation = user.preferences.dental_notation;
  const { close_all_gaps, close_all_gaps_value, close_all_gaps_spaces } = prescription;

  return (
    <div>
      <PortletTerm id="close-all-gaps-label">
        <FormattedMessage id="HEADER_CLOSE_ALL" />
      </PortletTerm>

      <div style={{ marginBottom: "20px" }} id="close-all-gaps-value">
        <p style={{ fontWeight: "600", marginBottom: "5px" }}>
          <FormattedMessage id={translateRxOption("close_all_gaps", close_all_gaps)} />
        </p>

        {close_all_gaps == CloseAllGaps.LEAVE_SPACE ? (
          <>
            {close_all_gaps_value ? (
              <p
                className="tw-mb-4 tw-ml-7 tw-whitespace-pre-line tw-italic"
                style={{ wordBreak: "break-word" }}
              >
                {close_all_gaps_value}
              </p>
            ) : null}

            {Object.keys(close_all_gaps_spaces).length > 0 ? (
              <>
                <PortletTerm id="close-all-gaps-label" semibold>
                  <FormattedMessage id="CAG_LEAVE_SPACE_FOR" />
                </PortletTerm>

                {Object.entries(close_all_gaps_spaces).map(([key, value]) => (
                  <div key={key}>
                    <FormattedMessage id="TOOTH_NUMBER" />
                    {` ${convertToDentalNotation(Number(key), dentalNotation)} - ${value} `}
                    <FormattedMessage id="MILLIMETRES" />
                  </div>
                ))}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
