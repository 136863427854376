const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function required(value: string | undefined): boolean {
  if (value) {
    return false;
  }
  return true;
}

export function isPhoneNumber(value: string | undefined): boolean {
  const phoneNumberPattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
  if (typeof value !== "undefined" && phoneNumberPattern.test(value)) {
    return false;
  }
  return true;
}

export function isLogisticPhoneNumber(value: string | undefined): boolean {
  if (value && value.match(new RegExp("^([+]{1}[0-9]{0,14}|[0-9]{0,15})$"))) {
    return false;
  }
  return true;
}

export function validateZIPCode(value: string | undefined): boolean {
  const reg = /^[a-z0-9\-\s\[\]]{1,10}$/i;
  if (!value || value.length > 6 || (typeof value === "string" && !reg.test(value))) {
    return true;
  }
  return false;
}

export function validEmail(value: string | undefined): boolean {
  if (value && value.match(new RegExp(EMAIL_REGEX))) {
    return false;
  }
  return true;
}
