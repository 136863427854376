import moment, { type Moment } from "moment";

import { deployedUSA } from "./utils";

export const DATE_FORMAT = deployedUSA() ? "MM-DD-YYYY" : "DD-MM-YYYY";

export function formatDatetime(e: React.FocusEvent<HTMLInputElement>): void {
  const usaFormat = deployedUSA() === true;
  const etv = e.target.value.replace(/[^0-9]/g, "");
  let dd = etv.slice(0, 2);
  let mm = etv.slice(2, 4);
  let yyyy = etv.slice(4, 8);

  if (usaFormat) {
    [dd, mm] = [mm, dd];
  }

  if (yyyy.length) {
    yyyy = `-${yyyy}`;
  }

  if (dd.length === 2 && parseInt(dd) > 31) {
    dd = "31";
  } else if (dd.length === 2 && parseInt(dd) === 0) {
    dd = "01";
  }
  if (mm.replace(/[^0-9]/g, "").length === 2 && parseInt(mm.replace(/[^0-9]/g, "")) > 12) {
    mm = "12";
  } else if (mm.replace(/[^0-9]/g, "").length === 2 && parseInt(mm.replace(/[^0-9]/g, "")) === 0) {
    mm = "01";
  }
  if (yyyy.replace(/[^0-9]/g, "").length === 4 && parseInt(yyyy.replace(/[^0-9]/g, "")) < 1900) {
    yyyy = "-1900";
  }

  if (usaFormat) {
    dd = dd && dd[0] !== "-" ? `-${dd}` : dd;
  } else {
    mm = mm && mm[0] !== "-" ? `-${mm}` : mm;
  }
  e.target.value = usaFormat === false ? `${dd}${mm}${yyyy}` : `${mm}${dd}${yyyy}`;
}

export function formatDate(date: Date): Moment {
  const fullDate = new Date(date);
  const day = fullDate.getDate() < 10 ? `0${fullDate.getDate()}` : fullDate.getDate();
  const month = fullDate.getMonth() + 1;
  const year = fullDate.getFullYear();

  return moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
}
