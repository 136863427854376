import React, { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { FileType } from "~/common/constants";
import type { RootState } from "~/store";
import type { TS3MediaItem } from "~/types/patient";

const mapStateToProps = (state: RootState) => {
  return {
    patient: state.patient,
  };
};

type PatientFilesProps = PropsFromRedux & {
  type: FileType;
};

class PatientFiles extends Component<PatientFilesProps> {
  async downloadFile(file: TS3MediaItem) {
    const target = $(`<a download="${file.file_name}"></a>`)[0] as HTMLAnchorElement;

    let url = target.href;

    if (!url || url === "javascript:void(0)") {
      const fileData = await this.getFileUrl(file);
      url = fileData[0].url;
      target.href = url;
    }

    target.click();
  }

  async getFileUrl(file: TS3MediaItem): Promise<(TS3MediaItem & { url: string; })[]> {
    const { patient } = this.props;

    const params = {
      patient_id: patient.patient_id,
      file_id: [file.file_id],
    };

    return fetch(`${process.env.API}/patients/media?${$.param(params)}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then((res) => res.json());
  }

  render() {
    const { patient } = this.props;

    if (!patient.s3_media) {
      return null;
    }

    const rows = patient.s3_media
      .filter((file) => file.file_type === this.props.type)
      .map((file, i) => {
        return (
          <tr key={i}>
            {this.props.type !== FileType._3SHAPE ? <td>{i + 1}</td> : null}

            {this.props.type === FileType._3SHAPE ? (
              <td style={{ width: "40%" }}>
                <div>
                  <img
                    src={"/img/upload_placeholders/THUMBNAIL_STL.svg"}
                    style={{ width: "60%" }}
                    alt=""
                  />
                </div>
              </td>
            ) : null}

            <td>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="javascript:void(0)"
                download={file.file_name}
                onClick={() => this.downloadFile(file)}
                data-matomo-mask
                data-hj-suppress
                style={{ wordBreak: "break-word" }}
              >
                {file.file_name}
              </a>
            </td>
          </tr>
        );
      });

    if (rows.length == 0) {
      return null;
    }

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="50"></th>
            <th></th>
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </table>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientFiles);
