import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import {
  Payer,
  PAYERS_TRANSLATION_MAP,
  PAYMENT_OPTIONS_TRANSLATION_MAP,
  type PaymentMethod,
  PaymentMethodId,
  type PaymentOption,
  PaymentOptionId,
} from "~/common/constants";
import { Status } from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { PortletTerm } from "~/components/ui/portlet";
import { addInstructions, removeInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";

import {
  PayerRadioGroup,
  PaymentMethodRadioGroup,
  PaymentOptionRadioGroup,
} from "../patient_new/patient_new_payment";

const mapStateToProps = (state: RootState) => {
  return {
    intl: state.intl,
    instructions: state.instructions,
    courseInstallment: state.courseInstallment,
  };
};

const mapDispatchToProps = {
  addInstruction: addInstructions,
  removeInstructions,
};

type PatientUpdatePaymentProps = PropsFromRedux & {
  intl: IntlShape;
  patient: TPatient;
  setDirty(key: string, data?: unknown): void;
};

class PatientUpdatePayment extends Component<PatientUpdatePaymentProps> {
  constructor(props: PatientUpdatePaymentProps) {
    super(props);
    this.initPaymentOption = this.initPaymentOption.bind(this);
    this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
    this.selectPaymentOption = this.selectPaymentOption.bind(this);
    this.selectPayer = this.selectPayer.bind(this);
    this.setPaymentCreditOption = this.setPaymentCreditOption.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_update_payment");
  }

  componentDidMount() {
    const { patient } = this.props;

    if (patient.course.payment_method != "None") {
      const paymentMethodStrToId: Record<PaymentMethod, PaymentMethodId> = {
        PM_BANK: PaymentMethodId.INVOICE,
        PM_CASH: PaymentMethodId.CASH,
        PM_CARD: PaymentMethodId.CARD,
      };
      this.selectPaymentMethod(paymentMethodStrToId[patient.course.payment_method]);
    }

    if (patient.course.payment_option != "None") {
      const paymentOptionStrToId: Record<PaymentOption, PaymentOptionId> = {
        PO_ADVANCE: PaymentOptionId.ADVANCED,
        PO_SPLIT_2: PaymentOptionId.SPLIT_2,
        PO_SPLIT_3: PaymentOptionId.SPLIT_3,
        PO_SPLIT_2_2: PaymentOptionId.SPLIT_2_2,
        PO_SPLIT_3_2: PaymentOptionId.SPLIT_3_2,
      };
      this.initPaymentOption(paymentOptionStrToId[patient.course.payment_option]);
    }

    if (this.props.intl.locale == "ru" && patient.payer_id) {
      this.props.addInstruction({
        payer_id: patient.payer_id,
        payer_last_name: patient.payer_last_name || null,
        payer_first_name: patient.payer_first_name || null,
        payer_patronymic: patient.payer_patronymic || null,
      });
    }

    if (patient.sber_credit) {
      this.setPaymentCreditOption();
    }
  }

  setPaymentCreditOption() {
    /** Payment option should be Advanced in case if credit option is chosen. On the UI/Interface level we do not show this */
    this.props.addInstruction({ sber_credit: true, payment_option_id: PaymentOptionId.ADVANCED });
  }

  selectPaymentMethod(data: string | PaymentMethodId) {
    this.props.addInstruction({ payment_method_id: parseInt(data as string) });
    this.props.setDirty("payment_method");
  }

  initPaymentOption(data: PaymentOptionId) {
    this.props.addInstruction({ payment_option_id: data });
  }

  selectPaymentOption(data: string) {
    const { can_edit_payment_option } = this.props.patient;

    if (can_edit_payment_option) {
      this.props.addInstruction({ payment_option_id: parseInt(data), sber_credit: false });
      this.props.setDirty("payment_option");
    }
  }

  selectPayer(data: string) {
    const payer_id = parseInt(data);
    this.props.addInstruction({ payer_id });
    this.props.setDirty("payer_id", payer_id);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (payer_id == Payer.PATIENT) {
      if (this.props.instructions.payment_method_id != PaymentMethodId.CARD) {
        this.props.addInstruction({ payment_method_id: null });
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    } else if (payer_id == Payer.DOCTOR) {
      if (this.props.instructions.payment_method_id != PaymentMethodId.CARD) {
        this.props.addInstruction({ payment_method_id: null });
      }
      this.removePatientPayer();
    } else {
      if (this.props.instructions.payment_method_id != PaymentMethodId.INVOICE) {
        this.props.addInstruction({ payment_method_id: null });
      }
      this.removePatientPayer();
    }
  }

  removePatientPayer() {
    this.props.removeInstructions({
      email: null,
      payer_last_name: null,
      payer_first_name: null,
      payer_patronymic: null,
    });
    if (this.props.instructions.sber_credit === true) {
      this.props.removeInstructions({ payment_option_id: null });
    }
    this.props.addInstruction({ sber_credit: false });
  }

  render() {
    const { patient, instructions } = this.props;
    const courseId = (instructions.course_id ??
      patient.course.course_id) as TPatient["course"]["course_id"];
    const installments = courseId != "None" ? this.props.courseInstallment[courseId] ?? {} : {};

    return (
      <div>
        <h3 className="block" style={{ fontWeight: "900" }}>
          <FormattedMessage id="BLOCKHEAD_PAYMENT" />
        </h3>

        {patient.status == Status.UNFILLED_CASE ? (
          <PayerRadioGroup
            payer_id={instructions.payer_id}
            onChange={(e) => this.selectPayer(e.target.value)}
          />
        ) : (
          <div className="tw-mb-5 tw-flex tw-flex-col tw-gap-3">
            <PortletTerm id="validation-payer_id">
              <FormattedMessage id="HEADER_PAYER" />
            </PortletTerm>

            <FormattedMessage id={PAYERS_TRANSLATION_MAP[patient.payer_id]} />
          </div>
        )}

        <PaymentMethodRadioGroup
          payer_id={instructions.payer_id}
          payment_method_id={instructions.payment_method_id}
          onChange={(e) => this.selectPaymentMethod(e.target.value)}
        />

        {patient.can_edit_payment_option ? (
          <PaymentOptionRadioGroup
            payment_option_id={instructions.payment_option_id}
            installments={installments}
            isSberCredit={instructions.sber_credit == true}
            course_id={courseId}
            onChange={(e) => this.selectPaymentOption(e.target.value)}
          />
        ) : (
          <div className="tw-mb-5 tw-flex tw-flex-col tw-gap-3">
            <PortletTerm id="">
              <FormattedMessage id="HEADER_PAYMENT_OPTION" />
            </PortletTerm>

            {instructions.payment_option_id ? (
              <FormattedMessage
                id={PAYMENT_OPTIONS_TRANSLATION_MAP[instructions.payment_option_id]}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdatePayment));
