import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink, type RouteComponentProps } from "react-router-dom";

import { getDoctorsTasks } from "~/actions/dashboard";
import { remoteLog } from "~/common/logging";
import { deployedRussia } from "~/common/utils";
import { FormatNumber } from "~/components/common/FormatNumber";
import { PersonName } from "~/components/common/PersonName";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { PatientStatusBadge } from "~/components/ui/patient-status-badge";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    tasks: state.doctorTasks,
  };
};

const mapDispatchToProps = {
  getTasks: getDoctorsTasks,
};

type PatientsTasksListProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps;

type PatientsTasksListState = {
  tasks: RootState["doctorTasks"]["patients"];
  paymentState: boolean;
  payment_alert: boolean;
  sort: {
    order: "asc" | "desc";
    name: "patient_id" | "last_name" | "status" | "latest_correction";
  };
};

class PatientsTasksList extends Component<PatientsTasksListProps, PatientsTasksListState> {
  constructor(props: PatientsTasksListProps) {
    super(props);
    this.state = {
      tasks: props.tasks.patients,
      paymentState: false,
      payment_alert: false,
      sort: {
        order: "desc",
        name: "patient_id",
      },
    };
    this.togglePayment = this.togglePayment.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patients_list");
  }

  UNSAFE_componentWillReceiveProps(next_props: PatientsTasksListProps) {
    if (this.props.user.account_id && !next_props.user.account_id) {
      this.props.history.push("/");
    }
    this.setState({ tasks: next_props.tasks.patients });
    if (next_props.tasks.payment_alert) {
      this.setState({ payment_alert: true });
    }
  }

  setSortOrder(newName: PatientsTasksListState["sort"]["name"]) {
    this.setState((prevState) => {
      if (prevState.sort.name === newName) {
        // same column, just change sort order
        return { sort: { name: newName, order: prevState.sort.order === "desc" ? "asc" : "desc" } };
      } else {
        return { sort: { name: newName, order: "desc" } };
      }
    });
  }

  handleFilterChange(str: string) {
    let tasks = this.props.tasks.patients;
    const fm = this.props.intl.formatMessage;
    if (str) {
      tasks = tasks.filter((p) => {
        return (
          p.first_name.toString().toLowerCase().indexOf(str) > -1 ||
          p.last_name.toString().toLowerCase().indexOf(str) > -1 ||
          p.patient_id.toString().toLowerCase().indexOf(str) > -1 ||
          fm({ id: p.latest_correction.status }).toLowerCase().indexOf(str) > -1
        );
      });
    }
    this.setState({ tasks: tasks });
  }

  sortedPatients() {
    if (this.state.tasks.length < 2) {
      return this.state.tasks;
    }

    const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id });
    const sort_order = this.state.sort.order === "asc" ? 1 : -1;
    return this.state.tasks.sort((p1, p2) => {
      if (this.state.sort.name === "patient_id") {
        return sort_order * (p1.patient_id - p2.patient_id);
      } else if (this.state.sort.name === "latest_correction") {
        const p1t = fm(p1.latest_correction.status);
        const p2t = fm(p2.latest_correction.status);
        return sort_order * p1t.localeCompare(p2t);
      } else {
        return sort_order * p1[this.state.sort.name].localeCompare(p2[this.state.sort.name]);
      }
    });
  }

  componentDidMount() {
    this.props.getTasks();
    window.scrollTo(0, 0);
    setDocumentTitle(this.props.intl.formatMessage({ id: "pat_table_tasks.block_head" }));
  }

  sortClass(colname: PatientsTasksListState["sort"]["name"]) {
    if (colname === this.state.sort.name) {
      return this.state.sort.order === "desc" ? "sorting_desc" : "sorting_asc";
    }
    return "sorting";
  }

  togglePayment() {
    this.setState({ paymentState: !this.state.paymentState });
  }

  outOf(data: PatientsTasksListState["tasks"][0]["latest_correction"]) {
    if (data.steps_count_total !== "None" && data.steps_count_completed !== "None") {
      return (
        <span>
          {data.steps_count_completed} <FormattedMessage id="pat_table.of" />{" "}
          {data.steps_count_total}
        </span>
      );
    } else {
      return null;
    }
  }

  renderPrice(value: number) {
    if ((value && value === "None") || (!value && value !== 0)) {
      return "";
    }
    return <FormatNumber value={value} />;
  }

  render() {
    const showStepsDelivered = deployedRussia();
    const isLoading = !this.props.tasks.hasBeenLoaded;

    return (
      <Layout>
        <Portlet as="main">
          <PortletTitle iconClassName="icon-users" id="patients-table-block-title">
            <FormattedMessage id="pat_table_tasks.block_head" />
          </PortletTitle>

          <div className="portlet-body">
            <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
              <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3">
                <div>
                  <Button
                    variant={this.state.payment_alert ? "danger" : "default"}
                    onClick={this.togglePayment}
                    disabled={isLoading}
                  >
                    <FormattedMessage id={`general.payment.${this.state.paymentState ? "close" : "show"}`} />
                  </Button>
                </div>

                <div>
                  <Input
                    id="patient-search-input"
                    className="tw-min-w-[225px]"
                    type="search"
                    aria-controls="doctor_pacients_table"
                    onChange={(e) => this.handleFilterChange(e.target.value.toString().toLowerCase())}
                    placeholder={this.props.intl.formatMessage({ id: "table.search" })}
                  />
                </div>
              </div>

              <div className="table-scrollable tw-mt-4">
                <table
                  className="table table-bordered table-hover dataTable no-footer"
                  id="doctor_pacients_table"
                  role="grid"
                >
                  <thead id="patients-table-head">
                    <tr role="row">
                      <th
                        className={this.sortClass("patient_id")}
                        style={{ width: 14 }}
                        onClick={() => this.setSortOrder("patient_id")}
                      >
                        #
                      </th>
                      <th
                        className={this.sortClass("last_name")}
                        style={{ width: 111 }}
                        onClick={() => this.setSortOrder("last_name")}
                      >
                        <span style={{ marginRight: "12px" }}>
                          <FormattedMessage id="pat_table.name" />
                        </span>
                      </th>
                      {showStepsDelivered ? (
                        <th style={{ width: 150 }}>
                          <FormattedMessage id="pat_table.delivered" />
                        </th>
                      ) : null}
                      <th style={{ width: 79 }}>
                        <FormattedMessage id="pat_table.received" />
                      </th>
                      <th style={{ width: 113 }}>
                        <FormattedMessage id="pat_table.tot_payment" />
                      </th>
                      <th
                        className={this.sortClass("status")}
                        style={{ width: 141 }}
                        onClick={() => this.setSortOrder("latest_correction")}
                      >
                        <FormattedMessage id="pat_table.status" />
                      </th>
                    </tr>
                  </thead>

                  <tbody id="patients-table-body">
                    {isLoading ? (
                      <tr className="clickable-row even" role="row">
                        <td colSpan={100} style={{ padding: 0 }}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      this.sortedPatients().map((patient, i) => {
                        const lc = patient.latest_correction;
                        const { total, paid } = patient.total_payments;

                        return (
                          <tr
                            key={"p" + i}
                            className={"clickable-row " + (i % 2 ? "even" : "odd")}
                            role="row"
                          >
                            <td className="sorting_1">{patient.patient_id}</td>
                            <td>
                              <NavLink to={`/pages/patient/${patient.patient_id}`}>
                                <PersonName person={patient} />
                              </NavLink>
                            </td>
                            {showStepsDelivered ? (
                              <td>
                                {lc.steps_count_total &&
                                (lc.steps_count_completed || lc.steps_count_completed === 0)
                                  ? this.outOf(lc)
                                  : null}
                              </td>
                            ) : null}
                            <td>{this.state.paymentState ? this.renderPrice(paid) : ""}</td>
                            <td>{this.state.paymentState ? this.renderPrice(total) : ""}</td>
                            <td>
                              <PatientStatusBadge status={patient.latest_correction.status} />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientsTasksList));
