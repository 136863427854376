import React from "react";
import { FormattedMessage } from "react-intl";

import reworkPopup from "../../assets/img/wpb-popup-ico-6.png";

export function rework3DPlanNotification() {
  return <ReworkPlanNotification />;
}

function ReworkPlanNotification() {
  return (
    <div
      style={{ borderRadius: "15px !important", visibility: "visible" }}
      className="wpb-popup-wr"
    >
      <div style={{ padding: "20px" }} className="wpb-popup">
        <a
          onClick={() => _paq.push(["trackEvent", "notification", "rework plan"])}
          style={{ width: "10px", height: "10px", top: "4px", right: "8px", opacity: "0.8" }}
          href="#"
          className="wpb-popup__close"
        ></a>
        <div className="wpb-popup__body">
          <FormattedMessage id="notify.rework.ok" values={{ labelTitle: WEBPACK_LABEL_TITLE }} />
          <div className="wpb-popup__btn-ok">
            <button>
              <FormattedMessage id="notify.ok" />
            </button>
          </div>
        </div>
        <div>
          <img src={reworkPopup} width="82" height="79" alt="" />
        </div>
      </div>
    </div>
  );
}
