import React from "react";
import { FormattedMessage } from "react-intl";

import planPopup from "../../assets/img/wpb-popup-ico-7.png";

export function approve3DPlanNotification() {
  return <ApprovePlanNotification />;
}

function ApprovePlanNotification() {
  return (
    <div
      style={{ borderRadius: "15px !important", visibility: "visible" }}
      className="wpb-popup-wr"
    >
      <div className="wpb-popup wpb-popup--small">
        <a
          onClick={() => _paq.push(["trackEvent", "notification", "approve plan"])}
          style={{ width: "10px", height: "10px", top: "4px", right: "8px", opacity: "0.8" }}
          href="#"
          className="wpb-popup__close"
        ></a>
        <div className="wpb-popup__ico">
          <img src={planPopup} width="65" height="69" alt="" />
        </div>
        <div className="wpb-popup__body">
          <FormattedMessage id="notify.approve.ok" />
        </div>
        <div className="wpb-popup__btn-ok">
          <button>
            <FormattedMessage id="notify.ok" />
          </button>
        </div>
      </div>
    </div>
  );
}
