import invariant from "tiny-invariant";

import { api } from "~/api";
import { remoteLog, shouldLogError } from "~/common/logging";
import { formatPersonName } from "~/components/common/PersonName";
import type { TPatient } from "~/types/patient";

import type {
  TCabinetService,
  TCabinetSlot,
  TExpert,
  TFeedbackMeeting,
  TMeeting,
} from "./meetings-types";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMeetingsPatients: builder.query<{ id: TPatient["patient_id"]; name: string }[], void>({
      query: () => ({ url: "meetings/patients" }),
    }),
    getMeetingsExperts: builder.query<TExpert[], { expert_id?: string }>({
      queryFn: async (params, __, ___, baseQuery) => {
        try {
          const expertsResponse = await baseQuery({ url: "meetings", params });
          if (expertsResponse.error) {
            return expertsResponse;
          }
          const experts = expertsResponse.data as TExpert[];
          const expertsWithSlots = await Promise.all(
            experts.map(async (expert) => {
              try {
                const nearestSlot = await fetchExpertNearestSlot(expert);
                return { ...expert, nearest_slot: nearestSlot };
              } catch (err) {
                if (shouldLogError(err)) {
                  remoteLog(err, "fetch_expert_nearest_slot");
                }
                return expert;
              }
            }),
          );
          expertsWithSlots.sort(compareExpertsByNearestSlotOrAlphabetically);
          expertsResponse.data = expertsWithSlots;
          return expertsResponse;
        } catch (error) {
          return {
            error: {
              status: "FETCH_ERROR",
              message: error instanceof Error ? error.message : String(error),
            },
          };
        }
      },
    }),
    getMeetingFeedback: builder.query<TMeeting | null, void>({
      query: () => ({ url: "meetings/feedback" }),
      providesTags: ["MeetingFeedback"],
    }),
    postMeetingFeedback: builder.mutation<void, TFeedbackMeeting>({
      query: (data) => ({ url: "meetings/feedback", method: "POST", body: data }),
      invalidatesTags: ["MeetingFeedback"],
    }),
    delayMeetingFeedback: builder.mutation<void, { meeting_id: number }>({
      query: (data) => ({ url: "meetings/feedback", method: "PATCH", body: data }),
    }),
    getDoctorMeetings: builder.query<TMeeting[], void>({
      query: () => ({ url: "meetings/doctor" }),
    }),
    getMeeting: builder.query<TMeeting, { meetingId: string }>({
      query: (params) => ({ url: `meetings/doctor/${params.meetingId}` }),
    }),
  }),
});

export const {
  useGetMeetingsPatientsQuery,
  useGetMeetingsExpertsQuery,
  useGetMeetingFeedbackQuery,
  usePostMeetingFeedbackMutation,
  useDelayMeetingFeedbackMutation,
  useGetDoctorMeetingsQuery,
  useGetMeetingQuery,
} = extendedApi;

async function fetchExpertNearestSlot(expert: TExpert): Promise<TCabinetSlot | null> {
  const EXPERT_URL = `https://cabinet.fm/api/teams/${process.env.CABINET_COMPANY}/lessons/${expert.link}`;

  const response = await fetch(EXPERT_URL);
  if (!response.ok) {
    throw new Error(
      `Error ocurred trying to fetch service for expert with id "${expert.account_id}"`,
    );
  }
  const service = (await response.json()) as TCabinetService;

  const workerId = service.workers[0]?.id;
  invariant(workerId, "no worker id received from cabinet fm api");

  let month = new Date().getMonth() + 1;
  let slots = await fetchFreeSpotsByMonth(expert, workerId, month);
  let nearestSlot = slots[0];

  if (nearestSlot) {
    nearestSlot.times = nearestSlot.times.map((time) => ({
      ...time,
      time: time.time.slice(0, 19), // slice off '+0300' at the end
    }));
  } else if (month < 12) {
    // Try to fetch free spots for the next month
    month++;
    slots = await fetchFreeSpotsByMonth(expert, workerId, month);
    nearestSlot = slots[0];

    if (nearestSlot) {
      nearestSlot.times = nearestSlot.times.map((time) => ({
        ...time,
        time: time.time.slice(0, 19), // slice off '+0300' at the end
      }));
    }
  }

  return nearestSlot ?? null;
}

async function fetchFreeSpotsByMonth(expert: TExpert, workerId: number, month: number) {
  const EXPERT_URL = `https://cabinet.fm/api/teams/${process.env.CABINET_COMPANY}/lessons/${expert.link}`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await fetch(`${EXPERT_URL}/free_spots_month`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ month, timezone, workers_ids: [workerId] }),
  });
  if (!response.ok) {
    throw new Error(
      `Error ocurred trying to fetch free spots for expert with id "${expert.account_id}"`,
    );
  }
  let slots = (await response.json()) as TCabinetSlot[];

  const SOLOP_ACCOUNT_ID = 3342;
  if (expert.account_id == SOLOP_ACCOUNT_ID) {
    // This expert has a fixed schedule and is only available from 09:00 am to 09:30 am
    // Cabinet.fm only allows to pick 1 hour shifts, so we remove slots for
    // a meeting from 09:30 am to 10:00 am
    slots = slots.filter(slot => {
      return slot.times.some(time => {
        return new Date(time.time).getMinutes() != 30;
      })
    })
  }
  return slots;
}

function compareExpertsByNearestSlotOrAlphabetically(expertA: TExpert, expertB: TExpert): number {
  // if both have nearest slots
  if (expertA.nearest_slot?.times[0]?.time && expertB.nearest_slot?.times[0]?.time) {
    const timeA = new Date(expertA.nearest_slot.times[0].time).getTime();
    const timeB = new Date(expertB.nearest_slot.times[0].time).getTime();
    return timeA - timeB || compareExpertsAlphabetically(expertA, expertB);
  }

  // if a has
  if (expertA.nearest_slot) {
    return -1;
  }

  // if b has
  if (expertB.nearest_slot) {
    return 1;
  }

  // none have
  return compareExpertsAlphabetically(expertA, expertB);
}

function compareExpertsAlphabetically(expertA: TExpert, expertB: TExpert): number {
  const fullNameA = formatPersonName({ person: expertA, useMiddleName: true });
  const fullNameB = formatPersonName({ person: expertB, useMiddleName: true });
  return fullNameA.localeCompare(fullNameB);
}
