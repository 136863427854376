import React from "react";

import { buildLocale } from "../../common/locale";

export function FormatNumber(props: Parameters<typeof formatNumber>[0]) {
  return <span data-matomo-mask>{formatNumber(props)}</span>;
}

function formatNumber({ value, currency = false }: { value: number; currency?: boolean }) {
  if (currency) {
    return value
      .toLocaleString(buildLocale, { style: "currency", currency: "RUB" })
      .replace("₽", "руб.");
  } else {
    return value.toLocaleString(buildLocale);
  }
}

export function formatPrice(price: number): string {
  return price.toLocaleString(buildLocale);
}
