import React from "react";
import { Redirect } from "react-router-dom";

import { useOptionalUser } from "~/common/user";
import { Loader } from "~/components/ui/loader";
import { useAppSelector } from "~/store";

export function PublicOnly({ children }: { children: React.ReactElement }) {
  const user = useAppSelector((state) => state.user);
  const userIsLoading = useAppSelector((state) => state.userIsLoading);

  if (userIsLoading) {
    return <Loader />;
  }

  if (Object.keys(user).length > 0) {
    return <Redirect to="/pages/patients" />;
  }

  return children;
}

export function AuthOnly({ children }: { children: React.ReactNode }) {
  return useOptionalUser() ? <>{children}</> : null;
}
