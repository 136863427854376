import React from "react";
import { isMobile } from "react-device-detect";
import { Redirect, useLocation } from "react-router-dom";

import { deployedRussia, deployedUSA } from "~/common/utils";
import { Loader } from "~/components/ui/loader";
import { useAppSelector } from "~/store";

import { PREV_PATHNAME_STORAGE_KEY } from "./welcome";

function CatchAll() {
  const user = useAppSelector((state) => state.user);
  const userIsLoading = useAppSelector((state) => state.userIsLoading);
  const intl = useAppSelector((state) => state.intl);
  const location = useLocation();

  if (userIsLoading) {
    // <Welcome /> component already displays a <Loader /> for every page under "/pages" path.
    return location.pathname.startsWith("/pages") ? null : <Loader />;
  }

  if (Object.keys(user).length == 0) {
    return <Redirect to="/login" />;
  }

  if (!user.has_signed_offer && (deployedUSA() || (deployedRussia() && intl.locale == "ru"))) {
    return <Redirect to="/pages/offer" />;
  }

  return (
    <Redirect
      to={
        window.localStorage.getItem(PREV_PATHNAME_STORAGE_KEY) ||
        (!isMobile &&
        (deployedUSA() || (deployedRussia() && window.location.hostname.endsWith(".ru")))
          ? "/pages/home"
          : "/pages/patients")
      }
    />
  );
}

export default CatchAll;
