import React, { Component } from "react";
import { FormattedMessage, type MessageDescriptor, useIntl } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { PortletLabel } from "~/components/ui/portlet";
import { addInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => {
  return {
    instructions: state.instructions,
  };
};

const mapDispatchToProps = {
  addInstruction: addInstructions,
};

type ModalProps = PropsFromRedux & {
  visible: boolean;
  saveAsADraft: boolean;
  onCancel(): void;
  onConfirm(): void;
  saveChanges(): void;
};

type ModalState = {
  showModal: boolean;
  showRequiredFields: boolean;
};

class Modal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      showModal: false,
      showRequiredFields: false,
    };
    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  addFirstName(data: string) {
    this.props.addInstruction({ first_name: data });
  }

  addLastName(data: string) {
    this.props.addInstruction({ last_name: data });
  }

  saveChanges() {
    const { instructions, saveAsADraft } = this.props;

    if (saveAsADraft) {
      if (instructions.last_name && instructions.first_name) {
        this.props.saveChanges();
      } else {
        this.setState({ showRequiredFields: true });
      }
    } else {
      this.closeModal();
      this.props.saveChanges();
    }
  }

  closeModal() {
    this.props.onCancel();
    this.setState({ showRequiredFields: false });
  }

  render() {
    const { showRequiredFields } = this.state;
    const { saveAsADraft, instructions } = this.props;

    if (!this.props.visible) {
      return null;
    }

    return (
      <div className="col-md-6 col-md-offset-4 col-sm-4 col-sm-offset-4">
        {showRequiredFields ? (
          <StepTwo
            first_name={instructions.first_name}
            last_name={instructions.last_name}
            onFirstNameChange={(value) => this.addFirstName(value)}
            onLastNameChange={(value) => this.addLastName(value)}
            onClose={() => this.closeModal()}
            onConfirm={() => this.props.onConfirm()}
            onSave={() => this.saveChanges()}
          />
        ) : (
          <StepOne
            title={saveAsADraft ? "BTN_SAVE_DRAFT_CONTINUE_LATER" : "BTN_SAVE_CONTINUE_LATER"}
            onClose={() => this.closeModal()}
            onConfirm={() => this.props.onConfirm()}
            onSave={() => this.saveChanges()}
          />
        )}
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Modal);

function StepOne({
  title,
  onClose,
  onConfirm,
  onSave,
}: {
  title: MessageDescriptor["id"];
  onClose(): void;
  onConfirm(): void;
  onSave(): void;
}) {
  return (
    <div className="custom-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>

        <h5 className="tw-pt-2 tw-font-normal tw-leading-9">
          <FormattedMessage id={title} />
        </h5>
      </div>

      <div className="modal-footer">
        <div className="tw-flex tw-gap-2">
          <Button type="button" variant="secondary" className="tw-w-full" onClick={onConfirm}>
            <FormattedMessage id="bool.false" />
          </Button>

          <Button type="button" variant="blue" className="tw-w-full" onClick={onSave}>
            <FormattedMessage id="bool.true" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function StepTwo({
  first_name,
  last_name,
  onFirstNameChange,
  onLastNameChange,
  onClose,
  onConfirm,
  onSave,
}: {
  first_name: string | null | undefined;
  last_name: string | null | undefined;
  onFirstNameChange(value: string): void;
  onLastNameChange(value: string): void;
  onClose(): void;
  onConfirm(): void;
  onSave(): void;
}) {
  const intl = useIntl();

  return (
    <div className="custom-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>

        <span className="tw-text-red-600">
          <FormattedMessage id="REQUIRED_FIELDS_FOR_A_DRAFT" />
        </span>
      </div>

      <div className="modal-body">
        <div className="row" id="patient-block-body">
          <div className="col-sm-6">
            <div className="form-group">
              <PortletLabel
                id="validation-last_name"
                htmlFor="patient-lastname-value"
                required
                red={!last_name?.trim()}
              >
                <FormattedMessage id="PAT_INFO_LAST_NAME" />
              </PortletLabel>

              <Input
                data-matomo-mask
                defaultValue={last_name ?? ""}
                id="patient-lastname-value"
                name="last_name"
                placeholder={intl.formatMessage({ id: "PAT_INFO_LAST_NAME_PLACEHOLDER" })}
                onChange={(e) => onLastNameChange(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <PortletLabel
                id="validation-first_name"
                htmlFor="patient-firstname-value"
                required
                red={!first_name?.trim()}
              >
                <FormattedMessage id="PAT_INFO_FIRST_NAME" />
              </PortletLabel>

              <Input
                data-matomo-mask
                defaultValue={first_name ?? ""}
                id="patient-firstname-value"
                name="first_name"
                placeholder={intl.formatMessage({ id: "PAT_INFO_FIRST_NAME_PLACEHOLDER" })}
                onChange={(e) => onFirstNameChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <div className="tw-flex tw-gap-2 max-sm:tw-flex-wrap">
          <Button type="button" variant="secondary" className="tw-w-full" onClick={onConfirm}>
            <FormattedMessage id="bool.false" />
          </Button>

          <Button
            type="button"
            variant="blue"
            className="tw-w-full"
            disabled={!(last_name && first_name)}
            onClick={onSave}
          >
            <FormattedMessage id="BTN_SAVE_DRAFT" />
          </Button>
        </div>
      </div>
    </div>
  );
}
