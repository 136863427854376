import React, { lazy, Suspense } from "react";

import { RemoteLogErrorBoundary } from "../common/remote-log-error-boundary";
import { Layout } from "../ui/layout";

const LazySignOfferBody = lazy(() => import("./sign_offer_body"));

export default function SignOffer() {
  return (
    <Layout displayPatientMenu={false} centerFooter>
      <RemoteLogErrorBoundary component="sign_offer_body">
        <Suspense fallback={null}>
          <LazySignOfferBody />
        </Suspense>
      </RemoteLogErrorBoundary>
    </Layout>
  );
}
