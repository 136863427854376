import type { MessageDescriptor } from "react-intl";
import { match } from "ts-pattern";

import type { TPrescription } from "~/types/prescription";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

import { deployedUSA } from "./utils";

export const Arch = {
  BOTH: 1,
  UPPER: 2,
  LOWER: 3,
} as const;

export const UpperArch = {
  MAINTAIN: 0,
  EXPAND: 1,
  NARROW: 2,
  NA: 3,
} as const;

export const LowerArch = {
  MAINTAIN: 0,
  EXPAND: 1,
  NARROW: 2,
  NA: 3,
} as const;

export const TeethLevelAlign = {
  MARGIN: 1,
  EDGE: 2,
} as const;

export const IncisorsVerticalOverlap = {
  MAINTAIN: 0,
  DECREASE: 1,
  INCREASE: 2,
  NA: 3,
} as const;

export const IncisorsUpperHorizontalOverlap = {
  MAINTAIN: 0,
  RETROCLINATE: 1,
  PROCLINATE: 2,
  NA: 3,
} as const;

export const IncisorsLowerHorizontalOverlap = {
  MAINTAIN: 0,
  RETROCLINATE: 1,
  PROCLINATE: 2,
  NA: 3,
} as const;

export const Overjet = {
  MAINTAIN: 0,
  CONTACT: 1,
  MAINTAIN_LARGE: 2,
  CORRECT: 3,
} as const;

export const Midline = {
  MAINTAIN: 0,
  IMPROVE: 1,
} as const;

const ADJUST_LOWER_TO_UPPER = 2;
const ADJUST_UPPER_TO_LOWER = 3;

export const CaninesRatioRight = {
  CLASS_1: 1,
  CLASS_2: 2,
  CLASS_3: 3,
} as const;

export const CaninesRatioLeft = {
  CLASS_1: 1,
  CLASS_2: 2,
  CLASS_3: 3,
} as const;

export const CaninesRatioHowTo = {
  DISTALIZE: 1,
  MESIALIZE: 2,
} as const;

const CANINES_RATIO_HOWTO_IPR_FALSE = false;
const CANINES_RATIO_HOWTO_IPR_TRUE = true;

const CANINES_RATIO_AI_FALSE = false;
const CANINES_RATIO_AI_TRUE = true;

export const MolarsRatioRight = {
  MAINTAIN: 0,
  CLASS_1: 1,
  CLASS_2: 2,
  CLASS_3: 3,
} as const;

export const MolarsRatioLeft = {
  CLASS_1: 1,
  CLASS_2: 2,
  CLASS_3: 3,
} as const;

export const MolarsRatioHowTo = {
  DISTALIZE: 1,
  MESIALIZE: 2,
} as const;

const MOLARS_RATIO_HOWTO_IPR_FALSE = false;
const MOLARS_RATIO_HOWTO_IPR_TRUE = true;

const MOLARS_RATIO_AI_FALSE = false;
const MOLARS_RATIO_AI_TRUE = true;

export const ElasticRecoilRight = {
  CLASS_1: 1,
  CLASS_2: 2,
  CLASS_3: 3,
} as const;

export const ElasticRecoilLeft = {
  CLASS_1: 1,
  CLASS_2: 2,
  CLASS_3: 3,
} as const;

export const CloseAllGaps = {
  YES: 1,
  MAINTAIN: 2,
  LEAVE_SPACE: 3,
} as const;

export const Material = {
  NA: 0,
  IMPRESSIONS: 1,
  SCAN: 2,
} as const;

export const OcclusalPlane = {
  PARALLEL: 0,
  RIGHT: 1,
  LEFT: 2,
} as const;

export const OcclusalPlaneAction = {
  SAVE: 0,
  EXTRUDE: 1,
  INTRUDE: 2,
  MODIFY: 3,
} as const;

const OCCLUSAL_PLANE_EXTRUDE_TEETH = [21, 22, 23] as const;
const OCCLUSAL_PLANE_INTRUDE_TEETH = [11, 12, 13] as const;

export function getOcclusalPlaneTeeth(
  plane: (typeof OcclusalPlane)["RIGHT"] | (typeof OcclusalPlane)["LEFT"],
  action: (typeof OcclusalPlaneAction)["EXTRUDE"] | (typeof OcclusalPlaneAction)["INTRUDE"],
) {
  return match({ plane, action })
    .with(
      { plane: OcclusalPlane.RIGHT, action: OcclusalPlaneAction.EXTRUDE },
      () => OCCLUSAL_PLANE_EXTRUDE_TEETH,
    )
    .with(
      { plane: OcclusalPlane.RIGHT, action: OcclusalPlaneAction.INTRUDE },
      () => OCCLUSAL_PLANE_INTRUDE_TEETH,
    )
    .with(
      { plane: OcclusalPlane.LEFT, action: OcclusalPlaneAction.EXTRUDE },
      () => OCCLUSAL_PLANE_INTRUDE_TEETH,
    )
    .with(
      { plane: OcclusalPlane.LEFT, action: OcclusalPlaneAction.INTRUDE },
      () => OCCLUSAL_PLANE_EXTRUDE_TEETH,
    )
    .exhaustive();
}

const RX_OPTION_TRANSLATIONS = {
  arch: {
    [Arch.BOTH]: "TA_BOTH",
    [Arch.UPPER]: "TA_UPPER",
    [Arch.LOWER]: "TA_LOWER",
  },
  upper_arch_action: {
    [UpperArch.MAINTAIN]: "TA_U_MAINTAIN",
    [UpperArch.EXPAND]: "TA_U_EXPAND",
    [UpperArch.NARROW]: "TA_U_NARROW",
    [UpperArch.NA]: "NA",
  },
  lower_arch_action: {
    [LowerArch.MAINTAIN]: "TA_L_MAINTAIN",
    [LowerArch.EXPAND]: "TA_L_EXPAND",
    [LowerArch.NARROW]: "TA_L_NARROW",
    [LowerArch.NA]: "NA",
  },
  teeth_level_align: {
    [TeethLevelAlign.MARGIN]: "TLA_G_MARGIN",
    [TeethLevelAlign.EDGE]: "TLA_C_EDGE",
  },
  vertical_overlap: {
    [IncisorsVerticalOverlap.MAINTAIN]: "VO_MAINTAIN",
    [IncisorsVerticalOverlap.DECREASE]: deployedUSA() === true ? "VO_DECREASE_USA" : "VO_DECREASE",
    [IncisorsVerticalOverlap.INCREASE]: deployedUSA() === true ? "VO_INCREASE_USA" : "VO_INCREASE",
    [IncisorsVerticalOverlap.NA]: "NA",
  },
  upper_horizontal_overlap: {
    [IncisorsUpperHorizontalOverlap.MAINTAIN]: "HO_U_MAINTAIN",
    [IncisorsUpperHorizontalOverlap.RETROCLINATE]: "HO_U_RETROCLINATE",
    [IncisorsUpperHorizontalOverlap.PROCLINATE]: "HO_U_PROCLINATE",
    [IncisorsUpperHorizontalOverlap.NA]: "NA",
  },
  lower_horizontal_overlap: {
    [IncisorsLowerHorizontalOverlap.MAINTAIN]: "HO_L_MAINTAIN",
    [IncisorsLowerHorizontalOverlap.RETROCLINATE]: "HO_L_RETROCLINATE",
    [IncisorsLowerHorizontalOverlap.PROCLINATE]: "HO_L_PROCLINATE",
    [IncisorsLowerHorizontalOverlap.NA]: "NA",
  },
  overjet: {
    [Overjet.MAINTAIN]: "OJ_MAINTAIN",
    [Overjet.CONTACT]: "OJ_CONTACT",
    [Overjet.MAINTAIN_LARGE]: "OJ_MAINTAIN_LARGE",
    [Overjet.CORRECT]: "OJ_MAINTAIN_CORRECT",
  },
  midline: {
    [Midline.MAINTAIN]: "ML_MAINTAIN",
    [Midline.IMPROVE]: "ML_IMPROVE",
    [ADJUST_LOWER_TO_UPPER]: "ML_ADJUST_LOWER_TO_UPPER",
    [ADJUST_UPPER_TO_LOWER]: "ML_ADJUST_UPPER_TO_LOWER",
  },
  canine_ratio_right: {
    [CaninesRatioRight.CLASS_1]: "CR_R_I_CLASS",
    [CaninesRatioRight.CLASS_2]: "CR_R_II_CLASS",
    [CaninesRatioRight.CLASS_3]: "CR_R_III_CLASS",
  },
  canine_ratio_left: {
    [CaninesRatioLeft.CLASS_1]: "CR_L_I_CLASS",
    [CaninesRatioLeft.CLASS_2]: "CR_L_II_CLASS",
    [CaninesRatioLeft.CLASS_3]: "CR_L_III_CLASS",
  },
  canine_method: {
    [CaninesRatioHowTo.DISTALIZE]: "CRH_DISTALIZE",
    [CaninesRatioHowTo.MESIALIZE]: "CRH_MESIALIZE",
  },
  canine_ipr: {
    [`${CANINES_RATIO_HOWTO_IPR_FALSE}`]: "IPR_FALSE",
    [`${CANINES_RATIO_HOWTO_IPR_TRUE}`]: "IPR_TRUE",
  },
  canine_method_ai: {
    [`${CANINES_RATIO_AI_FALSE}`]: "AI_FALSE",
    [`${CANINES_RATIO_AI_TRUE}`]: "CRH_AI",
  },
  molars_ratio_right: {
    [MolarsRatioRight.MAINTAIN]: "MR_R_MAINTAIN",
    [MolarsRatioRight.CLASS_1]: "MR_R_I_CLASS",
    [MolarsRatioRight.CLASS_2]: "MR_R_II_CLASS",
    [MolarsRatioRight.CLASS_3]: "MR_R_III_CLASS",
  },
  molars_ratio_left: {
    [MolarsRatioLeft.CLASS_1]: "MR_L_I_CLASS",
    [MolarsRatioLeft.CLASS_2]: "MR_L_II_CLASS",
    [MolarsRatioLeft.CLASS_3]: "MR_L_III_CLASS",
  },
  molars_method: {
    [MolarsRatioHowTo.DISTALIZE]: "CRH_DISTALIZE",
    [MolarsRatioHowTo.MESIALIZE]: "CRH_MESIALIZE",
  },
  molars_ipr: {
    [`${MOLARS_RATIO_HOWTO_IPR_FALSE}`]: "IPR_FALSE",
    [`${MOLARS_RATIO_HOWTO_IPR_TRUE}`]: "IPR_TRUE",
  },
  molars_method_ai: {
    [`${MOLARS_RATIO_AI_FALSE}`]: "AI_FALSE",
    [`${MOLARS_RATIO_AI_TRUE}`]: "CRH_AI",
  },
  close_all_gaps: {
    [CloseAllGaps.YES]: "CAG_YES",
    [CloseAllGaps.MAINTAIN]: "CAG_MAINTAIN",
    [CloseAllGaps.LEAVE_SPACE]: "CAG_LEAVE_SPACE",
  },
  elastics_recoil_right: {
    [ElasticRecoilRight.CLASS_1]: "ER_R_I_CLASS",
    [ElasticRecoilRight.CLASS_2]: "ER_R_II_CLASS",
    [ElasticRecoilRight.CLASS_3]: "ER_R_III_CLASS",
  },
  elastics_recoil_left: {
    [ElasticRecoilLeft.CLASS_1]: "ER_L_I_CLASS",
    [ElasticRecoilLeft.CLASS_2]: "ER_L_II_CLASS",
    [ElasticRecoilLeft.CLASS_3]: "ER_L_III_CLASS",
  },
  material: {
    [Material.NA]: "NA",
    [Material.IMPRESSIONS]: "C_IMPRESSIONS",
    [Material.SCAN]: "C_SCAN",
  },
  occlusal_plane: {
    [OcclusalPlane.PARALLEL]: "TA_P_OCCLUSAL",
    [OcclusalPlane.RIGHT]: "TA_R_OCCLUSAL",
    [OcclusalPlane.LEFT]: "TA_L_OCCLUSAL",
  },
  occlusal_plane_action: {
    [OcclusalPlaneAction.SAVE]: "TA_OCCLUSAL_SAVE",
    [OcclusalPlaneAction.EXTRUDE]: "TA_OCCLUSAL_TEETH_EXTRUDE",
    [OcclusalPlaneAction.INTRUDE]: "TA_OCCLUSAL_TEETH_INTRUDE",
  },
} satisfies Record<keyof TPrescription, Record<number | string, MessageDescriptor["id"]>>;

type RxTranslations = typeof RX_OPTION_TRANSLATIONS;

export function translateRxOption<T extends keyof RxTranslations>(
  key: T,
  option: keyof RxTranslations[T] | null,
): MessageDescriptor["id"] {
  if (option === null || option === undefined) {
    return "None";
  }

  return RX_OPTION_TRANSLATIONS[key][option];
}

export const INITIAL_VALUES_PRESCRIPTION: TPrescriptionReduxForm = {
  material: null,
  arch: null,
  upper_arch_action: null,
  lower_arch_action: null,
  teeth_level_align: null,
  vertical_overlap: null,
  vertical_overlap_comment: "",
  upper_horizontal_overlap: null,
  lower_horizontal_overlap: null,
  overjet: null,
  correct_retroclination_upper: false,
  correct_proclination_lower: false,
  correct_proclination_upper: false,
  correct_retroclination_lower: false,
  midline: null,
  midline_improve_by: "",
  canine_ratio_right: null,
  canine_ratio_left: null,
  canine_method: null,
  canine_method_ai: false,
  canine_ipr: false,
  molars_ratio_right: null,
  molars_ratio_left: null,
  molars_method: null,
  molars_method_ai: false,
  molars_ipr: false,
  close_all_gaps: null,
  close_all_gaps_value: "",
  elastics_recoil_right: null,
  elastics_recoil_left: null,
  elastics_options: {},
  comment: "",
  upper_arch_expansion_bodily: false,
  upper_arch_expansion_torque: false,
  lower_arch_expansion_bodily: false,
  lower_arch_expansion_torque: false,
  occlusal_plane: null,
  occlusal_plane_action: null,
  occlusal_plane_subaction: null,
  occlusal_plane_teeth: [],
  dont_move: [],
  avoid_locks: [],
  extractions: [],
  teething: [],
  close_all_gaps_spaces: [{}],
};

export function createTeeth(): number[][] {
  const quads = [1, 2, 4, 3];
  const teeth = quads.map((quad) => {
    const arr = [];

    for (let i = 1; i <= 8; i++) {
      arr.push(quad * 10 + i);
    }

    if (quad === 1 || quad === 4) arr.reverse();
    return arr;
  });
  return teeth;
}
