import clsx from "clsx";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import newYearBauble from "~/assets/img/new-year-bauble.svg";
import { SUPPORT_PHONE_NUMBER } from "~/common/constants";
import { useOptionalUser } from "~/common/user";
import { formatPhoneNumber } from "~/common/utils";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { useAppSelector } from "~/store";

import { ProfileMenu } from "./profile_menu";

export default function PageHeader() {
  const intl = useIntl();
  const user = useOptionalUser();

  const tour = useAppSelector((state) => state.tour);
  const banners = useAppSelector((state) => state.banners);
  const bannersHome = banners.filter((banner) => banner.align == "center");

  const hasSignedOffer = user?.has_signed_offer || intl.locale == "en";

  return (
    <div className="page-header navbar mob-navbar">
      <header
        id="top-navbar"
        className={clsx("page-header-inner container", { "max-sm:tw-flex": !hasSignedOffer })}
      >
        <div
          className={clsx("tw-relative tw-float-left tw-flex tw-h-[75px] tw-items-center tw-px-3", {
            "max-sm:tw-w-full": !hasSignedOffer,
          })}
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <div id="logo">
              <NavLink
                to={bannersHome.length > 0 ? "/pages/home" : "#"}
                style={tour ? { pointerEvents: "none" } : {}}
              >
                <img
                  src="/img/3d_smile_logo_rgb.svg"
                  alt="logo"
                  className="logo-default"
                  style={{ width: 120 }}
                />
              </NavLink>

              {DISPLAY_NEW_YEAR_DECORATION ? (
                <img
                  className="tw-absolute tw-right-[-70px] tw-top-[-25px] tw-z-50 desktop:tw-right-[-50px] desktop:tw-top-[-10px]"
                  src={newYearBauble}
                  alt=""
                />
              ) : null}
            </div>

            <div className="tw-flex tw-flex-col">
              <span className="tw-text-[13px]">
                <FormattedMessage id="care_support" />
              </span>
              <a
                href={`tel:${SUPPORT_PHONE_NUMBER}`}
                className="tw-text-[#34495e] tw-no-underline tw-transition-colors hover:tw-text-[#00aebe] active:tw-text-[#00aebe]"
              >
                {formatPhoneNumber(SUPPORT_PHONE_NUMBER).replaceAll("-", " ")}
              </a>
            </div>
          </div>

          <div className="menu-toggler sidebar-toggler"></div>
        </div>

        {hasSignedOffer ? (
          <button
            // NOTE: <PatientMenu /> uses this id to access the element and close the menu.
            id="js-patient-menu-toggle-btn"
            className="menu-toggler responsive-toggler"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            style={{ border: "none", backgroundColor: "transparent" }}
            aria-label="Toggle navigation menu"
            aria-controls="patient-menu-sidebar"
          ></button>
        ) : null}

        <div className={clsx("page-top", { "max-sm:tw-w-auto": !hasSignedOffer })}>
          <RemoteLogErrorBoundary component="profile_menu" fallbackRender={null}>
            <ProfileMenu />
          </RemoteLogErrorBoundary>
        </div>
      </header>
    </div>
  );
}

function shouldDisplayNewYearDecoration(): boolean {
  const currentDate = new Date();
  const startOfWinterDate = new Date(currentDate.getFullYear(), 11, 1);
  const endOfWinterHolidays = new Date(currentDate.getFullYear(), 0, 15);

  return currentDate >= startOfWinterDate || currentDate < endOfWinterHolidays;
}

const DISPLAY_NEW_YEAR_DECORATION = shouldDisplayNewYearDecoration();
