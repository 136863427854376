import React from "react";

export function Banner({ path, image, altText }: { path: string; image: string; altText: string }) {
  return (
    <div data-testid="banner_link">
      <div style={{ marginLeft: 1, marginRight: 1 }}>
        <a href={path} rel="noopener noreferrer" target="_blank">
          <div style={{ width: "100%", backgroundColor: "#3b8489" }}>
            <img src={image} width="100%" style={{ width: "100%" }} alt="" /* alt={altText} */ />
          </div>
        </a>
      </div>
    </div>
  );
}

export function BannerImage({ image, altText }: { image: string; altText: string }) {
  return (
    <div
      style={{ background: "#e9edf4", marginLeft: 1, marginRight: 1 }}
      data-testid="banner_image"
    >
      <div style={{ marginLeft: 1, marginRight: 1 }}>
        <div style={{ width: "100%", backgroundColor: "#3b8489" }}>
          <img src={image} width="100%" style={{ width: "100%" }} alt="" /* alt={altText} */ />
        </div>
      </div>
    </div>
  );
}
