import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useGetInvoicePaymentStatusQuery } from "../../api";
import { useDocumentTitle } from "../../hooks/use-document-title";
import { PaymentStatusMessage } from "../common/payment-status-message";
import { Portlet, PortletTitle } from "../ui/portlet";
import PageFooter from "./page_footer";
import PageHeader from "./page_header";

export default function PatientPaymentStatus() {
  useDocumentTitle(useIntl().formatMessage({ id: "pat.payments.online.payment" }));

  const { token, patient_id, invoice_id } = useParams<{
    token: string;
    patient_id: string;
    invoice_id: string;
  }>();

  const invoiceQuery = useGetInvoicePaymentStatusQuery({
    patientId: Number(patient_id),
    invoiceId: Number(invoice_id),
    patientToken: token,
  });

  return (
    <>
      <PageHeader />
      <div className="container">
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-content" style={{ minHeight: 584 }}>
              <div className="row">
                <div className="col-md-8">
                  <Portlet as="main">
                    <PortletTitle iconClassName="icon-book-open">
                      <FormattedMessage id="pat.payments.online.payment" />
                    </PortletTitle>

                    <div className="portlet-body">
                      <PaymentStatusMessage query={invoiceQuery} />
                    </div>
                  </Portlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}
