import * as RadixTooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import React, { useState } from "react";

export function QuestionTooltip({ children }: { children: React.ReactNode }) {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <TooltipRoot>
        <RadixTooltip.Trigger className="tw-cursor-default tw-border-none tw-bg-transparent">
          <i style={{ fontSize: "14px" }} className="icon-question" />
        </RadixTooltip.Trigger>

        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={clsx(
              "tw-max-w-[375px] tw-bg-[#f7f7f7] tw-p-2.5 tw-text-xs tw-shadow-md",
              "motion-safe:tw-duration-300 motion-safe:tw-animate-in",
              "data-[state=delayed-open]:tw-fade-in-0",
              "data-[state=delayed-open]:tw-slide-in-from-top-1",
            )}
            sideOffset={5}
            side="top"
          >
            {children}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </TooltipRoot>
    </RadixTooltip.Provider>
  );
}

function TooltipRoot({
  alwaysOpen,
  children,
}: {
  alwaysOpen?: boolean;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <RadixTooltip.Root open={alwaysOpen || open} delayDuration={0} onOpenChange={setOpen}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div style={{ display: "inline-block" }} onClick={() => setOpen(true)}>
        {children}
      </div>
    </RadixTooltip.Root>
  );
}
