import * as Sentry from "@sentry/react";
import invariant from "tiny-invariant";

import { isUser } from "~/common/user";
import { API_LOGIN, API_WHOAMI } from "~/config";
import type { AppDispatch } from "~/store";
import type { TUser } from "~/types/user";

import { bannersIsLoadingAction, getBanners } from "./banners";
import { getSmilesAction } from "./smiles";

export function userIsLoadingAction(bool: boolean) {
  return { type: "USER_IS_LOGGING_IN", userIsLoading: bool } as const;
}

export function userLoggedInSuccess(fields: TUser) {
  return { type: "USER_LOGGED_IN_SUCCESS", fields } as const;
}

function userLoggedInFailure() {
  return { type: "USER_LOGGED_IN_ERROR", message: "login.error" };
}

export function initializeUser() {
  return (dispatch: AppDispatch) => {
    dispatch(userIsLoadingAction(true));
    fetch(API_WHOAMI, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        invariant(isUser(json), `${API_LOGIN} - unexpected json received`);

        const { smiles, ...user } = json;
        dispatch(userLoggedInSuccess(user));
        // NOTE: Based on the smiles object we display a link in the `PatientMenu` and a link in the `ProfileMenu`.
        // It used to be fetched separately only on patients and smiles pages, which led to links not appearing
        // unless the user navigated to the patients or smiles page. Once the user navigated there,
        // the `PatientMenu` would shift and display a new link in the middle of itself, and a link with a badge count
        // would appear at the top which led to a poor user experience. To mitigate this, we now pass the smiles
        // object as part of the user object from the API, but still store them separately on the frontend for the
        // time being.
        dispatch(getSmilesAction(smiles));
        dispatch(userIsLoadingAction(false)); // Order matters. Must be after userLoggedInSuccess for CatchAll route to work correctly.

        Sentry.setUser({ id: user.account_id });
      })
      .catch((e) => {
        dispatch({ type: "USER_INIT_FAILURE", message: e.message });
        dispatch(userIsLoadingAction(false));
        dispatch(userLoggedInFailure());
      });
  };
}

export function loginUser(login: string, password: string) {
  return async (dispatch: AppDispatch) => {
    const response = await fetch(API_LOGIN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ login: login, password: password }),
    });

    if (!response.ok) {
      throw new Error(`Error occurred trying to login user: ${response.statusText}`);
    }

    const json = await response.json();
    invariant(isUser(json), `${API_LOGIN} - unexpected json received`);
    const { smiles, ...user } = json;

    dispatch(userLoggedInSuccess(user));
    dispatch(getSmilesAction(smiles));

    Sentry.setUser({ id: user.account_id });

    dispatch(bannersIsLoadingAction(true));
    await dispatch(getBanners());
  };
}
