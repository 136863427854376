import React from "react";
import { FormattedMessage } from "react-intl";

import { API_PATIENT_IMAGE } from "~/config";
import type { TCorrectionMediaView, TPatient } from "~/types/patient";

import PatientCorrectionPhoto from "../patient_correction_photo";

export function PatientCardPhotos({ patient }: { patient: TPatient }) {
  const { required_images } = patient.media;
  const prefix = API_PATIENT_IMAGE(patient.patient_id, "");

  return (
    <div>
      <h3 className="block" id="photo-protocol-block">
        <FormattedMessage id="PHOTO_BEFORE" />
      </h3>

      <div className="row">
        <div className="col-md-4" id="photo-profile">
          <div className="form-group">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_PROFILE" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.profile} />
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group" id="photo-front-smile">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_SMILE" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.full_face_with_smile} />
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group" id="photo-front-no-smile">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_PORTRATE" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.full_face_without_smile} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group" id="photo-upper-occlusal">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.occlusal_view_upper} />
          </div>
        </div>

        <div className="col-md-4" />

        <div className="col-md-4">
          <div className="form-group" id="photo-lower-occlusal">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.occlusal_view_lower} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group" id="photo-lateral-right">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_RIGHT" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.lateral_view_right} />
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group" id="photo-intraoral-front">
            <span className="tw-mb-1.5 tw-inline-block">
              <br />
              <FormattedMessage id="PHOTO_FRONT" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.front_view} />
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group" id="photo-lateral-left">
            <span className="tw-mb-1.5 tw-inline-block">
              <FormattedMessage id="PHOTO_LEFT" />
            </span>

            <RequiredPhoto prefix={prefix} view={required_images.lateral_view_left} />
          </div>
        </div>
      </div>

      {Object.keys(patient.media.correction_media).length == 0 ? null : (
        <PatientCorrectionPhoto patient={patient} />
      )}
    </div>
  );
}

function RequiredPhoto({
  prefix,
  view,
}: {
  prefix: string;
  view: TCorrectionMediaView | undefined;
}) {
  if (view) {
    return (
      <a href={prefix + view.linkto} target="_blank" rel="noreferrer">
        <img src={prefix + view.thumbnail + `?${Number(new Date())}`} width="100%" alt="" />
      </a>
    );
  }

  return null;
}
