import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";
import React, { forwardRef } from "react";

export const Select = SelectPrimitive.Root;

export const SelectGroup = SelectPrimitive.Group;

export const SelectTrigger = forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, forwardedRef) => (
  <SelectPrimitive.Trigger
    ref={forwardedRef}
    className={clsx(
      "col-xs-12 col-sm-6 col-md-6 col-lg-4 ",
      "tw-relative tw-h-9 tw-cursor-default tw-rounded-md tw-bg-white tw-pl-2.5 tw-text-left",
      "tw-border tw-border-solid tw-border-[#c2cad8] max-sm:tw-text-base",
      className,
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon className="tw-absolute tw-right-0 tw-inline-flex tw-items-center tw-pr-2">
      <ChevronDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

export const SelectContent = forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      sideOffset={6}
      className={clsx(
        "tw-relative tw-w-full tw-px-0",
        "tw-border tw-border-solid tw-border-[#c2cad8] tw-shadow-md",
        "data-[state=open]:tw-animate-in",
        "motion-safe:data-[state=open]:tw-fade-in-0 motion-safe:data-[state=open]:tw-zoom-in-95",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={clsx(
          "tw-bg-white tw-p-1 tw-shadow-lg",
          "tw-h-[var(--radix-select-trigger-height)] tw-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={clsx(
      "tw-relative tw-cursor-default max-sm:tw-text-base",
      "focus-visible:tw-outline-none data-[highlighted]:tw-bg-[#ddebff]",
      "tw-py-1.5 tw-pl-8 tw-pr-2",
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemIndicator className="tw-absolute tw-left-2 tw-top-2 tw-h-3.5 tw-w-3.5">
      <CheckIcon className="tw-h-4 tw-w-4" />
    </SelectPrimitive.ItemIndicator>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;
