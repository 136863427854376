import * as Sentry from "@sentry/react";
import { serializeError } from "serialize-error";

import { isDevelopment } from "./utils";

/**
 * Sends an error to the server.
 * @param {unknown} error Exception, error object, or message.
 * @param {string} component Name of component.
 */
export function remoteLog(error: unknown, component: string): void {
  sentryCaptureError(error, component);
  const url = window.location.pathname + window.location.search;

  fetch(`${process.env.API}/remote/log`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ content: serializeError(error), component, url }),
  }).catch((err) => {
    if (shouldLogError(err)) {
      sentryCaptureError(err, "remoteLog");
    }
  });

  if (isDevelopment) {
    console.error("remoteLog()", component, error);
  }
}

function sentryCaptureError(error: unknown, component: string): void {
  if (error instanceof Error) {
    Sentry.captureException(error, { tags: { component } });
  } else {
    Sentry.captureMessage(String(error), { tags: { component } });
  }
}

/**
 * Checks if an error should be logged based on its message.
 */
export function shouldLogError(err: unknown): boolean {
  return !(
    err instanceof Error &&
    err.name == "TypeError" &&
    ["Failed to fetch", "Load failed"].includes(err.message)
  );
}
