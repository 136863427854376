import React from "react";
import { FormattedMessage } from "react-intl";

export default function EventPaymentErrorPage() {
    return (
      <div className="row">
        <div className="col-md-8">
          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <i className="icon-book-open font-green" />
                <span className="caption-subject font-green bold uppercase">
                  <FormattedMessage id="pat.payments.online.payment" />
                </span>
              </div>
            </div>
            <div className="portlet-body">
              <div className="form-body">
                <div className="row fileupload-buttonbar">
                  <div className="col-lg-7">
                    <FormattedMessage id="pat.payments.course.paid.error" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
