import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { getExtraServices } from '~/actions/extra_services';
import { getPatientId } from '~/actions/get_patient_id';
import { sendInvoiceRequest } from '~/actions/invoice';
import { Payer, PAYMENT_SYSTEM_TYPE, PaymentMethod, PaymentSystem, SBERBANK_DISCLAIMER } from '~/common/constants';
import { remoteLog } from '~/common/logging';
import { isPatient } from '~/common/patient';
import { isDevelopment } from '~/common/utils';
import { Button } from '~/components/ui/button';
import { Layout } from '~/components/ui/layout';
import { Loader } from "~/components/ui/loader";
import { Portlet, PortletTitle } from '~/components/ui/portlet';
import { setDocumentTitle } from '~/hooks/use-document-title';
import type { RootState } from '~/store';
import type { TPatient } from "~/types/patient";

const mapStateToProps = (state: RootState) => {
  return {
    patient: state.patient,
    invoice: state.invoice,
    services: state.services,
  };
};

const mapDispatchToProps = {
  sendInvoiceRequest,
  getPatient: getPatientId,
  getExtraServices,
};

type PaymentInvoiceProps = PropsFromRedux & RouteComponentProps<{ patient_id: string }>;

type PaymentInvoiceState = {
  patient_id: string | null;
  amount : {
    price: number;
    course: boolean;
    extra: null;
  } | null;
  canSubmit: boolean;
  showLoader: boolean;
};

class PaymentInvoice extends Component<PaymentInvoiceProps, PaymentInvoiceState> {
  constructor(props: PaymentInvoiceProps) {
    super(props);
    this.state = {
      patient_id: null,
      amount: null,
      canSubmit: false,
      showLoader: false,
    }
    this.requestToPay = this.requestToPay.bind(this);
    this.paymentOptions = this.paymentOptions.bind(this);
    this.setAmount = this.setAmount.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "payment_invoice");
  }

  componentDidMount() {
    const { patient_id } = this.props.match.params;
    this.props.getPatient(patient_id);
    this.props.getExtraServices(patient_id);
    this.setState({ patient_id });
    setDocumentTitle("Он-лайн оплата")
  }

  setAmount(amount: PaymentInvoiceState["amount"]) {
   this.setState({ amount });
   this.setState({ canSubmit: true });
  }

  requestToPay() {
    if (this.state.amount) {
      this.setState({ showLoader: true })
      this.setState({ canSubmit: false })
      this.props.sendInvoiceRequest(this.state.patient_id, this.state.amount);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PaymentInvoiceProps) {
    if (nextProps.invoice.invoice && nextProps.invoice.invoice.formUrl) {
      window.location.href = nextProps.invoice.invoice.formUrl;
    }
  }

  paymentOptions(course: TPatient["course"]) {
    if (course && course.payments && Object.keys(course.payments).length > 0 && course.payment_method == PaymentMethod.CARD) {
      const { payment_option } = course;
      const { services } = this.props;
      const { payments } = course;
      const remain = payments['remain'];
      const next = payments['next'];
      const cbct = payments['cbct'];
      let extra = null;

      if (cbct == true && Array.isArray(services)) {
        const cbct_service = services.filter(service => service.tag === 'CBCT_ANALYSIS' && service.paid == false);
        if (cbct_service && cbct_service.length > 0) {
          extra = cbct_service.pop();
        }
      }
      if (remain > 0 || next > 0) {
        if (payment_option != "PO_ADVANCE") {
          return (
            <div className="form-group">
              <div className="radio-list">
                <label className="control-label">Доступные оплаты:</label>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        name="payment_option"
                        value={1}
                        onChange={() => this.setAmount({ price: next, course: true, extra })}
                      />
                    </span>
                  </div>
                  {next} рублей
                </label>
                {next != remain
                  ? (
                  <label>
                    <div className="radio">
                      <span>
                        <input
                          type="radio"
                          name="payment_option"
                          value={2}
                          onChange={() => this.setAmount({ price: remain, course: true, extra })}
                        />
                      </span>
                    </div>
                    Оплатить оставшуюся сумму целиком ({remain} рублей)
                  </label>
                  ) : null
                }
              </div>
            </div>
          )
        } else {
          return(
            <div className="form-group">
              <div>
                <div className="radio-list">
                  <label>
                    <div className="radio">
                      <span>
                        <input
                          type="radio"
                          name="payment_option"
                          value={1}
                          onChange={() => this.setAmount({ price: next, course: true, extra })}
                        />
                      </span>
                    </div>
                    Оплатить сумму {next} рублей
                  </label>
                  <br/>
                </div>
              </div>
            </div>
          )
        }
      }
      return null;
    }
  }

  renderExtraPayments(course: TPatient["course"]) {
    if (course.payments && course.payments.extra && Array.isArray(course.payments.extra) && course.payments.extra.length > 0) {
      const { extra } = course.payments;
      const extra_payments = [];
      extra.forEach((e, index) => {
        extra_payments.push(
          <label key={e.id}>
            <div className="radio">
              <span>
                <input
                  type="radio"
                  name="payment_option"
                  value={index}
                  onChange={() => this.setAmount({ price: e.price, course: false, extra: { ...e }})}
                />
              </span>
            </div>
            <FormattedMessage id={e.tag} /> {e.price} рублей
          </label>
        )
      })
      return (
        <div className="form-group">
          <div className="radio-list">
            <label className="control-label">Оплата дополнительных услуг:</label>
            {extra_payments}
          </div>
        </div>
      )
    }
  }

  render() {
    if (!isPatient(this.props.patient)) {
      return (
        <Layout>
          <div className="row">
            <div className="col-md-8">
              <Loader />
            </div>
          </div>
        </Layout>
      );
    }

    if (window.location.protocol === "https:" || isDevelopment) {
      const { patient } = this.props;
      const { course } = patient;

      if (patient.payer_id == Payer.DOCTOR && course && course.payment_method == PaymentMethod.CARD) {
        const { total_payments } = patient;

        return (
          <Layout>
            <div className="row">
              <div className="col-md-8">
                <Portlet as="main">
                  <PortletTitle iconClassName="icon-book-open">
                    Он-лайн оплата
                  </PortletTitle>
                  <div className="portlet-body">
                    <div>
                      <label>Оплачено {total_payments.paid} из {total_payments.total} рублей</label>
                    </div>
                    {this.paymentOptions(course)}
                    {this.renderExtraPayments(course)}
                    <div>
                      {PAYMENT_SYSTEM_TYPE == PaymentSystem.SBERBANK ? (
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            rows={10}
                            readOnly={true}
                            defaultValue={SBERBANK_DISCLAIMER}
                          >
                          </textarea>
                        </div>
                      ) : null}
                      <Button
                        id="test-btn"
                        disabled={!this.state.canSubmit}
                        onClick={() => this.requestToPay()}
                      >
                        Оплатить
                      </Button>
                    </div>
                    {this.state.showLoader && <Loader msg="Сейчас вы будете перенаправлены на форму оплаты" />}
                  </div>
                </Portlet>
              </div>
            </div>
          </Layout>
        );
      }
    }

    return null;
  }
}

const connector = connect(mapStateToProps,mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentInvoice);
