import React from "react";
import { FormattedMessage } from "react-intl";

import submitFormPop from "../../assets/img/submit_form.png";

export function formSubmitNotification() {
  return <FormSubmitNotification />;
}

function FormSubmitNotification() {
  return (
    <div style={{ visibility: "visible" }} className="wpb-popup-wr">
      <div className="wpb-popup wpb-popup--big">
        <a
          onClick={() => _paq.push(["trackEvent", "notification", "correction sumbit"])}
          href="#"
          className="wpb-popup__close"
        ></a>
        <div style={{ width: "100%" }} className="wpb-popup__ico">
          <img src={submitFormPop} width="200" height="200" alt="" />
        </div>
        <div className="wpb-popup__body">
          <p>
            <strong>
              <FormattedMessage id="pat.payments.thanks" />
            </strong>
            <FormattedMessage id="notify.form.submitted.ok" />
          </p>
          <div className="wpb-popup__btn-ok">
            <button>
              <FormattedMessage id="notify.ok" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
