import { PaymentMethodId } from "../common/constants";
import {
  API_EVENT,
  API_EVENT_ENROLL_FREE,
  API_EVENTS_FEEDBACK,
  API_EVENTS_PAYMENTS,
} from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { TEvent } from "../reducers/events";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from "./user_notification";

export const FETCH_EVENT = "FETCH_EVENT";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_ERROR = "FETCH_EVENT_ERROR";

export function getEvent(event_id: TEvent["id"]) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: FETCH_EVENT });
    fetch(API_EVENT(event_id), { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch event");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: FETCH_EVENT_SUCCESS, json });
      })
      .catch((e) => {
        dispatch({ type: FETCH_EVENT_ERROR, error: e });
      });
  };
}

export function enrollFree(event_id: TEvent["id"]) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "events.enroll.success",
    level: "success",
    position: "tl",
    autoDismiss: 10,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "events.enroll.error",
    level: "error",
    position: "tl",
    autoDismiss: 11,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_EVENT_ENROLL_FREE(event_id), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Error occurred trying to enroll into free event");
      }

      dispatch(getEvent(event_id));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
    } catch {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    }
  };
}

export function eventEnroll(
  event_id: TEvent["id"],
  amount: number,
  rate: string,
  payment_method_id: PaymentMethodId,
  smiles: number,
  email: string | null,
  entity: string | null,
) {
  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "events.payments.link.error",
    level: "error",
    position: "tl",
    autoDismiss: 11,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_EVENTS_PAYMENTS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        event_id,
        amount,
        rate,
        payment_method_id,
        smiles,
        email,
        entity,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to enroll into event");
        }
        return response.json();
      })
      .then((json) => {
        if (payment_method_id == PaymentMethodId.CARD) {
          dispatch({ type: "GET_INVOICE_DATA", json: { link: json } });
        } else {
          dispatch({ type: FETCH_EVENT_SUCCESS, json });
        }
      })
      .catch(() => dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR)));
  };
}

export function sendFeedback(event_id: TEvent["id"], comment: string) {
  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "events.send.feedback.error",
    level: "error",
    position: "tl",
    autoDismiss: 11,
  };

  const NOTIFICATION_MSG_SUCCESS: TUserActionNotification = {
    message: "events.send.feedback.success",
    level: "success",
    position: "tl",
    autoDismiss: 11,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_EVENTS_FEEDBACK(event_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ comment }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to send event feedback");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: FETCH_EVENT_SUCCESS, json });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_SUCCESS));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}
