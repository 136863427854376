import {
  DentalNotationType,
  DEPLOY_RUSSIA,
  DEPLOY_USA,
  DN_MAP_PALMER,
  DN_MAP_UNIVERSAL,
} from "./constants";

export const isDevelopment = process.env.NODE_ENV == "development";

export function deployedUSA(): boolean {
  return process.env.DEPLOY_COUNTRY == DEPLOY_USA;
}

export function deployedRussia(): boolean {
  return process.env.DEPLOY_COUNTRY == DEPLOY_RUSSIA;
}

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function convertToDentalNotation(tooth: number, dentalNotation: DentalNotationType | undefined) {
  if (dentalNotation === DentalNotationType.PALMER) {
    return DN_MAP_PALMER[tooth];
  } else if (dentalNotation === DentalNotationType.UNIVERSAL) {
    return DN_MAP_UNIVERSAL[tooth];
  }
  return tooth;
}

export function formatPhoneNumber(phone: string): string {
  if (/^\+7\d{10}$/.test(phone)) {
    const first = phone.slice(2, 5);
    const second = phone.slice(5, 8);
    const third = phone.slice(8, 10);
    const fourth = phone.slice(10);
    return `+7 (${first}) ${second}-${third}-${fourth}`;
  }

  if (/^8\d{10}$/.test(phone)) {
    const first = phone.slice(1, 4);
    const second = phone.slice(4, 7);
    const third = phone.slice(7, 9);
    const fourth = phone.slice(9);
    return `8 (${first}) ${second}-${third}-${fourth}`;
  }

  return phone;
}
