import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { useGetInvoicePaymentStatusQuery } from "../../../../api";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { PaymentStatusMessage } from "../../../common/payment-status-message";
import { Button } from "../../../ui/button";
import { Layout } from "../../../ui/layout";
import { Portlet, PortletTitle } from "../../../ui/portlet";

export default function PaymentInvoiceStatus() {
  useDocumentTitle(useIntl().formatMessage({ id: "pat.payments.online.payment" }));
  const { patient_id, invoice_id } = useParams<{ patient_id: string; invoice_id: string }>();

  const invoiceQuery = useGetInvoicePaymentStatusQuery({
    patientId: Number(patient_id),
    invoiceId: Number(invoice_id),
  });
  const invoiceStatus = invoiceQuery.data?.status;

  return (
    <Layout>
      <Portlet as="main" className="tw-h-full">
        <PortletTitle iconClassName="icon-book-open">
          <FormattedMessage id="pat.payments.online.payment" />
        </PortletTitle>

        <div className="portlet-body tw-space-y-4 max-desktop:tw-text-center">
          <PaymentStatusMessage query={invoiceQuery} />

          {invoiceStatus == "succeeded" ? (
            <Button asChild>
              <Link to={`/pages/patient/${patient_id}`}>
                <FormattedMessage id="pat.payments.payment_status.goto" />
              </Link>
            </Button>
           ) : null}
        </div>
      </Portlet>
    </Layout>
  );
}
