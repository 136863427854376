import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import { getLastCorrection } from "~/common/patient";
import { Button } from "~/components/ui/button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { useAppSelector } from "~/store";
import type { TPatient } from "~/types/patient";

export function PatientAdditionalAlignersCard({ patient }: { patient: TPatient }) {
  const services = useAppSelector((state) => state.services);

  const canOrderAdditionalAligners =
    getLastCorrection(patient).order_options.can_order_additional_aligners;

  const showAligners = Array.isArray(services)
    ? services.some((service) => service.tag == "ADDITIONAL_ALIGNERS")
    : false;

  return (
    <Portlet id="add-aligners-side-block">
      <PortletTitle as="h2" iconClassName="icon-speech" id="add-aligners-side-block-name">
        <FormattedMessage id="pat.additional.aligners.page.header" />
      </PortletTitle>

      <div className="portlet-body" id="add-aligners-side-block-body">
        {canOrderAdditionalAligners ? (
          <div className="form-group">
            <Button asChild>
              <NavLink to={`/pages/patient/${patient.patient_id}/additional/aligners`}>
                <FormattedMessage id="pat.status.button.aligners.additional" />
              </NavLink>
            </Button>
          </div>
        ) : null}

        <br />
        <br />

        {showAligners ? (
          <NavLink
            style={{ position: "relative", bottom: "10px" }}
            to={`/pages/patient/${patient.patient_id}/aligners/additional`}
          >
            <i>
              <FormattedMessage id="all.additional.aligners" />
            </i>
          </NavLink>
        ) : null}
      </div>
    </Portlet>
  );
}
