import type { SOArch, SOMaterial } from "~/common/constants";
import { formSubmitNotification } from "~/components/notifications/form_submit_pop";
import { API_POST_STATUS_RETAINERS } from "~/config";
import type { TUserActionNotification } from "~/reducers/dashboard";
import type { AppDispatch } from "~/store";
import type { TPatient } from "~/types/patient";

import { getExtraServices } from "./extra_services";
import { addUserActionNotification } from "./user_notification";

export function orderRetainers(
  patient_id: TPatient["patient_id"],
  material: SOMaterial,
  treatArchesOption: SOArch,
  quantity: number,
  comment: string,
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.form.submitted.ok",
    level: "success",
    position: "tc",
    autoDismiss: 10,
    children: formSubmitNotification(),
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.retainers.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_POST_STATUS_RETAINERS(patient_id), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          material: material,
          treat_arches_id: treatArchesOption,
          quantity,
          comment: comment,
        }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Error occurred trying to order retainers!");
      }
      const json = await response.json();
      dispatch({ type: "POST_STATUS_RETAINERS", json });
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      await dispatch(getExtraServices(patient_id));
    } catch {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    }
  };
}
