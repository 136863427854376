import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";

import type { DentalNotationType } from "~/common/constants";
import { createTeeth } from "~/common/prescription";
import { convertToDentalNotation } from "~/common/utils";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPrescription } from "~/types/prescription";

export function PatientCardTeethItems({
  label,
  notation,
  defaultValue,
}: {
  label: MessageDescriptor["id"];
  notation: DentalNotationType;
  defaultValue: number[];
}) {
  const teeth = createTeeth();

  if (defaultValue.length == 0) {
    return null;
  }

  return (
    <div className="PatientTeethItems form-group">
      <PortletTerm id="teeth-item-list">
        <FormattedMessage id={label} />
      </PortletTerm>

      <div className="row">
        <div className="col-xs-12">
          <div className="teeth-controls-upper">
            {teeth.slice(0, 2).map((quad) => (
              <div key={quad.join(",")} className="checkbox-list teeth-controls">
                {quad.map((tooth) => (
                  <PatientCardToothItem
                    key={tooth}
                    tooth={tooth}
                    notation={notation}
                    defaultChecked={defaultValue.includes(tooth)}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="col-xs-12">
          <div className="teeth-controls-lower">
            {teeth.slice(2, 4).map((quad) => (
              <div key={quad.join(",")} className="checkbox-list teeth-controls">
                {quad.map((tooth) => (
                  <PatientCardToothItem
                    key={tooth}
                    tooth={tooth}
                    notation={notation}
                    defaultChecked={defaultValue.includes(tooth)}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function PatientCardToothItem({
  tooth,
  notation,
  defaultChecked,
}: {
  tooth: number;
  notation: DentalNotationType;
  defaultChecked: boolean;
}) {
  return (
    <label key={tooth}>
      {convertToDentalNotation(tooth, notation)}
      <br />

      <div className="checker">
        <label>
          <input id="checkbox" type="checkbox" disabled defaultChecked={defaultChecked} />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label></label>
        </label>
      </div>
    </label>
  );
}

export function PatientCardElasticsTeethItems({
  name,
  label,
  defaultValue,
  notation,
  instructions,
}: {
  name: string;
  label: MessageDescriptor["id"];
  defaultValue: number[];
  notation: DentalNotationType;
  instructions: TPrescription | null;
}) {
  const teeth = createTeeth();

  function setClassName(i: number) {
    if (instructions.elastics_options[i] === 1) {
      return "button_hook_tip_read_only hook";
    } else if (instructions.elastics_options[i] === 2) {
      return "button_hook_tip_read_only button";
    } else {
      return "input_button";
    }
  }

  function setTooltip(i: number) {
    const elastics_options = instructions ? instructions.elastics_options : null;
    if (elastics_options && elastics_options[i] === 1) {
      return <FormattedMessage id="HOOK" tagName="span" />;
    } else if (elastics_options && elastics_options[i] === 2) {
      return <FormattedMessage id="BUTTON" tagName="span" />;
    }
  }

  function renderInput(i: number) {
    const elastics_options = instructions ? instructions.elastics_options : null;
    return (
      <label key={i}>
        {convertToDentalNotation(i, notation)}
        <br />
        <div className={setClassName(i)}>
          {(elastics_options && elastics_options[i] === 2) ||
          (elastics_options && elastics_options[i] === 1)
            ? setTooltip(i)
            : null}
          <input id="button_hook" name={name} type="checkbox" disabled={true} />
        </div>
      </label>
    );
  }

  if (defaultValue.length == 0) {
    return null;
  }

  return (
    <div className="PatientTeethItems form-group">
      <PortletTerm id="teeth-item-list">
        <FormattedMessage id={label} />
      </PortletTerm>

      <div className="row">
        <div className="col-xs-12">
          <div className="teeth-controls-upper">
            {teeth.slice(0, 2).map((quad) => {
              return (
                <div key={quad.join(",")} className="checkbox-list teeth-controls">
                  {quad.map(renderInput)}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xs-12">
          <div className="teeth-controls-lower">
            {teeth.slice(2, 4).map((quad) => {
              return (
                <div key={quad.join(",")} className="checkbox-list teeth-controls">
                  {quad.map(renderInput)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
