import React from "react";
import { FormattedMessage } from "react-intl";

import { useAppSelector } from "../../store";
import { PersonName } from "../common/PersonName";

export function saveDraftPatientNotification() {
  return <SaveDraftNotification />;
}

function SaveDraftNotification() {
  const instructions = useAppSelector((state) => state.instructions);

  return (
    <div style={{ borderRadius: "8px !important", visibility: "visible" }} className="wpb-popup-wr">
      <div className="wpb-popup wpb-popup--small">
        <a
          style={{ width: "10px", height: "10px", top: "4px", right: "8px", opacity: "0.8" }}
          href="#"
          className="wpb-popup__close"
        ></a>
        <div className="wpb-popup__body">
          <FormattedMessage id="notify.patient.create.draft.patient" />
          <PersonName person={instructions} />
          <FormattedMessage id="notify.patient.create.draft.ok" />
        </div>
      </div>
    </div>
  );
}
