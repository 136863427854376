import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import FileUpload from "./../../file_upload";

export function PatientNewInstructionsFiles({ style }: { style?: React.CSSProperties }) {
  const [numberOfUploads, setNumberOfUploads] = useState(0);

  return (
    <div id="extra-media-block" className="form-body" style={style}>
      <h4 id="instruction-files" className="block" style={{ fontWeight: 900 }}>
        <FormattedMessage id="UPLOAD_FILES" />
      </h4>

      {Array.from({ length: numberOfUploads + 1 }).map((_, uploadId) => (
        <FileUpload
          key={`recipe_${uploadId}`}
          id={`recipe_${uploadId}`}
          patient_id={0}
          semantics="recipe"
          onFileUploadEnd={() =>
            setNumberOfUploads((prevNumberOfUploads) => prevNumberOfUploads + 1)
          }
        />
      ))}
    </div>
  );
}
