import clsx from "clsx";
import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import { Field } from "redux-form";

import {
  isChildrenCourse,
  isRetainersCourse,
  isSingleArchCourse,
  isSuperShortCourse,
} from "~/common/courses";
import {
  Arch,
  getOcclusalPlaneTeeth,
  LowerArch,
  OcclusalPlane,
  OcclusalPlaneAction,
  TeethLevelAlign,
  UpperArch,
} from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { ValueOf } from "~/types/common";
import type { TCourse } from "~/types/patient";
import type { TPrescriptionReduxForm, TPrescriptionReduxFormChangeFn } from "~/types/redux-form";

import occlusalPlaneTeethLeftImgSrc from "./occlusal-plane-teeth-left.jpg";
import occlusalPlaneTeethParallelImgSrc from "./occlusal-plane-teeth-parallel.jpg";
import occlusalPlaneTeethRightImgSrc from "./occlusal-plane-teeth-right.jpg";
import { RecipeCheckbox, RecipeRadio } from "./recipe-shared";

export function RecipeTreatArches({
  course_id,
  upper_arch_action,
  lower_arch_action,
  occlusal_plane,
  occlusal_plane_action,
  resetForm,
  change,
}: {
  course_id: TCourse["course_id"] | null | undefined;
  upper_arch_action: ValueOf<typeof UpperArch> | null | undefined;
  lower_arch_action: ValueOf<typeof LowerArch> | null | undefined;
  occlusal_plane: TPrescriptionReduxForm["occlusal_plane"] | undefined;
  occlusal_plane_action: TPrescriptionReduxForm["occlusal_plane_action"] | undefined;
  resetForm(...args: string[]): void;
  change: TPrescriptionReduxFormChangeFn;
}) {
  function renderExpansionArchOptions(arch: "upper" | "lower") {
    return (
      <div className="row">
        <div className="form-group col-md-offset-2 col-sm-offset-1 col-xs-offset-1">
          <PortletTerm>
            <FormattedMessage id="TA_U_EXPAND_TYPE_HOWTO" />
          </PortletTerm>

          <div className="tw-flex tw-flex-col">
            <label
              className="tw-inline-flex tw-items-center tw-gap-1.5"
              htmlFor={`${arch}_arch_expansion_torque`}
            >
              <Field
                id={`${arch}_arch_expansion_torque`}
                aria-labelledby={clsx(
                  `validation-${arch}_arch_action`,
                  `${arch}_arch_expansion_torque`,
                )}
                className="tw-m-0"
                type="checkbox"
                name={`${arch}_arch_expansion_torque`}
                component="input"
              />

              <FormattedMessage id="TA_U_EXPAND_TYPE_TORQUE" />
            </label>

            <label
              className="tw-inline-flex tw-items-center tw-gap-1.5"
              htmlFor={`${arch}_arch_expansion_bodily`}
            >
              <Field
                aria-labelledby={clsx(
                  `validation-${arch}_arch_action`,
                  `${arch}_arch_expansion_bodily`,
                )}
                id={`${arch}_arch_expansion_bodily`}
                className="tw-m-0"
                type="checkbox"
                component="input"
                name={`${arch}_arch_expansion_bodily`}
              />

              <FormattedMessage id="TA_U_EXPAND_TYPE_BODILY" />
            </label>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="treat-arches-section">
      <h3 className="block" style={{ fontWeight: "900" }}>
        <FormattedMessage id="BLOCKHEAD_INSTRUCTIONS" />
      </h3>

      <h4 className="block" style={{ fontWeight: "900" }}>
        <FormattedMessage id="HEADER_ARCHES" />
      </h4>

      <div className="form-group">
        <PortletTerm id="validation-arch" required>
          <FormattedMessage id="ARCHES_SELECT" />
        </PortletTerm>

        <div className="radio-list">
          {isSingleArchCourse(course_id) || isSuperShortCourse(course_id) ? null : (
            <RecipeRadio
              name="arch"
              value={Arch.BOTH}
              intlId="TA_BOTH"
              ariaLabelledBy="validation-arch"
            />
          )}

          <RecipeRadio
            name="arch"
            value={Arch.UPPER}
            intlId="TA_UPPER"
            ariaLabelledBy="validation-arch"
          />

          <RecipeRadio
            name="arch"
            value={Arch.LOWER}
            intlId="TA_LOWER"
            ariaLabelledBy="validation-arch"
          />
        </div>

        <div id="form_tooth_arch_error"></div>
      </div>

      {isRetainersCourse(course_id) ? null : (
        <div className="row">
          <div className="form-group col-md-4" style={{ marginBottom: 20 }}>
            <PortletTerm id="validation-upper_arch_action">
              <FormattedMessage id="ARCHES_U_SELECT" />
            </PortletTerm>

            <div className="checkbox-list checkbox_list">
              <RecipeCheckbox
                name="upper_arch_action"
                value={UpperArch.MAINTAIN}
                intlId="TA_U_MAINTAIN"
                ariaLabelledBy="validation-upper_arch_action"
                onChange={() =>
                  resetForm("upper_arch_expansion_torque", "upper_arch_expansion_bodily")
                }
              />

              <RecipeCheckbox
                name="upper_arch_action"
                value={UpperArch.EXPAND}
                intlId="TA_U_EXPAND"
                ariaLabelledBy="validation-upper_arch_action"
                onChange={() =>
                  resetForm("upper_arch_expansion_torque", "upper_arch_expansion_bodily")
                }
              />

              {upper_arch_action == UpperArch.EXPAND ? renderExpansionArchOptions("upper") : false}

              {isChildrenCourse(course_id) ? null : (
                <RecipeCheckbox
                  name="upper_arch_action"
                  value={UpperArch.NARROW}
                  intlId="TA_U_NARROW"
                  ariaLabelledBy="validation-upper_arch_action"
                  onChange={() =>
                    resetForm("upper_arch_expansion_torque", "upper_arch_expansion_bodily")
                  }
                />
              )}
            </div>
          </div>

          <div className="form-group col-md-4" style={{ marginBottom: 20 }}>
            <PortletTerm id="validation-lower_arch_action">
              <FormattedMessage id="ARCHES_L_SELECT" />
            </PortletTerm>

            <div className="checkbox-list checkbox_list">
              <RecipeCheckbox
                name="lower_arch_action"
                value={LowerArch.MAINTAIN}
                intlId="TA_L_MAINTAIN"
                ariaLabelledBy="validation-lower_arch_action"
                onChange={() =>
                  resetForm("lower_arch_expansion_torque", "lower_arch_expansion_bodily")
                }
              />

              <RecipeCheckbox
                name="lower_arch_action"
                value={LowerArch.EXPAND}
                intlId="TA_L_EXPAND"
                ariaLabelledBy="validation-lower_arch_action"
                onChange={() =>
                  resetForm("lower_arch_expansion_torque", "lower_arch_expansion_bodily")
                }
              />

              {lower_arch_action == LowerArch.EXPAND ? renderExpansionArchOptions("lower") : false}

              {isChildrenCourse(course_id) ? null : (
                <RecipeCheckbox
                  name="lower_arch_action"
                  value={LowerArch.NARROW}
                  intlId="TA_L_NARROW"
                  ariaLabelledBy="validation-lower_arch_action"
                  onChange={() =>
                    resetForm("lower_arch_expansion_torque", "lower_arch_expansion_bodily")
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}

      {isChildrenCourse(course_id) || isRetainersCourse(course_id) ? null : (
        <div className="form-group">
          <PortletTerm id="teeth-level-label">
            <FormattedMessage id="ARCHES_TLA" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="teeth_level_align"
              value={TeethLevelAlign.MARGIN}
              intlId="TLA_G_MARGIN"
              ariaLabelledBy="teeth-level-label"
            />

            <RecipeCheckbox
              name="teeth_level_align"
              value={TeethLevelAlign.EDGE}
              intlId="TLA_C_EDGE"
              ariaLabelledBy="teeth-level-label"
            />
          </div>
        </div>
      )}

      {isRetainersCourse(course_id) ? null : (
        <OcclusalPlaneOptions
          occlusal_plane={occlusal_plane}
          occlusal_plane_action={occlusal_plane_action}
          change={change}
        />
      )}
    </div>
  );
}

function OcclusalPlaneOptions({
  occlusal_plane,
  occlusal_plane_action,
  change,
}: {
  occlusal_plane: TPrescriptionReduxForm["occlusal_plane"] | undefined;
  occlusal_plane_action: TPrescriptionReduxForm["occlusal_plane_action"] | undefined;
  change: TPrescriptionReduxFormChangeFn;
}) {
  function handleChange() {
    change("occlusal_plane_action", null);
    handleActionChange();
  }

  function handleActionChange() {
    change("occlusal_plane_subaction", null);
    handleSubActionChange();
  }

  function handleSubActionChange() {
    if (occlusal_plane == null || occlusal_plane == OcclusalPlane.PARALLEL) return;

    for (const tooth of getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.EXTRUDE)) {
      change(`occlusal_plane_extrude_${tooth}`, false);
    }
    for (const tooth of getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.INTRUDE)) {
      change(`occlusal_plane_intrude_${tooth}`, false);
    }
  }

  function handleToothChange(tooth: number, checked: boolean) {
    if (!checked) return;
    if (occlusal_plane == null || occlusal_plane == OcclusalPlane.PARALLEL) return;

    if (getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.EXTRUDE).includes(tooth)) {
      change("occlusal_plane_subaction", OcclusalPlaneAction.EXTRUDE);

      for (const tooth of getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.INTRUDE)) {
        change(`occlusal_plane_intrude_${tooth}`, false);
      }
    } else {
      change("occlusal_plane_subaction", OcclusalPlaneAction.INTRUDE);

      for (const tooth of getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.EXTRUDE)) {
        change(`occlusal_plane_extrude_${tooth}`, false);
      }
    }
  }

  function renderOcclusalPlaneLabel(intlId: MessageDescriptor["id"]) {
    return (
      <span>
        <FormattedMessage id={intlId} /> <strong>(до лечения)</strong>
      </span>
    );
  }

  return (
    <div className="form-group">
      <PortletTerm id="validation-occlusal_plane" required>
        <FormattedMessage id="TA_OCCLUSAL" />
      </PortletTerm>

      <div className="checkbox-list checkbox_list max-sm:tw-space-y-2">
        <div className="tw-flex tw-gap-2 max-sm:tw-flex-col-reverse">
          <img width={150} height={95} src={occlusalPlaneTeethParallelImgSrc} alt="" />

          <div className="tw-pt-2">
            <RecipeCheckbox
              name="occlusal_plane"
              value={OcclusalPlane.PARALLEL}
              intlId="TA_P_OCCLUSAL"
              ariaLabelledBy="validation-occlusal_plane"
              renderLabel={renderOcclusalPlaneLabel}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="tw-flex tw-gap-2 max-sm:tw-flex-col-reverse">
          <img width={150} height={95} src={occlusalPlaneTeethRightImgSrc} alt="" />

          <div className="tw-pt-2">
            <RecipeCheckbox
              name="occlusal_plane"
              value={OcclusalPlane.RIGHT}
              intlId="TA_R_OCCLUSAL"
              ariaLabelledBy="validation-occlusal_plane"
              renderLabel={renderOcclusalPlaneLabel}
              onChange={handleChange}
            />

            {occlusal_plane == OcclusalPlane.RIGHT ? (
              <OcclusalPlaneActions
                occlusal_plane={occlusal_plane}
                occlusal_plane_action={occlusal_plane_action}
                onActionChange={handleActionChange}
                onSubActionChange={handleSubActionChange}
                onToothChange={handleToothChange}
                ariaLabelledBy={`occlusal_plane-value-${OcclusalPlane.RIGHT}`}
              />
            ) : null}
          </div>
        </div>

        <div className="tw-flex tw-gap-2 max-sm:tw-flex-col-reverse">
          <img width={150} height={95} src={occlusalPlaneTeethLeftImgSrc} alt="" />

          <div className="tw-pt-2">
            <RecipeCheckbox
              name="occlusal_plane"
              value={OcclusalPlane.LEFT}
              intlId="TA_L_OCCLUSAL"
              ariaLabelledBy="validation-occlusal_plane"
              renderLabel={renderOcclusalPlaneLabel}
              onChange={handleChange}
            />

            {occlusal_plane == OcclusalPlane.LEFT ? (
              <OcclusalPlaneActions
                occlusal_plane={occlusal_plane}
                occlusal_plane_action={occlusal_plane_action}
                onActionChange={handleActionChange}
                onSubActionChange={handleSubActionChange}
                onToothChange={handleToothChange}
                ariaLabelledBy={`occlusal_plane-value-${OcclusalPlane.LEFT}`}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function OcclusalPlaneActions({
  occlusal_plane,
  occlusal_plane_action,
  onActionChange,
  onSubActionChange,
  onToothChange,
  ariaLabelledBy,
}: {
  occlusal_plane: (typeof OcclusalPlane)["RIGHT"] | (typeof OcclusalPlane)["LEFT"];
  occlusal_plane_action: TPrescriptionReduxForm["occlusal_plane_action"] | undefined;
  onActionChange(): void;
  onSubActionChange(): void;
  onToothChange(tooth: number, checked: boolean): void;
  ariaLabelledBy: string;
}) {
  return (
    <div className="checkbox-list checkbox_list tw-space-y-2 tw-pl-8">
      <RecipeCheckbox
        name="occlusal_plane_action"
        value={OcclusalPlaneAction.SAVE}
        intlId="TA_OCCLUSAL_SAVE"
        onChange={onActionChange}
        ariaLabelledBy={ariaLabelledBy}
      />

      <RecipeCheckbox
        name="occlusal_plane_action"
        value={OcclusalPlaneAction.MODIFY}
        intlId="TA_OCCLUSAL_MODIFY"
        onChange={onActionChange}
        ariaLabelledBy={ariaLabelledBy}
      />

      {occlusal_plane_action == OcclusalPlaneAction.MODIFY ? (
        <div className="checkbox-list checkbox_list tw-pl-8">
          <RecipeCheckbox
            name="occlusal_plane_subaction"
            value={OcclusalPlaneAction.EXTRUDE}
            intlId="TA_OCCLUSAL_TEETH_EXTRUDE"
            onChange={onSubActionChange}
            ariaLabelledBy={ariaLabelledBy}
          />

          <div className="tw-flex tw-items-center tw-gap-2 tw-pl-8">
            {getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.EXTRUDE).map((tooth) => (
              <OcclusalPlaneTooth
                key={tooth}
                tooth={tooth}
                occlusal_plane_subaction={OcclusalPlaneAction.EXTRUDE}
                onChange={(e) => onToothChange(tooth, e.target.checked)}
              />
            ))}
          </div>

          <RecipeCheckbox
            name="occlusal_plane_subaction"
            value={OcclusalPlaneAction.INTRUDE}
            intlId="TA_OCCLUSAL_TEETH_INTRUDE"
            onChange={onSubActionChange}
            ariaLabelledBy={ariaLabelledBy}
          />

          <div className="tw-flex tw-items-center tw-gap-2 tw-pl-8">
            {getOcclusalPlaneTeeth(occlusal_plane, OcclusalPlaneAction.INTRUDE).map((tooth) => (
              <OcclusalPlaneTooth
                key={tooth}
                tooth={tooth}
                occlusal_plane_subaction={OcclusalPlaneAction.INTRUDE}
                onChange={(e) => onToothChange(tooth, e.target.checked)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

type TOcclusalPlaneSubAction = NonNullable<TPrescriptionReduxForm["occlusal_plane_subaction"]>;

const subActionToString = {
  [OcclusalPlaneAction.EXTRUDE]: "extrude",
  [OcclusalPlaneAction.INTRUDE]: "intrude",
} as const satisfies Record<TOcclusalPlaneSubAction, string>;

function OcclusalPlaneTooth({
  tooth,
  occlusal_plane_subaction,
  onChange,
}: {
  tooth: number;
  occlusal_plane_subaction: TOcclusalPlaneSubAction;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const action = subActionToString[occlusal_plane_subaction];
  const inputId = `occlusal-plane-${action}-${tooth}`;

  return (
    <label key={tooth} htmlFor={inputId} className="tw-flex tw-items-center tw-gap-2">
      <Field
        id={inputId}
        aria-labelledby={clsx(
          `occlusal_plane_subaction-value-${occlusal_plane_subaction}`,
          inputId,
        )}
        className="tw-m-0"
        name={`occlusal_plane_${action}_${tooth}`}
        component="input"
        type="checkbox"
        onChange={onChange}
      />

      <span>{tooth}</span>
    </label>
  );
}
