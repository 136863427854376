import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router-dom";

import { getToken } from "~/actions/get_token";
import { Layout } from "~/components/ui/layout";
import { useAppDispatch, useAppSelector } from "~/store";

import PlanCancelSmile from "./smile_correction_page";

export default function PlanCancelBody(props: RouteComponentProps<{ patient_id: string }>) {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    _paq.push(["HeatmapSessionRecording::enable"]);

    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }

    dispatch(getToken());
  }, [user, dispatch]);

  return (
    <Layout>
      <div className="row">
        <div className="col-md-12">
          <PlanCancelSmile {...props} />
        </div>
      </div>
    </Layout>
  );
}
