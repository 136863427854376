import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Select, { components } from "react-select";

import { remoteLog } from '../../../common/logging';
import { Loader } from "~/components/ui/loader";
import { threeShapeAuth } from "../../../actions/3shape";
import {getMeditCase, meditAuth, uploadFile} from "../../../actions/medit";
import { getPatientId } from "../../../actions/get_patient_id";
import { deployedUSA } from "../../../common/utils";
import scanner from '../../../assets/img/scanner-blue.svg';


const mapStateToProps = (state) => {
    return {
        user: state.user,
        patient: state.patient,
        fileSpinner: state.fileSpinner,
        fileUploadError: state.fileUploadError
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getMeditCase: (case_id) => dispatch(getMeditCase(case_id)),
        uploadFile: (case_id, patient_id, file_id) => dispatch(uploadFile(case_id, patient_id, file_id)),
        getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
        meditAuth : () => dispatch(meditAuth()),
    }
}


const Option = (props) => {
    return (
      <div>
          <components.Option {...props}>
              <div style={{display: "flex", textAlign: "center", justifyContent: "space-between"}}>
                  <label style={{marginLeft: "10px"}}>{props.label}</label>
                  <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                  />
              </div>
          </components.Option>
      </div>
    );
};


class PatientScans extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meditCase: null,
            files: [],
            optionSelected: null,
            disable: true,
            uploading_files_names: [],
            showSelectFiles: true,
            file_id: null,
            file_name: null,
            uploaded_files: []
        }
        this.threeShapeBtn = this.threeShapeBtn.bind(this);
        this.getCase = this.getCase.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    componentDidCatch(e) {
        remoteLog(e, 'patient_scans');
    }

    componentDidMount() {
        const uploaded = [];
        const s3_media = this.props.patient.s3_media ? this.props.patient.s3_media : [];
        s3_media.map(f => f.file_type == 'medit' ? uploaded.push(f.file_name) : null);
        this.setState({uploaded_files: uploaded})
    }

    threeShapeBtn(patient_id) {
        if (!!this.props.user.privileges.has_3shape_scanner) {
            return (
                <div className="form-group col-md-12">
                    <button
                      style={{color: "#31c5d2", width: "100%", height: "40px", backgroundColor: "#efefef"}}
                      className="btn hover rounded-3"
                      onClick={() => threeShapeAuth(patient_id)}
                      id="integration-card-link"
                    >
                      <FormattedMessage id="UPLOADED_3SHAPE"/>
                    </button>
                </div>
            )
        }
    }

    getCase(case_id, id) {
        this.props.getMeditCase(case_id)
        .then(res => {
            this.setState({meditCase: res})
        })
        .then(() => {
            $(`${id}`).modal('show');
        })
        .catch(() => {
            this.props.meditAuth()
        })
    }

    closeModal() {
        const { meditCase } = this.state;
        this.setState({
            files: [],
            uploading_files_names:[],
            file_name: null,
            file_id: null,
            patient_id: null,
            optionSelected: null,
            showSelectFiles: true
        })
        this.props.getPatient(meditCase.patient_id)
    }

    onSelectFile(v, i) {
        if (v.length > 0) {
            this.setState({disable: false})
        } else {
            this.setState({disable: true})
        }
        const meditFiles = []
        v.map(f => meditFiles.push({value: f.value, label:f.label}))
        this.setState({files: meditFiles, optionSelected: meditFiles.value})
    }

    async uploadFiles(case_id, patient_id) {
        this.setState({disable: true, showSelectFiles: false})
        const { files } = this.state;
        const upload_files = [];
        const files_names = [];
        files.map(f => files_names.push(f.label));
        files.map(f => upload_files.push(f.value));
        const arr = [];
        const nameArr = [];
        for (const i of upload_files) {
            const file_name = files.map(f => f.value === i ? f.label : null)
            this.setState({file_id: i, file_name: file_name})
            await this.props.uploadFile(case_id, Number(patient_id), i)
            arr.push(i);
            nameArr.push(file_name)
            this.setState({uploaded_files: [...this.state.uploaded_files, file_name], uploading_files_names: nameArr})
        }
    }


    render() {
        const { patient_id, medit_id } = this.props.patient;
        const { optionSelected, meditCase, uploading_files_names,
            disable, showSelectFiles, file_name, uploaded_files
        } = this.state;
        const { fileSpinner, fileUploadError } = this.props;
        const medit_files = meditCase ? meditCase.files : [];
        const filesOptions = [];
        medit_files.map(c => !c.uploaded ? filesOptions.push({'value': `${c.uuid}`, 'label': `${c.name} ${c.fileType}`}) : []);

        return (
          <div className="portlet light bordered">
              <div className="portlet-title">
                  <div className="caption">
                      <img style={{width: "35px", marginLeft: "-8px"}} src={scanner}/>
                      <span style={{marginLeft: "2px"}} className="caption-subject font-green bold uppercase">
                          <FormattedMessage id="integrations.my.scanners"/>
                      </span>
                  </div>
              </div>
              <div className="portlet-body">
                  <div className="row">
                          {this.threeShapeBtn(patient_id)}

                          <div data-backdrop="static" data-keyboard="false" className="modal fade" id="connect" tabIndex="-1" role="dialog"
                               aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                      <div className="modal-header">
                                          <button
                                            onClick={() => this.closeModal()}
                                            disabled={fileSpinner}
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                          </button>
                                          <h3 className="modal-title" id="exampleModalLabel">
                                              <FormattedMessage id="th.medit.connect.patient"/>
                                          </h3>
                                      </div>
                                      <div className="modal-body">
                                          {uploaded_files.length > 0
                                                ? (
                                                  <div>
                                                      <h4><FormattedMessage id="th.medit.uploaded.files" /></h4>
                                                      {uploaded_files.map((f, id) => {
                                                          return (
                                                            <div data-matomo-mask data-hj-suppress key={f + id} style={{display: "flex", alignItems: "center", fontSize: '12px'}}>
                                                                <span style={{color: "#31c5d2"}}>{f}</span>&nbsp;&nbsp;
                                                                <i className="icon-check" style={{color: '#82D9D4', fontWeight: "900"}} />
                                                            </div> )

                                                      })} </div>
                                                ) : null
                                          }
                                          <br/>
                                          {filesOptions.length > 0
                                          ? (
                                              <div data-matomo-mask data-hj-suppress style={{display: !showSelectFiles ? 'none' : 'block'}} className="form-group">
                                                  <label className="col-form-label">
                                                      <FormattedMessage id="th.medit.action.сhoose.file"/>
                                                  </label>
                                                  <Select
                                                    components={{Option}}
                                                    isMulti
                                                    onChange={(v, i) => this.onSelectFile(v, i)}
                                                    options={filesOptions}
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    id="patient-choose-file"
                                                    name="medit_file"
                                                    value={optionSelected}
                                                  />
                                              </div>
                                            ) : <div style={{display: !showSelectFiles ? 'none' : 'block'}}>
                                                    <FormattedMessage id="th.medit.no.files" />
                                                </div>
                                          }
                                          {
                                              fileSpinner
                                                ? (
                                                  <div>
                                                      <span><FormattedMessage id="th.medit.file.name" /><span style={{color: "#31c5d2", fontWeight: "800"}}>{file_name}</span>
                                                          <FormattedMessage id="th.medit.file.uploading" />
                                                      </span>
                                                      <div><Loader /></div>
                                                  </div>
                                                ) : null
                                          }
                                          {
                                              (uploading_files_names.length > 0 && !fileSpinner && !fileUploadError)
                                                ? uploading_files_names.map(f => {
                                                    return (
                                                      <div data-matomo-mask data-hj-suppress key={f} style={{display: "flex", alignItems: "center"}}>
                                                          <span><FormattedMessage id="th.medit.file.name" /><span style={{color: "#31c5d2", fontWeight: "800"}}>{f}</span>
                                                              <FormattedMessage id="th.medit.file.uploaded" />
                                                          </span>&nbsp;&nbsp;
                                                          <i className="icon-check" style={{color: '#82D9D4', fontSize: '24px', fontWeight: 900}} />
                                                      </div> )
                                                })
                                                : null
                                          }
                                          {
                                              fileUploadError
                                                ? uploading_files_names.map(f => {
                                                    return (
                                                      <div key={f} style={{display: "flex", alignItems: "center"}}>
                                                          <span style={{color: "red"}}>File <span style={{fontWeight: "800"}}>{f}</span>
                                                              <FormattedMessage id="th.medit.file.upload_error" />
                                                          </span>&nbsp;&nbsp;
                                                          <i className="icon-dislike" style={{color: 'red', fontSize: '24px', fontWeight: 900}} />
                                                      </div> )
                                                }) : null
                                          }
                                      </div>
                                      <div className="modal-footer">
                                          <button
                                            style={{marginTop: "15px"}}
                                            disabled={disable}
                                            type="button"
                                            className="btn green"
                                            onClick={() => this.uploadFiles(medit_id, patient_id)}
                                          >
                                              <FormattedMessage id="BTN_SUBMIT" />
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      {deployedUSA() === true && medit_id !== null
                      ? (
                        <div className="form-group col-md-12">
                            <button
                              style={{color: "#31c5d2", width: "100%", height: "40px", backgroundColor: "#efefef"}}
                              className="btn hover rounded-3"
                              onClick={() => this.getCase(medit_id, '#connect')}
                              data-toggle="modal"
                              data-whatever="@mdo"
                            ><FormattedMessage id="upload_files_to_patient_card" />
                            </button>
                        </div>
                        ) : null
                      }
                  </div>
              </div>
          </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientScans);
