import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {Field, formValueSelector, reduxForm, getFormValues } from "redux-form";

import { isChildrenCourse, isRetainersCourse, isSingleArchCourse, isSuperShortCourse } from '../../common/courses';
import PlanCorrectionTeethCheck from '../3d_plan_correction/3d_plan_correction_teeth_check';
import { initializeBaseInstruction } from "../../common/instructions";
import { INITIAL_VALUES_PRESCRIPTION } from '../../common/prescription';
import { remoteLog } from "../../common/logging";


const mapStateToProps = (state) => {
	return {
		user: state.user,
		patient: state.patient,
		comments: state.comments,
		media: state.media,
		media_s3: state.media_s3,
		instructions: state.instructions,
		formValues: getFormValues('correction')(state)
	}
}


class RecipeFormShortRX extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: true
		}
		this.fm = this.fm.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}


	fm(id) { return this.props.intl.formatMessage({id: id}) };

	componentDidCatch(e) {
	  remoteLog(e, '3d_plan_correction_form');
	}

	resetForm(firstField, secondField = null) {
		this.props.change(firstField, false)
		this.props.change(secondField, false)
	}


	render() {
		const { submitting } = this.props;
 		const course_id = this.props.course_id;

		return (
			<div>
				<div id="treat-arches-section">
					<h3 className="block" style={{fontWeight: '900'}}><FormattedMessage id="BLOCKHEAD_INSTRUCTIONS"/></h3>
					<h4 className="block" style={{fontWeight: '900'}}><FormattedMessage id="HEADER_ARCHES"/></h4>
					<div className="form-group">
						<label className="control-label" id="validation-arch" style={{fontWeight: '900'}}>
							<FormattedMessage id="ARCHES_SELECT"/>
							<span className="required" aria-required="true">*</span>
						</label>
						<div className="radio-list">
							{(isSingleArchCourse(course_id) || isSuperShortCourse(course_id))
								? null
								: (
									<label>
										<div className="radio">
											<Field
												component="input"
												type="radio"
												id="treat-arches-value-1"
												name="arch"
												value='1'
											/>
										</div>
										<FormattedMessage id="TA_BOTH"/>
									</label>
								)
							}
							<label>
								<div className="radio">
									<Field
										component="input"
										type="radio"
										name="arch"
										id="treat-arches-value-2"
										value='2'
									/>
								</div>
								<FormattedMessage id="TA_UPPER"/>
							</label>
							<label>
								<div className="radio">
									<Field
										component="input"
										type="radio"
										name="arch"
										id="treat-arches-value-3"
										value='3'
									/>
								</div>
								<FormattedMessage id="TA_LOWER"/>
							</label>
						</div>
						<div id="form_tooth_arch_error"></div>
					</div>
				</div>

				{!isChildrenCourse(course_id) && !isRetainersCourse(course_id) &&
					<div>
						<label className="control-label" id="EXTRA_NOLOCK" style={{fontWeight: '900'}}>
						   <FormattedMessage id="EXTRA_NOLOCK"/>
						</label>
						<br/>
						<PlanCorrectionTeethCheck name="avoid_locks"/>
						<label className="control-label" id="EXTRA_EXTRACT" style={{fontWeight: '900'}}>
						   <FormattedMessage id="EXTRA_EXTRACT"/>
						</label>
						<br/>
						<PlanCorrectionTeethCheck name="extractions"/>
					</div>
				}
				<div className="form-group">
                    <label className="control-label" id="validation-comment" style={{fontWeight: '900'}}>
                        <FormattedMessage id="EXTRA_COMMENTS"/>
						<span className="required" aria-required="true">*</span>
                    </label>
					<a style={{color: "#000000", fontSize: "12px"}} data-toggle="collapse" href="#treatmentGoalsExamples" aria-expanded="false" aria-controls="collapseExample">
						<i className='icon-info' style={{verticalAlign: "middle", margin: "0 2px 0 15px"}}/><span><FormattedMessage id="treatment_goals_ex" /></span>
					</a>
					<ul style={{fontSize: "12px"}} className="collapse" id="treatmentGoalsExamples">
						<li><FormattedMessage id="treatment_goals_ex_1" /></li>
						<li><FormattedMessage id="treatment_goals_ex_2" /></li>
						<li><FormattedMessage id="treatment_goals_ex_3" /></li>
					</ul>
                    <Field
                        data-matomo-mask
                        component="textarea"
                        className="form-control"
                        rows={5}
                        name="comment"
                        format={v => v ? v : ""}
                    />
                </div>
				<br/>
				<button disabled={submitting} type="button" style={{display: 'none'}}>submit</button>
			</div>
		)
	}
}

RecipeFormShortRX = reduxForm({
	form: 'correction',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	updateUnregisteredFields: true
})(RecipeFormShortRX);

const selector = formValueSelector('correction')

RecipeFormShortRX = connect(
	(state) => {
		const impressions = selector(state, 'impressions')
		const information_correct = selector(state, 'information_correct')

		return {
			impressions,
			information_correct,
			initialValues: (
					state.patient && state.patient.course
					&& state.patient.course.correction[state.patient.course.correction.length - 1].prescription
					&& state.patient.course.correction[state.patient.course.correction.length - 1].order_options.can_order_correction === false
			)	? initializeBaseInstruction(state.patient.course.correction[state.patient.course.correction.length - 1].prescription)
				: INITIAL_VALUES_PRESCRIPTION
		}
	}
)(RecipeFormShortRX)


export default connect(mapStateToProps)(injectIntl(RecipeFormShortRX));
