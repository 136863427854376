import clsx from "clsx";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import {
  Payer,
  PAYERS_TRANSLATION_MAP,
  PAYMENT_METHODS_TRANSLATION_MAP,
  PAYMENT_OPTIONS_TRANSLATION_MAP,
  PaymentMethodId,
  PaymentOptionId,
} from "~/common/constants";
import type { Case } from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { FormatNumber } from "~/components/common/FormatNumber";
import { PortletTerm } from "~/components/ui/portlet";
import { addInstructions, removeInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";

const mapStateToProps = (state: RootState) => {
  return {
    intl: state.intl,
    instructions: state.instructions,
    courseInstallment: state.courseInstallment,
  };
};

const mapDispatchToProps = {
  addInstruction: addInstructions,
  removeInstructions,
};

type PatientNewPaymentProps = PropsFromRedux & {
  intl: IntlShape;
  setDirty(key: string, data?: unknown): void;
};

class PatientNewPayment extends Component<PatientNewPaymentProps> {
  constructor(props: PatientNewPaymentProps) {
    super(props);
    this.selectPayer = this.selectPayer.bind(this);
    this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
    this.selectPaymentOption = this.selectPaymentOption.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_new_payment");
  }

  selectPaymentMethod(data: string) {
    this.props.addInstruction({ payment_method_id: parseInt(data) });
    this.props.setDirty(data);
  }

  selectPaymentOption(data: string) {
    this.props.addInstruction({ payment_option_id: parseInt(data) });
    this.props.setDirty(data);
    this.props.addInstruction({ sber_credit: false });
  }

  selectPayer(data: string) {
    const payer_id = parseInt(data);
    this.props.addInstruction({ payer_id });
    this.props.setDirty(data);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (payer_id == Payer.PATIENT) {
      if (this.props.instructions.payment_method_id != PaymentMethodId.CARD) {
        this.props.removeInstructions({ payment_method_id: null });
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    } else if (payer_id == Payer.DOCTOR) {
      if (this.props.instructions.payment_method_id != PaymentMethodId.CARD) {
        this.props.removeInstructions({ payment_method_id: null });
      }
      this.removePatientPayer();
    } else {
      if (this.props.instructions.payment_method_id != PaymentMethodId.INVOICE) {
        this.props.removeInstructions({ payment_method_id: null });
      }
      this.removePatientPayer();
    }
  }

  removePatientPayer() {
    this.props.removeInstructions({
      email: null,
      payer_last_name: null,
      payer_first_name: null,
      payer_patronymic: null,
    });
    if (this.props.instructions.sber_credit) {
      this.props.removeInstructions({ payment_option_id: null });
    }
    this.props.addInstruction({ sber_credit: false });
  }

  render() {
    const { locale } = this.props.intl;
    const { course_id } = this.props.instructions;
    const installments = this.props.courseInstallment[course_id as Case] ?? {};

    return (
      <div>
        <h3 className="block" style={{ fontWeight: "900" }}>
          <FormattedMessage id="BLOCKHEAD_PAYMENT" />
        </h3>

        {locale == "ru" ? (
          <PayerRadioGroup onChange={(e) => this.selectPayer(e.target.value)} />
        ) : null}

        {locale == "ru" ? (
          <PaymentMethodRadioGroup
            payer_id={this.props.instructions.payer_id}
            onChange={(e) => this.selectPaymentMethod(e.target.value)}
          />
        ) : null}

        <PaymentOptionRadioGroup
          installments={installments}
          course_id={course_id}
          isSberCredit={false}
          onChange={(e) => this.selectPaymentOption(e.target.value)}
        />
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNewPayment));

export function PayerRadioGroup({
  payer_id,
  onChange,
}: {
  payer_id?: Payer;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const payers = [Payer.CLINIC, Payer.DOCTOR, Payer.PATIENT];

  return (
    <div className="form-group" id="payer-section">
      <PortletTerm id="validation-payer_id" required>
        <FormattedMessage id="HEADER_PAYER" />
      </PortletTerm>

      <div className="radio-list">
        {payers.map((payer) => (
          <label key={payer}>
            <div className="radio">
              <input
                type="radio"
                aria-labelledby={clsx("validation-payer_id", `payer-value-${payer}`)}
                id={`payer-value-${payer}`}
                name="payer_id"
                defaultValue={payer}
                checked={payer_id ? payer_id == payer : undefined}
                onChange={onChange}
              />
            </div>
            <FormattedMessage id={PAYERS_TRANSLATION_MAP[payer]} />
          </label>
        ))}
      </div>

      <div id="form_payer_error"> </div>
    </div>
  );
}

export function PaymentMethodRadioGroup({
  payer_id,
  payment_method_id,
  onChange,
}: {
  payer_id: Payer | undefined;
  payment_method_id?: PaymentMethodId | null;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const paymentMethods = [PaymentMethodId.INVOICE, PaymentMethodId.CARD];

  const payerToPaymentMethod: Record<Payer, PaymentMethodId> = {
    [Payer.CLINIC]: PaymentMethodId.INVOICE,
    [Payer.DOCTOR]: PaymentMethodId.CARD,
    [Payer.PATIENT]: PaymentMethodId.CARD,
  };

  return (
    <div className="form-group" id="payment-section" data-toggle="tooltip">
      <PortletTerm id="validation-payment_method_id" required>
        <FormattedMessage id="HEADER_PAYMENT_METHOD" />
      </PortletTerm>

      <div className="radio-list">
        {paymentMethods.map((paymentMethod) => {
          if (payer_id != null && paymentMethod != payerToPaymentMethod[payer_id]) {
            return null;
          }

          return (
            <label key={paymentMethod}>
              <div className="radio">
                <input
                  type="radio"
                  aria-labelledby={clsx("validation-payment_method_id", `payment-method-value-${paymentMethod}`)}
                  id={`payment-method-value-${paymentMethod}`}
                  name="payment_method_id"
                  defaultValue={paymentMethod}
                  checked={payment_method_id ? payment_method_id == paymentMethod : undefined}
                  onChange={onChange}
                />
              </div>
              <FormattedMessage id={PAYMENT_METHODS_TRANSLATION_MAP[paymentMethod]} />
            </label>
          );
        })}
      </div>

      <div id="form_payment_method_error"> </div>
    </div>
  );
}

export function PaymentOptionRadioGroup({
  payment_option_id,
  installments,
  isSberCredit,
  course_id,
  onChange,
}: {
  payment_option_id?: PaymentOptionId | null;
  installments: Partial<Record<PaymentOptionId, number[]>>;
  isSberCredit: boolean;
  course_id?: TPatient["course"]["course_id"] | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const paymentOptions = [
    PaymentOptionId.ADVANCED,
    PaymentOptionId.SPLIT_2_2,
    PaymentOptionId.SPLIT_2,
    PaymentOptionId.SPLIT_3_2,
    PaymentOptionId.SPLIT_3,
  ];

  const advancedPaymentOptions = [PaymentOptionId.SPLIT_2_2, PaymentOptionId.SPLIT_3_2];

  const paymentOptionToMaxCount: Record<PaymentOptionId, number> = {
    [PaymentOptionId.ADVANCED]: 1,
    [PaymentOptionId.SPLIT_2]: 2,
    [PaymentOptionId.SPLIT_2_2]: 2,
    [PaymentOptionId.SPLIT_3]: 3,
    [PaymentOptionId.SPLIT_3_2]: 3,
  };

  return (
    <div className="form-group">
      <PortletTerm id="validation-payment_option_id" required>
        <FormattedMessage id="HEADER_PAYMENT_OPTION" />
      </PortletTerm>

      <div className="radio-list tw-m-0 tw-mt-2 tw-flex tw-flex-col tw-gap-3 tw-overflow-hidden">
        {paymentOptions.map((paymentOption) => {
          const isCourseSelected = course_id != undefined && course_id != "None";

          if (
            (isCourseSelected && !(paymentOption in installments)) ||
            (!isCourseSelected && advancedPaymentOptions.includes(paymentOption))
          ) {
            return null;
          }

          const checked = payment_option_id
            ? payment_option_id == paymentOption && !isSberCredit
            : undefined;

          return (
            <div
              key={paymentOption}
              className={clsx(
                "tw-flex tw-min-h-[26px] tw-max-w-fit tw-gap-4 max-sm:tw-flex-col",
                "sm:tw-items-center sm:tw-gap-8",
              )}
            >
              <label className="tw-mb-0 tw-inline-flex tw-min-w-[170px] tw-items-center tw-gap-2">
                <input
                  type="radio"
                  aria-labelledby={clsx("validation-payment_option_id", `payment-option-value-${paymentOption}`)}
                  id={`payment-option-value-${paymentOption}`}
                  className="tw-m-0"
                  name="payment_option_id"
                  value={paymentOption}
                  onChange={onChange}
                  checked={checked}
                />

                <FormattedMessage id={PAYMENT_OPTIONS_TRANSLATION_MAP[paymentOption]} />
              </label>

              {isCourseSelected ? (
                <PricesTimeline
                  maxCount={paymentOptionToMaxCount[paymentOption]}
                  prices={installments[paymentOption] ?? []}
                />
              ) : null}
            </div>
          );
        })}
      </div>

      <div id="form_payment_option_error"> </div>
    </div>
  );
}

function PricesTimeline({ prices, maxCount }: { prices: number[]; maxCount: number }) {
  return (
    <div className="tw-relative tw-flex tw-max-w-xs tw-items-center tw-gap-14 sm:tw-gap-16">
      {maxCount > 1 ? <div className="tw-absolute tw-inset-x-0 tw-h-0.5 tw-bg-[#f4f4f5]" /> : null}

      {Array.from({ length: maxCount }, (_, i) => {
        const price = prices[i];

        return (
          <div
            key={i}
            className={clsx(
              "tw-rounded-3xl tw-bg-[#E9ECF3] tw-px-3 tw-py-1 tw-font-semibold tw-text-brand-black",
              "tw-z-10 tw-flex tw-items-center tw-justify-center",
              "tw-min-h-[26px] tw-min-w-[69px] tw-self-start tw-text-xs",
            )}
          >
            {price ? <FormatNumber value={price} /> : "?"}
          </div>
        );
      })}
    </div>
  );
}
