import { isDevelopment } from "~/common/utils";

/**
 * https://kentcdodds.com/blog/make-your-own-dev-tools
 */
export function loadDevTools(callback: () => void) {
  const explicitlyDisabled =
    window.location.search.includes("dev-tools=false") ||
    window.localStorage.getItem("dev-tools") == "false";

  const explicitlyEnabled =
    window.location.search.includes("dev-tools=true") ||
    window.localStorage.getItem("dev-tools") == "true";

  // prettier-ignore
  if (
    !explicitlyDisabled &&
    (isDevelopment || explicitlyEnabled)
  ) {
    void import("./dev-tools")
      .then(devTools => devTools.install())
      .finally(callback)
  } else {
    callback();
  }
}
