import React from "react";
import { FormattedMessage } from "react-intl";

import { FileType } from "~/common/constants";

import PatientFiles from "../../patient_files";

export function PatientCardCTFiles() {
  return (
    <div className="form-body" id="ct-media-block">
      <h4 style={{ fontWeight: "500" }} className="block">
        <FormattedMessage id="UPLOADED_CBCT" />
      </h4>

      <PatientFiles type={FileType.DICOM} />
    </div>
  );
}
