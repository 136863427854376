import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useParams } from "react-router-dom";

import { deployedRussia } from "../../common/utils";
import { API_PATIENT_OFFERS_LAST_STATIC } from "../../config";
import {
  PageFooterContainer,
  PageFooterFirstRow,
  PageFooterSecondRow,
  PageFooterYear,
} from "../page_footer";

export default function PageFooter() {
  const { patient_id, token } = useParams<{ patient_id: string; token: string }>();
  const intl = useIntl();

  return (
    <PageFooterContainer center>
      <PageFooterFirstRow>
        <span>
          <PageFooterYear /> <FormattedMessage id="page.footer.header" />
        </span>
        <a href="/" target="_blank">
          <FormattedMessage id="page.footer.content" />
        </a>
      </PageFooterFirstRow>

      <PageFooterSecondRow>
        <a href="https://3d-smile.ru/policy" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="Privacy" />
        </a>

        <a href="/contacts" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="page.footer.contacts" />
        </a>

        {deployedRussia() && intl.locale == "ru" ? (
          <a href={API_PATIENT_OFFERS_LAST_STATIC(patient_id, token)}>
            <FormattedMessage id="page.footer.offer" />
          </a>
        ) : null}

        <NavLink to="/payment/details">
          <FormattedMessage id="page.footer.payment" />
        </NavLink>
      </PageFooterSecondRow>
    </PageFooterContainer>
  );
}
