import clsx from "clsx";
import React, { forwardRef } from "react";

import { Button, type ButtonProps } from "./button";
import { Spinner } from "./spinner";

export type LoadingButtonProps = ButtonProps & { isLoading: boolean };

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  function LoadingButton({ isLoading, disabled, children, ...props }, forwardedRef) {
    return (
      <Button ref={forwardedRef} disabled={isLoading || disabled} {...props}>
        <Pending isPending={isLoading}>{children}</Pending>
      </Button>
    );
  },
);

function Pending({ children, isPending }: { children: React.ReactNode; isPending: boolean }) {
  return (
    <div className={clsx({ "tw-relative": isPending })}>
      <span className={clsx({ "tw-invisible": isPending })}>{children}</span>

      {isPending ? (
        <Spinner className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
      ) : null}
    </div>
  );
}
