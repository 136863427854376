import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

import Wrapper from "../hoc/main_wrapper";
import { remoteLog } from "../../common/logging";
import {auth3shapeWithSmile} from "../../actions/3shape";
import { addUserActionNotification } from '../../actions/user_notification';


const json = {
    message: "3shape.auth.err",
    level: 'error',
    position: 'tl',
    autoDismiss: '8',
}


const mapDispatchToProps = (dispatch) =>{
    return {
        setErrMessage : () => dispatch(addUserActionNotification(json))
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}


class ThreeShapeAuthPage extends Component {

    constructor() {
        super();
        this.state = {
            time: null
        }
    }

    componentDidCatch(e) {
        remoteLog(e, '3shape_auth_page');
    }

    componentDidMount() {
        if (!this.props.user.privileges.has_3shape_scanner){
            this.props.history.push('/pages/patients')
        }
        const code = this.props.location.search.split('=')[1].replace('&scope', '')
        auth3shapeWithSmile(code).then(res => {
            if (res.status === 200) {
                const time = setInterval(() => {
                    this.props.history.push('/pages/patients')
                }, 1000)
                this.setState({time: time})
            }else{
                this.props.setErrMessage()
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.time)
    }

    render() {
        return (
            <div className="page-content-wrapper">
                <div className="page-content" style={{minHeight: 584}}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="portlet light bordered">
                                <div className="portlet-title">
                                    <div className="caption">
                                        <i className="icon-book-open font-green"/>
                                        <span className="caption-subject font-green bold uppercase"><FormattedMessage
                                            id="pat.3shape.auth"/></span>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <FormattedMessage id="pat.3shape.auth.success"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(ThreeShapeAuthPage));
