import React, { useEffect } from "react";

import type { TBanner } from "../../reducers/dashboard";
import { Banner, BannerImage } from "./index";

export default function RenderBanners({
  banners,
  mobile,
}: {
  banners: TBanner[];
  mobile?: boolean;
}) {
  useEffect(() => {
    if (mobile) {
      $("#carousel").carousel();
    }
  }, [mobile]);

  const path = window.location.pathname;

  if (banners.length !== 0 && mobile) {
    return (
      <div
        id="carousel"
        className="carousel slide"
        data-ride="carousel"
        data-interval={4000}
        data-testid="carousel"
      >
        <div className="carousel-inner" role="listbox">
          {banners.map((banner, i) => {
            return (
              <div key={i} className={i === 0 ? `item active` : "item"}>
                {banner.type === "empty" ? (
                  <BannerImage
                    image={banner.icon_url}
                    altText={banner.description}
                    style={{ padding: "10px" }}
                    data-track-content
                    data-content-name="banners"
                    data-content-piece={
                      banner.dataMatomoContentPieceName ? banner.dataMatomoContentPieceName : null
                    }
                  />
                ) : (
                  <Banner
                    path={banner.link !== "" ? banner.link : banner.banner_url}
                    image={banner.icon_url}
                    altText={banner.description}
                    style={{ padding: "10px" }}
                    data-track-content
                    data-content-name="banners"
                    data-content-piece={
                      banner.dataMatomoContentPieceName ? banner.dataMatomoContentPieceName : null
                    }
                  />
                )}
              </div>
            );
          })}
          <a
            className="left carousel-control"
            href="#carousel"
            data-slide="prev"
            role="button"
            style={{ background: "transparent" }}
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
              style={{ marginLeft: "-26px", fontSize: "28px" }}
            ></span>
          </a>
          <a
            className="right carousel-control"
            href="#carousel"
            data-slide="next"
            role="button"
            style={{ background: "transparent" }}
          >
            <span
              className="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
              style={{ marginRight: "-26px", fontSize: "28px" }}
            ></span>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div>
      {banners.map((banner, i) => {
        if (path !== "/pages/home") {
          return (
            <div
              data-testid="left_sidebar_banners"
              data-track-content
              data-content-name="banners"
              data-content-piece={
                banner.dataMatomoContentPieceName ? banner.dataMatomoContentPieceName : null
              }
              key={i}
              style={{
                background: "#e9edf4",
                marginLeft: -16,
                marginRight: -16,
                paddingTop: "16px",
              }}
            >
              {banner.type === "empty" ? (
                <BannerImage
                  image={banner.icon_url}
                  altText={banner.description}
                  style={{ padding: "10px" }}
                  data-track-content
                  data-content-name="banners"
                  data-content-piece={
                    banner.dataMatomoContentPieceName ? banner.dataMatomoContentPieceName : null
                  }
                />
              ) : (
                <Banner
                  path={banner.link !== "" ? banner.link : banner.banner_url}
                  image={banner.icon_url}
                  altText={banner.description}
                  style={{ padding: "10px" }}
                  data-track-content
                  data-content-name="banners"
                  data-content-piece={
                    banner.dataMatomoContentPieceName ? banner.dataMatomoContentPieceName : null
                  }
                />
              )}
            </div>
          );
        }
      })}
    </div>
  );
}
