import React from "react";

import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";
import { useScrollToTop } from "~/hooks/use-scroll-to-top";

import { ExpertCard } from "./expert-card";
import { useGetMeetingsExpertsQuery } from "./meetings-api";
import { AnimateOpacity, MeetingsEmptyFallback, MeetingsErrorFallback } from "./meetings-shared";

export function MeetingsPage() {
  useIntlDocumentTitle("meetings.title");
  useScrollToTop();
  const expertsQuery = useGetMeetingsExpertsQuery({});

  return (
    <RemoteLogErrorBoundary
      component="meetings_page"
      fallbackRender={MeetingsErrorFallback}
      onReset={expertsQuery.refetch}
    >
      <ExpertsList />
    </RemoteLogErrorBoundary>
  );
}

function ExpertsList() {
  const presumedNumberOfExperts = 7;
  const expertsQuery = useGetMeetingsExpertsQuery({});
  const listClassName = "tw-flex tw-list-none tw-flex-col tw-gap-3 tw-p-0";

  if (expertsQuery.isUninitialized || expertsQuery.isLoading) {
    return (
      <AnimateOpacity>
        <ul role="list" className={listClassName}>
          {Array.from({ length: presumedNumberOfExperts }, (_, index) => (
            <ExpertCard key={index} expert={undefined} />
          ))}
        </ul>
      </AnimateOpacity>
    );
  }

  if (expertsQuery.isError) {
    return (
      <MeetingsErrorFallback title="meetings.error" resetErrorBoundary={expertsQuery.refetch} />
    );
  }

  const experts = expertsQuery.data;

  if (experts.length == 0) {
    return (
      <MeetingsEmptyFallback
        title="meetings.not.found"
        description="meetings.not.found.description"
      />
    );
  }

  return (
    <ul role="list" className={listClassName}>
      {experts.map((expert) => (
        <ExpertCard key={expert.account_id} expert={expert} />
      ))}
    </ul>
  );
}
