import { useCallback } from "react";

import { useSearchParams } from "./use-search-params";

type StringEnum = Record<string, string>;
type EnumCase<E extends StringEnum> = E[keyof E];

/**
 * A wrapper around `useSearchParams`, that makes sure that the search parameter's value is a valid enumeration case.
 */
export function useEnumSearchParam<E extends StringEnum>({
  name,
  enum: Enum,
}: {
  name: string;
  enum: E;
}): [EnumCase<E> | undefined, (newValue: EnumCase<E> | undefined) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const rawValue = searchParams.get(name);
  const value = isEnum(rawValue, Enum) ? rawValue : undefined;

  const setEnumSearchParam = useCallback(
    (newValue: EnumCase<E> | undefined) => {
      setSearchParams(
        (prevParams) => {
          if (newValue == undefined) {
            prevParams.delete(name);
          } else {
            prevParams.set(name, newValue);
          }
          return prevParams;
        },
        { replace: true },
      );
    },
    [name, setSearchParams],
  );

  return [value, setEnumSearchParam];
}

export function isEnum<E extends StringEnum>(
  value: string | null,
  enumeration: E,
): value is EnumCase<E> {
  return Object.values(enumeration).includes(value as string);
}
