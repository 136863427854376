import React, {Component} from 'react';

import PatientMenu from '../../../patient_menu';
import PageFooter from '../../../page_footer';
import PageHeader from '../../../page_header';
import PaymentInvoiceSuccess from './payment_invoice_success';
import { remoteLog } from '../../../../common/logging';
import { notificationCustomStyle } from "../../../notifications/notification_styles";
import Notification from "../../../notification";
import Tour from '../../../patient/tour';


const redirectToPatients = () => {
        window.location = '/pages/payments';
}


class PaymentInvoiceSuccessBody extends Component {
  componentDidCatch(e) {
    remoteLog(e, 'payment_invoice_success_body');
  }

  componentDidMount() {
      this.timer = setTimeout(() => {
          redirectToPatients()
          this.timer = 0;
      }, 15000)
  }

  componentWillUnmount() {
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = 0;
      }
  }

    render() {
    return (
      <div>
        <PageHeader {...this.props}/>
        <Tour />
        <div className="container">
          <div className="page-container">
            <PatientMenu {...this.props}/>
              <Notification style={notificationCustomStyle}/>
            <PaymentInvoiceSuccess {...this.props}/>
          </div>
          <PageFooter {...this.props}/>
        </div>
      </div>
    )
  }
}

export default PaymentInvoiceSuccessBody;
