import React from "react";

export function PublicLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="page-header navbar">
        <div className="page-header-inner container" id="top-navbar">
          <div className="page-logo">
            <img
              src="/img/3d_smile_logo_rgb.svg"
              alt="logo"
              className="logo-default"
              style={{ width: "120px", marginTop: "25px" }}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="page-container">
          <div className="page-content">{children}</div>
        </div>
      </div>
    </>
  );
}
