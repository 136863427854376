import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { remoteLog } from "~/common/logging";
import { Loader } from "~/components/ui/loader";
import { API_EVENTS_PAYMENTS } from "~/config";
import type { TEvent } from "~/reducers/events";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

type EventPaymentSuccessPageProps = PropsFromRedux &
  RouteComponentProps<{ event_id: string; hash: string }>;

type EventPaymentSuccessPageState = {
  loading: boolean;
  error: unknown;
};

class EventPaymentSuccessPage extends Component<
  EventPaymentSuccessPageProps,
  EventPaymentSuccessPageState
> {
  constructor(props: EventPaymentSuccessPageProps) {
    super(props);
    this.state = {
      loading: true,
      error: false,
    };
    this.commit = this.commit.bind(this);
    this.renderResult = this.renderResult.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "event_payment_success");
  }

  componentDidMount() {
    const event_id = this.props.match.params.event_id;
    const hash = this.props.match.params.hash;
    const order_id = this.props.location.search.replace("?orderId=", "").split("&")[0];
    this.commit(parseInt(event_id), hash, order_id);
  }

  commit(event_id: TEvent["id"], hash: string, order_id?: string) {
    fetch(API_EVENTS_PAYMENTS, {
      method: "PUT",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ event_id, order_id, hash }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to enroll into free event");
        }
        return;
      })
      .then(() => this.setState({ loading: false, error: false }))
      .catch(() => this.setState({ loading: false, error: true }));
  }

  renderResult() {
    const { loading, error } = this.state;
    if (loading === true) {
      return <Loader />;
    }
    if (error === true) {
      return <FormattedMessage id="pat.payments.course.paid.error" />;
    }
    return <FormattedMessage id="pat.payments.course.paid" />;
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-8">
          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <i className="icon-book-open font-green" />
                <span className="caption-subject font-green bold uppercase">
                  <FormattedMessage id="pat.payments.online.payment" />
                </span>
              </div>
            </div>
            <div className="portlet-body">
              <div className="form-body">
                <div className="row fileupload-buttonbar">
                  <div className="col-lg-7">{this.renderResult()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EventPaymentSuccessPage);
