import clsx from "clsx";
import React from "react";
import type NotificationSystem from "react-notification-system";

import Notification from "../notification";
import PageFooter from "../page_footer";
import PageHeader from "../page_header";
import PatientMenu from "../patient_menu";

export function Layout({
  children,
  notificationStyle,
  contentClassName,
  displayPatientMenu = true,
  centerFooter = false,
}: {
  children: React.ReactNode;
  notificationStyle?: NotificationSystem.Style;
  contentClassName?: string;
  displayPatientMenu?: boolean;
  centerFooter?: boolean;
}) {
  return (
    <>
      <PageHeader />
      <div className="container max-md:tw-px-1">
        <div className="page-container">
          {displayPatientMenu ? <PatientMenu /> : null}
          <Notification style={notificationStyle} />
          <div className="page-content-wrapper">
            <div className={clsx("page-content", contentClassName)} style={{ minHeight: 584 }}>
              {children}
            </div>
          </div>
        </div>
        <PageFooter center={centerFooter} />
      </div>
    </>
  );
}
