import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { Decimal } from "decimal.js";
import React, { Component, useState } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import invariant from "tiny-invariant";
import { match } from "ts-pattern";

import { enrollFree, eventEnroll, getEvent, sendFeedback } from "~/actions/events";
import {
  DECIMAL_PRECISION,
  PAYMENT_SYSTEM_TYPE,
  PaymentMethodId,
  PaymentSystem,
  SBERBANK_DISCLAIMER,
} from "~/common/constants";
import { remoteLog } from "~/common/logging";
import { deployedRussia, deployedUSA } from "~/common/utils";
import { FormatDate } from "~/components/common/FormatDate";
import { formatPrice } from "~/components/common/FormatNumber";
import { Button } from "~/components/ui/button";
import { Loader } from "~/components/ui/loader";
import { LoadingButton } from "~/components/ui/loading-button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { API_STATIC_GET } from "~/config";
import { setDocumentTitle } from "~/hooks/use-document-title";
import { EventMeetingType, PaymentStatus, type TEventDetail } from "~/reducers/events";
import type { RootState } from "~/store";

import { EventPayButton, VideoRedirectDialog, ZoomRedirectDialog } from "./event-card";

/** WARNING THIS IS A TEMPORARY SOLUTION FOR THE SINGLE EVENT TO HIDE DATE AND TIME
 * MUST BE REMOVED
 */
const EXCEPTION_FOR_EVENT_ID = 41;

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  event: state.event,
  invoice: state.invoice,
});

const mapDispatchToProps = {
  getEvent,
  enrollFree,
  eventEnroll,
  sendFeedback,
};

const smileIconStyles = {
  maxWidth: "25px",
  fontSize: "25px",
  top: "-1px",
  position: "relative",
  display: "inline-block",
  width: "1.5em",
  textAlign: "center",
  left: "-3px",
} as const;

const FILE_EVENT_OFFER = "/event_offer.pdf";

type EventPageItemProps = RouteComponentProps<{ event_id: string }> &
  PropsFromRedux & { intl: IntlShape };

type EventPageItemState = {
  price_smiles: number;
  price: number;
  payment_method: PaymentMethodId | null;
  accepted: boolean;
  payment_loading: boolean;
  comment: string;
  entity: string | null;
  email: string | null;
  package_payment: boolean;
  isSubmitting: boolean;
};

class EventPageItem extends Component<EventPageItemProps, EventPageItemState> {
  constructor(props: EventPageItemProps) {
    super(props);
    this.state = {
      price_smiles: 0,
      price: 0,
      payment_method: null,
      accepted: false,
      payment_loading: false,
      comment: "",
      entity: null,
      email: null,
      package_payment: false,
      isSubmitting: false,
    };
    this.acceptHostedAction = this.acceptHostedAction.bind(this);
    this.formattedPrice = this.formattedPrice.bind(this);
    this.renderEvent = this.renderEvent.bind(this);
    this.renderFreeEvent = this.renderFreeEvent.bind(this);
    this.renderEventPayment = this.renderEventPayment.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.renderPaymentLinkBlock = this.renderPaymentLinkBlock.bind(this);
    this.renderOfferSignBlock = this.renderOfferSignBlock.bind(this);
    this.renderEventPaymentBlock = this.renderEventPaymentBlock.bind(this);
    this.setAccepted = this.setAccepted.bind(this);
    this.renderFeedback = this.renderFeedback.bind(this);
    this.renderEmailField = this.renderEmailField.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "event_page");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { event_id } = this.props.match.params;
    this.props.getEvent(Number(event_id));
  }

  componentDidUpdate(prevProps: EventPageItemProps) {
    const { event: eventFetchState, invoice } = this.props;
    const { event: prevEventFetchState } = prevProps;
    const event = eventFetchState.data;
    const prevEvent = prevEventFetchState.data;

    if (!prevEvent || prevEvent != event) {
      if (event && event.rate && Object.keys(event.rate).length > 0) {
        this.setState({ price: event.rate.price, price_smiles: 0 });
      }
    }
    if (prevProps.invoice && Object.keys(prevProps.invoice).length === 0) {
      if (invoice && Object.keys(invoice).length > 0 && invoice.link) {
        if (deployedRussia() === true) {
          window.location.href = invoice.link;
        } else if (deployedUSA() === true) {
          const { link } = invoice;
          this.acceptHostedAction(link.payment_endpoint, link.token);
        }
      }
    }

    if (this.props.event.data != prevProps.event.data && this.props.event.data?.title) {
      setDocumentTitle(this.props.event.data.title);
    }
  }

  acceptHostedAction(path: string, token: string) {
    const form = document.createElement("form");
    form.method = "post";
    form.action = path;

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "token";
    hiddenField.value = token;
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  evaluatePrice(price: number, smiles: number, discount: number) {
    const dPrice = new Decimal(price);
    const dSmiles = new Decimal(smiles);
    const dDiscount = new Decimal(discount);

    return dPrice
      .minus(dSmiles.times(dDiscount).times(dPrice).dividedBy(new Decimal("100.00")))
      .toFixed(DECIMAL_PRECISION)
      .toString();
  }

  setPrices(smiles: number) {
    const { event: eventFetchState } = this.props;
    invariant(eventFetchState.status == "success", "expected event to have been fetched");
    const event = eventFetchState.data;

    const { rate, package_rate } = event;
    const { package_payment } = this.state;

    let final_price = rate.price || 0;
    if (
      package_payment === true &&
      package_rate &&
      package_rate != null &&
      Object.keys(package_rate).length > 0 &&
      package_rate.price
    ) {
      final_price = package_rate.price;
    }
    if (smiles > 0 && final_price) {
      // const price = rate.price - (((smiles * event.discount) * rate.price) / 100.0);
      const price = this.evaluatePrice(final_price, smiles, event.discount);
      this.setState({ price_smiles: smiles, price });
    } else {
      this.setState({ price: final_price, price_smiles: 0 });
    }
  }

  setPaymentMethod(value: PaymentMethodId) {
    this.setState({ payment_method: value, accepted: false });
  }

  setAccepted() {
    const { accepted } = this.state;
    this.setState({ accepted: !accepted });
  }

  submitPayment() {
    const { event: eventFetchState } = this.props;
    const event = eventFetchState.data;
    const { price, price_smiles, payment_method, email, entity, package_payment } = this.state;
    this.setState({ payment_loading: true });
    if (event && event.rate && price > 0 && payment_method) {
      if (package_payment === true && event.package_rate.price && event.package_rate.tag) {
        this.props.eventEnroll(
          event.id,
          price,
          event.package_rate.tag,
          payment_method,
          price_smiles,
          email,
          entity,
        );
      } else {
        this.props.eventEnroll(
          event.id,
          price,
          event.rate.tag,
          payment_method,
          price_smiles,
          email,
          entity,
        );
      }
    }
  }

  setPackage() {
    const { package_payment, price_smiles } = this.state;
    this.setState({ package_payment: !package_payment }, () => this.setPrices(price_smiles || 0));
  }

  formattedPrice(price: number) {
    if (deployedRussia() === true) {
      return (
        <div>
          {formatPrice(price)} <FormattedMessage id="pat.block.payment.currency" />
        </div>
      );
    }
    return (
      <div>
        <FormattedMessage id="pat.block.payment.currency.usa" /> {formatPrice(price)}
      </div>
    );
  }

  setEmail(email: string) {
    this.setState({ email });
  }

  setEntity(entity: string) {
    this.setState({ entity });
  }

  renderCycleInfo(event: TEventDetail) {
    if (event.cycle_info && event.cycle_info != null && Array.isArray(event.cycle_info)) {
      const elements = event.cycle_info.map((e, i) => {
        return (
          <li key={i}>
            <i>{e.title}</i>&nbsp;&nbsp;
            {e.start_date ? <FormatDate value={e.start_date} date time /> : null}
          </li>
        );
      });
      return (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b id="event-item-price" style={{ display: "inline-block", marginBottom: 5 }}>
            <FormattedMessage id="events.package.list" />
          </b>
          <ul>{elements}</ul>
        </div>
      );
    }
    return null;
  }

  renderFeedback(event: Readonly<TEventDetail>) {
    const { comment } = this.state;

    const doctorNotRegistered = !event.registered;
    const doctorDidntPay = event.rate != null && event.status?.status != PaymentStatus.SUCCESS;
    const eventNotFinished = !event.finished && event.meeting_type != EventMeetingType.RECORDING;
    const doctorLeftFeedback = event.feedback;
    const doctorCantLeaveFeedback = !event.can_leave_feedback;

    if (
      doctorNotRegistered ||
      doctorDidntPay ||
      eventNotFinished ||
      doctorCantLeaveFeedback ||
      doctorLeftFeedback
    ) {
      return null;
    }

    const handleSubmit = (evt: React.FormEvent) => {
      evt.preventDefault();
      const comment = this.state.comment.trim();

      if (
        (event.finished || event.meeting_type == EventMeetingType.RECORDING) &&
        comment.length > 0
      ) {
        this.props.sendFeedback(event.id, comment);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <hr style={{ margin: "20px" }} />

        <div className="form-group required tw-text-inherit">
          <label htmlFor="feedback-text" className="control-label">
            <FormattedMessage id="events.leave.feedback" />
          </label>

          <textarea
            id="feedback-text"
            className="form-control"
            name="feedback-text"
            required
            rows={10}
            maxLength={4096}
            onChange={(e) => this.setState({ comment: e.target.value })}
          />
        </div>

        <Button
          variant="primary"
          rounded
          size="xl"
          className="tw-my-1"
          disabled={comment.trim().length == 0}
        >
          <FormattedMessage id="BTN_SUBMIT" />
        </Button>
      </form>
    );
  }

  renderEvent(event: Readonly<TEventDetail>) {
    /** Event's general information */
    return (
      <div>
        <h4>
          <b>{event.title}</b>
        </h4>
        <hr style={{ margin: "10px" }} />
        <div>
          {event.description ? (
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <label className="control-label" id="event-item-datetime">
                <div
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              </label>
            </div>
          ) : null}
          <div>
            {event.id !== EXCEPTION_FOR_EVENT_ID ? (
              <label className="control-label" id="event-item-datetime">
                <b>
                  <FormattedMessage id="event.start.datetime" />
                </b>
                <div>
                  {event.start_date ? <FormatDate value={event.start_date} date time /> : null}
                </div>
              </label>
            ) : null}
          </div>
          {event.meeting_type == EventMeetingType.OFFLINE && event.city ? (
            <div>
              <label className="control-label" id="event-item-city">
                <b>
                  <FormattedMessage id="geo.usa.city" />
                </b>
                <div>{event.city}</div>
              </label>
            </div>
          ) : null}
          {(event.registered === false ||
            (event.registered === true &&
              event.status &&
              event.status.payment === false &&
              event.status.blocked === false)) &&
          event.rate &&
          event.rate.price &&
          event.rate.price > 0 ? (
            <div>
              <div>
                <label className="control-label" id="event-item-price">
                  <b>
                    <FormattedMessage id="events.rates.price" />
                  </b>
                  {this.formattedPrice(event.rate ? event.rate.price || 0 : 0)}
                </label>
              </div>
              {event.package_rate &&
              event.package_rate != null &&
              Object.keys(event.package_rate).length > 0 ? (
                <div>
                  <div>
                    <label className="control-label" id="event-item-price">
                      <b>
                        <FormattedMessage id="events.rates.price.package" />
                      </b>
                      {this.formattedPrice(event.package_rate.price)}
                    </label>
                  </div>
                  <div>{this.renderCycleInfo(event)}</div>
                </div>
              ) : null}
            </div>
          ) : null}
          {event.finished === false &&
          event.meeting_link &&
          event.meeting_link.includes("https") ? (
            <div>
              <label className="control-label" id="event-item-datetime">
                <div>
                  <a href={event.meeting_link} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="event.meeting_link" />
                  </a>
                </div>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderPaymentMethod() {
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <b>
          <FormattedMessage id="HEADER_PAYMENT_METHOD" />
        </b>
        <div className="radio-list" style={{ marginTop: "10px" }}>
          <label htmlFor="payment-method-invoice">
            <div className="radio">
              <input
                id="payment-method-invoice"
                type="radio"
                name="preferences.default_payment_method"
                defaultValue={PaymentMethodId.INVOICE}
                onChange={() => this.setPaymentMethod(PaymentMethodId.INVOICE)}
              />
            </div>
            <FormattedMessage id="PM_INVOICE" />
            &nbsp;
            <FormattedMessage id="events.payment.invoice.hint" />
          </label>
          <label htmlFor="payment-method-card">
            <div className="radio">
              <input
                id="payment-method-card"
                type="radio"
                name="preferences.default_payment_method"
                defaultValue={PaymentMethodId.CARD}
                onChange={() => this.setPaymentMethod(PaymentMethodId.CARD)}
              />
            </div>
            <FormattedMessage id="PM_CARD.usa" />
          </label>
        </div>
      </div>
    );
  }

  renderPaymentLinkBlock() {
    const { payment_method, payment_loading, accepted, entity, email } = this.state;

    let isSubmitEnabled = false;

    if (payment_method == PaymentMethodId.CARD) {
      isSubmitEnabled = accepted;
    } else if (payment_method == PaymentMethodId.INVOICE) {
      isSubmitEnabled = Boolean(entity && email && validateEmail(email));
    }

    return (
      <div>
        {payment_method == PaymentMethodId.CARD && PAYMENT_SYSTEM_TYPE == PaymentSystem.SBERBANK ? (
          <textarea
            className="form-control"
            rows={10}
            readOnly={true}
            defaultValue={SBERBANK_DISCLAIMER}
          />
        ) : null}

        <LoadingButton
          variant="primary"
          rounded
          size="xl"
          className="tw-my-3"
          disabled={!isSubmitEnabled}
          onClick={() => this.submitPayment()}
          isLoading={payment_loading}
        >
          <FormattedMessage id="event.button.register" />
        </LoadingButton>
      </div>
    );
  }

  renderOfferSignBlock() {
    const { accepted } = this.state;
    return (
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        <label
          id="approve-disclaimer-accept-label"
          style={{ display: "flex", alignItems: "center", gap: 8 }}
          htmlFor="approve-disclaimer-accept-value"
        >
          <input
            style={{ marginTop: 0 }}
            type="checkbox"
            id="approve-disclaimer-accept-value"
            onClick={() => this.setAccepted()}
            checked={accepted}
          />
          <b>
            {deployedRussia() === true ? (
              <div style={{ display: "inline" }}>
                <FormattedMessage id="events.offer.sign" />
                &nbsp;
                <a href={API_STATIC_GET(FILE_EVENT_OFFER)} target="_blank" rel="noreferrer">
                  <FormattedMessage id="offer.header" />
                </a>
              </div>
            ) : (
              <a href={API_STATIC_GET(FILE_EVENT_OFFER)} target="_blank" rel="noreferrer">
                <FormattedMessage id="offer.accept.predict" />
              </a>
            )}
          </b>
        </label>
      </div>
    );
  }

  renderEventPaymentBlock(event: Readonly<TEventDetail>) {
    if (event.registered && event.status && event.status.payment) {
      return <PaidEventRegistrationInfo event={event} status={event.status} />;
    }

    if (
      !event.finished &&
      (event.status == null || (!event.status.payment && !event.status.blocked))
    ) {
      return this.renderEventPayment(event);
    }

    return null;
  }

  renderEmailField() {
    return (
      <div style={{ maxWidth: "70%" }}>
        <div className="form-group">
          <label htmlFor="email" className="control-label">
            <FormattedMessage id="events.payment.email" />
            &nbsp;
            <FormattedMessage id="events.payment.email.doctor.hint" />
          </label>
          <input
            type="input"
            className="form-control"
            id="email"
            name="email"
            maxLength={256}
            onChange={(e) => this.setEmail(e.target.value)}
          />
        </div>
      </div>
    );
  }

  renderEventPayment(event: Readonly<TEventDetail>) {
    if (!event.rate) {
      return null;
    }

    const { max_discount, discount, smiles } = event;
    const { price_smiles, price, payment_method, package_payment } = this.state;
    const maxSmiles = discount > 0 ? Math.min(max_discount / discount, smiles) : 0;

    return (
      <div style={{ marginTop: "15px", marginBottom: "10px" }}>
        {event.package_rate && Object.keys(event.package_rate).length > 0 ? (
          <label
            style={{ display: "flex", alignItems: "center", gap: 8 }}
            htmlFor="event-pay-for-package"
          >
            <input
              type="checkbox"
              id="event-pay-for-package"
              style={{ marginTop: 0 }}
              onClick={() => this.setPackage()}
              defaultChecked={package_payment}
            />
            <FormattedMessage id="events.payments.package.pay" />
          </label>
        ) : null}
        <hr />

        <div>{this.renderPaymentMethod()}</div>

        {price > 0 && payment_method == PaymentMethodId.INVOICE ? (
          <InvoicePaymentFields
            onEntityChange={(e) => this.setEntity(e.target.value)}
            onEmailChange={(e) => this.setEmail(e.target.value)}
          />
        ) : null}

        {price > 0 && payment_method == PaymentMethodId.CARD && event.requires_email
          ? this.renderEmailField()
          : null}

        {maxSmiles > 0 ? (
          <div>
            <div>
              <label htmlFor="smiles-range-bar" className="form-label">
                <FormattedMessage tagName="b" id="event.payments.smiles" />
              </label>
            </div>
            <div style={{ maxWidth: "70%" }}>
              <input
                type="range"
                className="form-range"
                min="0"
                max={maxSmiles}
                id="smiles-range-bar"
                defaultValue={price_smiles}
                onChange={(e) => this.setPrices(e.target.valueAsNumber)}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <img src="/img/smile.svg" style={smileIconStyles} alt="" /> <b>{price_smiles}</b>
            </div>
          </div>
        ) : null}

        <label htmlFor="smiles-range-bar" className="form-label">
          <FormattedMessage tagName="b" id="event.payments.money" />:{this.formattedPrice(price)}
        </label>

        {price_smiles > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <label htmlFor="smiles-range-bar" className="form-label">
              <FormattedMessage tagName="b" id="event.payment.smiles.total" />
              :<br />
              {price_smiles}
            </label>
          </div>
        ) : null}

        {price > 0 && payment_method == PaymentMethodId.CARD ? this.renderOfferSignBlock() : null}
        {price > 0 ? this.renderPaymentLinkBlock() : null}
      </div>
    );
  }

  renderFreeEvent(event: Readonly<TEventDetail>) {
    const { accepted, isSubmitting } = this.state;

    if (event.registered) {
      return <FreeEventRegistrationInfo event={event} />;
    }

    const handleSubmit: React.FormEventHandler = async (evt) => {
      evt.preventDefault();
      this.setState({ isSubmitting: true });
      try {
        await this.props.enrollFree(event.id);
      } finally {
        this.setState({ isSubmitting: false });
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        {this.renderOfferSignBlock()}

        <LoadingButton
          variant="primary"
          rounded
          size="xl"
          disabled={!accepted}
          isLoading={isSubmitting}
        >
          <FormattedMessage id="event.button.register" />
        </LoadingButton>
      </form>
    );
  }

  render() {
    const { event: eventFetchState } = this.props;

    return (
      <Portlet as="main">
        <PortletTitle iconClassName="icon-calendar">
          <FormattedMessage id="events" />
        </PortletTitle>

        <div className="portlet-body">
          {match(eventFetchState)
            .with({ status: "idle" }, { status: "loading" }, () => <Loader />)
            .with({ status: "error" }, () => <FormattedMessage id="event.not.found" />)
            .with({ status: "success" }, ({ data: event }) => {
              const showRegistration =
                event.registered ||
                (typeof event.available_seats == "number" && event.available_seats > 0) ||
                event.meeting_type == EventMeetingType.RECORDING;
              const isFree = event.rate == null;

              return (
                <div>
                  {this.renderEvent(event)}
                  {showRegistration ? (
                    isFree ? (
                      this.renderFreeEvent(event)
                    ) : (
                      this.renderEventPaymentBlock(event)
                    )
                  ) : (
                    <>
                      <hr />
                      <p className="tw-mb-1 tw-font-semibold">
                        <FormattedMessage id="events.registration.completed.verbose" />
                      </p>
                    </>
                  )}
                  {this.renderFeedback(event)}
                </div>
              );
            })
            .exhaustive()}
        </div>
      </Portlet>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(EventPageItem));

function PaidEventRegistrationInfo({
  event,
  status,
}: {
  event: TEventDetail;
  status: NonNullable<TEventDetail["status"]>;
}) {
  const displayPayButton =
    status.status == PaymentStatus.PENDING || status.status == PaymentStatus.DECLINED;

  const displayZoomJoinButton =
    status.status == PaymentStatus.SUCCESS &&
    event.meeting_type == EventMeetingType.ONLINE &&
    event.zoom_join_url &&
    !event.finished;

  const displayWatchButton =
    status.status == PaymentStatus.SUCCESS && (event.recording_links ?? []).length > 0;

  return (
    <div className="tw-mb-2.5">
      <hr />

      <div className="tw-space-y-3">
        {status.smiles > 0 ? (
          <div>
            <FormattedMessage tagName="b" id="event.payment.smiles.total" />

            <div>{status.smiles}</div>
          </div>
        ) : null}

        <div>
          <FormattedMessage tagName="b" id="event.payments.money" />

          {status.amount ? (
            <div>
              {formatPrice(status.amount)} <FormattedMessage id="pat.block.payment.currency" />
            </div>
          ) : null}
        </div>

        <div>
          <FormattedMessage tagName="b" id="event.registration.payment.status" />

          {status.status ? (
            <FormattedMessage tagName="div" id={`event.registration.${status.status}`} />
          ) : null}
        </div>

        {displayPayButton ? (
          <EventPayButton eventId={event.id} variant="primary" rounded size="xl" />
        ) : null}

        {displayZoomJoinButton && event.zoom_join_url ? (
          <ZoomJoinButton title={event.title} zoom_join_url={event.zoom_join_url} />
        ) : null}

        {displayWatchButton && event.recording_links ? (
          <WatchVideoButton title={event.title} recording_links={event.recording_links} />
        ) : null}
      </div>
    </div>
  );
}

function FreeEventRegistrationInfo({ event }: { event: TEventDetail }) {
  const displayZoomJoinButton =
    event.meeting_type == EventMeetingType.ONLINE && event.zoom_join_url && !event.finished;

  const displayWatchButton = (event.recording_links ?? []).length > 0;

  return (
    <div className="tw-space-y-2">
      <div className="tw-py-1.5 tw-font-bold">
        <FormattedMessage id="events.enroll.success" />
      </div>

      {displayZoomJoinButton && event.zoom_join_url ? (
        <ZoomJoinButton title={event.title} zoom_join_url={event.zoom_join_url} />
      ) : null}

      {displayWatchButton && event.recording_links ? (
        <WatchVideoButton title={event.title} recording_links={event.recording_links} />
      ) : null}
    </div>
  );
}

function ZoomJoinButton({
  title,
  zoom_join_url,
  children,
}: {
  title: string;
  zoom_join_url: string;
  children?: React.ReactNode;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <ZoomRedirectDialog event={{ title, zoom_join_url }} onClose={() => setIsDialogOpen(false)} />

      <div>
        <Dialog.Trigger asChild>
          <Button variant="primary" rounded size="xl" className="tw-gap-2">
            {children ?? (
              <>
                <FormattedMessage id="event.button.zoom_join" />
                <span
                  className={clsx(
                    "tw-inline-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center",
                    "tw-rounded-full tw-bg-white",
                  )}
                >
                  <ArrowUpRightIcon className="tw-h-3 tw-w-3 tw-fill-primary" />
                </span>
              </>
            )}
          </Button>
        </Dialog.Trigger>
      </div>
    </Dialog.Root>
  );
}

function WatchVideoButton({
  title,
  recording_links,
}: {
  title: string;
  recording_links: NonNullable<TEventDetail["recording_links"]>;
}) {
  return (
    <Dialog.Root>
      <VideoRedirectDialog event={{ title, recording_links }} onClose={() => {}} />

      <Dialog.Trigger asChild>
        <Button variant="primary" rounded size="xl">
          <FormattedMessage id="events.action.watch" />
        </Button>
      </Dialog.Trigger>
    </Dialog.Root>
  );
}

function InvoicePaymentFields({
  onEntityChange,
  onEmailChange,
}: {
  onEntityChange: React.ChangeEventHandler<HTMLInputElement>;
  onEmailChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <div style={{ maxWidth: "70%" }}>
      <div className="form-group">
        <label htmlFor="entity" className="control-label" style={{ fontWeight: 600 }}>
          <FormattedMessage id="events.payment.legal_entity" />
          <span className="required mob-title" aria-required="true">
            *
          </span>
        </label>
        <input
          required
          type="text"
          id="entity"
          className="form-control"
          name="entity"
          maxLength={512}
          onChange={onEntityChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="email" className="control-label" style={{ fontWeight: 600 }}>
          <FormattedMessage id="events.payment.email" />
          &nbsp;
          <FormattedMessage id="events.payment.email.hint" />
          <span className="required mob-title" aria-required="true">
            *
          </span>
        </label>
        <input
          required
          type="input"
          className="form-control"
          name="email"
          id="email"
          maxLength={256}
          onChange={onEmailChange}
        />
      </div>
    </div>
  );
}
