export function animateScroll({
  targetPosition,
  initialPosition,
  duration,
}: {
  targetPosition: number;
  initialPosition: number;
  duration: number;
}) {
  let start: number;
  let position: number;
  let animationFrame: number;

  const maxAvailableScroll =
    document.documentElement.scrollHeight - document.documentElement.clientHeight;

  const amountOfPixelsToScroll = initialPosition - targetPosition;

  function step(timestamp: number) {
    if (start === undefined) {
      start = timestamp;
    }

    const elapsed = timestamp - start;
    const relativeProgress = elapsed / duration;
    const easedProgress = easeOutQuart(relativeProgress);

    position = initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);
    window.scrollTo(0, position);

    if (initialPosition !== maxAvailableScroll && window.scrollY === maxAvailableScroll) {
      window.cancelAnimationFrame(animationFrame);
      return;
    }

    if (elapsed < duration) {
      animationFrame = window.requestAnimationFrame(step);
    }
  }

  animationFrame = window.requestAnimationFrame(step);
}

function easeOutQuart(x: number) {
  return 1 - Math.pow(1 - x, 4);
}
