import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { getClinics } from "~/actions/clinics";
import { useRequiredUser } from "~/common/user";
import { PortletTerm } from "~/components/ui/portlet";
import { addInstructions } from "~/slices/instructions";
import { useAppDispatch, useAppSelector } from "~/store";
import type { TClinic } from "~/types/clinic";
import type { SetDirtyFn } from "~/types/patient-new";

export function PatientNewDoctorInfo({ setDirty }: { setDirty: SetDirtyFn }) {
  const dispatch = useAppDispatch();

  const user = useRequiredUser();
  const clinics = useAppSelector((state) => state.clinics);
  const clinic_id = useAppSelector((state) => state.instructions.clinic_id);

  useEffect(() => {
    // Store `doctor_id` inside `instructions`
    dispatch(addInstructions({ doctor_id: user.account_id }));
  }, [user, dispatch]);

  useEffect(() => {
    // Load clinics and set `clinic_id` inside `instructions`
    async function loadClinics() {
      try {
        const clinics = await dispatch(getClinics());

        if (clinics.length == 1) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dispatch(addInstructions({ clinic_id: clinics[0]!.clinic_id }));
        }
      } catch {
        // ignore error
      }
    }
    void loadClinics();
  }, [dispatch]);

  function selectClinic(clinic_id: TClinic["clinic_id"]) {
    dispatch(addInstructions({ clinic_id }));

    if (clinics.length > 1) {
      setDirty("clinic_id", clinic_id);
    }
  }

  return (
    <div>
      <h3 className="block" style={{ fontWeight: "900", marginTop: "0" }}>
        <FormattedMessage id="BLOCKHEAD_DOCTOR" />
      </h3>

      <div className="form-group" id="clinic-section">
        <PortletTerm id="validation-clinic_id" required>
          <FormattedMessage id="HEADER_CLINIC" />
        </PortletTerm>

        <div className="radio-list">
          {clinics.map((clinic) => (
            <label key={clinic.clinic_id}>
              <div className="radio">
                <input
                  id={`clinic-${clinic.clinic_id}`}
                  aria-labelledby={clsx("validation-clinic_id", `clinic-${clinic.clinic_id}`)}
                  type="radio"
                  name="clinic"
                  checked={clinic.clinic_id == clinic_id}
                  value={clinic.clinic_id}
                  onChange={(e) => selectClinic(Number(e.target.value))}
                />
              </div>{" "}
              {clinic.title}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}
