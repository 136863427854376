import React from "react";
import { FormattedMessage } from "react-intl";

import { FormatDate } from "~/components/common/FormatDate";

export function PatientCardLinks({ links }: { links: { link: string; timestamp: string }[] }) {
  return (
    <div>
      <h4 style={{ fontWeight: "500" }}>
        <FormattedMessage id="CBCT_LINKS" />
      </h4>

      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="LINK" />
            </th>

            <th className="link_created_date">
              <FormattedMessage id="CREATED_AT" />
            </th>

            <th>
              <FormattedMessage id="OPEN" />
            </th>
          </tr>
        </thead>

        <tbody>
          {links.map((item, index) => {
            return (
              <tr key={index + item.timestamp}>
                <td>
                  <div className="input-group">
                    <input
                      data-matomo-mask
                      type="text"
                      className="form-control"
                      readOnly={true}
                      value={item.link}
                    />
                  </div>
                </td>

                <td className="link_created_date" style={{ lineHeight: "35px" }}>
                  {<FormatDate value={item.timestamp} date={true} time={true} />}
                </td>

                <td>
                  <span className="input-group-btn">
                    <a
                      className="btn btn-default"
                      href={item.link}
                      onClick={(e) => {
                        e.preventDefault();
                        void navigator.clipboard.writeText(`${item.link}`);
                      }}
                    >
                      <span className="glyphicon glyphicon-copy"></span>
                    </a>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
