import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";
import { getFormValues } from "redux-form";

import {
  CO_TAG_FULL_3_STEPS_CBCT,
  CO_TAG_TEEN_3_STEPS_CBCT,
  hasDeepCBCT,
  isChildrenShortCourse,
  isRetainersCourse
} from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { getLastCorrection } from "~/common/patient";
import { translateRxOption } from "~/common/prescription";
import { canOrderTestPlastic } from "~/common/user";
import { Button } from "~/components/ui/button";
import { Portlet } from "~/components/ui/portlet";
import type { RootState } from "~/store";
import type { TCorrection, TPatient } from "~/types/patient";
import type { TUser } from "~/types/user";

import { PatientCardAdditionalServices } from "./patient-card/patient-card-additional-services";
import { PatientCardClinic } from "./patient-card/patient-card-clinic";
import { PatientCardCourse } from "./patient-card/patient-card-course";
import { PatientCardCTFiles } from "./patient-card/patient-card-ct-files";
import { PatientCardLinks } from "./patient-card/patient-card-links";
import { PatientCardPatientInfo } from "./patient-card/patient-card-patient-info";
import { PatientCardPayment } from "./patient-card/patient-card-payment";
import { PatientCardPhotos } from "./patient-card/patient-card-photos";
import { PatientCardRecipe } from "./patient-card/patient-card-recipe";
import { PatientCardUploadedFiles } from "./patient-card/patient-card-uploaded-files";

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.intl,
    services: state.services,
    instructions: state.instructions,
    formValues: getFormValues('correction')(state),
  };
};

type PatientCardProps = PropsFromRedux & {
  patient: TPatient;
  user: TUser;
};

type PatientCardState = {
  selected: number;
  rxTypeId: TPatient["rx_type_id"];
  prescription: TCorrection["prescription"];
  links: TCorrection["links"];
};

class PatientCard extends Component<PatientCardProps, PatientCardState> {
  constructor(props: PatientCardProps) {
    super(props)
    this.state = {
      selected: this.props.patient.course.correction.length - 1,
      rxTypeId: getLastCorrection(this.props.patient).prescription?.rx_type_id ?? this.props.patient.rx_type_id,
      prescription: getLastCorrection(this.props.patient).prescription,
      links: getLastCorrection(this.props.patient).links,
    }
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_card");
  }

  handleChange(index: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const correction = this.props.patient.course.correction[index]!;
    const rxTypeId = correction.prescription?.rx_type_id ?? this.props.patient.rx_type_id;

    this.setState({ selected: index, rxTypeId: rxTypeId });
    this.setState({ prescription: correction.prescription, links: correction.links });
  }

  render() {
    const { user, patient: p } = this.props;

    const { services } = this.props;
    const old_instructions = this.props.instructions;
    const instructions = this.state.prescription;
    const links = this.state.links;
    const hasCorrection = this.props.patient.course.correction.length > 1;
    const lastCorrection = getLastCorrection(this.props.patient);
    const isChildrenShort = isChildrenShortCourse(p.course.course_id);

    return (
      <Portlet id="patient-card">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-book-open font-green" />
            <span className="caption-subject font-green bold uppercase" id="patient-block-label">
                <FormattedMessage id="pat.view.header" />
            </span>
          </div>

          <div className="form-actions">
            <Button variant="secondary" asChild>
              <NavLink to={`/pages/edit_patient/${p.patient_id}`} style={{ float: "right" }}>
                <FormattedMessage id="BTN_EDIT" />
              </NavLink>
            </Button>
          </div>
        </div>

        <div className="portlet-body" id="patient-block-body">
          <PatientCardPatientInfo patient={p} />
          <PatientCardClinic patient={p} />
          <PatientCardPayment patient={p} />

          {old_instructions.anthropometric_diagnostic ||
          (Array.isArray(services) && services.length > 0) ||
          p.course.course_option_tag == CO_TAG_FULL_3_STEPS_CBCT ||
          p.course.course_option_tag == CO_TAG_TEEN_3_STEPS_CBCT ? (
            <PatientCardAdditionalServices
              patient={p}
              services={services}
              old_instructions={old_instructions}
            />
          ) : null}

          <PatientCardCourse patient={p} />

          <h3 className="col" id="course-section">
            <FormattedMessage id="BLOCKHEAD_INSTRUCTIONS" />
          </h3>

          <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {p.course.correction.map((item, index) => {
              return (
                <Button
                  key={item.id}
                  variant={index == this.state.selected ? "default" : "empty"}
                  className={index == this.state.selected ? "" : "tw-border-[#dcdcdc] tw-bg-[#dcdcdc] tw-text-white hover:tw-border-gray-400 hover:tw-bg-gray-400 active:tw-border-gray-400 active:tw-bg-gray-400"}
                  onClick={this.handleChange.bind(this, index)}
                >
                {index == 0 ? (
                    <FormattedMessage id="main.treatment" />
                  ) : (
                    <>
                      <FormattedMessage
                        id={isChildrenShort ? "pat.block.right.additional_course" : "correction"}
                      />{" "}
                      {index}
                    </>
                  )}
                </Button>
              );
            })}
          </div>
          <br />

          {instructions === null ? (
            <div>
              <FormattedMessage id="nocorrection" />
            </div>
          ) : (
            <div>
              {instructions.material
                ? <FormattedMessage id={translateRxOption('material', instructions.material)} />
                : null
              }

              {canOrderTestPlastic(this.props.user) && lastCorrection.test_plastic != null ? (
                <p style={{ marginTop: 15, marginBottom: 15 }}>
                  <FormattedMessage id={lastCorrection.test_plastic ? "C_TEST_PLASTIC_YES" : "C_TEST_PLASTIC_NO"} />
                </p>
              ) : null}

              {!isChildrenShort && hasDeepCBCT(this.props.patient) && hasCorrection ? (
                <p style={{ marginTop: 15, marginBottom: 15 }}>
                  <FormattedMessage id={lastCorrection.with_ct ? "C_YES_CT_CORRECTION" : "C_NO_CT_CORRECTION"} />
                </p>
              ) : null}

              <PatientCardRecipe user={user} course_id={p.course.course_id} prescription={instructions} />
            </div>
          )}

          {links.length > 0 ? <PatientCardLinks links={links} /> : null}
          <PatientCardUploadedFiles patient={p} user={user} />
          {isRetainersCourse(p.course.course_id) ? null : <PatientCardCTFiles />}
          {isRetainersCourse(p.course.course_id) ? null : <PatientCardPhotos patient={p} />}

          <div className="form-actions">
            <Button variant="secondary" asChild>
              <NavLink to={`/pages/edit_patient/${p.patient_id}`}>
                <FormattedMessage id="BTN_EDIT" />
              </NavLink>
            </Button>
          </div>
        </div>
      </Portlet>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientCard);
