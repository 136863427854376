import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";
import type { FallbackProps } from "react-error-boundary";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { Button,type ButtonProps } from "~/components/ui/button";
import { Img } from "~/components/ui/image";
import { Portlet } from "~/components/ui/portlet";
import { API_MEETINGS_IMAGE } from "~/config";
import type { Loading } from "~/types/common";

import expertNoAvatarImg from "./expert-no-avatar.png";
import type { TExpert } from "./meetings-types";

export function ExpertName({
  expert,
  as: Tag = "h3",
  className = "tw-my-0 tw-text-base tw-font-semibold tw-leading-9 tw-text-brand-black",
}: {
  expert: TExpert;
  as?: React.ElementType;
  className?: string;
}) {
  return (
    <Tag className={className}>
      {expert.last_name} {expert.first_name} <br />
      {/* To align expert's name that doesn't have a middle_name with other experts' names visually */}
      {expert.middle_name || <span className="tw-invisible">{expert.first_name}</span>}
    </Tag>
  );
}

export function ExpertImage({ expert }: { expert: Loading<TExpert> }) {
  const size = 75;
  const imageSkeleton = <Skeleton width={size} height={size} className="tw-rounded-full" />;

  return expert ? (
    <Img
      width={size}
      height={size}
      errorFallback={
        <Img
          width={size}
          height={size}
          errorFallback={null}
          loadingFallback={imageSkeleton}
          src={expertNoAvatarImg}
        />
      }
      loadingFallback={imageSkeleton}
      src={expert.avatar ? API_MEETINGS_IMAGE(expert.account_id, expert.avatar) : expertNoAvatarImg}
    />
  ) : (
    imageSkeleton
  );
}

export function AnimateOpacity({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={className}
    >
      {children}
    </motion.div>
  );
}

export function MeetingsEmptyFallback(props: {
  title: MessageDescriptor["id"];
  description: MessageDescriptor["id"];
  link?: { to: string; children: MessageDescriptor["id"] };
}) {
  return <MeetingsFallback {...props} isError={false} />;
}

export function MeetingsErrorFallback({
  title = "error_boundary.title",
  ...props
}: { title?: MessageDescriptor["id"] } & Partial<FallbackProps>) {
  return <MeetingsFallback title={title} isError {...props} />;
}

function MeetingsFallback({
  title,
  description,
  isError,
  link,
  resetErrorBoundary,
}: {
  title: MessageDescriptor["id"];
  description?: MessageDescriptor["id"];
  isError: boolean;
  link?: { to: string; children: MessageDescriptor["id"] };
} & Partial<FallbackProps>) {
  const buttonProps: ButtonProps = {
    variant: "primary",
    rounded: "extra",
    size: "2xl",
    className: "sm:tw-self-start",
  };

  return (
    <Portlet className="tw-rounded-3xl tw-text-brand-black">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-3.5">
        <h2
          className={clsx("tw-m-0 tw-text-base tw-font-semibold tw-leading-10", {
            "tw-text-red-500": isError,
          })}
        >
          <FormattedMessage id={title} />
        </h2>

        <p role="alert" className="tw-m-0 tw-leading-[2.75rem]">
          {isError ? (
            <>
              <span className="tw-block">
                <FormattedMessage id={"error_boundary.message"} />.
              </span>

              <span className="tw-block">
                <FormattedMessage id="error_boundary.message_2" />.
              </span>
            </>
          ) : description ? (
            <span className="tw-block">
              <FormattedMessage id={description} />.
            </span>
          ) : null}
        </p>

        {isError && resetErrorBoundary ? (
          <Button {...buttonProps} onClick={resetErrorBoundary}>
            <FormattedMessage id="general.retry" />
          </Button>
        ) : link ? (
          <Button {...buttonProps} asChild>
            <Link to={link.to}>
              <FormattedMessage id={link.children} />
            </Link>
          </Button>
        ) : null}
      </div>
    </Portlet>
  );
}

export function MeetingsTag({ children, className }: { children: string; className?: string }) {
  return (
    <span
      className={clsx(
        "tw-rounded-3xl tw-bg-[#E9ECF3] tw-px-3 tw-py-1 tw-font-semibold tw-text-brand-black",
        className,
      )}
    >
      {children}
    </span>
  );
}
