import type { Location } from "history";
import React from "react";
import { Prompt, Redirect } from "react-router-dom";

import Modal from "./modal";

type RouteLeavingGuardProps = {
  when: boolean | undefined;
  shouldBlockNavigation(): boolean | undefined;
  saveChanges(): void;
  saveAsADraft: boolean;
  currentPath?: string | null;
};

type RouteLeavingGuardState = {
  isModalVisible: boolean;
  isNavigationConfirmed: boolean;
  location: Location | null;
  lastLocation: Location | null;
};

class RouteLeavingGuard extends React.Component<RouteLeavingGuardProps, RouteLeavingGuardState> {
  constructor(props: RouteLeavingGuardProps) {
    super(props);
    this.state = {
      isModalVisible: false,
      isNavigationConfirmed: false,
      location: null,
      lastLocation: null,
    };
    this.handleConfirmNavigationClick = this.handleConfirmNavigationClick.bind(this);
    this.handleBlockedNavigation = this.handleBlockedNavigation.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleBlockedNavigation(nextLocation: Location) {
    const { shouldBlockNavigation, currentPath } = this.props;

    if (shouldBlockNavigation()) {
      if (currentPath != nextLocation.pathname) {
        this.setState({ location: nextLocation });
        window.history.forward();
      }

      this.setState({ isModalVisible: true, lastLocation: nextLocation });
      return false;
    }

    return true;
  }

  handleConfirmNavigationClick() {
    this.closeModal(() => {
      const { lastLocation } = this.state;

      if (lastLocation) {
        this.setState({ isNavigationConfirmed: true });
      }
    });
  }

  closeModal(callback?: () => void) {
    this.setState({ isModalVisible: false }, callback);
  }

  render() {
    const { when } = this.props;
    const { isModalVisible, isNavigationConfirmed, location } = this.state;

    if (isNavigationConfirmed && location) {
      return <Redirect to={location.pathname} />;
    }

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />

        <Modal
          visible={isModalVisible}
          onCancel={this.closeModal}
          saveChanges={this.props.saveChanges}
          onConfirm={this.handleConfirmNavigationClick}
          saveAsADraft={this.props.saveAsADraft}
        />
      </>
    );
  }
}

export default RouteLeavingGuard;
