import React from "react";
import { FormattedMessage } from "react-intl";

export function SBCreditComponent({ showConditions = true }) {
  return (
    <div>
      <FormattedMessage id="PO_CREDIT_SB" />
      &nbsp;
      <img src="/img/psb_icon.png" alt="" style={{ height: "20px" }} />
      &nbsp;&nbsp;
      {showConditions ? (
        <a href="https://www.pokupay.ru/credit_terms" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="PO_CREDIT_SB_COND" />
        </a>
      ) : null}
    </div>
  );
}
