import { api } from "~/api";

export enum ToastTypeTag {
  TELEGRAM_CHANNEL = "TELEGRAM_CHANNEL",
  EVENT_INVITATION_I_AM_DOCTOR = "EVENT_INVITATION_I_AM_DOCTOR",
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getToasts: builder.query<ToastTypeTag[], void>({
      query: () => ({ url: "toasts" }),
      providesTags: ["Toast"],
    }),
    closeToast: builder.mutation<void, { tag: ToastTypeTag }>({
      query: (data) => ({ url: "toasts", method: "POST", body: data }),
      invalidatesTags: ["Toast"],
    }),
  }),
});

export const { useGetToastsQuery, useCloseToastMutation } = extendedApi;
