import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenCourse } from "~/common/courses";
import { translateRxOption } from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";
import type { TPrescription } from "~/types/prescription";

export function PatientCardMolars({
  course_id,
  prescription,
}: {
  course_id: TPatient["course"]["course_id"];
  prescription: TPrescription;
}) {
  const { molars_ratio_right, molars_ratio_left, molars_method_ai, molars_method, molars_ipr } =
    prescription;

  return (
    <div>
      <h4 id="molars-section" className="block" style={{ fontWeight: "500" }}>
        <FormattedMessage id="HEADER_MOLAR" />
      </h4>

      {isChildrenCourse(course_id) ? (
        <div className="row">
          <div className="form-group col-md-4">
            <p>
              <FormattedMessage id={translateRxOption("molars_ratio_right", molars_ratio_right)} />
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="form-group col-md-3">
            <PortletTerm id="molars-ratio-right-label">
              <FormattedMessage id="MOLAR_RIGHT" />
            </PortletTerm>

            <p id="molars-ratio-right-value">
              <FormattedMessage id={translateRxOption("molars_ratio_right", molars_ratio_right)} />
            </p>
          </div>

          <div className="form-group col-md-3">
            <PortletTerm id="molars-ratio-left-label">
              <FormattedMessage id="MOLAR_LEFT" />
            </PortletTerm>

            <p id="molars-ratio-left-value">
              <FormattedMessage id={translateRxOption("molars_ratio_left", molars_ratio_left)} />
            </p>
          </div>

          {molars_method_ai ? (
            <div className="form-group col-lg-4 col-sm-6">
              <PortletTerm id="molars-ratio-howto-label">
                <FormattedMessage id="MOLAR_HOWTO" />
              </PortletTerm>

              <p style={{ marginTop: "10px" }}>
                <FormattedMessage id={translateRxOption("molars_method_ai", molars_method_ai)} />
              </p>
            </div>
          ) : (
            <>
              <div className="form-group col-md-3">
                <PortletTerm id="molars-ratio-howto-label">
                  <FormattedMessage id="MOLAR_HOWTO" />
                </PortletTerm>

                <p id="molars-ratio-howto-value">
                  <FormattedMessage id={translateRxOption("molars_method", molars_method)} />
                </p>
              </div>

              {molars_ipr ? (
                <div className="form-group col-lg-3 col-sm-5">
                  <p style={{ marginTop: "10px" }}>
                    <FormattedMessage id={translateRxOption("molars_ipr", molars_ipr)} />
                  </p>
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
}
