import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { Loader } from "~/components/ui/loader";
import { API_MANUALS_GET } from "../../config";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import { Layout } from "../ui/layout";
import { Portlet, PortletTitle } from "../ui/portlet";
import ManualsCard from "./manuals_card";

const style = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  maxWidth: "900px",
  width: "100%",
  height: "100%",
} as const;

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

type ManualsListProps = PropsFromRedux & { intl: IntlShape };

type ManualsListState = {
  loading: boolean;
  manuals: TManual[];
};

type TManual = {
  filename: string;
  manual_id: number;
  source_type: 1 | 2;
  thumbnail: string;
  title: string;
  url: string;
};

class ManualsPage extends Component<ManualsListProps, ManualsListState> {
  constructor(props: ManualsListProps) {
    super(props);
    this.state = {
      manuals: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    fetch(API_MANUALS_GET, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch manuals");
        }
        return response.text();
      })
      .then((response) => JSON.parse(response))
      .then((json) => this.setState({ manuals: json, loading: false }))
      .catch(() => this.setState({ loading: false }));

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }
    setDocumentTitle(this.props.intl.formatMessage({ id: "manuals.title" }));
  }

  renderCards(manuals: TManual[]) {
    const { loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (manuals && Array.isArray(manuals) && manuals.length > 0) {
      return manuals.map((manual) => <ManualsCard manual={manual} key={manual.manual_id} />);
    }

    return <FormattedMessage id="manuals.empty.query" />;
  }

  render() {
    return (
      <Layout>
        <Portlet as="main">
          <PortletTitle iconClassName="icon-users" id="manual-list-page">
            <FormattedMessage id="manuals.title" />
          </PortletTitle>

          <div className="portlet-body">
            <div className="instructions-block" style={style}>
              {this.renderCards(this.state.manuals)}
            </div>
          </div>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(ManualsPage));
