import React, {Component} from 'react';
import {connect} from "react-redux";
import { remoteLog } from "../../common/logging";
import {FormattedMessage} from "react-intl";
import {get3ShapeCases} from "../../actions/3shape";
import {getPatientId} from "../../actions/get_patient_id";
import {deployedUSA} from "../../common/utils";
import DatePicker from 'react-datepicker'
import {formatDate, DATE_FORMAT} from "../../common/datetime";
import Wrapper from "../hoc/main_wrapper";
import {NavLink} from "react-router-dom";
import { Loader } from "~/components/ui/loader";


const mapStateToProps = (state) => {
    return {
        patient: state.patient,
        user: state.user,
        cases: state.threeShapeCases,
        spinner: state.spinner,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientId: (patient_id, calls) => dispatch(getPatientId(patient_id, false)),
        getCases: (patient_id, page, from, to, searchString) => dispatch(get3ShapeCases(patient_id, page, from, to, searchString))
    }
}


class ThreeShapePidCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            from: null,
            to: null,
            searchString: null,
            page: 1,
            perPage: 10,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            upperPageBound: 3,
            lowerPageBound: 0,
        }

        this.submit = this.submit.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.btnDecrementClick = this.btnDecrementClick.bind(this);
        this.btnIncrementClick = this.btnIncrementClick.bind(this);
        this.btnIncrementClick = this.btnIncrementClick.bind(this);
        this.btnNextClick = this.btnNextClick.bind(this);
        this.btnPrevClick = this.btnPrevClick.bind(this);
        this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    }

    componentDidCatch(e) {
        remoteLog(e, '3shape_pid_cases');
    }

    componentDidMount() {
        if (this.props.user && this.props.user.privileges) {
            if (!this.props.user.privileges.has_3shape_scanner) {
                this.props.history.push('/pages/patients');
            }
            this.props.getPatientId(this.props.match.params.patient_id);
            this.props.getCases(this.props.match.params.patient_id, 0, this.state.from, this.state.to, this.state.searchString);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.user.privileges) {
            if (!this.props.user.privileges.has_3shape_scanner) {
                this.props.history.push('/pages/patients');
            }
            if (prevProps.cases && prevProps.cases.cases && Object.keys(prevProps.cases.cases).length === 0) {
                if (prevProps.user && Object.keys(prevProps.user).length === 0) {
                    this.props.getPatientId(this.props.match.params.patient_id);
                    this.props.getCases(this.props.match.params.patient_id, 0, this.state.from, this.state.to, this.state.searchString);
                }
            }
        }
    }

    onChangeStartDate(e) {
        this.setState({startDate: e && e._d ? formatDate(e._d) : null})
        this.setState({from: e && e._d ? formatDate(e._d)._i : null})
    }

    onChangeEndDate(e) {
        this.setState({endDate: e && e._d ? formatDate(e._d) : null})
        this.setState({to: e && e._d ? formatDate(e._d)._i : null})
    }

    handleFilterChange(str) {
        if (str.keyCode === 32) {
            return false;
        } else {
            const string = str.target.value.trimEnd().trimStart().toString().toLowerCase();
            if (str.target.value.length === 0) {
                this.setState({searchString: false})
            } else {
                this.setState({searchString: string})
            }
        }
    }

    handleEnterSearch(event) {
        if (event.key === 'Enter') {
            const str = event.target.value.trimEnd().trimStart();
            if (str === '') {
                this.setState({searchString: false})
            } else {
                this.setState({searchString: str})
            }
            setTimeout(() => {
                this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
            })
        }
    }

    submit() {
        this.setState({lowerPageBound: 0})
        this.setState({upperPageBound: 3})
        this.setState({page: 1})
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
        })
    }

    clear() {
        this.setState({
            startDate: null,
            endDate: null,
            from: null,
            to: null,
            searchString: null,
            page: 1,
            perPage: 10,
            isNextBtnActive: '',
            upperPageBound: 3,
            lowerPageBound: 0
        })
        document.getElementById('patient-cases-form-input').value = ''
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
        })
    }

    handleClick(event) {
        let listed = Number(event.target.id);
        this.setState({
            page: listed
        });
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
        })
        $("ul#paginate li.active").removeClass('active');
        $('ul li#' + listed).addClass('active');
        this.setPrevAndNextBtnClass(listed);
    }

    setPrevAndNextBtnClass(listed) {
        let totalPage = Math.ceil(this.props.cases.count / this.state.perPage);
        this.setState({isNextBtnActive: 'disabled'});
        this.setState({isPrevBtnActive: 'disabled'});
        if (totalPage === listed && totalPage > 1) {
            this.setState({isPrevBtnActive: ''});
        } else if (listed === 1 && totalPage > 1) {
            this.setState({isNextBtnActive: ''});
        } else if (totalPage > 1) {
            this.setState({isNextBtnActive: ''});
            this.setState({isPrevBtnActive: ''});
        }
    }

    btnIncrementClick() {
        this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
        let listed = this.state.upperPageBound + 1;
        this.setState({page: listed});
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);

        })
        this.setPrevAndNextBtnClass(listed);
    }

    btnDecrementClick() {
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
        let listed = this.state.upperPageBound - this.state.pageBound;
        this.setState({page: listed});
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
        })
        this.setPrevAndNextBtnClass(listed);
    }

    btnPrevClick() {
        if ((this.state.page - 1) % this.state.pageBound === 0) {
            this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
            this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
        }
        let listed = this.state.page - 1;
        this.setState({page: listed});
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
        })
        this.setPrevAndNextBtnClass(listed);
    }

    btnNextClick() {
        if ((this.state.page + 1) > this.state.upperPageBound) {
            this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
            this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
        }
        let listed = this.state.page + 1;
        this.setState({page: listed});
        setTimeout(() => {
            this.props.getCases(this.props.match.params.patient_id, this.state.page, this.state.from, this.state.to, this.state.searchString);
        })
        this.setPrevAndNextBtnClass(listed);
    }

    render() {
        const {page, perPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive} = this.state;
        const {cases, count} = this.props.cases;
        const patientName = deployedUSA() ? `${this.props.patient.first_name} ${this.props.patient.last_name} ${this.props.patient.patient_id}` : `${this.props.patient.last_name} ${this.props.patient.first_name} ${this.props.patient.patient_id}`

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(count / perPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            if (number === 1 && page === 1) {
                return (
                    <li key={number} className='active' id={number}><a href='#' id={number}
                                                                       onClick={this.handleClick}>{number}</a></li>
                )
            } else if ((number < upperPageBound + 1) && number > lowerPageBound) {
                return (
                    <li key={number} id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                )
            }
        });
        let pageIncrementBtn = null;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = <li className=''><a href="#" onClick={this.btnIncrementClick}> &hellip; </a></li>
        }
        let pageDecrementBtn = null;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = <li className=''><a href="#" onClick={this.btnDecrementClick}> &hellip; </a></li>
        }
        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn =
                <li className={isPrevBtnActive}><span id="btnPrev"><i className="fa fa-angle-left"/></span></li>
        } else {
            renderPrevBtn = <li className={isPrevBtnActive}><a href='#' id="btnPrev" onClick={this.btnPrevClick}><i
                className="fa fa-angle-left"/></a></li>
        }
        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled' || pageNumbers.length === 1) {
            renderNextBtn =
                <li className={pageNumbers.length === 1 ? 'disabled' : isNextBtnActive}><span id="btnNext"><i
                    className="fa fa-angle-right"/></span></li>
        } else {
            renderNextBtn = <li className={isNextBtnActive}><a href='#' id="btnNext" onClick={this.btnNextClick}><i
                className="fa fa-angle-right"/></a></li>
        }

        return (
            <div>
                <div>
                    <div className="page-content-wrapper">
                        <div className="page-content" id="patient-cases-table">
                            <div className="portlet light bordered">
                                <div className="portlet-title">
                                    <div className="caption">
                                        <i className="icon-users font-green"/>
                                        <span data-matomo-mask data-hj-suppress className="caption-subject font-green bold uppercase"
                                              id="patient-table-block-title">
                                            {this.props.patient.patient_id ?
                                                (deployedUSA() ? `${this.props.patient.first_name} ${this.props.patient.last_name} ${this.props.patient.patient_id}`
                                                    : `${this.props.patient.last_name} ${this.props.patient.first_name} ${this.props.patient.patient_id}`)
                                                : null}
                                    </span>
                                    </div>
                                </div>
                                <div data-matomo-mask data-hj-suppress>
                                    <label className="control-label" style={{fontWeight: 600}}><i><FormattedMessage
                                        id="3shape.patient.pick"/>&#160;{this.props.patient.patient_id ? patientName : null}
                                    </i></label>
                                </div>
                                <div className="portlet-body">
                                    <div id="patient-cases_table_wrapper"
                                         className="dataTables_wrapper no-footer">
                                        <div className="row">
                                            <div className="col-md-3 col-xs-8">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat={DATE_FORMAT}
                                                    selected={this.state.startDate}
                                                    onChange={(e) => this.onChangeStartDate(e)}
                                                    startDate={this.state.start}
                                                    placeholderText={deployedUSA() ? 'From' : 'С'}
                                                    locale={deployedUSA() ? 'en-US' : 'ru-RU'}
                                                />
                                            </div>
                                            <div className="col-md-3 col-xs-8">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat={DATE_FORMAT}
                                                    selected={this.state.endDate}
                                                    onChange={(e) => this.onChangeEndDate(e)}
                                                    endDate={this.state.end}
                                                    minDate={new Date()}
                                                    placeholderText={deployedUSA() ? 'To' : 'По'}
                                                />
                                            </div>
                                            <div data-matomo-mask data-hj-suppress className="col-md-3 col-xs-8">
                                                <input name="search"
                                                       maxLength="128"
                                                       onKeyUp={(e) => this.handleFilterChange(e)}
                                                       type="search" className="form-control"
                                                       aria-controls="patient-cases_table"
                                                       id="patient-cases-form-input"
                                                       placeholder={deployedUSA() ? 'Search' : 'Поиск'}
                                                       style={{height: "34px"}}
                                                       onKeyDown={(e) => this.handleEnterSearch(e)}/>
                                            </div>
                                            <div className="col-md-1 col-xs-1">
                                                <button
                                                  style={{backgroundColor: "#efefef", marginLeft: "-16px"}}
                                                  type="submit"
                                                  onClick={this.submit}
                                                  className="btn btn-circle"
                                                  title={deployedUSA() ? 'Search' : 'Поиск'}
                                                >
                                                  <span className="glyphicon glyphicon-search"></span>
                                                </button>
                                            </div>
                                            <div className="col-md-1 col-xs-2">
                                                <button
                                                  style={{backgroundColor: "#efefef"}}
                                                  type="reset"
                                                  onClick={this.clear}
                                                  className="btn btn-circle"
                                                  title={deployedUSA() ? 'Clear' : 'Очистить'}
                                                >
                                                  <span className="glyphicon glyphicon-refresh"></span>
                                                </button>
                                            </div>
                                        </div>
                                        {!this.props.spinner ?
                                            <div data-matomo-mask data-hj-suppress className="table-responsive">
                                                <table
                                                    className="table table-bordered table-hover dataTable no-footer"
                                                    id="patient-cases_table" role="grid">
                                                    <thead id="patient-cases-head">
                                                    <tr role="row">
                                                        <th><FormattedMessage id="th.3shape.patientName"/></th>
                                                        <th><FormattedMessage id="th.3shape.threeShapeOrderNo"/>
                                                        </th>
                                                        <th><FormattedMessage id="th.3shape.receivedOn"/></th>
                                                        <th><FormattedMessage id="th.3shape.version"/></th>
                                                    </tr>
                                                    </thead>

                                                    <tbody id="patient-cases-body">
                                                    {
                                                        cases.map((c, index) => {
                                                            return (
                                                                <tr key={`cases${index}`}>
                                                                    <td><NavLink
                                                                        to={`/pages/3shape/${this.props.patient.patient_id}/cases/${c.Id}`}>{c.PatientName}</NavLink>
                                                                    </td>
                                                                    <td>{c.ThreeShapeOrderNo}</td>
                                                                    <td>{c.ReceivedOn.split('T')[0]}</td>
                                                                    <td>{c.Version}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                            : <Loader/>
                                        }
                                        {cases.length === 0 && !this.props.spinner ?
                                            <div style={{textAlign: 'center'}}><FormattedMessage
                                                id="3shape.search.message"/></div> : null}
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="dataTables_paginate paging_bootstrap_number"
                                                     id="patient_cases_table_paginate">
                                                    <ul className="pagination" id="paginate"
                                                        style={{visibility: 'visible'}}>
                                                        {renderPrevBtn}
                                                        {pageDecrementBtn}
                                                        {renderPageNumbers}
                                                        {pageIncrementBtn}
                                                        {renderNextBtn}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(ThreeShapePidCases));
