import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type URLSearchParamsInit = ConstructorParameters<typeof URLSearchParams>[0];

type SetURLSearchParams = (
  nextInit: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOptions?: { replace?: boolean },
) => void;

/**
 * A convenient wrapper for reading and writing search parameters via the
 * URLSearchParams interface.
 *
 * NOTE: this hook ships with `react-router-dom` v6, so make sure to remove this
 * implementation after migration.
 */
export function useSearchParams(): [URLSearchParams, SetURLSearchParams] {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const history = useHistory();
  const setSearchParams = useCallback<SetURLSearchParams>(
    (nextInit, navigateOptions = {}) => {
      const newSearchParams = new URLSearchParams(
        typeof nextInit == "function" ? nextInit(searchParams) : nextInit,
      );
      if (navigateOptions.replace) {
        history.replace({ search: newSearchParams.toString(), hash: history.location.hash });
      } else {
        history.push({ search: newSearchParams.toString(), hash: history.location.hash });
      }
    },
    [history, searchParams],
  );

  return [searchParams, setSearchParams];
}
