import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenCourse } from "~/common/courses";
import {
  IncisorsVerticalOverlap,
  Midline,
  Overjet,
  translateRxOption,
} from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";
import type { TPrescription } from "~/types/prescription";

export function PatientCardIncisors({
  course_id,
  prescription,
}: {
  course_id: TPatient["course"]["course_id"];
  prescription: TPrescription;
}) {
  const {
    vertical_overlap,
    vertical_overlap_comment,
    upper_horizontal_overlap,
    lower_horizontal_overlap,
    overjet,
    correct_retroclination_upper,
    correct_proclination_lower,
    correct_proclination_upper,
    correct_retroclination_lower,
    midline,
    midline_improve_by,
  } = prescription;

  return (
    <div>
      <h4 id="incisors-section" className="block" style={{ fontWeight: "500" }}>
        <FormattedMessage id="HEADER_INCISORS" />
      </h4>

      <PortletTerm id="incisors-vert-overlap-label">
        <FormattedMessage id="INCISORS_VO" />
      </PortletTerm>

      <p id="incisors-vert-overlap-value">
        <FormattedMessage id={translateRxOption("vertical_overlap", vertical_overlap)} />
      </p>

      {vertical_overlap != IncisorsVerticalOverlap.MAINTAIN && vertical_overlap_comment ? (
        <div className="tw-ml-5">
          <PortletTerm id="rx-comment-label" italic semibold>
            <FormattedMessage id="VO_COMMENT" />:
          </PortletTerm>

          <p>{vertical_overlap_comment}</p>
        </div>
      ) : null}

      {isChildrenCourse(course_id) ? null : (
        <div>
          {upper_horizontal_overlap != null || lower_horizontal_overlap != null ? (
            <>
              <PortletTerm id="incisors-horiz-overlap-label">
                <FormattedMessage id="INCISORS_HO" />
              </PortletTerm>

              <div className="row">
                <div className="form-group col-md-4">
                  <PortletTerm>
                    <FormattedMessage id="INCISORS_HO_U" />
                  </PortletTerm>

                  <p id="incisors-upper-horiz-overlap-value">
                    <FormattedMessage
                      id={translateRxOption("upper_horizontal_overlap", upper_horizontal_overlap)}
                    />
                  </p>
                </div>

                <div className="form-group col-md-4">
                  <PortletTerm>
                    <FormattedMessage id="INCISORS_HO_L" />
                  </PortletTerm>

                  <p id="incisors-lower-horiz-overlap-value">
                    <FormattedMessage
                      id={translateRxOption("lower_horizontal_overlap", lower_horizontal_overlap)}
                    />
                  </p>
                </div>
              </div>
            </>
          ) : null}

          <div className="row">
            {overjet == Overjet.CORRECT ? (
              <div className="form-group col-md-8">
                <PortletTerm>
                  <FormattedMessage id="OVERJET" />
                </PortletTerm>

                <p id="overjet-value">
                  <FormattedMessage id={translateRxOption("overjet", overjet)} />
                </p>

                <ul id="overjet-value-options" style={{ marginBottom: "20px" }}>
                  {correct_retroclination_upper ? (
                    <li>
                      <FormattedMessage id="CORRECT_RETROCLINATION_UPPER" />
                    </li>
                  ) : null}

                  {correct_proclination_lower ? (
                    <li>
                      <FormattedMessage id="CORRECT_PROCLINATION_LOWER" />
                    </li>
                  ) : null}

                  {correct_proclination_upper ? (
                    <li>
                      <FormattedMessage id="CORRECT_PROCLINATION_UPPER" />
                    </li>
                  ) : null}

                  {correct_retroclination_lower ? (
                    <li>
                      <FormattedMessage id="CORRECT_RETROCLINATION_LOWER" />
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : (
              <div className="form-group col-md-4">
                <PortletTerm>
                  <FormattedMessage id="OVERJET" />
                </PortletTerm>

                <p id="overjet-value">
                  <FormattedMessage id={translateRxOption("overjet", overjet)} />
                </p>
              </div>
            )}
          </div>

          <PortletTerm id="midline-label">
            <FormattedMessage id="MIDLINE" />
          </PortletTerm>

          <div id="midline-value">
            <FormattedMessage id={translateRxOption("midline", midline)} />

            {midline == Midline.IMPROVE ? (
              <p className="tw-ml-5 tw-pt-4">
                {midline_improve_by}
              </p>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
