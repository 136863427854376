import { type SOArch, PaymentMethod } from "../common/constants";
import { deployedUSA } from "../common/utils";
import { API_POST_ADDITIONAL_ALIGNERS } from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { TPatient } from "~/types/patient";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from "./user_notification";

export function orderAdditionalAligners(
  patient_id: TPatient["patient_id"],
  stage: number,
  amount: number,
  treat_arch_id: SOArch,
  elastics_left: number,
  elastics_right: number,
  paymentMethod: PaymentMethod,
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.additional.aligners.ok",
    level: "success",
    position: "tl",
    autoDismiss: 7,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.additional.aligners.error",
    level: "error",
    position: "tl",
    autoDismiss: 7,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_POST_ADDITIONAL_ALIGNERS(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stage: stage,
        amount: amount,
        treat_arch_id: treat_arch_id,
        elastics_left: elastics_left,
        elastics_right: elastics_right,
      }),
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to order additional aligners!");
        }
        return response;
      })
      .then(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        if (deployedUSA() && paymentMethod === PaymentMethod.CARD) {
          window.location = "/pages/payments";
        } else {
          window.location = "/pages/patients";
        }
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}
