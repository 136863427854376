import { AnimatePresence } from 'framer-motion';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import type { RxType } from '~/common/constants';
import {
  Case,
  CO_TAG_FULL_3_STEPS_CBCT,
  CO_TAG_TEEN_3_STEPS_CBCT,
  COURSE_VERSION_V2,
  is3DPROCourse,
  isCBCTCourse,
  isRetainersCourse,
  Status,
} from '~/common/courses';
import { remoteLog } from '~/common/logging';
import { getLastCorrection } from '~/common/patient';
import { Arch } from '~/common/prescription';
import { canOrderTestPlastic } from '~/common/user';
import { addInstructions, removeInstructions } from '~/slices/instructions';
import type { RootState } from '~/store';
import type { TNone } from '~/types/common';
import type { TPatient } from "~/types/patient";

import ImpressionScanOptions from '../impression_scan_options';
import {
  ChildrenShortTooltip,
  ChildrenV3Tooltip,
  NewBadge,
  ScanUploadCTWarning,
} from '../patient_new/patient_new_course';
import { TestPlaticOptions } from '../test_plastic_options';

const smile_link = "/api/v1/manuals/document/2"
const predict_link = "/api/v1/manuals/document/51"

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    lang: state.intl,
    instructions: state.instructions,
    courseInstallment: state.courseInstallment,
    formValues: getFormValues('correction')(state)
  }
}

const mapDispatchToProps = {
  addInstruction: addInstructions,
  removeInstructions,
  change,
};

type PatientUpdateCourseProps = PropsFromRedux & {
  intl: IntlShape;
  rxTypeId: RxType;
  patient: TPatient;
  setDirty(key: string, data: unknown): void;
};

class PatientUpdateCourse extends Component<PatientUpdateCourseProps> {
  constructor(props: PatientUpdateCourseProps) {
    super(props);
    this.addCondition = this.addCondition.bind(this);
    this.selectCourse = this.selectCourse.bind(this);
    this.selectDeepCBCTAnalysis = this.selectDeepCBCTAnalysis.bind(this);
    this.addComment = this.addComment.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, 'patient_update_course');
  }

  componentDidMount() {
    const { patient } = this.props;

    if (patient.course.course != "None") {
      const courseStrToId: Record<Exclude<TPatient["course"]["course"], TNone>, Case> = {
        C_FULL: Case.FULL,
        C_SHORT: Case.SHORT,
        C_ONE_JAW: Case.SINGLE_ARCH,
        C_SUPER_SHORT: Case.SUPER_SHORT,
        C_UNLIMITED: Case.CBCT,
        C_3D_PLAN: Case.PLAN_3D,
        C_CHILDREN: Case.CHILDREN,
        C_RETAINERS: Case.RETAINERS,
        C_3DSMILE_PRO: Case.PRO,
        C_TEEN: Case.TEEN,
        C_CHILDREN_SHORT: Case.CHILDREN_SHORT,
      };
      this.selectCourse(courseStrToId[patient.course.course]);
    }

    if (patient && patient.deep_cbct
      || (patient.course && [CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT].includes(patient.course.course_option_tag))
    ) {
      this.props.addInstruction({ deep_cbct: true });
    }

    this.props.addInstruction({ condition: patient.diagnosis });
    const lastCorrection = getLastCorrection(patient);

    if (canOrderTestPlastic(this.props.user) && lastCorrection.test_plastic != null) {
      this.props.addInstruction({ test_plastic: lastCorrection.test_plastic });
    }
  }

  selectCourse(data: Case | string | undefined) {
    if (data == undefined) {
      return;
    }

    const course_id = parseInt(data);

    if (this.props.instructions && this.props.instructions.course_id !== course_id) {
      const isCourseInstallmentLoaded = Object.keys(this.props.courseInstallment).length > 0;

      if (
        isCourseInstallmentLoaded &&
        this.props.instructions.payment_option_id &&
        !(this.props.instructions.payment_option_id in (this.props.courseInstallment[course_id as Case] ?? {}))
      ) {
        const { can_edit_payment_option } = this.props.patient;

        if (can_edit_payment_option) {
          this.props.addInstruction({ payment_option_id: null });
        }
      }
    }
    this.props.addInstruction({ 'course_id': course_id });
    if (!isNaN(course_id)) {
      this.props.setDirty('course_id', course_id);
    }

    const correctionFormValues = this.props.formValues;
    if (correctionFormValues && correctionFormValues.arch && correctionFormValues.arch == Arch.BOTH) {
      if (course_id == Case.SINGLE_ARCH || course_id == Case.SUPER_SHORT) {
        this.props.change('correction', 'arch', null);
      }
    }
    if (isCBCTCourse(course_id) || isRetainersCourse(course_id)) {
      this.props.addInstruction({ 'deep_cbct': false });
    }
    if (this.props.instructions && course_id == Case.RETAINERS) {
      this.props.addInstruction({ 'sber_credit': false });
    }
  }

  selectDeepCBCTAnalysis(data) {
    this.props.addInstruction({ 'deep_cbct': Boolean(data.checked) });
    this.props.setDirty('deep_cbct', Boolean(data.checked));
  }

  addCondition(data) {
    this.props.addInstruction({ 'condition': data });
    this.props.setDirty('diagnosis', data);
  }

  addComment(data) {
    this.props.change('correction', 'comment', data);
  }

  render() {
    const { patient } = this.props;
    const lastCorrection = getLastCorrection(patient);
    const locale = this.props.lang.locale;
    const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id: id });

    const cnamePostfix = (locale === 'ru' && patient && patient.course_version == COURSE_VERSION_V2) ? "_V2" : "";

    let course_id = null;
    let show_cbct_option = false;

    if (this.props.instructions && Object.keys(this.props.instructions).length > 0) {
      course_id = this.props.instructions.course_id || null;
      show_cbct_option = course_id !== null && !isCBCTCourse(course_id) && !isRetainersCourse(course_id);
    }
    let comment = null;
    if (lastCorrection.prescription !== null) {
      comment = lastCorrection.prescription.comment;
    }
    const isNotChildrenOrChildrenV3 =
      patient.course.course_id != Case.CHILDREN || patient.course.course_option_tag == "CHILDREN_V3";

    const showTestPlasticOptions =
      canOrderTestPlastic(this.props.user) &&
      (patient.status == Status.UNFILLED_CASE || lastCorrection.test_plastic != null);

    return (
      <div id="course-section">
        <h3 className="block" style={{ fontWeight: '900' }}>
          <FormattedMessage id="BLOCKHEAD_INSTRUCTIONS" />
        </h3>
        <div className="form-group">
          <span className="control-label" id="validation-course_id" style={{ fontWeight: '900', fontSize: '17px', marginBottom: '10px', display: 'inline-block' }}>
            <FormattedMessage id="HEADER_INSTRUCTIONS" />
            <span style={{ marginRight: "4px" }} className="required" aria-required="true">*</span>
            <span className='prescription_tooltip_show'>
              <i style={{fontSize: "14px"}} className='icon-question' />
              <span className="d-inline-block prescription_tooltip">
                <FormattedMessage id="course.type.tooltip" />
                <a href={window.location.hostname.endsWith(".ru") ? smile_link : predict_link} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage id="tooltip.chart" />
                </a>
                <br />
                <FormattedMessage id="course.type.tooltip.sec" />
              </span>
            </span>
          </span>

        <div className="radio-list">
          <label id="course-label-5">
            <div className="radio">
              <input
                type="radio"
                id="course-value-5"
                name="course_id"
                value={Case.CBCT}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.CBCT}
              />
            </div>
            <div className="course_title">
              <img src="/img/deepcbct.png" alt="cbct-icon" style={{ width: '80px', height: '42px' }} />
              <br /><FormattedMessage id={`C_UNLIMITED${cnamePostfix}`} /><FormattedMessage id={`C_UNLIMITED_COMMENT${cnamePostfix}`} />
            </div>
          </label>

          {locale == 'ru' && patient.status != Status.UNFILLED_CASE && is3DPROCourse(patient.course.course_id) ?
            (
              <label id="course-label-9" className="tool_tip">
                <div className="radio">
                  <input
                    className="radio"
                    type="radio"
                    id="course-value-9"
                    name="course_id"
                    value={Case.PRO}
                    onChange={(e) => this.selectCourse(e.target.value)}
                    defaultChecked={patient.course.course_id == Case.PRO}
                  />
                </div>
                <div className="course_title">
                  <img src="/img/pro_smile_button.svg" alt="pro-icon" style={{ width: '105px' }} />
                  <br /><FormattedMessage id='C_3DSMILE_PRO_COMMENT' />
                </div>
              </label>
            ) : null
          }

          <label id="course-label-1">
            <div className="radio">
              <input
                type="radio"
                id="course-value-1"
                name="course_id"
                value={Case.FULL}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.FULL}
              />
            </div>
            <div className="course_title">
              <span className={course_id === Case.FULL ? "boldText" : undefined}>
                <FormattedMessage id={`C_FULL${cnamePostfix}`} />
              </span>
              <FormattedMessage id={`C_FULL_COMMENT${cnamePostfix}`} />
            </div>
          </label>

          {patient.can_set_teen_course === true
            ? (
              <label id="course-label-10">
                <div className="radio">
                  <input
                    type="radio"
                    id="course-value-10"
                    name="course_id"
                    value={Case.TEEN}
                    onChange={(e) => this.selectCourse(e.target.value)}
                    defaultChecked={patient.course.course_id == Case.TEEN}
                  />
                </div>
                <div className="course_title">
                  <span className={course_id === Case.TEEN ? "boldText" : undefined}>
                    <FormattedMessage id={`C_TEEN${cnamePostfix}`} />
                  </span>
                  <FormattedMessage id={`C_TEEN_COMMENT${cnamePostfix}`} />
                </div>
              </label>
            ) : null
          }

          <label id="course-label-2">
            <div className="radio">
              <input
                type="radio"
                id="course-value-2"
                name="course_id"
                value={Case.SHORT}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.SHORT}
              />
            </div>
            <div className="course_title">
              <span className={course_id === Case.SHORT ? "boldText" : undefined}>
                <FormattedMessage id={`C_SHORT${cnamePostfix}`} />
              </span>
              <FormattedMessage id={`C_SHORT_COMMENT${cnamePostfix}`} />
            </div>
          </label>

          <label id="course-label-3">
            <div className="radio">
              <input
                type="radio"
                id="course-value-3"
                name="course_id"
                value={Case.SINGLE_ARCH}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.SINGLE_ARCH}
              />
            </div>
            <div className="course_title">
              <span className={course_id === Case.SINGLE_ARCH ? "boldText" : undefined}>
                <FormattedMessage id={`C_ONE_JAW${cnamePostfix}`} />
              </span>
              <FormattedMessage id={`C_ONE_JAW_COMMENT${cnamePostfix}`} />
            </div>
          </label>

          <label id="course-label-4">
            <div className="radio">
              <input
                type="radio"
                id="course-value-4"
                name="course_id"
                value={Case.SUPER_SHORT}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.SUPER_SHORT}
              />
            </div>
            <div className="course_title">
              <span className={course_id === Case.SUPER_SHORT ? "boldText" : undefined}>
                <FormattedMessage id={`C_SUPER_SHORT${cnamePostfix}`} />
              </span>
              <FormattedMessage id={`C_SUPER_SHORT_COMMENT${cnamePostfix}`} />
            </div>
          </label>

          <label id="course-label-6">
            <div className="radio">
              <input
                type="radio"
                id="course-value-6"
                name="course_id"
                value={Case.PLAN_3D}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.PLAN_3D}
              />
            </div>
            <div className="course_title">
              <span className={course_id === Case.PLAN_3D ? "boldText" : undefined}>
                <FormattedMessage id={`C_3D_PLAN${cnamePostfix}`} />
              </span>
            </div>
          </label>

          <label id="course-label-7">
            <div className="radio">
              <input
                type="radio"
                id="course-value-7"
                name="course_id"
                value={Case.CHILDREN}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.CHILDREN}
              />
            </div>
            <div className="course_title">
              {isNotChildrenOrChildrenV3 ? <NewBadge /> : null}
              <span className={course_id === Case.CHILDREN ? "boldText" : undefined}>
                <FormattedMessage
                  id={`C_CHILDREN${isNotChildrenOrChildrenV3 ? "_V3" : cnamePostfix}`}
                />
              </span>
              <FormattedMessage
                id={`C_CHILDREN_COMMENT${isNotChildrenOrChildrenV3 ? "_V3" : cnamePostfix}`}
              />
              {isNotChildrenOrChildrenV3 ? <ChildrenV3Tooltip /> : null}
            </div>
          </label>

          <label id="course-label-11">
            <div className="radio">
              <input
                type="radio"
                id="course-value-11"
                name="course_id"
                value={Case.CHILDREN_SHORT}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course?.course_id == Case.CHILDREN_SHORT}
              />
            </div>
            <div className="course_title">
              <NewBadge />
              <span className={course_id === Case.CHILDREN_SHORT ? "boldText" : undefined}>
                <FormattedMessage id="C_CHILDREN_SHORT_V1" />
              </span>
              <FormattedMessage id="C_CHILDREN_SHORT_COMMENT_V1" />
              <ChildrenShortTooltip />
            </div>
          </label>

          <label id="course-label-8">
            <div className="radio">
              <input
                type="radio"
                id="course-value-8"
                name="course_id"
                value={Case.RETAINERS}
                onChange={(e) => this.selectCourse(e.target.value)}
                defaultChecked={patient.course.course_id == Case.RETAINERS}
              />
            </div>
            <div className="course_title">
              <span className={course_id === Case.RETAINERS ? "boldText" : undefined}>
                <FormattedMessage id='C_RETAINERS' />
              </span>
              <FormattedMessage id='C_RETAINERS_COMMENT' />
            </div>
          </label>
        </div>

        <AnimatePresence>
          {course_id === Case.CBCT || patient.deep_cbct === true ? <ScanUploadCTWarning /> : null}
        </AnimatePresence>

          <div id="form_course_error" />
        </div>

        <div className="form-group">
          <label className="control-label" id="validation-condition" style={{ fontWeight: '900' }}>
            <FormattedMessage id="HEADER_CONDITION" />
          </label>
          <textarea
            data-matomo-mask
            className="form-control"
            id="patient-diagnosis-value"
            rows={5}
            name="diagnos"
            placeholder={fm("CONDITION_PLACEHOLDER")}
            defaultValue={patient.diagnosis}
            onChange={(e) => this.addCondition(e.target.value)}
          />
        </div>

        <ImpressionScanOptions />

        {showTestPlasticOptions ? (
          <TestPlaticOptions
            value={this.props.instructions.test_plastic}
            onValueChange={(testPlastic) => this.props.addInstruction({ test_plastic: testPlastic })}
          />
        ) : null}

        {show_cbct_option
          ? (
            <div id="deep-cbct-section">
              <h5 className="block" id='deep-cbct-label' style={{ fontWeight: '900' }}>
                <FormattedMessage id="ADDITIONAL_INSTRUCTIONS" />
              </h5>
              <label style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <input
                  type="checkbox"
                  id="deep-cbct-value-1"
                  name="deep_cbct"
                  value={1}
                  style={{ margin: 0 }}
                  disabled={
                    patient.status != Status.UNFILLED_CASE
                    && (
                      patient.deep_cbct == true
                      || (patient.course && [CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT].includes(patient.course.course_option_tag))
                    )
                  }
                  onClick={(e) => this.selectDeepCBCTAnalysis(e.target)}
                  defaultChecked={
                    patient.deep_cbct || (
                      patient.course && [CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT].includes(patient.course.course_option_tag)
                    )
                  }
                />
                <FormattedMessage id="C_DEEP_CBCT_ANALYSIS_ITEM" />
              </label>
              <br />
              <br />
            </div>
          ) : null
        }

      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdateCourse));
