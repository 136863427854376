import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";

import paymentPopup from "../../assets/img/wpb-popup-ico-4.png";
import { deployedRussia } from "../../common/utils";


export const paymentNotification = () => {
    return (
      <div>
          <PaymentNotificationComponent />
      </div>
    )
}

const redirectToPatients = () => {
        window.location = '/pages/payments';
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        lang: state.intl,
    }
}

export class PaymentNotification extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { last_name } = this.props.user;
        const locale = this.props.lang.locale;
        return (
          <div style={{visibility: 'visible'}} className="wpb-popup-wr">
              <div onClick={() => redirectToPatients()} className="wpb-popup wpb-popup--big">
                  <a 
                    onClick={() => _paq.push(['trackEvent', 'notification', 'payment success'])}  
                    href="#" 
                    className="wpb-popup__close">
                  </a>
                  <div className="wpb-popup__ico">
                      <img src={paymentPopup} width="275" height="218" alt="" />
                  </div>
                  <div className="wpb-popup__body">
                      <p><FormattedMessage id="pat.payments.dear.doctor" />
                          {deployedRussia() === true && locale === 'ru'
                            ? null
                            : <span>{last_name}</span>}
                      </p>
                      <p>
                          <strong><FormattedMessage id="pat.payments.thanks" /></strong>
                          <FormattedMessage id="pat.payments.payment" />
                      </p>
                      <div className="wpb-popup__btn-ok">
                          <button onClick={() => redirectToPatients()}><FormattedMessage id="notify.done" /></button>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}


const PaymentNotificationComponent = connect(mapStateToProps)(PaymentNotification);



