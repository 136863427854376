import { API_REMINDER } from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from "./user_notification";

export function sendPassword(login: string | null, locale: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_REMINDER, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ login_or_email: login, locale: locale }),
      });
      if (!response.ok) {
        throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
      }
      const NOTIFICATION_MSG_SUCCESS: TUserActionNotification = {
        message: "notify.send_password.SENDING_RESTORE_SUCCESS",
        level: "info",
        position: "tc",
        autoDismiss: 5,
      };
      dispatch(addUserActionNotification(NOTIFICATION_MSG_SUCCESS));
      return { status: true };
    } catch {
      const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
        message: "notify.send_password.SENDING_ERROR",
        level: "error",
        position: "tc",
        autoDismiss: 5,
      };
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      return { status: false };
    }
  };
}
