import React, { createContext, useContext, useMemo, useState } from "react";

import { Layout } from "~/components/ui/layout";

import { Tab as EventListTab } from "./events-list";

// FIXME: no spacings on mobile between the main content and patient menu when opened
export default function EventLayout({ children }: { children: React.ReactElement }) {
  return (
    // overflow-visible is required for tabs triggers to become sticky
    // had to use negative margins to mitigate the padding set by light.min.css with higher specificty
    <Layout contentClassName="tw-overflow-visible max-md:-tw-my-7">
        <div className="row">
          {/* NOTE: With horizontal padding we don't have enough space for cards on IPhone SE */}
          <div className="col-md-12 max-md:tw-px-3 max-sm:tw-px-0">
            {/* NOTE: if you put shared <main> here, events tabs trigger
              framer motion animation starts sliding from the top. */}
            <EventsPagesProvider>{children}</EventsPagesProvider>
          </div>
        </div>
    </Layout>
  );
}

type UpcomingPages = Record<EventListTab, number>;

type EventsPagesContextValue = {
  upcomingPages: UpcomingPages;
  setUpcomingPages: React.Dispatch<React.SetStateAction<UpcomingPages>>;
};

const EventsPagesContext = createContext<EventsPagesContextValue | undefined>(undefined);

const initialUpcomingPages: UpcomingPages = {
  [EventListTab.SCHEDULE]: 1,
  [EventListTab.ENROLLED]: 1,
  [EventListTab.RECORDINGS]: 1,
};

function EventsPagesProvider({ children }: { children: React.ReactNode }) {
  const [upcomingPages, setUpcomingPages] = useState(initialUpcomingPages);

  const contextValue = useMemo(
    () => ({ upcomingPages, setUpcomingPages }),
    [upcomingPages,  setUpcomingPages],
  );

  return <EventsPagesContext.Provider value={contextValue}>{children}</EventsPagesContext.Provider>;
}

export function useUpcomingEventsPages() {
  const maybeContextValue = useContext(EventsPagesContext);

  if (!maybeContextValue) {
    throw new Error("useUpcomingEventsPages() should be used inside <EventsPagesProvider />");
  }

  const { upcomingPages, setUpcomingPages } = maybeContextValue;

  function setNextPage(tabValue: keyof UpcomingPages) {
    setUpcomingPages((prevPages) => ({ ...prevPages, [tabValue]: prevPages[tabValue] + 1 }));
  }

  function resetPages() {
    setUpcomingPages(initialUpcomingPages);
  }

  return { pages: upcomingPages, setNextPage, resetPages };
}
