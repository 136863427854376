import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Wrapper from "../hoc/main_wrapper";
import { Loader } from "~/components/ui/loader";
import { getPatientId } from "../../actions/get_patient_id";
import { deployedUSA } from "../../common/utils";
import {
    getThreeShapeCase, updateAttachesStatus, uploadThreeShapeFileToSmile
} from "../../actions/3shape";
import { addUserActionNotification } from '../../actions/user_notification';


const json = {
    message: "th.3shape.err",
    level: 'error',
    position: 'tl',
    autoDismiss: '9',
}


const mapDispatchToProps = (dispatch) => {
    return {
        getPatientId: (patient_id, calls) => dispatch(getPatientId(patient_id, false)),
        setErrMessage: () => dispatch(addUserActionNotification(json))
    }
}


const mapStateToProps = (state) => {
    return {
        patient: state.patient,
        user: state.user
    }
}


class ThreeShapeCase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Application: null,
            Attachments: [],
            Id: null,
            PatientName: null,
            ReceivedOn: null,
            ScanSource: null,
            Scans: [],
            ThreeShapeOrderNo: null,
            Version: null,
            attaches: [],
            clicked: {},
            loading: {},
            click: 0,
            timer: null,
            updatedAttaches: {}
        }
        this.getCases = this.getCases.bind(this);
    }

    componentDidMount() {
        this.props.getPatientId(this.props.match.params.patient_id);
        if (!this.props.user.privileges.has_3shape_scanner) {
            this.props.history.push('/pages/patients')
        } else {
            this.getCases();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Object.keys(prevProps.patient).length === 0 && Object.keys(this.props.patient).length > 0) {
            this.props.getPatientId(this.props.match.params.patient_id);
            if (!this.props.user.privileges.has_3shape_scanner) {
                this.props.history.push('/pages/patients')
            } else {
                this.getCases();
            }
        }

        if (this.state.click > prevState.click) {
            const timing = setTimeout(() => {
                updateAttachesStatus(this.props.match.params.patient_id)
                    .then(res => {
                        this.setState({
                            updatedAttaches: res
                        })
                        this.decrementCalls()
                    })
            }, this.state.click * 10000)
            this.setState({timing})
        } else {
            return false;
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timing)
    }

    getCases() {
        getThreeShapeCase(this.props.match.params.patient_id, this.props.match.params.case_id)
            .then((res) => {
                this.setState({...res})
            })
    }

    incrementCalls() {
        this.setState(state => {
            return {
                ...state.click,
                click: state.click + 1
            }
        })
    }

    decrementCalls() {
        this.setState(state => {
            if (state.click > 0) {
                return {
                    ...state.click,
                    click: state.click - 1
                }
            } else {
                return {
                    click: 0
                }
            }

        })
    }

    upload3ShapeFileToSmile(fileId) {
        this.setState({attaches: [fileId]})
        this.setState(prevState => {
            let loading = {...prevState.loading, [fileId]: true}
            return {
                loading
            }
        })
        setTimeout(() => {
            uploadThreeShapeFileToSmile(this.props.match.params.patient_id, this.props.match.params.case_id, this.state.attaches)
                .then(res => {
                    if (res.status === 200) {
                        this.incrementCalls();
                        this.setState(prevState => {
                            let clicked = {...prevState.clicked, [fileId]: true}
                            let loading = {...prevState.loading, [fileId]: false}
                            return {
                                clicked,
                                loading
                            }
                        })
                    } else if (res.status === 401) {
                        this.props.history.push(`/pages/patient/${this.props.match.params.patient_id}`)
                    } else {
                        this.props.setErrMessage();
                    }
                })
        })
    }


    render() {
        const {
            ThreeShapeOrderNo, PatientName, ScanSource, Attachments,
            clicked, loading, updatedAttaches
        } = this.state;

        return (
            this.state.Id !== null ?
            <div className="page-content-wrapper">
                <div className="page-content" style={{minHeight: 584}}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="portlet light bordered">
                                <div className="portlet-title">
                                    <div className="caption">
                                        <i className="icon-book-open font-green"/>
                                        <span data-matomo-mask data-hj-suppress className="caption-subject font-green bold uppercase">
                                            <NavLink to={`/pages/patient/${this.props.patient.patient_id}`}
                                                     target="_blank">
                                                {this.props.patient.patient_id ?
                                                    (deployedUSA() ? `${this.props.patient.first_name} ${this.props.patient.last_name} ${this.props.patient.patient_id}`
                                                        : `${this.props.patient.last_name} ${this.props.patient.first_name} ${this.props.patient.patient_id}`)
                                                    : null}
                                            </NavLink>
                                        </span>
                                    </div>
                                </div>
                                <div data-matomo-mask data-hj-suppress className="portlet-body">
                                    <h3 className="block" id="patient-info-label"><FormattedMessage
                                        id="3shape.patient.info"/></h3>
                                    <label className="control-label"><strong> <FormattedMessage
                                        id="th.3shape.threeShapeOrderNo"/> </strong></label>
                                    <p>{ThreeShapeOrderNo}</p>
                                    <label className="control-label"><strong> <FormattedMessage
                                        id="th.3shape.patientName"/> </strong></label>
                                    <p>{PatientName}</p>
                                    <label className="control-label"><strong> <FormattedMessage
                                        id="th.3shape.scan.source"/> </strong></label>
                                    <p>{ScanSource}</p>
                                    {this.state.click > 0 ?
                                        (<div className="col-xs-12">
                                            <div className="alert alert-warning" id="course-alert-warning"
                                                 style={{color: 'dimgrey'}}>
                                                <FormattedMessage id="3shape.upload.files.warning"/>
                                            </div>
                                        </div>)
                                        : null
                                    }

                                    <label className="control-label"><strong> <FormattedMessage
                                        id="th.3shape.files"/> </strong></label>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-bordered table-hover dataTable no-footer"
                                            id="patient-cases_table" role="grid">
                                            <thead id="patient-cases-head">
                                            <tr role="row">
                                                <th><FormattedMessage id="th.3shape.file.name"/></th>
                                                <th><FormattedMessage id="th.3shape.file.status"/></th>
                                            </tr>
                                            </thead>
                                            <tbody id="patient-cases-body">
                                            {Attachments.map((file, index) => {
                                                return (
                                                    <tr key={`files${index}`}>
                                                        <td>{file.Name}</td>
                                                        <td style={{textAlign: 'center'}}>{updatedAttaches[file.Id] || file.uploaded ?
                                                            <span><FormattedMessage
                                                                id="th.3shape.file.uploaded"/></span> :
                                                            <button
                                                                onClick={() => this.upload3ShapeFileToSmile(file.Id)}
                                                                disabled={clicked[file.Id]}
                                                                className="btn btn-link">
                                                                <FormattedMessage
                                                                    id="th.3shape.file.upload"/>
                                                                {loading[file.Id] ? <Loader/> : null}
                                                            </button>}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <Loader/>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(ThreeShapeCase));
