import {
    API_MEDIT_AUTH,
    API_MEDIT_PATIENT_CASES,
    API_MEDIT_CASE,
    API_MEDIT_PATIENTS,
    API_POST_PATIENT_MEDIT,
    API_POST_PATIENT_CONNECT,
    API_POST_PATIENT_DISCONNECT,
    API_POST_UPLOAD_FILE,
} from "../config";
import { patientIsSavingSuccess } from "./post_patient";


function fileUploadSuccess(bool) {
    return {
        type: 'FILE_UPLOAD_SUCCESSFULL',
        fileUploaded: bool
    }
}


export function meditAuth() {
    fetch(API_MEDIT_AUTH, {credentials: 'include'})
        .then((response) => {
            if (response.status === 401) {
                return response.text().then((res) => {
                    window.location = res;
                });
            } else if (response.status === 200) {
                window.location = '/pages/integrations/medit/cases'
            }
        })
        .catch(() => {})
}


export function authorizeMeditCode(code) {
    return fetch(API_MEDIT_AUTH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            code
        })
    })
    .then((response) => {
        if (!response.ok) {
            console.error(`Failed on ${API_MEDIT_AUTH}`);
            throw Error(response.statusText);
        }
        return response;
    })
}


export function getMeditCases(page, start, end, searchString, size) {

    const s = !!start ? `start=${start}` : false
    const e = !!end ? `end=${end}` : false
    const sz = `size=${size}`
    const search = !!searchString ? `name=${searchString}` : false
    const pg = page >= 1 ? `page=${page - 1}` : `page=${0}`

    let url = `${API_MEDIT_PATIENT_CASES}?${pg}`
    if (s) {
        url += `&${s}`
    }
    if (e) {
        url += `&${e}`
    }
    if (sz) {
        url += `&${sz}`
    }
    if (search) {
        url += `&${search.toString()}`
    }

    return (dispatch) => {
        dispatch({type: 'LOAD_SPINNER'})
        return fetch(url, {credentials: 'include'})
            .then((res) => {
                if (!res.ok) {
                    window.location = '/pages/scanners'
                    console.error(`Failed on ${API_MEDIT_PATIENT_CASES}`);
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then(json => {
                dispatch({type: 'GET_MEDIT_CASES', json})
                dispatch({type: 'CLEAR_SPINNER'})
                return json
            })
    }
}


export function getMeditCase(case_id) {
    return (dispatch) => {
        return fetch(API_MEDIT_CASE(case_id), {credentials: "include"})
        .then((res) => {
            if (!res.ok) {
                console.error(`Failed on ${API_MEDIT_CASE(case_id)}`);
                throw Error(res.statusText);
            }
            return res;
        })
        .then((res) => res.json())
    }
}


export function searchPatientsData(search) {

    const srch = !!search ? `search=${search}` : false

    let url = `${API_MEDIT_PATIENTS}?`

    if (srch) {
        url += `${srch}`
    }
    return (dispatch) => {
        dispatch({type: 'LOAD_SPINNER'})
        return  fetch(url, {credentials: 'include'})
        .then((response) => {
            if (!response.ok) {
                console.error(`Failed on ${API_MEDIT_PATIENTS}`);
                throw Error(response.statusText);
            }
            return response;
        })
        .then((res) => res.json())
        .then(json => {
            dispatch({type: 'GET_MEDIT_PATIENTS_SUCCESS', json});
            dispatch({type: 'CLEAR_SPINNER'});
            return json;
        })
    }
}


export function createPatientFromMedit(instructions, case_id) {

    return (dispatch) => {
        dispatch({type: 'LOAD_SPINNER'})
        return fetch(API_POST_PATIENT_MEDIT(case_id), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                first_name: instructions.first_name,
                last_name: instructions.last_name
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error occurred trying to create patient from medit');
            }
            dispatch({type: 'CLEAR_SPINNER'})
            return response;
        })
        .then(response => response.json())
        .then(json => {
            dispatch(patientIsSavingSuccess(true));
            dispatch({type: 'ADD_PATIENT_SUCCESSFULL', json});
            dispatch(patientIsSavingSuccess(false))
            return json
        })
        .catch(() => {
            dispatch(patientIsSavingSuccess(false));
        })
    }
}


export function connectPatientToMedit(case_id, patient_id) {

    return (dispatch) => {
        dispatch({type: 'LOAD_SPINNER'})
        return fetch(API_POST_PATIENT_CONNECT(case_id, patient_id), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error occurred trying to connect patient to medit');
                dispatch({type: 'CLEAR_SPINNER'})
            }
            dispatch({type: 'CLEAR_SPINNER'})
            return response;
        })
        .then(response => response.json())
    }
}


export function uploadFile(case_id, patient_id, file_id) {

    return (dispatch) => {
            dispatch(fileUploadSuccess(false))
            dispatch({type: 'LOAD_FILE_SPINNER'})
           return fetch(API_POST_UPLOAD_FILE(case_id), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    patient_id: patient_id,
                    file_id: file_id
                })
            })
           .then(response => {
                if (response.status !== 200) {
                    dispatch({type: 'UPLOAD_ERROR'});
                    dispatch(fileUploadSuccess(false));
                    dispatch({type: 'CLEAR_FILE_SPINNER'});
                    throw Error(response.statusText);
                }
                dispatch({type: 'NO_ERROR'});
                dispatch(fileUploadSuccess(true));
                dispatch({type: 'CLEAR_FILE_SPINNER'});
                return response;
            })
           .then(response => response.json())
           .catch(() => {
                dispatch(fileUploadSuccess(false));
           })
    }
}


export function disConnectCase(patient_id) {

    return () => {
       return fetch(API_POST_PATIENT_DISCONNECT(patient_id), {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error occurred trying to disconnect patient');
            }
            return response;
        })
    }
}
