import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useRouteMatch } from "react-router-dom";

import { Layout } from "~/components/ui/layout";
import { ScrollToTop } from "~/components/ui/scroll-to-top";
import { useEnumSearchParam } from "~/hooks/use-enum-search-param";
import { useScrollToTop } from "~/hooks/use-scroll-to-top";

export function MeetingsLayout({
  children,
  displayHeader = true,
}: {
  children: React.ReactElement;
  displayHeader?: boolean;
}) {
  useScrollToTop();

  return (
    <Layout contentClassName="max-sm:-tw-mx-2 max-sm:-tw-my-6 tw-overflow-visible">
      <main className="tw-mb-8 tw-px-1 sm:tw-px-3">
        {displayHeader ? (
          <>
            {/* NOTE: If you wrap h1 and tabs in header, sticky only works if applied to header, */}
            <h1
              className={clsx(
                "tw-my-0 tw-mb-6 tw-max-w-fit tw-text-xl tw-font-semibold tw-text-brand-black",
                "max-sm:tw-px-1 sm:tw-mt-3 sm:tw-text-2xl",
              )}
            >
              <FormattedMessage id="meetings.heading" />
            </h1>

            <MeetingsTabs />
          </>
        ) : null}

        {children}

        <ScrollToTop />
      </main>
    </Layout>
  );
}

function MeetingsTabs() {
  const activeTab = useActiveMeetingsTab();

  const isMeetingsRoute = Boolean(useRouteMatch({ path: "/pages/meetings", exact: true }));
  const isMeetingBookRoute = Boolean(useRouteMatch({ path: "/pages/meetings/book" }));
  const isMeetingsEnrolledRoute = Boolean(useRouteMatch("/pages/meetings/enrolled"));

  function tabClassName(isActive: boolean) {
    return clsx(
      "tw-underline tw-decoration-transparent tw-decoration-2 tw-underline-offset-4",
      "tw-font-semibold tw-transition-colors tw-duration-250 hover:tw-decoration-brand-gray",
      isActive ? " tw-text-brand-black" : "tw-text-brand-gray",
    );
  }

  return (
    <div
      className={clsx(
        "tw-flex tw-justify-between tw-self-start tw-rounded-2xl tw-bg-white tw-px-3 tw-py-4",
        "tw-w-full max-sm:tw-w-full sm:tw-gap-6 sm:tw-px-6 sm:tw-py-4 sm:tw-text-base",
        "tw-sticky tw-top-0 tw-z-10 tw-mb-6 tw-shadow-sm sm:tw-max-w-fit",
      )}
    >
      <NavLink
        exact
        to="/pages/meetings"
        className={tabClassName(isMeetingsRoute || isMeetingBookRoute)}
      >
        <FormattedMessage id="meetings.tabs.new" />
      </NavLink>

      {Object.entries(MeetingsTab).map(([_, tab]) => (
        <NavLink
          key={tab}
          to={`/pages/meetings/enrolled?tab=${tab}`}
          className={tabClassName(isMeetingsEnrolledRoute && tab == activeTab)}
        >
          <FormattedMessage id={`meetings.tabs.${tab}`} />
        </NavLink>
      ))}
    </div>
  );
}

export enum MeetingsTab {
  UPCOMING = "upcoming",
  FINISHED = "finished",
  ALL = "all",
}

export function useActiveMeetingsTab(): MeetingsTab {
  const [activeTab = MeetingsTab.UPCOMING] = useEnumSearchParam({ name: "tab", enum: MeetingsTab });
  return activeTab;
}
