import React from "react";
import { FormattedMessage } from "react-intl";

import { MolarsRatioHowTo, MolarsRatioLeft, MolarsRatioRight } from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";

import { RecipeCheckbox } from "./recipe-shared";

export function RecipeMolars() {
  return (
    <div id="molars-section">
      <h4 id="molars-heading" className="block" style={{ fontWeight: "900" }}>
        <FormattedMessage id="HEADER_MOLAR" />
      </h4>

      <div className="row">
        <div className="form-group col-md-3" style={{ marginBottom: 20 }}>
          <PortletTerm id="molars-ratio-right-label">
            <FormattedMessage id="MOLAR_RIGHT" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="molars_ratio_right"
              value={MolarsRatioRight.CLASS_1}
              intlId="MR_R_I_CLASS"
              ariaLabelledBy={["molars-heading", "molars-ratio-right-label"]}
            />

            <RecipeCheckbox
              name="molars_ratio_right"
              value={MolarsRatioRight.CLASS_2}
              intlId="MR_R_II_CLASS"
              ariaLabelledBy={["molars-heading", "molars-ratio-right-label"]}
            />

            <RecipeCheckbox
              name="molars_ratio_right"
              value={MolarsRatioRight.CLASS_3}
              intlId="MR_R_III_CLASS"
              ariaLabelledBy={["molars-heading", "molars-ratio-right-label"]}
            />
          </div>
        </div>

        <div className="form-group col-md-3" style={{ marginBottom: 20 }}>
          <PortletTerm id="molars-ratio-left-label">
            <FormattedMessage id="MOLAR_LEFT" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="molars_ratio_left"
              value={MolarsRatioLeft.CLASS_1}
              intlId="MR_L_I_CLASS"
              ariaLabelledBy={["molars-heading", "molars-ratio-left-label"]}
            />

            <RecipeCheckbox
              name="molars_ratio_left"
              value={MolarsRatioLeft.CLASS_2}
              intlId="MR_L_II_CLASS"
              ariaLabelledBy={["molars-heading", "molars-ratio-left-label"]}
            />

            <RecipeCheckbox
              name="molars_ratio_left"
              value={MolarsRatioLeft.CLASS_3}
              intlId="MR_L_III_CLASS"
              ariaLabelledBy={["molars-heading", "molars-ratio-left-label"]}
            />
          </div>
        </div>

        <div className="form-group col-md-3">
          <PortletTerm id="molars-ratio-howto-label">
            <FormattedMessage id="MOLAR_HOWTO" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="molars_method"
              value={MolarsRatioHowTo.DISTALIZE}
              intlId="MRH_DISTALIZE"
              ariaLabelledBy={["molars-heading", "molars-ratio-howto-label"]}
            />

            <RecipeCheckbox
              name="molars_method"
              value={MolarsRatioHowTo.MESIALIZE}
              intlId="MRH_MESIALIZE"
              ariaLabelledBy={["molars-heading", "molars-ratio-howto-label"]}
            />
          </div>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="molars_ipr"
              value={undefined}
              intlId="MRH_IPR"
              ariaLabelledBy={["molars-heading", "molars-ratio-howto-label"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function RecipeMolarsChildren() {
  return (
    <div id="molars-section">
      <h4 className="block" style={{ fontWeight: "900" }}>
        <FormattedMessage id="HEADER_MOLAR" />
      </h4>

      <div className="row">
        <div className="form-group col-md-2" style={{ marginBottom: 20 }}>
          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="molars_ratio_right"
              value={MolarsRatioRight.MAINTAIN}
              intlId="MR_R_MAINTAIN"
            />

            <RecipeCheckbox
              name="molars_ratio_right"
              value={MolarsRatioRight.CLASS_1}
              intlId="MR_R_I_CLASS"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
