import React from "react";
import { Route, Switch } from "react-router-dom";

import { requireOfferSign } from "~/require-offer-sign";

import EventPage from "./event_page";
import EventPaymentErrorPage from "./event_payment_error";
import EventPaymentSuccessPage from "./event_payment_success";
import EventPaymentStatusPage from "./event-payment-status";
import EventLayout from "./events-layout";
import EventsListPage from "./events-list";

const EventLayoutRequireOfferSign = requireOfferSign(EventLayout);

export default function EventRoutes() {
  return (
    <EventLayoutRequireOfferSign>
      <Switch>
        <Route
          path="/pages/events/:event_id/payments/status/:transaction_id"
          component={EventPaymentStatusPage}
        />
        <Route
          path="/pages/events/:event_id/payments/success/:hash"
          component={EventPaymentSuccessPage}
        />
        <Route path="/pages/events/:event_id/payments/error" component={EventPaymentErrorPage} />
        <Route path="/pages/events/:event_id" component={EventPage} />
        <Route path="/pages/events/" component={EventsListPage} />
      </Switch>
    </EventLayoutRequireOfferSign>
  );
}
