import React from "react";

import { Layout } from "~/components/ui/layout";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { useDocumentTitle } from "~/hooks/use-document-title";
import { useScrollToTop } from "~/hooks/use-scroll-to-top";

export default function YandexMetrikaPage() {
  useDocumentTitle("Яндекс Метрика");
  useScrollToTop();

  return (
    <Layout contentClassName="max-sm:-tw-mx-2 max-sm:-tw-my-6">
      <Portlet as="main">
        <PortletTitle iconClassName="icon-info">Яндекс Метрика</PortletTitle>

        <div className="portlet-body">
          <p>
            Этот сайт использует сервис веб-аналитики Яндекс Метрика, предоставляемый компанией ООО
            «ЯНДЕКС», 119021, Россия, Москва, ул. Л. Толстого, 16 (далее — Яндекс).
          </p>

          <p>
            Сервис Яндекс Метрика использует технологию «cookie» — небольшие текстовые файлы,
            размещаемые на компьютере пользователей с целью анализа их пользовательской активности.
          </p>

          <p>
            Собранная при помощи cookie информация не может идентифицировать вас, однако может
            помочь нам улучшить работу нашего сайта. Информация об использовании вами данного сайта,
            собранная при помощи cookie, будет передаваться Яндексу и храниться на сервере Яндекса в
            РФ и/или в ЕЭЗ. Яндекс будет обрабатывать эту информацию в интересах владельца сайта, в
            частности для оценки использования вами сайта, составления отчетов об активности на
            сайте. Яндекс обрабатывает эту информацию в порядке, установленном в Условиях
            использования сервиса Яндекс Метрика.
          </p>

          <p>
            Вы можете отказаться от использования cookies, выбрав соответствующие настройки в
            браузере. Также вы можете использовать инструмент —
            <a
              href="https://yandex.ru/support/metrika/general/opt-out.html"
              target="_black"
              rel="noopener noreferrer"
            >
              https://yandex.ru/support/metrika/general/opt-out.html
            </a>
            . Однако это может повлиять на работу некоторых функций сайта. Используя этот сайт, вы
            соглашаетесь на обработку данных о вас в порядке и целях, указанных выше.
          </p>
        </div>
      </Portlet>
    </Layout>
  );
}
