import { api, type TYooKassaPaymentStatus } from "~/api";
import type { EventMeetingType, TEvent } from "~/reducers/events";

import type { TrainingLevel } from "./events-const";

type PaginatedResponse<T> = {
  total: number;
  count: number;
  prev: number | null;
  next: number | null;
  results: T[];
};

export type EventsQueryArgs = {
  endpoint: "schedule" | "enrolled" | "recordings";
  search?: string;
  type?: EventMeetingType;
  training_level?: TrainingLevel;
  city_id?: TEventCity["city_id"];
  free?: boolean;
  page?: number;
  per_page?: number;
};

export type TEventCity = {
  city_id: number;
  title: string;
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<PaginatedResponse<TEvent>, EventsQueryArgs>({
      providesTags: ["Event"],
      query: ({ endpoint, ...params }) => {
        const searchParams = new URLSearchParams();

        for (let [key, value] of Object.entries(params)) {
          if (typeof value == "string") {
            value = value.trim();
          }
          if (value === undefined || value === "") {
            continue;
          }
          searchParams.append(key, value.toString());
        }

        return { url: `events/${endpoint}`, params: searchParams.entries() };
      },
    }),
    getEventsCities: builder.query<TEventCity[], void>({
      query: () => ({ url: "events/cities" }),
    }),
    getUnpaidEvents: builder.query<Record<EventMeetingType, number>, void>({
      query: () => ({ url: "events/unpaid" }),
    }),
    getEventPaymentStatus: builder.query<
      { status: TYooKassaPaymentStatus },
      { transactionId: string }
    >({
      query: (params) => ({ url: `events/payments/${params.transactionId}` }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventsCitiesQuery,
  useGetUnpaidEventsQuery,
  useGetEventPaymentStatusQuery,
} = extendedApi;
