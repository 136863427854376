import { TUpdatedDoctor } from "../components/doctor/doctor_update_page";
import { API_EDIT_DOCTOR } from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { AppDispatch } from "../store";
import { userLoggedInSuccess } from "./login";
import { addUserActionNotification } from "./user_notification";

export function changePassword(password: string) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.doctor.password.success",
    level: "success",
    position: "tl",
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.doctor.password.failed",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return (dispatch: AppDispatch) => {
    return fetch(API_EDIT_DOCTOR, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ password }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
        }
        return response;
      })
      .then((response) => response.json())
      .then((fields) => {
        dispatch({ type: "EDIT_DOCTOR_SUCCESS", fields });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        // window.location = '/pages/patients';
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function changeAccountData(account_data: TUpdatedDoctor) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.doctor.update.success",
    level: "success",
    position: "tl",
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.doctor.update.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_EDIT_DOCTOR, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(account_data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
        }
        return response;
      })
      .then((response) => response.json())
      .then((fields) => {
        dispatch(userLoggedInSuccess(fields));
        dispatch({ type: "EDIT_DOCTOR_SUCCESS", fields });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}
