import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useGetInvoicePaymentBatchStatusQuery } from "../../../../api";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { PaymentStatusMessage } from "../../../common/payment-status-message";
import { Layout } from "../../../ui/layout";
import { Portlet, PortletTitle } from "../../../ui/portlet";

export default function PaymentInvoiceBatchStatus() {
  useDocumentTitle(useIntl().formatMessage({ id: "pat.payments.online.payment" }));

  const { batch_id } = useParams<{ batch_id: string }>();
  const invoiceQuery = useGetInvoicePaymentBatchStatusQuery({ batchId: batch_id });

  return (
    <Layout>
      <Portlet as="main" className="tw-h-full">
        <PortletTitle iconClassName="icon-book-open">
          <FormattedMessage id="pat.payments.online.payment" />
        </PortletTitle>

        <div className="portlet-body">
          <PaymentStatusMessage query={invoiceQuery} />
        </div>
      </Portlet>
    </Layout>
  );
}
