import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";

import { getClinics, setAddress } from "~/actions/clinics";
import { remoteLog } from "~/common/logging";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    clinics: state.clinics,
    spinner: state.spinner,
  };
};

const mapDispatchToProps = {
  getClinics,
};

type ClinicsListProps = PropsFromRedux & { intl: IntlShape };

class ClinicsList extends Component<ClinicsListProps> {
  componentDidMount() {
    const { user } = this.props;
    window.scrollTo(0, 0);
    this.props.getClinics();

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }

    setDocumentTitle(this.props.intl.formatMessage({ id: "clin_table.header" }));
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "clinic_list");
  }

  render() {
    const { clinics, spinner } = this.props;
    return (
      <Layout>
        <Portlet as="main">
          <PortletTitle iconClassName="icon-layers">
            <FormattedMessage id="clin_table.header" />
          </PortletTitle>

          <div className="portlet-body">
            {spinner ? (
              <Loader />
            ) : clinics.length == 0 ? (
              <FormattedMessage id="clinics_not_found" />
            ) : (
              <div className="table-scrollable">
                <table className="table table-bordered table-hover" id="doctor-clinics">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <FormattedMessage id="clin_table.name" />
                      </th>
                      <th>
                        <FormattedMessage id="clin_table.address" />
                      </th>
                      <th>
                        <FormattedMessage id="clin_table.phone" />
                      </th>
                      <th>
                        <FormattedMessage id="clin_table.email" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {clinics.map((clinic, i) => (
                      <tr key={clinic.clinic_id}>
                        <td>{i + 1}</td>
                        <td>
                          <NavLink to={`/pages/doctor/clinics/${clinic.clinic_id}`}>
                            {clinic.title}
                          </NavLink>
                        </td>
                        <td>{setAddress(clinic)}</td>
                        <td>{clinic.phone}</td>
                        <td>{clinic.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(ClinicsList));
