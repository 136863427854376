import React from "react";

import { buildLocale } from "../../common/locale";

export function FormatDate({
  className,
  ...props
}: Parameters<typeof formatDate>[0] & { className?: string }) {
  return <span className={className}>{formatDate(props)}</span>;
}

function formatDate({
  value,
  date = true,
  time = false,
}: {
  value: string;
  date?: boolean;
  time?: boolean;
}): string {
  if (!value) return "";
  if (date && time) {
    return new Date(value.replace(/ /g, "T")).toLocaleString(buildLocale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  if (date) {
    return new Date(value).toLocaleDateString(buildLocale);
  }
  if (time) {
    return new Date(value).toLocaleTimeString(buildLocale);
  }
  return value;
}
