import { Separator } from "@radix-ui/react-separator";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Link, useHistory, useParams } from "react-router-dom";

import { buildLocale } from "~/common/locale";
import { isQueryNotFoundError } from "~/common/query";
import { PersonName } from "~/components/common/PersonName";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";
import { useLoadingDelay } from "~/hooks/use-loading-delay";
import type { Loading } from "~/types/common";

import { MeetingCard } from "./meeting-card";
import { useGetMeetingQuery } from "./meetings-api";
import { AnimateOpacity, MeetingsEmptyFallback, MeetingsErrorFallback } from "./meetings-shared";
import type { TMeeting } from "./meetings-types";

export function MeetingDetailPage() {
  useIntlDocumentTitle("meetings.detail.title");
  const { meeting_id } = useParams<{ meeting_id: string }>();
  const meetingQuery = useGetMeetingQuery({ meetingId: meeting_id });

  return (
    <>
      <MeetingDetailHeader />

      <RemoteLogErrorBoundary
        component="meeting_detail_page"
        fallbackRender={MeetingsErrorFallback}
        onReset={meetingQuery.refetch}
      >
        <MeetingDetailContent />
      </RemoteLogErrorBoundary>
    </>
  );
}

function MeetingDetailHeader() {
  const history = useHistory();

  const backButtonElement = (
    <button
      className={clsx(
        "tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-xl",
        "focus-visible:tw-ring-offset-3 focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600",
        "tw-border-none tw-bg-white tw-transition-colors hover:tw-bg-[#f4f4f5]",
      )}
      onClick={history.goBack}
    >
      <LeftArrowIcon />

      <span className="tw-sr-only">
        <FormattedMessage id="reminder.back" />
      </span>
    </button>
  );

  return (
    <header className="tw-mb-7 tw-mt-3 tw-flex tw-items-center max-sm:tw-justify-between sm:tw-gap-7">
      {backButtonElement}

      <h1 className="tw-my-0 tw-text-center tw-text-xl tw-font-semibold tw-text-brand-black sm:tw-text-2xl">
        <FormattedMessage id="meetings.detail" />
      </h1>

      {/* To center h1 across the page with flexbox. */}
      <div className="tw-invisible sm:tw-hidden">{backButtonElement}</div>
    </header>
  );
}

function MeetingDetailContent() {
  const { meeting_id } = useParams<{ meeting_id: string }>();
  const meetingQuery = useGetMeetingQuery({ meetingId: meeting_id });
  const isLoadingMinDuration = useLoadingDelay(meetingQuery.isLoading);

  if (meetingQuery.isUninitialized || meetingQuery.isLoading || isLoadingMinDuration) {
    return (
      <AnimateOpacity>
        <MeetingDetailCard meeting={undefined} />
      </AnimateOpacity>
    );
  }

  if (isQueryNotFoundError(meetingQuery.error)) {
    return (
      <MeetingsEmptyFallback
        title="meetings.detail.not_found"
        description="meetings.detail.not_found.description"
        link={{ to: "/pages/meetings/enrolled", children: "meetings.enrolled.title.all" }}
      />
    );
  }

  if (meetingQuery.isError) {
    return (
      <MeetingsErrorFallback
        title="meetings.detail.error"
        resetErrorBoundary={meetingQuery.refetch}
      />
    );
  }

  return <MeetingDetailCard meeting={meetingQuery.data} />;
}

function MeetingDetailCard({ meeting }: { meeting: Loading<TMeeting> }) {
  const [selectedPatientIndex, setSelectedPatientIndex] = useState("0");

  return (
    <article
      className={clsx(
        "tw-mb-8 tw-flex tw-rounded-3xl tw-bg-white tw-p-5 tw-shadow-sm",
        "max-desktop-lg:tw-flex-col max-desktop-lg:tw-gap-8 sm:tw-p-8",
      )}
    >
      <Tabs.Root
        value={selectedPatientIndex}
        onValueChange={setSelectedPatientIndex}
        className={clsx(
          "tw-flex tw-grow tw-flex-col tw-gap-8 tw-border-y-0 tw-border-l-0 tw-border-r-[#E4E4E4]",
          "desktop-lg:tw-mr-6 desktop-lg:tw-border-r desktop-lg:tw-border-solid desktop-lg:tw-pr-6",
        )}
      >
        <Tabs.List className="tw-flex tw-max-h-20 tw-gap-1">
          {meeting?.patients.map(({ patient }, index, patients) => {
            const isSelected = `${index}` == selectedPatientIndex && patients.length > 1;

            return (
              <Tabs.Trigger
                key={patient.patient_id}
                value={`${index}`}
                className={clsx(
                  "tw-flex tw-w-full tw-flex-col tw-gap-1 tw-rounded-xl tw-border tw-border-solid",
                  "tw-overflow-hidden tw-whitespace-nowrap tw-bg-transparent tw-p-3 tw-text-left sm:tw-p-4",
                  "tw-text-brand-black tw-transition-colors hover:tw-bg-[#f4f4f5] active:tw-bg-[#f4f4f5]",
                  isSelected ? "tw-border-brand-gray" : "tw-border-[#E9E9E9]",
                )}
              >
                <Link
                  to={`/pages/patient/${patient.patient_id}`}
                  className="tw-self-start tw-font-semibold"
                >
                  {patient.patient_id}
                </Link>

                <PersonName
                  person={patient}
                  className="tw-inline-block tw-max-w-full tw-overflow-hidden tw-overflow-ellipsis"
                />
              </Tabs.Trigger>
            );
          }) ?? <Skeleton containerClassName="tw-w-full" className="tw-rounded-xl" height={75} />}
        </Tabs.List>

        <div>
          {(meeting?.patients ?? [undefined]).map((meetingPatient, index) => {
            return (
              <Tabs.Content
                key={index}
                value={`${index}`}
                className="tw-flex tw-flex-col tw-gap-8"
                tabIndex={`${index}` == selectedPatientIndex ? 0 : -1}
              >
                <h2 className="tw-my-0 tw-text-base tw-font-semibold tw-text-brand-black sm:tw-text-xl">
                  {meetingPatient?.theme ?? <Skeleton className="tw-max-w-[75%] tw-rounded-md" />}
                </h2>

                <div className="tw-flex tw-flex-col tw-gap-1">
                  <div className="tw-text-brand-gray">
                    {meetingPatient ? (
                      <FormattedMessage id="meetings.your_question" />
                    ) : (
                      <Skeleton className="tw-rounded-md" width={100} />
                    )}
                  </div>

                  <p className="tw-m-0 tw-whitespace-pre-wrap tw-text-brand-black">
                    {meetingPatient?.question ?? <Skeleton className="tw-rounded-md" count={3} />}
                  </p>
                </div>

                <div className="tw-flex tw-flex-col tw-gap-1">
                  <div className="tw-text-brand-gray">
                    {meetingPatient ? (
                      <FormattedMessage id="meetings.expert_comment" />
                    ) : (
                      <Skeleton className="tw-rounded-md" width={175} />
                    )}
                  </div>

                  <p
                    className={clsx(
                      "tw-m-0 tw-whitespace-pre-wrap",
                      meetingPatient?.expert_comment ? "tw-text-brand-black" : "tw-text-brand-gray",
                    )}
                  >
                    {meetingPatient ? (
                      meetingPatient.expert_comment ?? (
                        <span className="tw-opacity-60">
                          <FormattedMessage id="meetings.expert_comment_default" />
                        </span>
                      )
                    ) : (
                      <Skeleton className="tw-rounded-md" count={2} />
                    )}
                  </p>
                </div>
              </Tabs.Content>
            );
          })}
        </div>
      </Tabs.Root>

      <Separator className="tw-h-px tw-bg-[#D9DCE7]" />

      <div className="tw-flex tw-flex-col tw-gap-8 sm:tw-min-w-[300px]">
        <div className="tw-flex tw-flex-col tw-gap-1">
          <div className="tw-text-brand-gray">
            {meeting ? (
              <FormattedMessage id="meetings.datetime" />
            ) : (
              <Skeleton className="tw-max-w-[50%]" />
            )}
          </div>

          <p className="tw-m-0 tw-font-semibold tw-text-brand-black">
            {meeting ? (
              formatStartDate(meeting.start_time_utc)
            ) : (
              <Skeleton className="tw-max-w-[75%]" />
            )}
          </p>
        </div>

        <MeetingCard
          meeting={meeting}
          padding={false}
          separator={false}
          shadow={false}
          dimFinished={false}
          displayDateBadges={false}
          displayDetailLink={false}
        />
      </div>
    </article>
  );
}

function formatStartDate(startTime: string) {
  const startDate = new Date(startTime);
  const dateStr = startDate.toLocaleDateString(buildLocale, { month: "long", day: "numeric" });
  const weekdayStr = startDate.toLocaleDateString(buildLocale, { weekday: "long" });
  const timeStr = startDate.toLocaleTimeString(buildLocale, { hour: "2-digit", minute: "2-digit" });
  return buildLocale == "ru-RU"
    ? `${dateStr}, ${weekdayStr}, в ${timeStr}`
    : `${dateStr}, ${weekdayStr}, ${timeStr}`;
}

function LeftArrowIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4396 10.9999L5.9729 10.9999L9.27957 14.2799C9.66624 14.6799 9.66624 15.3066 9.27957 15.7066C8.8929 16.0932 8.2529 16.0932 7.86624 15.7066L2.83957 10.7066C2.46624 10.3332 2.46624 9.66658 2.83957 9.29325L7.86624 4.29325C8.2529 3.90658 8.8929 3.90658 9.27957 4.29325C9.46624 4.49325 9.5729 4.74792 9.5729 4.99992C9.5729 5.25325 9.46624 5.51992 9.27957 5.70658L5.9729 8.99992L16.4396 8.99992C16.9996 8.99992 17.4396 9.43992 17.4396 9.99992C17.4396 10.5466 16.9996 10.9999 16.4396 10.9999Z"
        className="tw-fill-brand-black"
      />
    </svg>
  );
}
