import { CrossCircledIcon } from "@radix-ui/react-icons";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { eraseStateProp } from "~/actions/dashboard";
import { clearExtraServices, getExtraServices } from "~/actions/extra_services";
import { getPatientIdWithError } from "~/actions/get_patient_id";
import { getToken } from "~/actions/get_token";
import { Status } from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { getLastCorrection, isPatient } from "~/common/patient";
import { isUser } from "~/common/user";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { Portlet } from "~/components/ui/portlet";
import { ScrollToTop } from "~/components/ui/scroll-to-top";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

import PatientAskQuestion from "./patient_ask_question";
import Patient3DPlan from "./patient_show/patient_3d_plan";
import { PatientAdditionalAlignersCard } from "./patient_show/patient_additional_aligners";
import { PatientAdditionalServices } from "./patient_show/patient_additional_services";
import PatientCard from "./patient_show/patient_card";
import PatientCommentsSmile from "./patient_show/patient_comments_smile";
import PatientDeliveryBlock from "./patient_show/patient_delivery";
import PatientId from "./patient_show/patient_id";
import { PatientInvoice } from "./patient_show/patient_invoice";
import { PatientPayment } from "./patient_show/patient_payment";
import PatientScans from "./patient_show/patient_scans";
import PatientStatus from "./patient_show/patient_status";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    token: state.token,
    intl: state.intl,
  };
};

const mapDispatchToProps = {
  getPatient: getPatientIdWithError,
  getToken,
  clearExtraServices,
  getExtraServices,
  eraseStateProp,
};

type PatientPageBodyProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
  patient_id: string;
}>;

type PatientPageBodyState = {
  isNotFound: boolean;
};

class PatientPage extends Component<PatientPageBodyProps, PatientPageBodyState> {
  constructor(props: PatientPageBodyProps) {
    super(props);
    this.state = {
      isNotFound: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { user } = this.props;

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }

    const patient_id = this.props.match.params.patient_id;
    this.props.getToken();
    this.props.clearExtraServices();
    void this.props.getExtraServices(Number(patient_id));

    try {
      await this.props.getPatient(Number(patient_id), true);
    } catch (err) {
      if (err instanceof Error && err.cause instanceof Response && err.cause.status == 404) {
        this.setState({ isNotFound: true });
      }
    }

    setDocumentTitle(
      this.props.intl.formatMessage({ id: "pat.block.right.header" }) + " " + patient_id,
    );
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_page_body");
  }

  componentWillUnmount() {
    this.props.eraseStateProp('patient');
  }

  renderQuestionSection() {
    if (this.props.patient.status == Status._3D_PLAN_APPROVAL) {
      return (
        <div
          className="portlet light bordered"
          id="question-side-block"
          style={{ textAlign: "center" }}
        >
          <PatientAskQuestion />
        </div>
      );
    }
    return null;
  }

  render() {
    if (isPatient(this.props.patient) && isUser(this.props.user)) {
      const lastCorrection = getLastCorrection(this.props.patient);
      const {
        can_order_correction,
        can_end_treatment,
        can_order_retainers,
        can_order_additional_aligners,
      } = lastCorrection.order_options;
      const can_upload_from_3shape = Boolean(this.props.user.privileges?.has_3shape_scanner);
      const can_set_bonus_patient = this.props.patient.bonus_set_free;

      return (
        <Layout>
          <div className="row">
            <div className="col-md-8">
              {this.props.token && this.props.patient.media["3d-plan"].response ? (
                <Patient3DPlan patient={this.props.patient} token={this.props.token} />
              ) : null}
              {can_upload_from_3shape ? <PatientScans /> : null}
              {can_order_correction || can_end_treatment || can_order_retainers || can_set_bonus_patient ? (
                <PatientAdditionalServices patient={this.props.patient} />
              ) : null}
              {this.props.patient.status != Status.UNFILLED_CASE ? (
                <PatientInvoice patient={this.props.patient} />
              ) : null}
              <PatientCommentsSmile patient={this.props.patient} />
              <PatientCard patient={this.props.patient} user={this.props.user} />
            </div>
            <div className="col-md-4">
              <PatientId />
              <PatientStatus />
              <PatientPayment patient={this.props.patient} />
              <PatientDeliveryBlock />
              {this.renderQuestionSection()}
              {can_order_additional_aligners ? (
                <PatientAdditionalAlignersCard patient={this.props.patient} />
              ) : null}
            </div>
          </div>

          <ScrollToTop />
        </Layout>
      );
    }

    if (this.state.isNotFound) {
      return (
        <Layout>
          <Portlet as="main" className="tw-p-5">
            <div className="tw-flex tw-items-center tw-gap-2 max-desktop:tw-justify-center">
              <CrossCircledIcon className="tw-h-5 tw-w-5 tw-text-red-600" />
              <span className="tw-font-semibold">
                <FormattedMessage id="pat.block.not_found" />
              </span>
            </div>
          </Portlet>
        </Layout>
      );
    }

    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientPage));
