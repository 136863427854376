import React from "react";

import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";
import { useLoadingDelay } from "~/hooks/use-loading-delay";

import { MeetingCard } from "./meeting-card";
import { useGetDoctorMeetingsQuery } from "./meetings-api";
import { MeetingsTab, useActiveMeetingsTab } from "./meetings-layout";
import { AnimateOpacity, MeetingsEmptyFallback, MeetingsErrorFallback } from "./meetings-shared";
import type { TMeeting } from "./meetings-types";

export function MeetingsEnrolledPage() {
  const activeTab = useActiveMeetingsTab();
  useIntlDocumentTitle(`meetings.enrolled.title.${activeTab}`);
  const meetingsQuery = useGetDoctorMeetingsQuery();

  return (
    <RemoteLogErrorBoundary
      component="meetings_enrolled_page"
      fallbackRender={MeetingsErrorFallback}
      onReset={meetingsQuery.refetch}
    >
      <MeetingsGrid />
    </RemoteLogErrorBoundary>
  );
}

function MeetingsGrid() {
  const activeTab = useActiveMeetingsTab();

  const meetingsQuery = useGetDoctorMeetingsQuery();
  const gridClassName = "tw-grid tw-gap-3 desktop-lg:tw-grid-cols-2";

  const isLoadingMinDuration = useLoadingDelay(meetingsQuery.isLoading);

  if (meetingsQuery.isUninitialized || meetingsQuery.isLoading || isLoadingMinDuration) {
    return (
      <AnimateOpacity className={gridClassName}>
        {Array.from({ length: 2 }, (_, index) => (
          <MeetingCard
            key={index}
            meeting={undefined}
            displayActions={activeTab != MeetingsTab.FINISHED}
            displayJoinLink={activeTab != MeetingsTab.FINISHED}
          />
        ))}
      </AnimateOpacity>
    );
  }

  if (meetingsQuery.isError) {
    return (
      <MeetingsErrorFallback
        title="meetings.enrolled.error"
        resetErrorBoundary={meetingsQuery.refetch}
      />
    );
  }

  const meetings = filterMeetingsByTab(meetingsQuery.data, activeTab);
  const hasUpcomingMeetings = meetings.some((meeting) => !meeting.is_finished);

  if (meetings.length == 0) {
    return (
      <MeetingsEmptyFallback
        title="meetings.enrolled.none"
        description={`meetings.enrolled.${activeTab}.none`}
        link={
          hasUpcomingMeetings ? undefined : { to: "/pages/meetings", children: "meetings.tabs.new" }
        }
      />
    );
  }

  function renderMeetings(meetings: TMeeting[]) {
    return (
      <div className={gridClassName}>
        {meetings.map((meeting) => (
          <MeetingCard
            key={meeting.id}
            meeting={meeting}
            dimFinished={activeTab != MeetingsTab.FINISHED && hasUpcomingMeetings}
            displayActions={activeTab != MeetingsTab.FINISHED && !meeting.is_finished}
          />
        ))}
      </div>
    );
  }

  if (activeTab == MeetingsTab.ALL) {
    const upcomingMeetings = filterMeetingsByTab(meetings, MeetingsTab.UPCOMING);
    const finishedMeetings = filterMeetingsByTab(meetings, MeetingsTab.FINISHED);

    return (
      <div className="tw-flex tw-flex-col tw-gap-6">
        {upcomingMeetings.length > 0 ? renderMeetings(upcomingMeetings) : null}
        {finishedMeetings.length > 0 ? renderMeetings(finishedMeetings) : null}
      </div>
    );
  }

  return renderMeetings(meetings);
}

function filterMeetingsByTab(meetings: TMeeting[], activeTab: MeetingsTab) {
  switch (activeTab) {
    case MeetingsTab.UPCOMING:
      return meetings.filter((meeting) => !meeting.is_finished);

    case MeetingsTab.FINISHED:
      return meetings
        .filter((meeting) => meeting.is_finished)
        .sort(function byStartTimeDesc(a, b) {
          return new Date(b.start_time_utc).getTime() - new Date(a.start_time_utc).getTime();
        });

    case MeetingsTab.ALL:
      return meetings;
  }
}
