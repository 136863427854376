import React from "react";
import { FormattedMessage } from "react-intl";

import { GENDER_MAP, Payer } from "~/common/constants";
import { FormatDate } from "~/components/common/FormatDate";
import { newLineToLineBreakTag } from "~/components/common/formatText/formatText";
import { PersonName } from "~/components/common/PersonName";
import ProbablyFormattedMessage from "~/components/probably_formatted_message";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";

export function PatientCardPatientInfo({ patient }: { patient: TPatient }) {
  return (
    <div>
      <h3 className="block" id="patient-info-label">
        <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
      </h3>

      <PortletTerm id="patient-name-label">
        <FormattedMessage id="pat_table.name" />
      </PortletTerm>

      <p id="patient-name-value">
        <PersonName person={patient} />
      </p>

      <PortletTerm id="patient-gender-label">
        <FormattedMessage id="HEADER_SEX" />
      </PortletTerm>

      <p id="patient-gender-value">
        <ProbablyFormattedMessage id={patient.sex ? GENDER_MAP[patient.sex] : undefined} />
      </p>

      <PortletTerm id="patient-bdate-label">
        <FormattedMessage id="HEADER_BDATE" />
      </PortletTerm>

      <p id="patient-bdate-value">
        {patient.bdate ? <FormatDate value={patient.bdate} date /> : ""}
      </p>

      {patient.payer_id == Payer.PATIENT ? (
        <>
          <PortletTerm id="patient-email-label">
            <FormattedMessage id="PAT_EMAIL" />
          </PortletTerm>

          <p data-matomo-mask data-hj-suppress id="patient-email-value">
            {patient.email ? patient.email : <FormattedMessage id="None" />}
          </p>
        </>
      ) : null}

      <PortletTerm id="patient-diagnosis-label">
        <FormattedMessage id="HEADER_CONDITION" />
      </PortletTerm>

      <p data-matomo-mask data-hj-suppress id="patient-diagnosis-value">
        {patient.diagnosis ? newLineToLineBreakTag(patient.diagnosis) : ""}
      </p>
    </div>
  );
}
