import React from "react";
import { Redirect, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { isMatching, P } from "ts-pattern";

import { remoteLog } from "../common/logging";
import { useRequiredUser } from "../common/user";
import { useGetEventPaymentStatusQuery } from "../features/events/events-api";
import { PaymentStatusMessage } from "./common/payment-status-message";
import { Button } from "./ui/button";
import { Layout } from "./ui/layout";
import { Portlet, PortletTitle } from "./ui/portlet";

export default function ElitePage() {
  const user = useRequiredUser();

  if (!user.elite_to_be_paid) {
    return <Redirect to="/pages/patients" />;
  }

  const toBePaidFormatted = new Intl.NumberFormat("ru-RU", { currency: "RUB" }).format(
    user.elite_to_be_paid,
  );

  async function payElite() {
    try {
      const API_ELITE = `${process.env.API}/elite`;
      const response = await fetch(API_ELITE, { method: "POST", credentials: "include" });
      const json = await response.json();
      invariant(isMatching({ link: P.string }, json), `${API_ELITE} - unexpected json received`);
      const link = json.link;
      window.location.href = link;
    } catch (err) {
      remoteLog(err, "payElite");
    }
  }

  return (
    <Layout>
      <Portlet as="main">
        <PortletTitle iconClassName="icon-book-open">Оплата членства в 3D Smile Elite</PortletTitle>

        <div className="portlet-body tw-space-y-4 max-desktop:tw-text-center">
          <Button rounded onClick={payElite}>
            Оплатить {toBePaidFormatted} руб.
          </Button>
        </div>
      </Portlet>
    </Layout>
  );
}

export function ElitePaymentStatus() {
  const { transaction_id } = useParams<{ transaction_id: string }>();

  const invoiceQuery = useGetEventPaymentStatusQuery({ transactionId: transaction_id });

  return (
    <Layout>
      <Portlet as="main" className="tw-h-full">
        <PortletTitle iconClassName="icon-book-open">Оплата членства в 3D Smile Elite</PortletTitle>

        <div className="portlet-body tw-space-y-4 max-desktop:tw-text-center">
          <PaymentStatusMessage query={invoiceQuery} />
        </div>
      </Portlet>
    </Layout>
  );
}
