import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast as sonnerToast, Toaster } from "sonner";

import { remoteLog } from "~/common/logging";
import { useRequiredUser } from "~/common/user";
import { Button, type ButtonProps } from "~/components/ui/button";
import { LoadingButton, type LoadingButtonProps } from "~/components/ui/loading-button";
import { useLoadingDelay } from "~/hooks/use-loading-delay";

import eventInvitation from "./event-invitation.jpg";
import telegramChannelLogo from "./telegram-channel-logo.svg";
import { ToastTypeTag, useCloseToastMutation, useGetToastsQuery } from "./toasts-api";

const TOAST_TIMEOUT_MS = 1500;

export function AppToaster() {
  const user = useRequiredUser();

  const toastsQuery = useGetToastsQuery();

  const isYandexMetrikaToastShown = useRef(false);
  const isTelegramChannelToastShown = useRef(false);
  const isEventInvitationToastShown = useRef(false);

  useEffect(() => {
    const toastTypesForDisplay = toastsQuery.data;
    if (!toastTypesForDisplay) {
      return;
    }

    const toasts: React.ComponentType<ToastProps>[] = [];

    if (!user.privileges.accepted_yandex_metrika && !isYandexMetrikaToastShown.current) {
      isYandexMetrikaToastShown.current = true;
      toasts.push(YandexMetrikaToast);
    }

    if (
      toastTypesForDisplay.includes(ToastTypeTag.TELEGRAM_CHANNEL) &&
      !isTelegramChannelToastShown.current
    ) {
      isTelegramChannelToastShown.current = true;
      toasts.push(TelegramChannelToast);
    }

    if (
      toastTypesForDisplay.includes(ToastTypeTag.EVENT_INVITATION_I_AM_DOCTOR) &&
      !isEventInvitationToastShown.current
    ) {
      isEventInvitationToastShown.current = true;
      toasts.push(EventInvitationToast);
    }

    for (const [index, ToastComponent] of toasts.entries()) {
      setTimeout(() => persistentToast(ToastComponent), TOAST_TIMEOUT_MS * (index + 1));
    }
  }, [user.privileges.accepted_yandex_metrika, toastsQuery.data]);

  return <Toaster gap={12} mobileOffset={{ left: 4, bottom: 8 }} />;
}

function YandexMetrikaToast({ toastId }: ToastProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const displayLoading = useLoadingDelay(isSubmitting, { delay: 200 });

  async function handleClick() {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch(`${process.env.API}/yandex-metrika`, {
        method: "POST",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(
          `Error occurred trying to accept yandex metrika "${response.status} ${response.statusText}"`,
        );
      }
    } catch (err) {
      remoteLog(err, "yandex_metrika_toast");
    } finally {
      sonnerToast.dismiss(toastId);
    }
  }

  return (
    <Toast>
      <p className="tw-m-0">
        Мы используем файлы cookie для улучшения работы сайта.
        <span className="tw-block tw-h-1" />
        <Link to="/pages/yandex-metrika" className="tw-underline tw-underline-offset-4">
          Подробнее
        </Link>
      </p>

      <ToastAction onClick={handleClick} isLoading={displayLoading}>
        OK
      </ToastAction>
    </Toast>
  );
}

function TelegramChannelToast({ toastId }: ToastProps) {
  const [closeToast, closeToastResult] = useCloseToastMutation();

  async function handleClick() {
    if (closeToastResult.isLoading) {
      return;
    }
    try {
      await closeToast({ tag: ToastTypeTag.TELEGRAM_CHANNEL }).unwrap();
    } catch (err) {
      remoteLog(err, "telegram_channel_toast");
    } finally {
      sonnerToast.dismiss(toastId);
    }
  }

  return (
    <Toast className="tw-px-5 tw-py-4 max-[600px]:tw-flex-wrap">
      <div className="tw-flex tw-items-center tw-gap-5 tw-text-[13px]">
        <img width={70} src={telegramChannelLogo} style={{ width: 70, height: 70 }} alt="" />

        <p className="tw-m-0">
          Актуальные новости лаборатории, анонсы мероприятий в нашем Telegram-канале{" "}
          <br className="max-[600px]:tw-hidden" /> 3D Smile LIVE
        </p>
      </div>

      <ToastAction asChild>
        <a
          href="https://t.me/smile_aligners_lab"
          target="_blank"
          rel="noopener noreferrer"
          className="max-[600px]:tw-w-full"
          onClick={handleClick}
        >
          Перейти
        </a>
      </ToastAction>
    </Toast>
  );
}

function EventInvitationToast({ toastId }: ToastProps) {
  const [closeToast, closeToastResult] = useCloseToastMutation();

  async function handleClick() {
    if (closeToastResult.isLoading) {
      return;
    }
    try {
      await closeToast({ tag: ToastTypeTag.EVENT_INVITATION_I_AM_DOCTOR }).unwrap();
    } catch (err) {
      remoteLog(err, "event_invitation_toast");
    } finally {
      sonnerToast.dismiss(toastId);
    }
  }

  return (
    <Toast className="tw-px-5 tw-py-4 max-[600px]:tw-flex-wrap">
      <div className="tw-flex tw-items-center tw-gap-5 tw-text-[13px]">
        <img
          width={70}
          src={eventInvitation}
          className="tw-rounded-full"
          style={{ width: 70, height: 70 }}
          alt=""
        />

        <p className="tw-m-0">
          Приглашаем на конференцию 3D Smile
          <br /> {`"Я врач!" 4 апреля 2025 в Москве.`}
          <br /> ОРТОДОНТИЯ. ЭСТЕТИКА. БИЗНЕС
        </p>
      </div>

      <ToastAction asChild>
        <a
          href="https://3d-smile.ru/konferenciya-3d-smile-2025"
          target="_blank"
          rel="noopener noreferrer"
          className="max-[600px]:tw-w-full"
          onClick={handleClick}
        >
          Подробнее
        </a>
      </ToastAction>
    </Toast>
  );
}

function Toast({ children, className }: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={clsx(
        "tw-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded-xl tw-border",
        "tw-border-solid tw-border-gray-300 tw-bg-white tw-p-6 tw-leading-8 tw-text-brand-black tw-shadow-lg",
        "min-[600px]:tw-min-w-[550px]",
        className,
      )}
    >
      {children}
    </div>
  );
}

function ToastAction({ isLoading, ...props }: Partial<LoadingButtonProps>) {
  const buttonProps = {
    ...props,
    variant: "primary",
    rounded: "extra",
    size: "1.5xl",
  } satisfies ButtonProps;

  if (typeof isLoading == "boolean") {
    return <LoadingButton {...buttonProps} isLoading={isLoading} />;
  }

  return <Button {...buttonProps} />;
}

type ToastProps = {
  toastId: string | number;
};

function persistentToast(Component: React.ComponentType<ToastProps>) {
  return sonnerToast.custom((id) => <Component toastId={id} />, {
    dismissible: false,
    duration: Infinity,
  });
}
