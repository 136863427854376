import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { Payer, PaymentMethod } from "~/common/constants";
import { Status } from "~/common/courses";
import { deployedRussia, deployedUSA, isDevelopment } from "~/common/utils";
import { Button } from "~/components/ui/button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { useAppSelector } from "~/store";
import type { TPatient } from "~/types/patient";

export function PatientInvoice({ patient }: { patient: TPatient }) {
  const user = useAppSelector((state) => state.user);
  const intl = useIntl();

  function showPayments() {
    let allow = false;
    if (
      (window.location.protocol == "https:" || isDevelopment) &&
      patient.status != Status.UNFILLED_CASE
    ) {
      const { payment_available } = user;
      const { payment_method } = patient.course;

      if (payment_available && payment_method == PaymentMethod.CARD) {
        const { payments } = patient.course;
        const not_paid_full =
          payments.remain > 0 || payments.next > 0 || (payments.extra && payments.extra.length > 0);

        if (deployedRussia() && intl.locale == "ru" && not_paid_full) {
          if (patient.payer_id == Payer.DOCTOR) {
            allow = true;
          }
        } else if (deployedUSA() && not_paid_full) {
          allow = true;
        }
      }
    }
    return allow;
  }

  if (!showPayments()) {
    return null;
  }

  return (
    <Portlet>
      <PortletTitle as="h2" iconClassName="icon-docs">
        <FormattedMessage id="pat.payments.course.pay.course" />
      </PortletTitle>

      <div className="portlet-body">
        <div className="form-body">
          <div className="row fileupload-buttonbar">
            <div className="col-lg-7">
              <Button asChild>
                <NavLink to={`/pages/patient/${patient.patient_id}/payment`}>
                  <FormattedMessage id="pat.payments.buttons.pay" />
                </NavLink>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Portlet>
  );
}
