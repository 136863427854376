import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

import { remoteLog } from '../../../../common/logging';


class PaymentInvoiceError extends Component {
  componentDidCatch(e) {
    remoteLog(e, 'payment_invoice_error');
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <div className="page-content" style={{minHeight: 584}}>
          <div className="row">
            <div className="col-md-8">
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption">
                    <i className="icon-book-open font-green" />
                    <span className="caption-subject font-green bold uppercase"><FormattedMessage id="pat.payments.online.payment" /></span>
                  </div>
                </div>
                <div className="portlet-body">
                  <div className="form-body">
                    <div className="row fileupload-buttonbar">
                      <div className="col-lg-7">
                        <FormattedMessage id="pat.payments.course.paid.error" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PaymentInvoiceError;

