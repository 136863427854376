import { createAction, createReducer } from "@reduxjs/toolkit";

import type { Payer, PaymentMethodId, PaymentOptionId, RxType } from "~/common/constants";

export type TInstructions = {
  rx_type_id: RxType;
  doctor_id: number;
  payer_id: Payer;
  payment_method_id: PaymentMethodId | null;
  clinic_id: number;
  course_id?: number;
  payment_option_id?: PaymentOptionId | null;
  deep_cbct?: boolean | null;
  sber_credit?: boolean;
  condition?: string;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  bdate?: string | null;
  payer_last_name?: string | null;
  payer_first_name?: string | null;
  payer_patronymic?: string | null;
  sex?: number | null;
  test_plastic?: boolean;
  anthropometric_diagnostic?: boolean;
};

const initialState: Readonly<Partial<TInstructions>> = {};

export const createInstructionsSuccess = createAction("CREATE_PATIENT_INSTRUCTION");
export const addInstructions = createAction<typeof initialState>("ADD_PATIENT_INSTRUCTION");
export const removeInstructions = createAction<typeof initialState>("REMOVE_PATIENT_INSTRUCTION");

export const instructions = createReducer(initialState, (builder) => {
  builder.addCase(addInstructions, (state, action) => ({ ...state, ...action.payload }));
  builder.addCase(removeInstructions, (state, action) => {
    for (const key in action.payload) {
      delete state[key as keyof TInstructions];
    }
    return state;
  });
  builder.addCase(createInstructionsSuccess, () => ({}));
  builder.addCase("ERASE_INSTRUCTIONS", () => ({}));
});
