import React from "react";
import { FormattedMessage } from "react-intl";

import { FileType } from "~/common/constants";
import { API_PATIENT_IMAGE } from "~/config";
import type { TPatient, TPatientOptionalMedia } from "~/types/patient";
import type { TUser } from "~/types/user";

import PatientFiles from "../../patient_files";

export function PatientCardUploadedFiles({ patient, user }: { patient: TPatient; user: TUser }) {
  const { optional_media, plan_media } = patient.media;
  const prefix = API_PATIENT_IMAGE(patient.patient_id, "");

  return (
    <form
      id="fileupload extra-media-block"
      method="post"
      encType="multipart/form-data"
      data-upload-template-id="template-upload-2"
      data-download-template-id="template-download-2"
    >
      <div className="form-body">
        <h4 style={{ fontWeight: "500" }} className="block">
          <FormattedMessage id="UPLOADED_FILES" />
        </h4>

        {/* The table listing the files available for upload/download */}
        <table
          role="presentation"
          className="table table-striped clearfix"
          style={{ wordBreak: "break-all" }}
          cellSpacing={0}
          cellPadding={0}
          width="100%"
        >
          <tbody className="files">
            {optional_media.map((m) => (
              <OptionalMedia key={m.linkto} prefix={prefix} media={m} />
            ))}

            {plan_media.map((m) => (
              <OptionalMedia key={m.linkto} prefix={prefix} media={m} />
            ))}
          </tbody>
        </table>

        {user.privileges.has_3shape_scanner ? (
          <div id="3shape-media-block">
            <PatientFiles type={FileType._3SHAPE} />
          </div>
        ) : null}

        <div id="medit-media-block">
          <PatientFiles type={FileType.MEDIT} />
        </div>
      </div>
    </form>
  );
}

function OptionalMedia({ prefix, media }: { prefix: string; media: TPatientOptionalMedia }) {
  const fileName = media.linkto.split("/").slice(-1)[0];

  if (media.thumbnail.match("THUMBNAIL")) {
    return (
      <tr className="template-download fade in">
        <td style={{ width: "40%" }}>
          <div>
            <a
              href={prefix + media.linkto}
              title={media.linkto}
              download={prefix + media.linkto}
              data-gallery
              data-matomo-mask
              data-hj-suppress
            >
              <img
                src={
                  "/img/upload_placeholders/" + media.thumbnail + ".svg" + `?${Number(new Date())}`
                }
                style={{ width: "60%" }}
                alt=""
              />
            </a>
          </div>
        </td>

        <td>
          <p className="name">
            <a
              href={prefix + encodeURIComponent(media.linkto)}
              title={fileName}
              download={prefix + encodeURIComponent(media.linkto)}
              data-gallery
              data-matomo-mask
              data-hj-suppress
            >
              {fileName}
            </a>
          </p>
        </td>

        <td>
          <span className="size">{media.media_size} kB</span>
        </td>
      </tr>
    );
  } else {
    return (
      <tr className="template-download fade in">
        <td>
          <span className="preview">
            <a
              href={prefix + media.linkto}
              title={fileName}
              download={prefix + media.linkto}
              data-gallery
              data-matomo-mask
              data-hj-suppress
            >
              <img src={prefix + media.thumbnail + `?${Number(new Date())}`} alt="" />
            </a>
          </span>
        </td>

        <td>
          <p className="name">
            <a
              href={prefix + media.linkto}
              title={fileName}
              download={prefix + media.linkto}
              data-gallery
              data-matomo-mask
              data-hj-suppress
            >
              {fileName}
            </a>
          </p>
        </td>

        <td>
          <span className="size">{media.media_size} kB</span>
        </td>
      </tr>
    );
  }
}
