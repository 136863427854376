import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import React, { forwardRef } from "react";

const buttonVariants = cva(
  [
    "tw-font-semibold",
    "tw-inline-flex",
    "tw-items-center",
    "tw-justify-center",
    "tw-no-underline",
    "tw-transition-colors",
    "tw-whitespace-nowrap",
    "tw-border-2",
    "tw-border-solid",
    "focus-visible:tw-outline-none",
    "focus-visible:tw-ring-2",
    "focus-visible:tw-ring-blue-600",
    "focus-visible:tw-ring-offset-1",
    "disabled:tw-cursor-not-allowed",
    "disabled:tw-opacity-[.65]",
  ],
  {
    variants: {
      variant: {
        default: [
          "tw-border-primary",
          "tw-bg-primary",
          "tw-text-white",
          "hover:tw-border-[#26a1ab]",
          "hover:tw-bg-[#26a1ab]",
          "active:tw-border-[#26a1ab]",
          "active:tw-bg-[#26a1ab]",
          "disabled:tw-border-[#32c5d2]",
          "disabled:tw-bg-[#32c5d2]",
        ],
        primary: [
          "tw-border-brand-green",
          "tw-bg-brand-green",
          "tw-text-white",
          "hover:tw-border-brand-green-hover",
          "hover:tw-bg-brand-green-hover",
          "active:tw-border-brand-green-hover",
          "active:tw-bg-brand-green-hover",
          "disabled:tw-border-[#32c5d2]",
          "disabled:tw-bg-[#32c5d2]",
        ],
        empty: [],
        outline: [
          "tw-border-[#acb5c3]",
          "tw-bg-white",
          "tw-text-[#acb5c3]",
          "hover:tw-bg-[#acb5c3]",
          "hover:tw-text-[#fafcfb]",
          "active:tw-bg-[#acb5c3]",
          "active:tw-text-[#fafcfb]",
        ],
        secondary: [
          "tw-border-[#efefef]",
          "tw-bg-[#efefef]",
          "tw-text-[#34495e]",
          "hover:tw-border-[#e0e0e0]",
          "hover:tw-bg-[#e0e0e0]",
          "active:tw-border-[#e0e0e0]",
          "active:tw-bg-[#e0e0e0]",
          "disabled:tw-border-[#efefef]",
          "disabled:tw-bg-[#efefef]",
        ],
        blue: [
          "tw-border-[#3598dc]",
          "tw-bg-[#3598dc]",
          "tw-text-white",
          "hover:tw-border-[#217ebd]",
          "hover:tw-bg-[#217ebd]",
          "active:tw-border-[#217ebd]",
          "active:tw-bg-[#217ebd]",
          "disabled:tw-border-[#3598dc]",
          "disabled:tw-bg-[#3598dc]",
        ],
        danger: [
          "tw-border-[#e7505a]",
          "tw-bg-[#e7505a]",
          "tw-text-white",
          "hover:tw-border-[#e12330]",
          "hover:tw-bg-[#e12330]",
          "active:tw-border-[#e12330]",
          "active:tw-bg-[#e12330]",
        ],
      },
      size: {
        default: "tw-px-3.5 tw-py-1.5",
        xl: "tw-px-5 tw-py-2",
        "1.5xl": "tw-px-6 tw-py-2.5",
        "2xl": "tw-px-7 tw-py-3 tw-text-base",
      },
      rounded: {
        true: "tw-rounded-md",
        extra: "tw-rounded-xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & { asChild?: boolean };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { className, variant, size, rounded, asChild = false, ...props },
  forwardedRef,
) {
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      ref={forwardedRef}
      className={buttonVariants({ variant, size, rounded, className })}
      {...props}
    />
  );
});
