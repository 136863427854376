import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import { Field, formValueSelector, getFormValues, reduxForm } from "redux-form";

import { isChildrenCourse, isRetainersCourse } from '~/common/courses';
import { initializeBaseInstruction } from "~/common/instructions";
import { remoteLog } from "~/common/logging";
import { INITIAL_VALUES_PRESCRIPTION } from '~/common/prescription';
import { PortletTerm } from '~/components/ui/portlet';
import type { RootState } from '~/store';
import type { TCourse } from "~/types/patient";
import type { TPrescription } from '~/types/prescription';

import PlanCorrectionTeethCheck from '../3d_plan_correction/3d_plan_correction_teeth_check';
import { RecipeCanines } from './recipe/recipe-canines';
import { RecipeCloseAllGaps } from './recipe/recipe-close-all-gaps';
import { RecipeElastics } from './recipe/recipe-elastics';
import { RecipeIncisors } from './recipe/recipe-incisors';
import { RecipeMolars, RecipeMolarsChildren } from './recipe/recipe-molars';
import { RecipeTreatArches } from './recipe/recipe-treat-arches';

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    comments: state.comments,
    media: state.media,
    media_s3: state.media_s3,
    instructions: state.instructions,
    formValues: getFormValues('correction')(state) as TPrescription
  };
};

type RecipeFormSmileProps = PropsFromRedux & {
  intl: IntlShape;
  course_id: TCourse["course_id"] | null | undefined;
};

class RecipeFormSmile extends Component<RecipeFormSmileProps> {
  constructor(props: RecipeFormSmileProps) {
    super(props);
    this.state = {
      checked: true
    }
    this.resetForm = this.resetForm.bind(this);
    this.resetElasticsOptionsRight = this.resetElasticsOptionsRight.bind(this);
    this.resetElasticsOptionsLeft = this.resetElasticsOptionsLeft.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_correction_form");
  }

  resetForm(...arg: string[]) {
    arg.forEach(field => this.props.change(field, false))
  }

  resetElasticsOptionsLeft(e: React.ChangeEvent<HTMLInputElement>, obj: string) {
    const elastics_left = this.props.formValues.elastics_options
    if (e.target.checked === false) {
      for (let key in elastics_left) {
        if (key.startsWith('2')) {
          delete elastics_left[key]
        } else if (key.startsWith('3')) {
          delete elastics_left[key]
        }
      }
      this.setState({ checked: false })
      return elastics_left
    }
    this.props.change(obj, elastics_left)
  }

  resetElasticsOptionsRight(e: React.ChangeEvent<HTMLInputElement>, obj: string) {
    const elastics_right = this.props.formValues.elastics_options
    if (e.target.checked === false) {
      for (let key in elastics_right) {
        if (key.startsWith('1')) {
          delete elastics_right[key]
        } else if (key.startsWith('4')) {
          delete elastics_right[key]
        }
      }
      return elastics_right
    }
    this.props.change(obj, elastics_right)
  }

  render() {
    const {
      change, upper_arch_action, lower_arch_action, close_all_gaps,
      midline, elastics_recoil_right, elastics_recoil_left,
      vertical_overlap, occlusal_plane, occlusal_plane_action } = this.props;
    const course_id = this.props.course_id;
    const elastics_options = this.props.patient && Object.keys(this.props.patient).length > 0
      && this.props.patient.course && this.props.patient.course.correction
      && this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription
      ? Object.keys(this.props.patient.course.correction[this.props.patient.course.correction.length - 1]
        .prescription.elastics_options).map(i => parseInt(i)) : [];

    return (
      <div>
        <RecipeTreatArches
          course_id={course_id}
          upper_arch_action={upper_arch_action}
          lower_arch_action={lower_arch_action}
          occlusal_plane={occlusal_plane}
          occlusal_plane_action={occlusal_plane_action}
          resetForm={this.resetForm}
          change={change}
        />

        {isRetainersCourse(course_id) ? null : (
          <RecipeIncisors
            course_id={course_id}
            vertical_overlap={vertical_overlap}
            midline={midline}
          />
        )}

        {isRetainersCourse(course_id) || isChildrenCourse(course_id) ? null : <RecipeCanines />}
        {isRetainersCourse(course_id) || isChildrenCourse(course_id) ? null : <RecipeMolars />}
        {isChildrenCourse(course_id) ? <RecipeMolarsChildren /> : null}

        {isRetainersCourse(course_id) || isChildrenCourse(course_id) ? null : (
          <RecipeCloseAllGaps close_all_gaps={close_all_gaps} change={change} />
        )}

        {isRetainersCourse(course_id) || isChildrenCourse(course_id) ? null : (
          <RecipeElastics
            elastics_recoil_right={elastics_recoil_right}
            elastics_recoil_left={elastics_recoil_left}
            elastics_options={elastics_options}
            resetElasticsOptionsRight={this.resetElasticsOptionsRight}
            resetElasticsOptionsLeft={this.resetElasticsOptionsLeft}
            resetForm={this.resetForm}
          />
        )}

        {isChildrenCourse(course_id) ?
          <div>
            <PortletTerm id="EXTRA_TEETHING">
              <FormattedMessage id="EXTRA_TEETHING" />
            </PortletTerm>

            <PlanCorrectionTeethCheck name="teething" ariaLabelledBy="EXTRA_TEETHING" />
          </div>
        : null}

        {isChildrenCourse(course_id) || isRetainersCourse(course_id) ? null : (
          <div>
            <PortletTerm id="EXTRA_NOMOVE">
              <FormattedMessage id="EXTRA_NOMOVE" />
            </PortletTerm>

            <PlanCorrectionTeethCheck name="dont_move" ariaLabelledBy="EXTRA_NOMOVE"  />

            <PortletTerm id="EXTRA_NOLOCK">
              <FormattedMessage id="EXTRA_NOLOCK" />
            </PortletTerm>

            <PlanCorrectionTeethCheck name="avoid_locks" ariaLabelledBy="EXTRA_NOLOCK" />

            <PortletTerm id="EXTRA_EXTRACT">
              <FormattedMessage id="EXTRA_EXTRACT" />
            </PortletTerm>

            <PlanCorrectionTeethCheck name="extractions" ariaLabelledBy="EXTRA_EXTRACT" />
          </div>
        )}

        <div className="form-group">
          <label
            htmlFor="correction-add-comment"
            className="control-label"
            id="correction-add-comment-label"
            style={{ fontWeight: '900' }}
          >
            <FormattedMessage id="EXTRA_COMMENTS" />
          </label>

          <Field
            id="correction-add-comment"
            data-matomo-mask
            component="textarea"
            className="form-control"
            rows={5}
            name="comment"
            format={v => v ? v : ""}
          />
        </div>
      </div>
    )
  }
}

RecipeFormSmile = reduxForm({
  form: 'correction',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(RecipeFormSmile);

const selector = formValueSelector('correction')

RecipeFormSmile = connect(
  (state) => {
    const upper_arch_action = selector(state, 'upper_arch_action')
    const lower_arch_action = selector(state, 'lower_arch_action')
    const impressions = selector(state, 'impressions')
    const close_all_gaps = selector(state, 'close_all_gaps')
    const elastics_recoil_right = selector(state, 'elastics_recoil_right')
    const elastics_recoil_left = selector(state, 'elastics_recoil_left')
    const close_all_gaps_value = selector(state, 'close_all_gaps_value')
    const upper_arch_expansion_torque = selector(state, 'upper_arch_expansion_torque')
    const upper_arch_expansion_bodily = selector(state, 'upper_arch_expansion_bodily')
    const information_correct = selector(state, 'information_correct')
    const midline = selector(state, 'midline')
    const vertical_overlap = selector(state, 'vertical_overlap')
    const overjet = selector(state, 'overjet')
    const molars_ipr = selector(state, 'molars_ipr')
    const molars_method = selector(state, 'molars_method')
    const occlusal_plane = selector(state, 'occlusal_plane');
    const occlusal_plane_action = selector(state, 'occlusal_plane_action');

    return {
      upper_arch_action,
      lower_arch_action,
      impressions,
      vertical_overlap,
      close_all_gaps,
      elastics_recoil_right,
      elastics_recoil_left,
      close_all_gaps_value,
      upper_arch_expansion_bodily,
      upper_arch_expansion_torque,
      information_correct,
      midline,
      overjet,
      molars_method,
      molars_ipr,
      occlusal_plane,
      occlusal_plane_action,
      initialValues: (
        state.patient && state.patient.course
        && state.patient.course.correction[state.patient.course.correction.length - 1].prescription
        && state.patient.course.correction[state.patient.course.correction.length - 1].order_options.can_order_correction === false
      ) ? initializeBaseInstruction(state.patient.course.correction[state.patient.course.correction.length - 1].prescription)
        : INITIAL_VALUES_PRESCRIPTION
    }
  }
)(RecipeFormSmile)

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(RecipeFormSmile));
